import { Component, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder,Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Item } from 'src/app/Models/Item';
import { MemberFormField, MembersForm } from 'src/app/Models/MembersForm';
import { PolicyQuotesComponent } from '../policy-quotes/policy-quotes.component';
import * as moment from 'moment';
import { GeneralService } from 'src/app/Services/general.service';
import { LookUpService } from 'src/app/Services/lookup.service';



@Component({
  selector: 'app-travelinsurance-policy',
  templateUrl: './travelinsurance-policy.component.html',
  styleUrls: ['./travelinsurance-policy.component.css']
})
export class TravelinsurancePolicyComponent { 
  travelForm: FormGroup;
  travling:FormGroup;
  travelPlace: string | null = null;
  isTravelPlaceTouched = false;
  sidemenue:boolean=false;
  dropdownList = [];
  selectedCountries="";
  cities: string[] = [];
  showCities: boolean = false;
  isFamily: boolean = true;
  isGroup: boolean = false;
  isStudent: boolean = false;
  isSelf: boolean = false;
  isFather: boolean = false;
  isStudent1: boolean = false;
  isStudent2: boolean = false;
  isStudent3: boolean = false;
  isStudent4: boolean = false;
  isStudent5: boolean = false;
  isStudent6: boolean = false;
  isMember1: boolean = false;
  isMember2: boolean = false;
  isMember3: boolean = false;
  isMember4: boolean = false;
  isMember5: boolean = false;
  isMember6: boolean = false;
  selectSelf: boolean = false;
  selectFather: boolean = false;
  selectAdd: boolean = false;
  selectAdd1: boolean = false;
  selectAdd2: boolean = false;
  selectStudent1: boolean = false;
  selectStudent2: boolean = false;
  selectStudent3: boolean = false;
  selectStudent4: boolean = false;
  selectStudent5: boolean = false;
  selectStudent6: boolean = false;
  selectMember1: boolean = false;
  selectMember2: boolean = false;
  selectMember3: boolean = false;
  selectMember4: boolean = false;
  selectMember5: boolean = false;
  selectMember6: boolean = false;
 
  self:boolean=true;
  father:boolean=true;
  std1:boolean=true;
  std2:boolean=true;
  mbr1:boolean=true;
  mbr2:boolean=true;
  selfAge:boolean=false;
  fatherAge:boolean=false;
  student1Age:boolean=false;
  student2Age:boolean=false;
  member1Age:boolean=false;
  member2Age:boolean=false;
  selectAge:any=[];
 
  selectedTab:number = 1;
  isAdd:boolean=false;
  showAddMinus:boolean=false;
  showAddMinus1:boolean=false;
  showAddMinus2:boolean=false;
  
  showMember:boolean=false;
  showmbr:boolean=false;
  showStudent:boolean=false;
  countryItems: Item[] = [];
  members:any = [];
  destinationList:Item[] = [];
  showDestinationError:boolean = false;

  familyMembersCount:number[]=[0,1,2,3,4,5,6];  
  businessMembersCount:number[]=[0,1,2,3,4,5,6,7,8,9,10];
  studentsCount:number[]=[0,1,2,3,4,5,6,7,8,9,10];
  required_input_fields_business:MembersForm[] = [];
  required_input_fields_family:MembersForm[] = [];
  required_input_fields_student:MembersForm[] = [];
  required_input_fields_disease:MembersForm[] = [];
  isTravelPage:boolean=true;
  tripDuration:number = 0;
  isTravelStartDateGTEndDate:boolean = false;
  familyMembersCountSelected:number = 0;  
  businessMembersCountSelected:number = 0;
  studentsCountSelected:number = 0;
  travelPurpose ="Leisure";
  @ViewChild(PolicyQuotesComponent) PolicyQuotesComponent: PolicyQuotesComponent;
  constructor(private fb: FormBuilder,private rout:Router, private generalService : GeneralService, private lookUpService : LookUpService) {

    this.travelForm = this.fb.group({
      "planFor" : [''],
      "userId": [0],
      "isAgent": [false],
      "destination": [''],      
      "travelPurpose": [''],
      "travelStartDate": [''],
      "travelEndDate": [''],
      "travelMultipleTimesInAYear": [false],
      "memberHasMedicalCondition": ['No'],
      "tripFrequencyInDays": [30],
      "url": [''],
      "filter": [''],
      "royalCalculatePremiumRequest": [''],      
      "futureGeneraliRequest": [''],
      "members": [],
      "membersWithCondition": [],
      "dynamicForm":[],
      "proposer":[],
      "requestId":['']
    });
    
    this.destinationList = [
      { id: 'Thailand:Asia', name: 'Thailand', checked: false },
      { id: 'Malaysia:Asia', name: 'Malaysia', checked: false }]; 
      
      this.GetTravelRelationship();
      this.GetTravelNomineeRelationship();
  }

  travelRelationshipList:string[]=[];
  travelNomineeRelationshipList:string[]=[];
  async GetTravelRelationship(){
    const res = await this.lookUpService.GetTravelRelationship();
    if (res != null && res.result) {
      this.travelRelationshipList = res.result.map((item:any)=> {return item.text});
    }
  }
  async GetTravelNomineeRelationship(){
    const res = await this.lookUpService.GetTravelNomineeRelationship();
    if (res != null && res.result) {
      this.travelNomineeRelationshipList = res.result.map((item:any)=> {return item.text});
    }
  }

  images: any[] = [ 
    './assets/partners/new/digit.png', 
    './assets/partners/new/sompo.png',
    './assets/partners/new/shriram.png',
    './assets/partners/new/reliance.png'
  ]
  images1: any[] = [
    './assets/partners/new/icici.png',
    './assets/partners/new/oriental.png',
    './assets/partners/new/bajaj.png',
    './assets/partners/new/national.png'
  ]  
  showAllImages = false;
  openItemIndex = -1;
  toggleAnswer(index: number): void {
    if (this.openItemIndex === index) {
      this.openItemIndex = -1; // Clicking the open item again should close it
    } else {
      this.openItemIndex = index; // Open the clicked item
    }
  }
  text ='';
  type =0;
  getValue(type: number) {
    this.type = type;
    if (type == 1) {
      this.text = 'Covers the full cost of repairs or replacements without accounting for depreciation, ensuring you receive the maximum claim amount.';
    }
    else if (type == 2) {
      this.text = 'Consumable cover reimburses the cost of items like engine oil, nuts, bolts, and other consumables used during bike repairs.';
    }
    else if (type == 3) {
      this.text = 'Return to Invoice cover reimburses the difference between your bike’s insured declared value and its original invoice value in case of total loss or theft.';
    }
    else if (type == 4) {
      this.text = 'Roadside assistance provides emergency support for issues like breakdowns, flat tires, or fuel shortages, ensuring help is available wherever you are.';
    }
    else if (type == 5) {
      this.text = 'Engine and gearbox protection covers repair or replacement costs for damage caused to the engine and transmission system due to issues like water ingress or mechanical failure.';
    }
    else if (type == 6) {
      this.text = 'Daily allowance provides a fixed amount of compensation for each day your bike is under repair at a garage, helping to cover alternative transportation costs.';
    }

  }


  ngOnInit(){
    this.getValue(1);
    this.getselfAge();
  this.countryItems = [
    { id: '1', name: 'India', checked: false },
    { id: '2', name: 'USA', checked: false },
    { id: '3', name: 'Africa', checked: false },
    { id: '4', name: 'Japan', checked: false },
    { id: '5', name: 'Australia', checked: false },
    { id: '6', name: 'Ingland', checked: false },
    { id: '7', name: 'Africa', checked: false },
    { id: '8', name: 'China', checked: false },
    { id: '9', name: 'Korea', checked: false }

  ];
 }
 async getselfAge() {
  this.selectAge = [];
  for (var i = 18; i <= 100; i++) {
    this.selectAge.push(i)
  }
}

get travelFormControl() {
  return this.travelForm.controls;
}

isTrevel()
{
  if(this.travelFormControl["destination"].value != ""){
    this.sidemenue=true;
    this.showDestinationError = false;
  } 
  else{
    this.showDestinationError = true;
  } 
}

  close(){
    this.sidemenue=false;
  }

  goto(tab:any){
    this.isFamily= tab == 'self'; 
    this.isGroup= tab == 'group';
    this.isStudent = tab == 'student'; 
  }

  selectedCard(value:any){
    if (value == 'self') {
      this.isSelf = !this.isSelf;
      this.selectSelf = !this.selectSelf;
      this.selfAge = !this.selfAge;
      
    }else  if (value == 'father') {
      this.isFather = !this.isFather;
      this.selectFather = !this.selectFather;
      this.fatherAge = !this.fatherAge;
      
    }else  if (value == 'addMember') {
     
      this.isAdd = !this.isAdd;
      this.selectAdd = !this.selectAdd;
      this.showAddMinus = !this.showAddMinus;
      this.showmbr=!this.showmbr;

    }

  }
  studentCard(value:any){
   if(value=='student1'){
      this.student1Age=!this.student1Age
      this.selectStudent1=!this.selectStudent1
    } else if(value=='student2'){
      this.student2Age=!this.student2Age
      this.selectStudent2=!this.selectStudent2
    } else if(value=='addstudent'){
      this.showAddMinus1 = !this.showAddMinus1;
      this.showStudent=!this.showStudent
      this.selectAdd2 = !this.selectAdd2;
    } 


  }
  membercard(value:any){

    if(value=='member1'){
       this.member1Age=!this.member1Age
       this.selectMember1=!this.selectMember1
     } else if(value=='member2'){
       this.member2Age=!this.member2Age
       this.selectMember2=!this.selectMember2
     } else if(value=='addmember'){
       this.showAddMinus2 = !this.showAddMinus2;
       this.showMember=!this.showMember
       this.selectAdd1 = !this.selectAdd1;
     } 
 
   }

  mbrsCount:any=1;
  counterFunction(type:any){
    
    if(type=='decrement'){
      if(this.mbrsCount>=2){
     this.mbrsCount--;
      }
    }else if(type=='increment'){
      if(this.mbrsCount<=3){
        this.mbrsCount++;
      }
      
    }

  }

  membersCount:any=1
  counterFunction2(type:any)
{
  if(type=='decrement'){
    if(this.membersCount>=2){
      this.membersCount--;
      }
    
  } else if(type=='increment'){
    if(this.membersCount<=2){
      this.membersCount++;
    }
  }
}

//   studentsCount:any=1
//  counterFunction3(type:any)
// {
// if(type=='decrement'){
//   if(this.studentsCount>=2){
//     this.studentsCount--;
//      }
  
// } else if(type=='increment'){
//   if(this.studentsCount<=2){
//     this.studentsCount++;
//   }
// }
// }
Page(page:number)
{  
  if(page==1)
  {
    this.selectedTab = page;
    //this.showHideMembersDetails(page);
  }
  if(page==2 && this.isDynamicFormIsValid())
  {
    this.selectedTab = page;
  }
  if(page==3 && this.travelFormControl['travelStartDate'].value != "" && this.travelFormControl['travelEndDate'].value != "")
  {
    this.selectedTab = page;
    this.showHideMembersDetails(page);
  }
}
getQuotes(){
  this.formatFinalObject();
  this.isTravelPage = false;
  if(this.travelForm.value.filter == null || this.travelForm.value.filter == undefined || this.travelForm.value.filter == "")
    {
      this.travelForm.get("filter")?.setValue({});
    }
    let requestId = moment(new Date()).format("YYYYMMDDHHmmssSSSSSS");
    this.travelForm.get("requestId")?.setValue(requestId);    
    this.generalService.setDataToLocalStorage('quoteDataTravel',JSON.stringify ({memberDetails:this.travelForm.value,quoteList:null,quote:null}));  
  
  //this.PolicyQuotesComponent.getQuotes(this.travelForm.value);
  //this.rout.navigate(['/travel-policy']); 
  this.rout.navigate(['/travel-policy',{q:requestId}]); 
}

bindMembers(event:any,cat:string){  
this.travelForm.get("planFor")?.setValue(cat);
switch(this.travelForm.get("planFor")?.value){
  case "business" : this.required_input_fields_business = this.getMembersList(parseInt(event.target.value),cat); break;
  case "family" : this.required_input_fields_family = this.getMembersList(parseInt(event.target.value),cat); break;
  case "student" : this.required_input_fields_student = this.getMembersList(parseInt(event.target.value),cat); break;
}
// if(cat == "business"){
//   this.required_input_fields_business =this.getMembersList(parseInt(event.target.value),cat);
// }
// else if(cat == "family"){
//   this.required_input_fields_family = this.getMembersList(parseInt(event.target.value),cat);
// }
// else if(cat == "student"){
//   this.required_input_fields_student = this.getMembersList(parseInt(event.target.value),cat);
//   }  
}

onDestinationSelect(data:Item[]){
  this.travelForm.get("destination")?.setValue(data.map((m:Item)=>{return m.id}).toString()); 

  if(this.travelFormControl["destination"].value != ""){
    this.showDestinationError = false;
  } 
  else
  {
    this.showDestinationError = true;
  }
   
}
isProposerArray:any=[];
getSavedData(data:any,cat:string){  
  if(data){
    let chekcedFor = "";

    if(cat == "business"){
      for(let i=0;i<this.required_input_fields_business.length;i++){
        if(data.for == this.required_input_fields_business[i].for){
          this.required_input_fields_business[i] = data.data;
          let chekcedForArrya = data.data.memberFormFields.filter((f:MemberFormField)=> {return f.id?.startsWith("IsSelfProposer") && f.selectedValue?.toUpperCase() == "YES"})?.map((m:MemberFormField) => {return m.id});
          chekcedFor = chekcedForArrya?.length > 0 ? chekcedForArrya[0] : "";
        }
      }

      if(chekcedFor != ""){
        this.required_input_fields_business.forEach((mem:MembersForm) => {
          mem?.memberFormFields?.forEach((field: any) => {
            if (field.id === chekcedFor) {
              field.selectedValue = "Yes";
            } else if (field.id.startsWith("IsSelfProposer_")) {
              field.selectedValue = "No";
            }
          });
        });
      }
      this.travelForm.get("dynamicForm")?.setValue(this.required_input_fields_business);
    }
    else if(cat == "family"){
      for(let i=0;i<this.required_input_fields_family.length;i++){
        if(data.for == this.required_input_fields_family[i].for){
          this.required_input_fields_family[i] = data.data;
          let chekcedForArrya = data.data.memberFormFields.filter((f:MemberFormField)=> {return f.id?.startsWith("IsSelfProposer") && f.selectedValue?.toUpperCase() == "YES"})?.map((m:MemberFormField) => {return m.id});
          chekcedFor = chekcedForArrya?.length > 0 ? chekcedForArrya[0] : "";
        }
      }

      if(chekcedFor != ""){
        this.required_input_fields_family.forEach((mem:MembersForm) => {
          mem?.memberFormFields?.forEach((field: any) => {
            if (field.id === chekcedFor) {
              field.selectedValue = "Yes";
            } else if (field.id.startsWith("IsSelfProposer_")) {
              field.selectedValue = "No";
            }
          });
        });
      }
      this.travelForm.get("dynamicForm")?.setValue(this.required_input_fields_family);
    }
    else if(cat == "student"){
      for(let i=0;i<this.required_input_fields_student.length;i++){
        if(data.for == this.required_input_fields_student[i].for){
          this.required_input_fields_student[i] = data.data;
          let chekcedForArrya = data.data.memberFormFields.filter((f:MemberFormField)=> {return f.id?.startsWith("IsSelfProposer") && f.selectedValue?.toUpperCase() == "YES"})?.map((m:MemberFormField) => {return m.id});
          chekcedFor = chekcedForArrya?.length > 0 ? chekcedForArrya[0] : "";
        }
      }
      if(chekcedFor != ""){
        this.required_input_fields_student.forEach((mem:MembersForm) => {
          mem?.memberFormFields?.forEach((field: any) => {
            if (field.id === chekcedFor) {
              field.selectedValue = "Yes";
            } else if (field.id.startsWith("IsSelfProposer_")) {
              field.selectedValue = "No";
            }
          });
        });
      }
      this.travelForm.get("dynamicForm")?.setValue(this.required_input_fields_student);
    }    
    console.log(this.travelForm.get("dynamicForm")?.value);
  }
  this.isDynamicFormIsValid();
}

showHideMembersDetails(tab:number){
  let memebrsList:MembersForm[] =  JSON.parse(JSON.stringify(this.travelForm.get("dynamicForm")?.value)); 
  let Proposer:any = undefined;
  let member1st:any = undefined;
  for(let i =0;i<memebrsList.length;i++){
    let frm = memebrsList[i];
    if(i == 0){
      member1st = frm;
    }
    if(frm != undefined && frm.memberFormFields != undefined && frm.memberFormFields.length > 0){
      for(let j =0;j<frm.memberFormFields.length;j++){
        if(frm.memberFormFields != undefined){
          if(frm.memberFormFields[j].id?.startsWith('Title') ||frm.memberFormFields[j].id?.startsWith('Firstname') || frm.memberFormFields[j].id?.startsWith('Lastname') || frm.memberFormFields[j].id?.startsWith('Disease'))
          {
            frm.memberFormFields[j].isVisible = true;
          }
          else
          {
            frm.memberFormFields[j].isVisible = false;
          }

        if(frm.memberFormFields[j].id?.startsWith('IsSelfProposer') && frm.memberFormFields[j].selectedValue == "Yes"){
          Proposer = frm;
        }
          
        }
      }
    }    
  };
  if(Proposer != undefined){
    this.travelForm.get("proposer")?.setValue(this.getValueFromObject(Proposer));
  }
  else
  {
    this.travelForm.get("proposer")?.setValue(this.getValueFromObject(member1st)); 
  }
  this.required_input_fields_disease = memebrsList;
}

formatFinalObject(){
  let memebrsList:MembersForm[] =  this.travelForm.get("dynamicForm")?.value;
  let objArray=[];
  for(let i =0;i<memebrsList.length;i++){
    objArray.push(this.getValueFromObject(memebrsList[i]));
  }
  this.travelForm.get("membersWithCondition")?.setValue(objArray);
  this.travelForm.get("members")?.setValue(objArray);
}

validateForm2():boolean{
  let isValid=false;
  isValid = this.travelFormControl['travelStartDate'].value !='' && this.travelFormControl['travelEndDate'].value !='' && this.tripDuration > 0 ;
  return isValid;
}

isDynamicFormIsValid():boolean{
   let count = 0; 
    let memebrsList:MembersForm[] =  [];//JSON.parse(JSON.stringify(this.travelForm.get("dynamicForm")?.value)); 
    switch(this.travelForm.get("planFor")?.value){
      case "business" : memebrsList = this.required_input_fields_business; break;
      case "family" : memebrsList = this.required_input_fields_family; break;
      case "student" : memebrsList = this.required_input_fields_student; break;
    } 
    for(let i =0;i<(memebrsList?.length??0);i++){
      let frm = memebrsList[i];
      if(frm != undefined && frm.memberFormFields != undefined && frm.memberFormFields.length > 0){
        for(let j =0;j<frm.memberFormFields.length;j++){
          if(frm.memberFormFields != undefined){
            console.log('visible :' + frm.memberFormFields[j].isVisible);
            console.log('isRequired :' + frm.memberFormFields[j].isRequired);
            console.log('selectedValue :' + frm.memberFormFields[j].selectedValue);
            let isValid:boolean = true;
            if(frm.memberFormFields[j].isVisible == true && (frm.memberFormFields[j].isRequired == true && frm.memberFormFields[j].selectedValue == "" || 
              ((frm.memberFormFields[j].minLength??0) > 0 && (frm.memberFormFields[j].minLength??0) > 0 && ((frm.memberFormFields[j].selectedValue?.length??0) < (frm.memberFormFields[j].minLength??0) || (frm.memberFormFields[j].selectedValue?.length??0) > (frm.memberFormFields[j].maxLength??0))) ||
              ((frm.memberFormFields[j].pattern??"") != "" && !frm.memberFormFields[j].selectedValue?.match(frm.memberFormFields[j].pattern??""))) 
            ){
              count ++;
              isValid = false;
            } 
            frm.memberFormFields[j].isValid = isValid;        
          }
        }
      }    
    }
    this.travelForm.get("dynamicForm")?.setValue(memebrsList);
    count = memebrsList == null ? 1 : count;
    return count == 0;
  }

  getValueFromObject(frm:MembersForm){
    let obj ={
      "id": 1,
      "title":"",
      "firstname": "",
      "lastname": "",
      "email": "",
      "age": 0,
      "dob":"",
      "gender": "",
      "hasIllnessInjuryHealthCondition": false,
      "disease": "",
      "relation":"",
      "pancard":"",
      "passport":"",
      "isSelfProposer":"",
      "nomineeFirstname":"",
      "nomineeLastname":"",
      "nomineeRelation":"",
      "nomineeDOB":"",
      "guardianFirstname":"",
      "guardianLastname":"",
      "guardianRelation":"",
      "guardianDOB":"",
      "addressLine1":"",
      "addressLine2":"",
      "addressPincode":"",
      "addressCity":"",
      "addressState":"",
      "universityName" : "",
      "universityCourseName" : "",
      "universityAddress1" : "",
      "universityAddress2" : "",
      "universityCity" : "",
      "universityState" : "",
      "universityCountry" : "",
      "universityTuitionFeePerMonth" : "",
      "sponsorName" : "",
      "sponsorRelationshipToInsured" : "",
      "sponsorAddress1" : "",
      "sponsorAddress2" : "",
      "sponsorAddress3" : "",
      "sponsorCity" : "",
      "sponsorState" : "",
      "sponsorCountry" : "",
      "sponsorDateofBirth" : "",
      "sponsorPhoneNumber" : ""
    }
    if(frm != undefined && frm.memberFormFields != undefined && frm.memberFormFields.length > 0){      
      for(let j =0;j<frm.memberFormFields.length;j++){
        if(frm.memberFormFields != undefined){
          if(frm.memberFormFields[j].id?.startsWith('Title'))
          {
            obj.title = frm.memberFormFields[j].selectedValue ??"";
          }
          if(frm.memberFormFields[j].id?.startsWith('Email'))
          {
            obj.email = frm.memberFormFields[j].selectedValue ??"";
          }
          else if(frm.memberFormFields[j].id?.startsWith('Firstname'))
          {
            obj.firstname = frm.memberFormFields[j].selectedValue ??"";
          }
          else if(frm.memberFormFields[j].id?.startsWith('Lastname'))
          {
            obj.lastname = frm.memberFormFields[j].selectedValue ??"";
          }
          else if(frm.memberFormFields[j].id?.startsWith('dob'))
          {
            obj.dob = frm.memberFormFields[j].selectedValue ??"";
          }
          else if(frm.memberFormFields[j].id?.startsWith('Relation'))
          {
            obj.relation = frm.memberFormFields[j].selectedValue ??"";
          }
          else if(frm.memberFormFields[j].id?.startsWith('Gender'))
          {
            obj.gender = frm.memberFormFields[j].selectedValue ??"";
          }
          else if(frm.memberFormFields[j].id?.startsWith('Age'))
          {
            obj.age = parseInt(frm.memberFormFields[j].selectedValue == undefined || (frm.memberFormFields[j].selectedValue??"") == "" ? "0" : frm.memberFormFields[j].selectedValue??"0" );
          }
          else if(frm.memberFormFields[j].id?.startsWith('Disease'))
          {
            obj.disease = frm.memberFormFields[j].selectedValue ??"";
            obj.hasIllnessInjuryHealthCondition = ((frm.memberFormFields[j].selectedValue??"") == "" || (frm.memberFormFields[j].selectedValue ??"") =="Select Disease") ? false : true;
          }
          else if(frm.memberFormFields[j].id?.startsWith('Pancard'))
          {
            obj.pancard = frm.memberFormFields[j].selectedValue ??"";
          }
          else if(frm.memberFormFields[j].id?.startsWith('Passport'))
          {
            obj.passport = frm.memberFormFields[j].selectedValue ??"";
          }
          else if(frm.memberFormFields[j].id?.startsWith('IsSelfProposer'))
          {
            obj.isSelfProposer = frm.memberFormFields[j].selectedValue ??"";
          }
          else if(frm.memberFormFields[j].id?.startsWith('NomineeFirstname'))
          {
            obj.nomineeFirstname = frm.memberFormFields[j].selectedValue ??"";
          }
          else if(frm.memberFormFields[j].id?.startsWith('NomineeLastname'))
          {
            obj.nomineeLastname = frm.memberFormFields[j].selectedValue ??"";
          }
          else if(frm.memberFormFields[j].id?.startsWith('NomineeRelation'))
          {
            obj.nomineeRelation = frm.memberFormFields[j].selectedValue ??"";
          }
          else if(frm.memberFormFields[j].id?.startsWith('Nomineedob'))
          {
            obj.nomineeDOB = frm.memberFormFields[j].selectedValue ??"";
          }
          else if(frm.memberFormFields[j].id?.startsWith('GuardianFirstname'))
          {
            obj.guardianFirstname = frm.memberFormFields[j].selectedValue ??"";
          }
          else if(frm.memberFormFields[j].id?.startsWith('GuardianLastname'))
          {
            obj.guardianLastname = frm.memberFormFields[j].selectedValue ??"";
          }
          else if(frm.memberFormFields[j].id?.startsWith('GuardianRelation'))
          {
            obj.guardianRelation = frm.memberFormFields[j].selectedValue ??"";
          }
          else if(frm.memberFormFields[j].id?.startsWith('Guardiandob'))
          {
            obj.guardianDOB = frm.memberFormFields[j].selectedValue ??"";
          }
          else if(frm.memberFormFields[j].id?.startsWith('AddressLine1'))
          {
            obj.addressLine1 = frm.memberFormFields[j].selectedValue ??"";
          }
          else if(frm.memberFormFields[j].id?.startsWith('AddressLine2'))
          {
            obj.addressLine2 = frm.memberFormFields[j].selectedValue ??"";
          }
          else if(frm.memberFormFields[j].id?.startsWith('AddressPincode'))
          {
            obj.addressPincode = frm.memberFormFields[j].selectedValue ??"";
          }
          else if(frm.memberFormFields[j].id?.startsWith('AddressCity'))
          {
            obj.addressCity = frm.memberFormFields[j].selectedValue ??"";
          }
          else if(frm.memberFormFields[j].id?.startsWith('AddressState'))
          {
            obj.addressState = frm.memberFormFields[j].selectedValue ??"";
          }
          else if(frm.memberFormFields[j].id?.startsWith('UniversityName'))
          {
            obj.universityName = frm.memberFormFields[j].selectedValue ??"";
          }
          else if(frm.memberFormFields[j].id?.startsWith('UniversityCourseName'))
          {
            obj.universityCourseName = frm.memberFormFields[j].selectedValue ??"";
          }
          else if(frm.memberFormFields[j].id?.startsWith('UniversityAddress1'))
          {
            obj.universityAddress1 = frm.memberFormFields[j].selectedValue ??"";
          }
          else if(frm.memberFormFields[j].id?.startsWith('UniversityAddress2'))
          {
            obj.universityAddress2 = frm.memberFormFields[j].selectedValue ??"";
          }
          else if(frm.memberFormFields[j].id?.startsWith('UniversityCity'))
          {
            obj.universityCity = frm.memberFormFields[j].selectedValue ??"";
          }
          else if(frm.memberFormFields[j].id?.startsWith('UniversityState'))
          {
            obj.universityState = frm.memberFormFields[j].selectedValue ??"";
          }
          else if(frm.memberFormFields[j].id?.startsWith('UniversityCountry'))
          {
            obj.universityCountry = frm.memberFormFields[j].selectedValue ??"";
          }
          else if(frm.memberFormFields[j].id?.startsWith('UniversityTuitionFeePerMonth'))
          {
            obj.universityTuitionFeePerMonth = (frm.memberFormFields[j].selectedValue ??"").toString();
          }
          else if(frm.memberFormFields[j].id?.startsWith('SponsorName'))
          {
            obj.sponsorName = frm.memberFormFields[j].selectedValue ??"";
          }
          else if(frm.memberFormFields[j].id?.startsWith('SponsorRelationshipToInsured'))
          {
            obj.sponsorRelationshipToInsured = frm.memberFormFields[j].selectedValue ??"";
          }
          else if(frm.memberFormFields[j].id?.startsWith('SponsorAddress1'))
          {
            obj.sponsorAddress1 = frm.memberFormFields[j].selectedValue ??"";
          }
          else if(frm.memberFormFields[j].id?.startsWith('SponsorAddress2'))
          {
            obj.sponsorAddress2 = frm.memberFormFields[j].selectedValue ??"";
          }
          else if(frm.memberFormFields[j].id?.startsWith('SponsorAddress3'))
          {
            obj.sponsorAddress3 = frm.memberFormFields[j].selectedValue ??"";
          }
          else if(frm.memberFormFields[j].id?.startsWith('SponsorCity'))
          {
            obj.sponsorCity = frm.memberFormFields[j].selectedValue ??"";
          }
          else if(frm.memberFormFields[j].id?.startsWith('SponsorState'))
          {
            obj.sponsorState = frm.memberFormFields[j].selectedValue ??"";
          }
          else if(frm.memberFormFields[j].id?.startsWith('SponsorCountry'))
          {
            obj.sponsorCountry = frm.memberFormFields[j].selectedValue ??"";
          }
          else if(frm.memberFormFields[j].id?.startsWith('SponsorDateofBirth'))
          {
            obj.sponsorDateofBirth = frm.memberFormFields[j].selectedValue ??"";
          }
          else if(frm.memberFormFields[j].id?.startsWith('SponsorPhoneNumber'))
          {
            obj.sponsorPhoneNumber = (frm.memberFormFields[j].selectedValue ??"").toString();
          }
        }
      }      
    } 
    return obj;
  }

  travelDateChange(dateFor:string){

    if(this.travelFormControl['travelStartDate'].value != "" && this.travelFormControl['travelEndDate'].value != "") {
      let startDate = moment(this.travelFormControl['travelStartDate'].value);
      let endDate = moment(this.travelFormControl['travelEndDate'].value);
      this.tripDuration = endDate.diff(startDate, 'days');
    }
    else
    {
      this.tripDuration = 0;
    }
  }

  getMembersList(membersCount:number,cat:string){
    let membersList:MembersForm[] = [];
    for(let i=1; i <= membersCount; i++){
      let idSuffix = i > 9 ? "_" + i.toString() : "_0" + i.toString(); 
      let membersForm:MembersForm={};
      let titlePrefix = "Member "+ i.toString()
      membersForm.for = cat + idSuffix;
      membersForm.memberFormFields = [];
      membersForm.memberFormFields?.push({
        member:i,	
        id:"IsSelfProposer"+idSuffix,
        title: titlePrefix + " Is Self Proposer",
        type:"checkbox",
        isVisible:true,
        name:"Title"+idSuffix,
        options:["Mr","Ms","Mrs"],
        selectedValue: idSuffix == "_01" ? "Yes" : "No",
        placeholder:"",
        errorText:"",
        isRequired:false,
        maxLength:0,
        minLength:0,
        isCascade:false,
        cascadeItems:{},
        className:'col-12',
        isValid:true
      });
      membersForm.memberFormFields?.push({
        member:i,	
        id:"Title"+idSuffix,
        title: titlePrefix + " Title",
        type:"select",
        isVisible:true,
        name:"Title"+idSuffix,
        options:["Mr","Ms","Mrs"],
        selectedValue:"Mr",
        placeholder:"",
        errorText:"",
        isRequired:true,
        maxLength:0,
        minLength:0,
        isCascade:false,
        cascadeItems:{},
        className:"col-md-4",
        isValid:true
      });
      membersForm.memberFormFields?.push({
        member:i,	
        id:"Firstname"+idSuffix,
        title: titlePrefix + " Firstname",
        type:"text",
        isVisible:true,
        name:"Firstname"+idSuffix,
        options:["Yes","No"],
        selectedValue:"",
        placeholder:titlePrefix + " Firstname",
        errorText:"",
        isRequired:true,
        maxLength:0,
        minLength:0,
        isCascade:false,
        cascadeItems:{},
        className:"col-md-4",
        isValid:true,
        pattern:"^[A-Za-z][A-Za-z ]*[A-Za-z]$"
      });
      membersForm.memberFormFields?.push({
        member:i,	
        id:"Lastname"+idSuffix,
        title: titlePrefix + " Lastname",
        type:"text",
        isVisible:true,
        name:"Lastname"+idSuffix,
        options:["Yes","No"],
        selectedValue:"",
        placeholder:titlePrefix + " Lastname",
        errorText:"",
        isRequired:true,
        maxLength:0,
        minLength:0,
        isCascade:false,
        cascadeItems:{},
        className:"col-md-4",
        isValid:true,
        pattern:"^[A-Za-z][A-Za-z ]*[A-Za-z]$"
      });
      membersForm.memberFormFields?.push({
        member:i,	
        id:"Email"+idSuffix,
        title: titlePrefix + " Email",
        type:"email",
        isVisible:true,
        name:"Email"+idSuffix,
        options:["Yes","No"],
        selectedValue:"",
        placeholder:titlePrefix + " Email",
        errorText:"",
        isRequired:true,
        maxLength:0,
        minLength:0,
        isCascade:false,
        cascadeItems:{},
        isValid:true,
        pattern:'^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'
      });     
      membersForm.memberFormFields?.push({
        member:i,
        id:"Relation"+idSuffix,
        title:titlePrefix + " Relation",
        type:"select",
        isVisible:true,
        name:"Relation"+idSuffix,
        options:this.travelRelationshipList,
        selectedValue:"",
        placeholder:titlePrefix + " Relation",
        errorText:"",
        isRequired:true,
        maxLength:0,
        minLength:0,
        isCascade:false,
        cascadeItems:{},
        isValid:true
      });
      membersForm.memberFormFields?.push({
        member:i,
        id:"Pancard"+idSuffix,
        title:titlePrefix + " Pan card",
        type:"text",
        isVisible:false,
        name:"Pancard"+idSuffix,
        options:["Yes","No"],
        selectedValue:"",
        placeholder:titlePrefix + " Pan card",
        errorText:"",
        isRequired:true,
        maxLength:0,
        minLength:0,
        isCascade:false,
        cascadeItems:{},
        isValid:true
      });
      membersForm.memberFormFields?.push({
        member:i,
        id:"Passport"+idSuffix,
        title:titlePrefix + " Passport",
        type:"passport",
        isVisible:true,
        name:"Passport"+idSuffix,
        options:["Yes","No"],
        selectedValue:"",
        placeholder:titlePrefix + " Passport",
        errorText:"",
        isRequired:true,
        maxLength:0,
        minLength:0,
        isCascade:false,
        cascadeItems:{},
        isValid:true,
        pattern:'^[A-Z]{1}[0-9]{7}$'
      });
      membersForm.memberFormFields?.push({
        member:i,
        id:"dob"+idSuffix,
        title:titlePrefix + " DOB",
        type:"date",
        isVisible:true,
        name:"dob"+idSuffix,
        options:["Yes","No"],
        selectedValue:"",
        placeholder:titlePrefix + " DOB",
        errorText:"",
        isRequired:true,
        maxLength:0,
        minLength:0,
        isCascade:false,
        cascadeItems:{},
        isValid:true
      });
      membersForm.memberFormFields?.push({
        member:i,
        id:"Gender"+idSuffix,
        title:titlePrefix + " Gender",
        type:"select",
        isVisible:true,
        name:"Gender"+idSuffix,
        options:["Select Gender","Male","Female"],
        selectedValue:"",
        placeholder:titlePrefix + " Gender",
        errorText:"",
        isRequired:true,
        maxLength:0,
        minLength:0,
        isCascade:false,
        cascadeItems:{},
        isValid:true
      });
      membersForm.memberFormFields?.push({
        member:i,
        id:"Age"+idSuffix,
        title:titlePrefix + " Age",
        type:"number",
        isVisible:false,
        name:"Age"+idSuffix,
        options:["Yes","No"],
        selectedValue:"",
        placeholder:titlePrefix + " Age",
        errorText:"",
        isRequired:true,
        maxLength:0,
        minLength:0,
        isCascade:false,
        cascadeItems:{},
        isValid:true
      }); 
      membersForm.memberFormFields?.push({
        member:i,
        id:"Disease"+idSuffix,
        title:titlePrefix + " Disease",
        type:"select",
        isVisible:false,
        name:"Disease"+idSuffix,
        options:["Select Disease","Influenza (Flu)","Diabetes Mellitus","Hypertension (High Blood Pressure)","Asthma",'Coronary Artery Disease'],
        selectedValue:"",
        placeholder:"",
        errorText:"",
        isRequired:true,
        maxLength:0,
        minLength:0,
        isCascade:false,
        cascadeItems:{},
        isValid:true
      }); 

      //Nominee Start
      membersForm.memberFormFields?.push({
        member:i,	
        id:"NomineeFirstname"+idSuffix,
        title: titlePrefix + " Nominee Firstname",
        type:"text",
        isVisible:false,
        name:"NomineeFirstname"+idSuffix,
        options:["Yes","No"],
        selectedValue:"",
        placeholder:titlePrefix + " Nominee Firstname",
        errorText:"",
        isRequired:true,
        maxLength:0,
        minLength:0,
        isCascade:false,
        cascadeItems:{},
        isValid:true,
        pattern:"^[A-Za-z][A-Za-z ]*[A-Za-z]$"
      });
      membersForm.memberFormFields?.push({
        member:i,	
        id:"NomineeLastname"+idSuffix,
        title: titlePrefix + " Nominee Lastname",
        type:"text",
        isVisible:false,
        name:"NomineeLastname"+idSuffix,
        options:["Yes","No"],
        selectedValue:"",
        placeholder:titlePrefix + " Nominee Lastname",
        errorText:"",
        isRequired:true,
        maxLength:0,
        minLength:0,
        isCascade:false,
        cascadeItems:{},
        isValid:true,
        pattern:"^[A-Za-z][A-Za-z ]*[A-Za-z]$"
      });    
      membersForm.memberFormFields?.push({
        member:i,
        id:"NomineeRelation"+idSuffix,
        title:titlePrefix + " Nominee Relation",
        type:"select",
        isVisible:false,
        name:"NomineeRelation"+idSuffix,
        options:this.travelNomineeRelationshipList,
        selectedValue:"",
        placeholder:titlePrefix + " Nominee Relation",
        errorText:"",
        isRequired:true,
        maxLength:0,
        minLength:0,
        isCascade:false,
        cascadeItems:{},
        isValid:true
      });
      membersForm.memberFormFields?.push({
        member:i,
        id:"Nomineedob"+idSuffix,
        title:titlePrefix + " Nominee DOB",
        type:"date",
        isVisible:false,
        name:"Nomineedob"+idSuffix,
        options:["Yes","No"],
        selectedValue:"",
        placeholder:titlePrefix + " Nominee DOB",
        errorText:"",
        isRequired:true,
        maxLength:0,
        minLength:0,
        isCascade:false,
        cascadeItems:{},
        isValid:true
      });
      //Nominee End

      //Address Start
      membersForm.memberFormFields?.push({
        member:i,	
        id:"AddressLine1"+idSuffix,
        title: titlePrefix + " Flat/Street Number",
        type:"text",
        isVisible:false,
        name:"AddressLine1"+idSuffix,
        options:["Yes","No"],
        selectedValue:"",
        placeholder:titlePrefix + " Flat/Street Number",
        errorText:"",
        isRequired:true,
        maxLength:0,
        minLength:0,
        isCascade:false,
        cascadeItems:{},
        isValid:true
      });
      membersForm.memberFormFields?.push({
        member:i,	
        id:"AddressLine2"+idSuffix,
        title: titlePrefix + " Street Address",
        type:"text",
        isVisible:false,
        name:"AddressLine2"+idSuffix,
        options:["Yes","No"],
        selectedValue:"",
        placeholder:titlePrefix + " Street Address",
        errorText:"",
        isRequired:true,
        maxLength:0,
        minLength:0,
        isCascade:false,
        cascadeItems:{},
        isValid:true
      });    
      membersForm.memberFormFields?.push({
        member:i,
        id:"AddressPincode"+idSuffix,
        title:titlePrefix + " Pincode",
        type:"autoComplete",
        isVisible:false,
        name:"AddressPincode"+idSuffix,
        options:[],
        selectedValue:"",
        placeholder:titlePrefix + " Pincode",
        errorText:"",
        isRequired:true,
        maxLength:0,
        minLength:0,
        isCascade:false,
        cascadeItems:{},
        isValid:true
      });
      membersForm.memberFormFields?.push({
        member:i,	
        id:"AddressState"+idSuffix,
        title: titlePrefix + " State",
        type:"text",
        isVisible:false,
        name:"AddressState"+idSuffix,
        options:["Yes","No"],
        selectedValue:"",
        placeholder:titlePrefix + " State",
        errorText:"",
        isRequired:true,
        maxLength:0,
        minLength:0,
        isCascade:false,
        cascadeItems:{},
        isDisable:true,
        isValid:true
      }); 
      membersForm.memberFormFields?.push({
        member:i,	
        id:"AddressCity"+idSuffix,
        title: titlePrefix + " City",
        type:"text",
        isVisible:false,
        name:"AddressCity"+idSuffix,
        options:["Yes","No"],
        selectedValue:"",
        placeholder:titlePrefix + " City",
        errorText:"",
        isRequired:true,
        maxLength:0,
        minLength:0,
        isCascade:false,
        cascadeItems:{},
        isDisable:true,
        isValid:true
      }); 
      //Address End

      if(cat =="student")
      {
      //University Start
        membersForm.memberFormFields?.push({
          member:i,	
          id:"UniversityName"+idSuffix,
          title: titlePrefix + " University Name",
          type:"text",
          isVisible:true,
          name:"UniversityName"+idSuffix,
          options:["Yes","No"],
          selectedValue:"",
          placeholder:titlePrefix + " University Name",
          errorText:"",
          isRequired:true,
          maxLength:0,
          minLength:0,
          isCascade:false,
          cascadeItems:{},
          isValid:true,
          pattern:"^[A-Za-z][A-Za-z .'-]*[A-Za-z]$"
        });
        membersForm.memberFormFields?.push({
          member:i,	
          id:"UniversityCourseName"+idSuffix,
          title: titlePrefix + " Course Name",
          type:"text",
          isVisible:true,
          name:"UniversityCourseName"+idSuffix,
          options:["Yes","No"],
          selectedValue:"",
          placeholder:titlePrefix + " Course Name",
          errorText:"",
          isRequired:true,
          maxLength:0,
          minLength:0,
          isCascade:false,
          cascadeItems:{},
          isValid:true,
          pattern:"^[A-Za-z][A-Za-z .'-]*[A-Za-z]$"
        });    
        membersForm.memberFormFields?.push({
          member:i,
          id:"UniversityAddress1"+idSuffix,
          title:titlePrefix + " University Address1",
          type:"text",
          isVisible:true,
          name:"UniversityAddress1"+idSuffix,
          options:[],
          selectedValue:"",
          placeholder:titlePrefix + " University Address1",
          errorText:"",
          isRequired:true,
          maxLength:0,
          minLength:0,
          isCascade:false,
          cascadeItems:{},
          isValid:true
        });
        membersForm.memberFormFields?.push({
          member:i,
          id:"UniversityAddress2"+idSuffix,
          title:titlePrefix + " University Address2",
          type:"text",
          isVisible:true,
          name:"UniversityAddress2"+idSuffix,
          options:[],
          selectedValue:"",
          placeholder:titlePrefix + " University Address2",
          errorText:"",
          isRequired:true,
          maxLength:0,
          minLength:0,
          isCascade:false,
          cascadeItems:{},
          isValid:true
        });
        membersForm.memberFormFields?.push({
          member:i,	
          id:"UniversityCity"+idSuffix,
          title: titlePrefix + " University City",
          type:"text",
          isVisible:true,
          name:"UniversityCity"+idSuffix,
          options:["Yes","No"],
          selectedValue:"",
          placeholder:titlePrefix + " University City",
          errorText:"",
          isRequired:true,
          maxLength:0,
          minLength:0,
          isCascade:false,
          cascadeItems:{},
          isValid:true
        }); 
        membersForm.memberFormFields?.push({
          member:i,	
          id:"UniversityState"+idSuffix,
          title: titlePrefix + " University State",
          type:"text",
          isVisible:true,
          name:"UniversityState"+idSuffix,
          options:["Yes","No"],
          selectedValue:"",
          placeholder:titlePrefix + " University State",
          errorText:"",
          isRequired:true,
          maxLength:0,
          minLength:0,
          isCascade:false,
          cascadeItems:{},
          isValid:true
        }); 
        membersForm.memberFormFields?.push({
          member:i,	
          id:"UniversityCountry"+idSuffix,
          title: titlePrefix + " University Country",
          type:"text",
          isVisible:true,
          name:"UniversityCountry"+idSuffix,
          options:["Yes","No"],
          selectedValue:"",
          placeholder:titlePrefix + " University Country",
          errorText:"",
          isRequired:true,
          maxLength:0,
          minLength:0,
          isCascade:false,
          cascadeItems:{},
          isValid:true
        }); 
        membersForm.memberFormFields?.push({
          member:i,	
          id:"UniversityTuitionFeePerMonth"+idSuffix,
          title: titlePrefix + " University Tuition Fee Per Month (₹)",
          type:"number",
          isVisible:true,
          name:"UniversityTuitionFeePerMonth"+idSuffix,
          options:["Yes","No"],
          selectedValue:"",
          placeholder:titlePrefix + " University Tuition Fee Per Month (₹)",
          errorText:"",
          isRequired:true,
          maxLength:0,
          minLength:0,
          isCascade:false,
          cascadeItems:{},
          isValid:true
        }); 
        //University End

        //Sponsor Start
        membersForm.memberFormFields?.push({
          member:i,	
          id:"SponsorName"+idSuffix,
          title: titlePrefix + " Sponsor Name",
          type:"text",
          isVisible:true,
          name:"SponsorName"+idSuffix,
          options:["Yes","No"],
          selectedValue:"",
          placeholder:titlePrefix + " Sponsor Name",
          errorText:"",
          isRequired:true,
          maxLength:0,
          minLength:0,
          isCascade:false,
          cascadeItems:{},
          isValid:true,
          pattern:"^[A-Za-z][A-Za-z ']*[A-Za-z]$"
        });
        membersForm.memberFormFields?.push({
          member:i,	
          id:"SponsorRelationshipToInsured"+idSuffix,
          title: titlePrefix + " Sponsor Relationship To Insured",
          type:"text",
          isVisible:true,
          name:"SponsorRelationshipToInsured"+idSuffix,
          options:["Yes","No"],
          selectedValue:"",
          placeholder:titlePrefix + " Sponsor Relationship To Insured",
          errorText:"",
          isRequired:true,
          maxLength:0,
          minLength:0,
          isCascade:false,
          cascadeItems:{},
          isValid:true
        });    
        membersForm.memberFormFields?.push({
          member:i,
          id:"SponsorAddress1"+idSuffix,
          title:titlePrefix + " Sponsor Address1",
          type:"text",
          isVisible:true,
          name:"SponsorAddress1"+idSuffix,
          options:[],
          selectedValue:"",
          placeholder:titlePrefix + " Sponsor Address1",
          errorText:"",
          isRequired:true,
          maxLength:0,
          minLength:0,
          isCascade:false,
          cascadeItems:{},
          isValid:true
        });
        membersForm.memberFormFields?.push({
          member:i,	
          id:"SponsorAddress2"+idSuffix,
          title: titlePrefix + " Sponsor Address2",
          type:"text",
          isVisible:true,
          name:"SponsorAddress2"+idSuffix,
          options:["Yes","No"],
          selectedValue:"",
          placeholder:titlePrefix + " Sponsor Address2",
          errorText:"",
          isRequired:true,
          maxLength:0,
          minLength:0,
          isCascade:false,
          cascadeItems:{},
          isValid:true
        }); 
        membersForm.memberFormFields?.push({
          member:i,	
          id:"SponsorAddress3"+idSuffix,
          title: titlePrefix + " Sponsor Address3",
          type:"text",
          isVisible:true,
          name:"SponsorAddress3"+idSuffix,
          options:["Yes","No"],
          selectedValue:"",
          placeholder:titlePrefix + " Sponsor Address3",
          errorText:"",
          isRequired:true,
          maxLength:0,
          minLength:0,
          isCascade:false,
          cascadeItems:{},
          isValid:true
        }); 
        membersForm.memberFormFields?.push({
          member:i,	
          id:"SponsorCity"+idSuffix,
          title: titlePrefix + " Sponsor City",
          type:"text",
          isVisible:true,
          name:"SponsorCity"+idSuffix,
          options:["Yes","No"],
          selectedValue:"",
          placeholder:titlePrefix + " Sponsor City",
          errorText:"",
          isRequired:true,
          maxLength:0,
          minLength:0,
          isCascade:false,
          cascadeItems:{},
          isValid:true
        }); 
        membersForm.memberFormFields?.push({
          member:i,	
          id:"SponsorState"+idSuffix,
          title: titlePrefix + " Sponsor State",
          type:"text",
          isVisible:true,
          name:"SponsorState"+idSuffix,
          options:["Yes","No"],
          selectedValue:"",
          placeholder:titlePrefix + " Sponsor State",
          errorText:"",
          isRequired:true,
          maxLength:0,
          minLength:0,
          isCascade:false,
          cascadeItems:{},
          isValid:true
        });
        membersForm.memberFormFields?.push({
          member:i,	
          id:"SponsorCountry"+idSuffix,
          title: titlePrefix + " Sponsor Country",
          type:"text",
          isVisible:true,
          name:"SponsorCountry"+idSuffix,
          options:["Yes","No"],
          selectedValue:"",
          placeholder:titlePrefix + " Sponsor Country",
          errorText:"",
          isRequired:true,
          maxLength:0,
          minLength:0,
          isCascade:false,
          cascadeItems:{},
          isValid:true
        });
        membersForm.memberFormFields?.push({
          member:i,	
          id:"SponsorDateofBirth"+idSuffix,
          title: titlePrefix + " Sponsor DOB",
          type:"date",
          isVisible:true,
          name:"SponsorDateofBirth"+idSuffix,
          options:["Yes","No"],
          selectedValue:"",
          placeholder:titlePrefix + " Sponsor DOB",
          errorText:"",
          isRequired:true,
          maxLength:0,
          minLength:0,
          isCascade:false,
          cascadeItems:{},
          isValid:true
        });
        membersForm.memberFormFields?.push({
          member:i,	
          id:"SponsorPhoneNumber"+idSuffix,
          title: titlePrefix + " Sponsor Phone Number",
          type:"phone",
          isVisible:true,
          name:"SponsorPhoneNumber"+idSuffix,
          options:["Yes","No"],
          selectedValue:"",
          placeholder:titlePrefix + " Sponsor Phone Number",
          errorText:"",
          isRequired:true,
          maxLength:10,
          minLength:10,
          isCascade:false,
          cascadeItems:{},
          isValid:true          
        }); 
      //University End
    }
    
      membersList.push(membersForm);
    }
    return membersList;
  }

  travelPurposeChange(event:any){
    this.travelForm.get("travelPurpose")?.setValue(event.target.value);
  }
}