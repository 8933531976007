import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AutoCompleteItem, Item } from 'src/app/Models/Item';
import { HealthQuestionForm } from 'src/app/Models/HeathQuestionForm';
import { HealtQuoteResponseModel } from 'src/app/Models/HealtQuoteModel';
import { LookUpService } from 'src/app/Services/lookup.service';
import { CommonService } from 'src/app/Services/common.service';
import { InsuranceService } from 'src/app/Services/insurance.service';
import swal from 'sweetalert2';
import * as moment from 'moment';
import { GeneralService } from 'src/app/Services/general.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-health-summary',
  templateUrl: './health-summary.component.html',
  styleUrls: ['./health-summary.component.css']
})
export class HealthSummaryComponent {
  @Input()  healthObj:any;
  @Output() gotoEvent = new EventEmitter<void>();
  Up: boolean = true;
  Up1: boolean = false;
  Up2: boolean = false;
  Up3: boolean = false;
  down: boolean = false;
  down1: boolean = true;
  down2: boolean = true;
  down3: boolean = true;
  chUp:boolean=false;
  chUp1:boolean=false;
  chUp2:boolean=false;
  chUp3:boolean=false;
  chDw:boolean=true;
  chDw1:boolean=true;
  chDw2:boolean=true;
  chDw3:boolean=true;

  ageList: any[];
    feetList: any[];
    inchList: any[];
    isEditPolicy: boolean = false;
    proposalForm: FormGroup;
    membersForm: FormGroup;
    medicalForm: FormGroup;
    nomineeForm: FormGroup;
    vehicleForm: FormGroup;
    showExistingPolicyControls: boolean = true;
    userForm: FormGroup;
    @Input() ownerDetails: any;
    titleList: string[] = ["Mr", "Mrs", "Ms"];
    districtList: AutoCompleteItem[] = [];
    insurancePartnersList: AutoCompleteItem[] = [];
    stateList: AutoCompleteItem[] = [];
    pincodeList: AutoCompleteItem[] = [];
    cityList: AutoCompleteItem[] = [];
    RelationShipList: { id: number; name: string; }[];
    submittedAddress: boolean = false;
    submittedNominee: boolean = false;
    submittedVehicle: boolean = false;
    submittedPolicy: boolean = false;
    isUserLogged: boolean = false;
    royalHealthObj: any;

  chilVal: string = '';
  value: string = '';
  proposerForm: FormGroup;
  insurerForm: FormGroup;
  addressInfo: FormGroup;
  healthData: any;
  insuranceDetailsScreen_one: boolean = true;
  insuranceDetailsScreen_two: boolean = false;
  @Input() quoteData: any
  selectedQuoteData: any
  quoteObj:HealtQuoteResponseModel;
  premiumObject: any;
  defaultDate = "";
  today = new Date();
  maxDate = '';
  maritalStatusList: string[] = ["Single","Married","Divorced","Separated"];
  maxDOB = moment(this.today).add(-18, 'years').format('YYYY-MM-DD');
  genderList: string[] = ["","Male","Female","Other"];
  isSummary:boolean = false;
  panel:number = 1;
  rId:any;
  uId:any;
  occupationList =
  [
    {value:"", text:"Select Occupation"},
    {value:"Salaried", text:"Salaried"},
    {value:"Self Employed", text:"Self Employed"},
    {value:"Student", text:"Student"},
    {value:"Housewife", text:"Housewife"},
    {value:"Politician", text:"Politician"},
    {value:"Others", text:"Others"}
  ];
  isPaymentLink:boolean = false;
  paymentObject:any;
  showKYCPopup:boolean=false;
  showhttpKYCPopup:boolean=false;
  kycUrl:any='';
  showNegativeKycInfo:boolean = false;
  required_input_fields: HealthQuestionForm = {
      formTitle: "Health insurance Questions",
      formSubTitle: "",
      formFields: [
        {
          id: "consultedDoc",
          title: "Within the last 2 years have you consulted a doctor or healthcare professional?(other than Preventive or Pre Employment Health Check-up)",
          type: "radio",
          isVisible: true,
          name: "consultedDoc",
          options: ["Yes", "No"],
          selectedValue: "No",
          placeholder: "",
          errorText: "",
          isRequired: false,
          maxLength: 0,
          minLength: 0,
          isCascade: false,
          isValid:true,
          cascadeItems: {
            formTitle: "",
            formSubTitle: "",
            formFields: [
              {
                id: "nameOfIllness",
                title: "Name of illness/injury suffering from or suffered in the past",
                type: "text",
                isVisible: true,
                name: "nameOfIllness",
                options: ["Yes", "No"],
                selectedValue: "",
                placeholder: "",
                errorText: "",
                isRequired: true,
                maxLength: 0,
                minLength: 0,
                isCascade: false,
                isValid:true,
                cascadeItems: {}
              },
              {
                id: "dateOfDiagnosis",
                title: "Date of first diagnosis",
                type: "date",
                isVisible: true,
                name: "dateOfDiagnosis",
                options: ["Yes", "No"],
                selectedValue: "",
                placeholder: "",
                errorText: "",
                isRequired: true,
                maxLength: 0,
                minLength: 0,
                isCascade: false,
                isValid:true,
                cascadeItems: {}
              },
              {
                id: "medicationDetail",
                title: "Treatment/medication",
                type: "text",
                isVisible: true,
                name: "medicationDetail",
                options: ["Yes", "No"],
                selectedValue: "",
                placeholder: "",
                errorText: "",
                isRequired: true,
                maxLength: 0,
                minLength: 0,
                isCascade: false,
                isValid:true,
                cascadeItems: {}
              },
              {
                id: "treatmentOutCome",
                title: "Treatment outcome",
                type: "text",
                isVisible: true,
                name: "treatmentOutCome",
                options: ["Yes", "No"],
                selectedValue: "",
                placeholder: "",
                errorText: "",
                isRequired: true,
                maxLength: 0,
                minLength: 0,
                isCascade: false,
                isValid:true,
                cascadeItems: {}
              }
            ]
          }
        },
        {
          id: "detailedInvestigation",
          title: "Within the last 2 years have you undergone for any detailed investigation other than Preventive or Pre Employment Health Check-up.e.g. X-ray, CT Scan, biopsy, MRI, Sonography, etc",
          type: "radio",
          isVisible: true,
          name: "detailedInvestigation",
          options: ["Yes", "No"],
          selectedValue: "No",
          placeholder: "",
          errorText: "",
          isRequired: false,
          maxLength: 0,
          minLength: 0,
          isCascade: false,
          isValid:true,
          cascadeItems: {
            formTitle: "",
            formSubTitle: "",
            formFields: [
              {
                id: "nameOfIllness",
                title: "Name of illness/injury suffering from or suffered in the past",
                type: "text",
                isVisible: true,
                name: "nameOfIllness",
                options: ["Yes", "No"],
                selectedValue: "",
                placeholder: "",
                errorText: "",
                isRequired: true,
                maxLength: 0,
                minLength: 0,
                isCascade: false,
                isValid:true,
                cascadeItems: {}
              },
              {
                id: "dateOfDiagnosis",
                title: "Date of first diagnosis",
                type: "date",
                isVisible: true,
                name: "dateOfDiagnosis",
                options: ["Yes", "No"],
                selectedValue: "",
                placeholder: "",
                errorText: "",
                isRequired: true,
                maxLength: 0,
                minLength: 0,
                isCascade: false,
                isValid:true,
                cascadeItems: {}
              },
              {
                id: "medicationDetail",
                title: "Treatment/medication",
                type: "text",
                isVisible: true,
                name: "medicationDetail",
                options: ["Yes", "No"],
                selectedValue: "",
                placeholder: "",
                errorText: "",
                isRequired: true,
                maxLength: 0,
                minLength: 0,
                isCascade: false,
                isValid:true,
                cascadeItems: {}
              },
              {
                id: "treatmentOutCome",
                title: "Treatment outcome",
                type: "text",
                isVisible: true,
                name: "treatmentOutCome",
                options: ["Yes", "No"],
                selectedValue: "",
                placeholder: "",
                errorText: "",
                isRequired: true,
                maxLength: 0,
                minLength: 0,
                isCascade: false,
                isValid:true,
                cascadeItems: {}
              }
            ]
          }
        },
        {
          id: "operation",
          title: "Within the last 5 years have you been to a hospital for an operation/medical treatment?",
          type: "radio",
          isVisible: true,
          name: "operation",
          options: ["Yes", "No"],
          selectedValue: "No",
          placeholder: "",
          errorText: "",
          isRequired: false,
          maxLength: 0,
          minLength: 0,
          isCascade: false,
          isValid:true,
          cascadeItems: {
            formTitle: "",
            formSubTitle: "",
            formFields: [
              {
                id: "nameOfIllness",
                title: "Name of illness/injury suffering from or suffered in the past",
                type: "text",
                isVisible: true,
                name: "nameOfIllness",
                options: ["Yes", "No"],
                selectedValue: "",
                placeholder: "",
                errorText: "",
                isRequired: true,
                maxLength: 0,
                minLength: 0,
                isCascade: false,
                isValid:true,
                cascadeItems: {}
              },
              {
                id: "dateOfDiagnosis",
                title: "Date of first diagnosis",
                type: "date",
                isVisible: true,
                name: "dateOfDiagnosis",
                options: ["Yes", "No"],
                selectedValue: "",
                placeholder: "",
                errorText: "",
                isRequired: true,
                maxLength: 0,
                minLength: 0,
                isCascade: false,
                isValid:true,
                cascadeItems: {}
              },
              {
                id: "medicationDetail",
                title: "Treatment/medication",
                type: "text",
                isVisible: true,
                name: "medicationDetail",
                options: ["Yes", "No"],
                selectedValue: "",
                placeholder: "",
                errorText: "",
                isRequired: true,
                maxLength: 0,
                minLength: 0,
                isCascade: false,
                isValid:true,
                cascadeItems: {}
              },
              {
                id: "treatmentOutCome",
                title: "Treatment outcome",
                type: "text",
                isVisible: true,
                name: "treatmentOutCome",
                options: ["Yes", "No"],
                selectedValue: "",
                placeholder: "",
                errorText: "",
                isRequired: true,
                maxLength: 0,
                minLength: 0,
                isCascade: false,
                isValid:true,
                cascadeItems: {}
              }
            ]
          }
        },
        {
          id: "regularBasisMedicines",
          title: "Do you take tablets, medicines or drugs on a regular basis?",
          type: "radio",
          isVisible: true,
          name: "regularBasisMedicines",
          options: ["Yes", "No"],
          selectedValue: "No",
          placeholder: "",
          errorText: "",
          isRequired: false,
          maxLength: 0,
          minLength: 0,
          isCascade: false,
          isValid:true,
          cascadeItems: {
            formTitle: "",
            formSubTitle: "",
            formFields: [
              {
                id: "nameOfIllness",
                title: "Name of illness/injury suffering from or suffered in the past",
                type: "text",
                isVisible: true,
                name: "nameOfIllness",
                options: ["Yes", "No"],
                selectedValue: "",
                placeholder: "",
                errorText: "",
                isRequired: true,
                maxLength: 0,
                minLength: 0,
                isCascade: false,
                isValid:true,
                cascadeItems: {}
              },
              {
                id: "dateOfDiagnosis",
                title: "Date of first diagnosis",
                type: "date",
                isVisible: true,
                name: "dateOfDiagnosis",
                options: ["Yes", "No"],
                selectedValue: "",
                placeholder: "",
                errorText: "",
                isRequired: true,
                maxLength: 0,
                minLength: 0,
                isCascade: false,
                isValid:true,
                cascadeItems: {}
              },
              {
                id: "medicationDetail",
                title: "Treatment/medication",
                type: "text",
                isVisible: true,
                name: "medicationDetail",
                options: ["Yes", "No"],
                selectedValue: "",
                placeholder: "",
                errorText: "",
                isRequired: true,
                maxLength: 0,
                minLength: 0,
                isCascade: false,
                isValid:true,
                cascadeItems: {}
              },
              {
                id: "treatmentOutCome",
                title: "Treatment outcome",
                type: "text",
                isVisible: true,
                name: "treatmentOutCome",
                options: ["Yes", "No"],
                selectedValue: "",
                placeholder: "",
                errorText: "",
                isRequired: true,
                maxLength: 0,
                minLength: 0,
                isCascade: false,
                isValid:true,
                cascadeItems: {}
              }
            ]
          }
        },
        {
          id: "experiencedHealthProblems",
          title: "Within the last 3 months have you experienced any health problems or medical conditions which you/proposed insured person have/has not seen a doctor for",
          type: "radio",
          isVisible: true,
          name: "experiencedHealthProblems",
          options: ["Yes", "No"],
          selectedValue: "No",
          placeholder: "",
          errorText: "",
          isRequired: false,
          maxLength: 0,
          minLength: 0,
          isCascade: false,
          isValid:true,
          cascadeItems: {
            formTitle: "",
            formSubTitle: "",
            formFields: [
              {
                id: "nameOfIllness",
                title: "Name of illness/injury suffering from or suffered in the past",
                type: "text",
                isVisible: true,
                name: "nameOfIllness",
                options: ["Yes", "No"],
                selectedValue: "",
                placeholder: "",
                errorText: "",
                isRequired: true,
                maxLength: 0,
                minLength: 0,
                isCascade: false,
                isValid:true,
                cascadeItems: {}
              },
              {
                id: "dateOfDiagnosis",
                title: "Date of first diagnosis",
                type: "date",
                isVisible: true,
                name: "dateOfDiagnosis",
                options: ["Yes", "No"],
                selectedValue: "",
                placeholder: "",
                errorText: "",
                isRequired: true,
                maxLength: 0,
                minLength: 0,
                isCascade: false,
                isValid:true,
                cascadeItems: {}
              },
              {
                id: "medicationDetail",
                title: "Treatment/medication",
                type: "text",
                isVisible: true,
                name: "medicationDetail",
                options: ["Yes", "No"],
                selectedValue: "",
                placeholder: "",
                errorText: "",
                isRequired: true,
                maxLength: 0,
                minLength: 0,
                isCascade: false,
                isValid:true,
                cascadeItems: {}
              },
              {
                id: "treatmentOutCome",
                title: "Treatment outcome",
                type: "text",
                isVisible: true,
                name: "treatmentOutCome",
                options: ["Yes", "No"],
                selectedValue: "",
                placeholder: "",
                errorText: "",
                isRequired: true,
                maxLength: 0,
                minLength: 0,
                isCascade: false,
                isValid:true,
                cascadeItems: {}
              }
            ]
          }
        },
        {
          id: "seriousIllness",
          title: "Have any of the person proposed to be insured ever suffered from or taken treatment, or hospitalized for or have been recommended to take investigations/medication/surgery or undergone a surgery for any of the following – Diabetes; Hypertension; Ulcer/Cyst/Cancer; Cardiac Disorder; Kidney or Urinary Tract Disorder; Disorder of muscle/bone/joint; Respiratory disorder; Digestive tract or gastrointestinal disorder; Nervous System disorder; Mental Illness or disorder, HIV or AIDS",
          type: "radio",
          isVisible: true,
          name: "seriousIllness",
          options: ["Yes", "No"],
          selectedValue: "No",
          placeholder: "",
          errorText: "",
          isRequired: false,
          maxLength: 0,
          minLength: 0,
          isCascade: false,
          isValid:true,
          cascadeItems: {
            formTitle: "",
            formSubTitle: "",
            formFields: [
              {
                id: "nameOfIllness",
                title: "Name of illness/injury suffering from or suffered in the past",
                type: "text",
                isVisible: true,
                name: "nameOfIllness",
                options: ["Yes", "No"],
                selectedValue: "",
                placeholder: "",
                errorText: "",
                isRequired: true,
                maxLength: 0,
                minLength: 0,
                isCascade: false,
                isValid:true,
                cascadeItems: {}
              },
              {
                id: "dateOfDiagnosis",
                title: "Date of first diagnosis",
                type: "date",
                isVisible: true,
                name: "dateOfDiagnosis",
                options: ["Yes", "No"],
                selectedValue: "",
                placeholder: "",
                errorText: "",
                isRequired: true,
                maxLength: 0,
                minLength: 0,
                isCascade: false,
                isValid:true,
                cascadeItems: {}
              },
              {
                id: "medicationDetail",
                title: "Treatment/medication",
                type: "text",
                isVisible: true,
                name: "medicationDetail",
                options: ["Yes", "No"],
                selectedValue: "",
                placeholder: "",
                errorText: "",
                isRequired: true,
                maxLength: 0,
                minLength: 0,
                isCascade: false,
                isValid:true,
                cascadeItems: {}
              },
              {
                id: "treatmentOutCome",
                title: "Treatment outcome",
                type: "text",
                isVisible: true,
                name: "treatmentOutCome",
                options: ["Yes", "No"],
                selectedValue: "",
                placeholder: "",
                errorText: "",
                isRequired: true,
                maxLength: 0,
                minLength: 0,
                isCascade: false,
                isValid:true,
                cascadeItems: {}
              }
            ]
          }
        },
        {
          id: "alcohol",
          title: "Alcohol",
          type: "radio",
          isVisible: true,
          name: "alcohol",
          options: ["Yes", "No"],
          selectedValue: "No",
          placeholder: "",
          errorText: "",
          isRequired: false,
          maxLength: 0,
          minLength: 0,
          isCascade: false,
          isValid:true,
          cascadeItems: {
            formTitle: "",
            formSubTitle: "",
            formFields: [
              {
                id: "alcohol_quanlity",
                title: "Quantity in MiliLiter",
                type: "number",
                isVisible: true,
                name: "alcohol_quanlity",
                options: ["Yes", "No"],
                selectedValue: "0",
                placeholder: "",
                errorText: "",
                isRequired: false,
                maxLength: 0,
                minLength: 0,
                isCascade: false,
                isValid:true,
                cascadeItems: {}
              },
              {
                id: "alcohol_years",
                title: "No. of Years",
                type: "number",
                isVisible: true,
                name: "alcohol_years",
                options: ["Yes", "No"],
                selectedValue: "0",
                placeholder: "",
                errorText: "",
                isRequired: false,
                maxLength: 0,
                minLength: 0,
                isCascade: false,
                isValid:true,
                cascadeItems: {}
              },
              {
                id: "alcohol_habit_in_past",
                title: "These habit has been in the past?",
                type: "radio",
                isVisible: true,
                name: "alcohol_habit_in_past",
                options: ["Yes", "No"],
                selectedValue: "No",
                placeholder: "",
                errorText: "",
                isRequired: false,
                maxLength: 0,
                minLength: 0,
                isCascade: false,
                isValid:true,
                cascadeItems: {
                  formTitle: "",
                  formSubTitle: "",
                  formFields: [
                    {
                      id: "alcohol_habit_left_date",
                      title: "Habit left date",
                      type: "date",
                      isVisible: true,
                      name: "alcohol_habit_left_date",
                      options: ["Yes", "No"],
                      selectedValue: "1912-12-12",
                      placeholder: "",
                      errorText: "",
                      isRequired: false,
                      maxLength: 0,
                      minLength: 0,
                      isCascade: false,
                      isValid:true,
                      cascadeItems: {}
                    },
                    {
                      id: "alcohol_habit_left_reason",
                      title: "Habit left reason",
                      type: "textarea",
                      isVisible: true,
                      name: "alcohol_habit_left_reason",
                      options: ["Yes", "No"],
                      selectedValue: "",
                      placeholder: "",
                      errorText: "",
                      isRequired: false,
                      maxLength: 0,
                      minLength: 0,
                      isCascade: false,
                      isValid:true,
                      cascadeItems: {}
                    }
                  ]
                }
              }
            ]
          }
        },
        {
          id: "smoking",
          title: "Smoking",
          type: "radio",
          isVisible: true,
          name: "smoking",
          options: ["Yes", "No"],
          selectedValue: "No",
          placeholder: "",
          errorText: "",
          isRequired: false,
          maxLength: 0,
          minLength: 0,
          isCascade: false,
          isValid:true,
          cascadeItems: {
            formTitle: "",
            formSubTitle: "",
            formFields: [
              {
                id: "smoking_quanlity",
                title: "Quantity Per Day",
                type: "number",
                isVisible: true,
                name: "smoking_quanlity",
                options: ["Yes", "No"],
                selectedValue: "0",
                placeholder: "",
                errorText: "",
                isRequired: false,
                maxLength: 0,
                minLength: 0,
                isCascade: false,
                isValid:true,
                cascadeItems: {}
              },
              {
                id: "smoking_years",
                title: "No. of Years",
                type: "number",
                isVisible: true,
                name: "smoking_years",
                options: ["Yes", "No"],
                selectedValue: "0",
                placeholder: "",
                errorText: "",
                isRequired: false,
                maxLength: 0,
                minLength: 0,
                isCascade: false,
                isValid:true,
                cascadeItems: {}
              },
              {
                id: "smoking_habit_in_past",
                title: "These habit has been in the past?",
                type: "radio",
                isVisible: true,
                name: "smoking_habit_in_past",
                options: ["Yes", "No"],
                selectedValue: "No",
                placeholder: "",
                errorText: "",
                isRequired: false,
                maxLength: 0,
                minLength: 0,
                isCascade: false,
                isValid:true,
                cascadeItems: {
                  formTitle: "",
                  formSubTitle: "",
                  formFields: [
                    {
                      id: "smoking_habit_left_date",
                      title: "Habit left date",
                      type: "date",
                      isVisible: true,
                      name: "smoking_habit_left_date",
                      options: ["Yes", "No"],
                      selectedValue: "1912-12-12",
                      placeholder: "",
                      errorText: "",
                      isRequired: false,
                      maxLength: 0,
                      minLength: 0,
                      isCascade: false,
                      isValid:true,
                      cascadeItems: {}
                    },
                    {
                      id: "smoking_habit_left_reason",
                      title: "Habit left reason",
                      type: "textarea",
                      isVisible: true,
                      name: "smoking_habit_left_reason",
                      options: ["Yes", "No"],
                      selectedValue: "",
                      placeholder: "",
                      errorText: "",
                      isRequired: false,
                      maxLength: 0,
                      minLength: 0,
                      isCascade: false,
                      isValid:true,
                      cascadeItems: {}
                    }
                  ]
                }
              }
            ]
          }
        },
        {
          id: "tobacco",
          title: "Tobacco",
          type: "radio",
          isVisible: true,
          name: "tobacco",
          options: ["Yes", "No"],
          selectedValue: "No",
          placeholder: "",
          errorText: "",
          isRequired: false,
          maxLength: 0,
          minLength: 0,
          isCascade: false,
          isValid:true,
          cascadeItems: {
            formTitle: "",
            formSubTitle: "",
            formFields: [
              {
                id: "tobacco_quanlity",
                title: "Quantity in Gram",
                type: "number",
                isVisible: true,
                name: "tobacco_quanlity",
                options: ["Yes", "No"],
                selectedValue: "0",
                placeholder: "",
                errorText: "",
                isRequired: false,
                maxLength: 0,
                minLength: 0,
                isCascade: false,
                isValid:true,
                cascadeItems: {}
              },
              {
                id: "tobacco_years",
                title: "No. of Years",
                type: "number",
                isVisible: true,
                name: "tobacco_years",
                options: ["Yes", "No"],
                selectedValue: "0",
                placeholder: "",
                errorText: "",
                isRequired: false,
                maxLength: 0,
                minLength: 0,
                isCascade: false,
                isValid:true,
                cascadeItems: {}
              },
              {
                id: "tobacco_habit_in_past",
                title: "These habit has been in the past?",
                type: "radio",
                isVisible: true,
                name: "tobacco_habit_in_past",
                options: ["Yes", "No"],
                selectedValue: "No",
                placeholder: "",
                errorText: "",
                isRequired: false,
                maxLength: 0,
                minLength: 0,
                isCascade: false,
                isValid:true,
                cascadeItems: {
                  formTitle: "",
                  formSubTitle: "",
                  formFields: [
                    {
                      id: "tobacco_habit_left_date",
                      title: "Habit left date",
                      type: "date",
                      isVisible: true,
                      name: "tobacco_habit_left_date",
                      options: ["Yes", "No"],
                      selectedValue: "1912-12-12",
                      placeholder: "",
                      errorText: "",
                      isRequired: false,
                      maxLength: 0,
                      minLength: 0,
                      isCascade: false,
                      isValid:true,
                      cascadeItems: {}
                    },
                    {
                      id: "tobacco_habit_left_reason",
                      title: "Habit left reason",
                      type: "textarea",
                      isVisible: true,
                      name: "tobacco_habit_left_reason",
                      options: ["Yes", "No"],
                      selectedValue: "",
                      placeholder: "",
                      errorText: "",
                      isRequired: false,
                      maxLength: 0,
                      minLength: 0,
                      isCascade: false,
                      isValid:true,
                      cascadeItems: {}
                    }
                  ]
                }
              }
            ]
          }
        },
        {
          id: "narcotics",
          title: "Narcotics",
          type: "radio",
          isVisible: true,
          name: "narcotics",
          options: ["Yes", "No"],
          selectedValue: "No",
          placeholder: "",
          errorText: "",
          isRequired: false,
          maxLength: 0,
          minLength: 0,
          isCascade: false,
          isValid:true,
          cascadeItems: {
            formTitle: "",
            formSubTitle: "",
            formFields: [
              {
                id: "narcotics_quanlity",
                title: "Quantity in Gram",
                type: "number",
                isVisible: true,
                name: "narcotics_quanlity",
                options: ["Yes", "No"],
                selectedValue: "0",
                placeholder: "",
                errorText: "",
                isRequired: false,
                maxLength: 0,
                minLength: 0,
                isCascade: false,
                isValid:true,
                cascadeItems: {}
              },
              {
                id: "narcotics_years",
                title: "No. of Years",
                type: "number",
                isVisible: true,
                name: "narcotics_years",
                options: ["Yes", "No"],
                selectedValue: "0",
                placeholder: "",
                errorText: "",
                isRequired: false,
                maxLength: 0,
                minLength: 0,
                isCascade: false,
                isValid:true,
                cascadeItems: {}
              },
              {
                id: "narcotics_habit_in_past",
                title: "These habit has been in the past?",
                type: "radio",
                isVisible: true,
                name: "narcotics_habit_in_past",
                options: ["Yes", "No"],
                selectedValue: "No",
                placeholder: "",
                errorText: "",
                isRequired: false,
                maxLength: 0,
                minLength: 0,
                isCascade: false,
                isValid:true,
                cascadeItems: {
                  formTitle: "",
                  formSubTitle: "",
                  formFields: [
                    {
                      id: "narcotics_habit_left_date",
                      title: "Habit left date",
                      type: "date",
                      isVisible: true,
                      name: "narcotics_habit_left_date",
                      options: ["Yes", "No"],
                      selectedValue: "1912-12-12",
                      placeholder: "",
                      errorText: "",
                      isRequired: false,
                      maxLength: 0,
                      minLength: 0,
                      isCascade: false,
                      isValid:true,
                      cascadeItems: {}
                    },
                    {
                      id: "narcotics_habit_left_reason",
                      title: "Habit left reason",
                      type: "textarea",
                      isVisible: true,
                      name: "narcotics_habit_left_reason",
                      options: ["Yes", "No"],
                      selectedValue: "",
                      placeholder: "",
                      errorText: "",
                      isRequired: false,
                      maxLength: 0,
                      minLength: 0,
                      isCascade: false,
                      isValid:true,
                      cascadeItems: {}
                    }
                  ]
                }
              }
            ]
          }
        }
      ]
    };
  constructor(private fb: FormBuilder, private _lookupService: LookUpService, private commonService:CommonService, private insuranceService: InsuranceService, private generalService: GeneralService, private route : ActivatedRoute, private router: Router, private sanitizer: DomSanitizer, private spinner: NgxSpinnerService){
    this.getAge();
    this.getFeet();
    this.getInch();
    // this.quoteData = JSON.parse(this.generalService.getDataToLocalStorage("healthBriefData"));
    // this.healthData = JSON.parse(this.generalService.getDataToLocalStorage("healthDetailData"));
    this.rId = this.route.snapshot.queryParams['q'];      
    this.uId = this.route.snapshot.queryParams['u'];
    if(this.rId == undefined || this.rId == null){
      this.rId = this.route.snapshot.paramMap.get('q')??"";
      this.uId = this.route.snapshot.paramMap.get('u')??undefined;
    }
  }

  ngOnInit(){
    this.spinner.hide();
    this.maxDate = moment(this.today).format('YYYY-MM-DD');
    this.userForm = this.fb.group({
      "firstName": ['', [Validators.required]],
      "phoneNumber": ['', [Validators.required, Validators.pattern('[0-9]{10}')]],
      "countryCode": [''],
      "email": ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      "dob": [''],
      "gender": ['Male', [Validators.required]],
      "streetAddress": ['', [Validators.required]],
      "pincode": ['', [Validators.required, Validators.pattern('[0-9]{6}')]],
      "pincodeId": [''],
      "dateOfBirth": ['', [Validators.required]],
      "panNumber": ['', [Validators.required, Validators.pattern('[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}')]],
      "flatNo": ['', [Validators.required]],
      "city": ['', [Validators.required]],
      "district": [''],
      "state": ['', [Validators.required]],
      "title": ['Mr'],
    });

    this.proposerForm = this.fb.group({
      // "proposerSelf": [''],
      "proposerFirstName": ['', [Validators.required]],
      "proposerLastName": ['', [Validators.required]],
      "proposerGender": [''],
      "proposerPancard": ['', [Validators.required, Validators.pattern('[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}')]],
      "proposerEmail": ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      "proposerMobile": ['', [Validators.required, Validators.pattern('[0-9]{10}')]],
      "selfDOB": ['', [Validators.required]],
      "proposerTitle": ['Mr', [Validators.required]],
    });

    this.addressInfo = this.fb.group({
      "flatNumber": ['', [Validators.required]],
      "streetAddress": ['', [Validators.required]],
      "proposerPinCode": ['', [Validators.required]],
      "proposerState": [''],
      "proposerCity": [''],
      "district": [''],
    });

    this.insurerForm = this.fb.group({
      "selfFirstName": [''],
      "selfLastName": [''],
      "selfDOB": [''],
      "selfHeightFeet": [''],
      "selfHeightInches": [''],
      "selfWeight": [''],
      "selfOccupation": [''],
      "spouseFirstName": [''],
      "spouseLastName": [''],
      "spouseDOB": [''],
      "spouseHeightFeet": [''],
      "spouseHeightInches": [''],
      "spouseWeight": [''],
      "fatherFirstName": [''],
      "fatherLastName": [''],
      "fatherDOB": [''],
      "fatherHeightFeet": [''],
      "fatherHeightInches": [''],
      "fatherWeight": [''],
      "motherFirstName": [''],
      "motherLastName": [''],
      "motherDOB": [''],
      "motherHeightFeet": [''],
      "motherHeightInches": [''],
      "motherWeight": [''],
      "grandPaFirstName": [''],
      "grandPaLastName": [''],
      "grandPaDOB": [''],
      "grandPaHeightFeet": [''],
      "grandPaHeightInches": [''],
      "grandPaWeight": [''],
      "grandMaFirstName": [''],
      "grandMaLastName": [''],
      "grandMaDOB": [''],
      "grandMaHeightFeet": [''],
      "grandMaHeightInches": [''],
      "grandMaWeight": [''],
      "fatherLawFirstName": [''],
      "fatherLawLastName": [''],
      "fatherLawDOB": [''],
      "fatherLawHeightFeet": [''],
      "fatherLawHeightInches": [''],
      "fatherLawWeight": [''],
      "motherLawFirstName": [''],
      "motherLawLastName": [''],
      "motherLawDOB": [''],
      "motherLawHeightFeet": [''],
      "motherLawHeightInches": [''],
      "motherLawWeight": [''],
      "uncleFirstName": [''],
      "uncleLastName": [''],
      "uncleDOB": [''],
      "uncleHeightFeet": [''],
      "uncleHeightInches": [''],
      "uncleWeight": [''],
      "auntFirstName": [''],
      "auntLastName": [''],
      "auntDOB": [''],
      "auntHeightFeet": [''],
      "auntHeightInches": [''],
      "auntWeight": [''],
      "son1FirstName": [''],
      "son1LastName": [''],
      "son1DOB": [''],
      "son1HeightFeet": [''],
      "son1HeightInches": [''],
      "son1Weight": [''],
      "son2FirstName": [''],
      "son2LastName": [''],
      "son2DOB": [''],
      "son2HeightFeet": [''],
      "son2HeightInches": [''],
      "son2Weight": [''],
      "son3FirstName": [''],
      "son3LastName": [''],
      "son3DOB": [''],
      "son3HeightFeet": [''],
      "son3HeightInches": [''],
      "son3Weight": [''],
      "son4FirstName": [''],
      "son4LastName": [''],
      "son4DOB": [''],
      "son4HeightFeet": [''],
      "son4HeightInches": [''],
      "son4Weight": [''],
      "dau1FirstName": [''],
      "dau1LastName": [''],
      "dau1DOB": [''],
      "dau1HeightFeet": [''],
      "dau1HeightInches": [''],
      "dau1Weight": [''],
      "dau2FirstName": [''],
      "dau2LastName": [''],
      "dau2DOB": [''],
      "dau2HeightFeet": [''],
      "dau2HeightInches": [''],
      "dau2Weight": [''],
      "dau3FirstName": [''],
      "dau3LastName": [''],
      "dau3DOB": [''],
      "dau3HeightFeet": [''],
      "dau3HeightInches": [''],
      "dau3Weight": [''],
      "dau4FirstName": [''],
      "dau4LastName": [''],
      "dau4DOB": [''],
      "dau4HeightFeet": [''],
      "dau4HeightInches": [''],
      "dau4Weight": [''],
      "spouseOccupation": [''],
      "son1Occupation": [''],
      "son2Occupation": [''],
      "son3Occupation": [''],
      "son4Occupation": [''],
      "dau1Occupation": [''],
      "dau2Occupation": [''],
      "dau3Occupation": [''],
      "dau4Occupation": [''],
      "FatherOccupation": [''],
      "MotherOccupation": [''],
      "grandPaOccupation": [''],
      "grandMaOccupation": [''],
      "fatherLawOccupation": [''],
      "motherLawOccupation": [''],
      "uncleOccupation": [''],
      "auntOccupation": [''],
    });
    this.nomineeForm = this.fb.group({
      "nomineeFirstName": ['', [Validators.required]],
      "nomineeLastName": ['', [Validators.required]],
      "nomineeDOB": ['', [Validators.required]],
      "nomineeRelationWithProposer": ['', [Validators.required]],
      // "nomineeContactNumber": ['', [Validators.required]],
      "nomineeGender": ['', [Validators.required]],
    });

        // let healthObj ={
    //   quoteData :{},
    //   healthData : {},
    //   proposerForm : {},
    //   addressInfo : {},
    //   nomineeForm : {},
    //   selectedQuoteData :{}
    // };
    let healthObjStr = this.generalService.getDataToLocalStorage("healthObj");
    if(healthObjStr != null && healthObjStr != undefined && healthObjStr != ''){
      let healthObj = JSON.parse(healthObjStr);
      this.quoteData = healthObj.quoteData;
      this.healthData = healthObj.healthData;
      this.selectedQuoteData = healthObj.selectedQuoteData;
      this.insurerFormValidationRemove();
      this.insurerFormValidationAdd();
    }
    // if(healthObj.proposerForm && healthObj.proposerForm)
    // this.proposalForm.patchValue(healthObj.proposerForm)
  }

  insurerFormValidationRemove(){
    //if (this.healthData?.selfSelected) 
      {
      this.insurerForm.get('selfFirstName')?.clearValidators();
      this.insurerForm.get('selfFirstName')?.updateValueAndValidity();
      this.insurerForm.get('selfLastName')?.clearValidators();
      this.insurerForm.get('selfLastName')?.updateValueAndValidity();
      this.insurerForm.get('selfDOB')?.clearValidators();
      this.insurerForm.get('selfDOB')?.updateValueAndValidity();
      this.insurerForm.get('selfHeightFeet')?.clearValidators();
      this.insurerForm.get('selfHeightFeet')?.updateValueAndValidity();
      this.insurerForm.get('selfHeightInches')?.clearValidators();
      this.insurerForm.get('selfHeightInches')?.updateValueAndValidity();
      this.insurerForm.get('selfWeight')?.clearValidators();
      this.insurerForm.get('selfWeight')?.updateValueAndValidity();
      this.insurerForm.get('selfOccupation')?.clearValidators();
      this.insurerForm.get('selfOccupation')?.updateValueAndValidity();
      }
    //if (this.healthData?.spouseSelected) 
      {
      this.insurerForm.get('spouseFirstName')?.clearValidators();
      this.insurerForm.get('spouseFirstName')?.updateValueAndValidity();
      this.insurerForm.get('spouseLastName')?.clearValidators();
      this.insurerForm.get('spouseLastName')?.updateValueAndValidity();
      this.insurerForm.get('spouseDOB')?.clearValidators();
      this.insurerForm.get('spouseDOB')?.updateValueAndValidity();
      this.insurerForm.get('spouseHeightFeet')?.clearValidators();
      this.insurerForm.get('spouseHeightFeet')?.updateValueAndValidity();
      this.insurerForm.get('spouseHeightInches')?.clearValidators();
      this.insurerForm.get('spouseHeightInches')?.updateValueAndValidity();
      this.insurerForm.get('spouseWeight')?.clearValidators();
      this.insurerForm.get('spouseWeight')?.updateValueAndValidity();
      this.insurerForm.get('spouseOccupation')?.clearValidators();
      this.insurerForm.get('spouseOccupation')?.updateValueAndValidity();
      }
      //if (this.healthData?.fatherSelected) 
      {
      this.insurerForm.get('fatherFirstName')?.clearValidators();
      this.insurerForm.get('fatherFirstName')?.updateValueAndValidity();
      this.insurerForm.get('fatherLastName')?.clearValidators();
      this.insurerForm.get('fatherLastName')?.updateValueAndValidity();
      this.insurerForm.get('fatherDOB')?.clearValidators();
      this.insurerForm.get('fatherDOB')?.updateValueAndValidity();
      this.insurerForm.get('fatherHeightFeet')?.clearValidators();
      this.insurerForm.get('fatherHeightFeet')?.updateValueAndValidity();
      this.insurerForm.get('fatherHeightInches')?.clearValidators();
      this.insurerForm.get('fatherHeightInches')?.updateValueAndValidity();
      this.insurerForm.get('fatherWeight')?.clearValidators();
      this.insurerForm.get('fatherWeight')?.updateValueAndValidity();
      this.insurerForm.get('FatherOccupation')?.clearValidators();
      this.insurerForm.get('FatherOccupation')?.updateValueAndValidity();
      }
      //if (this.healthData?.motherSelected) 
      {
      this.insurerForm.get('motherFirstName')?.clearValidators();
      this.insurerForm.get('motherFirstName')?.updateValueAndValidity();
      this.insurerForm.get('motherLastName')?.clearValidators();
      this.insurerForm.get('motherLastName')?.updateValueAndValidity();
      this.insurerForm.get('motherDOB')?.clearValidators();
      this.insurerForm.get('motherDOB')?.updateValueAndValidity();
      this.insurerForm.get('motherHeightFeet')?.clearValidators();
      this.insurerForm.get('motherHeightFeet')?.updateValueAndValidity();
      this.insurerForm.get('motherHeightInches')?.clearValidators();
      this.insurerForm.get('motherHeightInches')?.updateValueAndValidity();
      this.insurerForm.get('motherWeight')?.clearValidators();
      this.insurerForm.get('motherWeight')?.updateValueAndValidity();
      this.insurerForm.get('MotherOccupation')?.clearValidators();
      this.insurerForm.get('MotherOccupation')?.updateValueAndValidity();
      }
      //if (this.healthData?.grandPaSelected) 
      {
      this.insurerForm.get('grandPaFirstName')?.clearValidators();
      this.insurerForm.get('grandPaFirstName')?.updateValueAndValidity();
      this.insurerForm.get('grandPaLastName')?.clearValidators();
      this.insurerForm.get('grandPaLastName')?.updateValueAndValidity();
      this.insurerForm.get('grandPaDOB')?.clearValidators();
      this.insurerForm.get('grandPaDOB')?.updateValueAndValidity();
      this.insurerForm.get('grandPaHeightFeet')?.clearValidators();
      this.insurerForm.get('grandPaHeightFeet')?.updateValueAndValidity();
      this.insurerForm.get('grandPaHeightInches')?.clearValidators();
      this.insurerForm.get('grandPaHeightInches')?.updateValueAndValidity();
      this.insurerForm.get('grandPaWeight')?.clearValidators();
      this.insurerForm.get('grandPaWeight')?.updateValueAndValidity();
      this.insurerForm.get('grandPaOccupation')?.clearValidators();
      this.insurerForm.get('grandPaOccupation')?.updateValueAndValidity();
      }
      //if (this.healthData?.grandMaSelected) 
      {
      this.insurerForm.get('grandMaFirstName')?.clearValidators();
      this.insurerForm.get('grandMaFirstName')?.updateValueAndValidity();
      this.insurerForm.get('grandMaLastName')?.clearValidators();
      this.insurerForm.get('grandMaLastName')?.updateValueAndValidity();
      this.insurerForm.get('grandMaDOB')?.clearValidators();
      this.insurerForm.get('grandMaDOB')?.updateValueAndValidity();
      this.insurerForm.get('grandMaHeightFeet')?.clearValidators();
      this.insurerForm.get('grandMaHeightFeet')?.updateValueAndValidity();
      this.insurerForm.get('grandMaHeightInches')?.clearValidators();
      this.insurerForm.get('grandMaHeightInches')?.updateValueAndValidity();
      this.insurerForm.get('grandMaWeight')?.clearValidators();
      this.insurerForm.get('grandMaWeight')?.updateValueAndValidity();
      this.insurerForm.get('grandMaOccupation')?.clearValidators();
      this.insurerForm.get('grandMaOccupation')?.updateValueAndValidity();
      }
      //if (this.healthData?.fatherLawSelected) 
      {
      this.insurerForm.get('fatherLawFirstName')?.clearValidators();
      this.insurerForm.get('fatherLawFirstName')?.updateValueAndValidity();
      this.insurerForm.get('fatherLawLastName')?.clearValidators();
      this.insurerForm.get('fatherLawLastName')?.updateValueAndValidity();
      this.insurerForm.get('fatherLawDOB')?.clearValidators();
      this.insurerForm.get('fatherLawDOB')?.updateValueAndValidity();
      this.insurerForm.get('fatherLawHeightFeet')?.clearValidators();
      this.insurerForm.get('fatherLawHeightFeet')?.updateValueAndValidity();
      this.insurerForm.get('fatherLawHeightInches')?.clearValidators();
      this.insurerForm.get('fatherLawHeightInches')?.updateValueAndValidity();
      this.insurerForm.get('fatherLawWeight')?.clearValidators();
      this.insurerForm.get('fatherLawWeight')?.updateValueAndValidity();
      this.insurerForm.get('fatherLawOccupation')?.clearValidators();
      this.insurerForm.get('fatherLawOccupation')?.updateValueAndValidity();
      }
      //if (this.healthData?.MotherLawSelected) 
      {
      this.insurerForm.get('motherLawFirstName')?.clearValidators();
      this.insurerForm.get('motherLawFirstName')?.updateValueAndValidity();
      this.insurerForm.get('motherLawLastName')?.clearValidators();
      this.insurerForm.get('motherLawLastName')?.updateValueAndValidity();
      this.insurerForm.get('motherLawDOB')?.clearValidators();
      this.insurerForm.get('motherLawDOB')?.updateValueAndValidity();
      this.insurerForm.get('motherLawHeightFeet')?.clearValidators();
      this.insurerForm.get('motherLawHeightFeet')?.updateValueAndValidity();
      this.insurerForm.get('motherLawHeightInches')?.clearValidators();
      this.insurerForm.get('motherLawHeightInches')?.updateValueAndValidity();
      this.insurerForm.get('motherLawWeight')?.clearValidators();
      this.insurerForm.get('motherLawWeight')?.updateValueAndValidity();
      this.insurerForm.get('motherLawOccupation')?.clearValidators();
      this.insurerForm.get('motherLawOccupation')?.updateValueAndValidity();
      }
      //if (this.healthData?.uncleSelected) 
      {
      this.insurerForm.get('uncleFirstName')?.clearValidators();
      this.insurerForm.get('uncleFirstName')?.updateValueAndValidity();
      this.insurerForm.get('uncleLastName')?.clearValidators();
      this.insurerForm.get('uncleLastName')?.updateValueAndValidity();
      this.insurerForm.get('uncleDOB')?.clearValidators();
      this.insurerForm.get('uncleDOB')?.updateValueAndValidity();
      this.insurerForm.get('uncleHeightFeet')?.clearValidators();
      this.insurerForm.get('uncleHeightFeet')?.updateValueAndValidity();
      this.insurerForm.get('uncleHeightInches')?.clearValidators();
      this.insurerForm.get('uncleHeightInches')?.updateValueAndValidity();
      this.insurerForm.get('uncleWeight')?.clearValidators();
      this.insurerForm.get('uncleWeight')?.updateValueAndValidity();
      this.insurerForm.get('uncleOccupation')?.clearValidators();
      this.insurerForm.get('uncleOccupation')?.updateValueAndValidity();
      }
      //if (this.healthData?.auntSelected) 
      {
      this.insurerForm.get('auntFirstName')?.clearValidators();
      this.insurerForm.get('auntFirstName')?.updateValueAndValidity();
      this.insurerForm.get('auntLastName')?.clearValidators();
      this.insurerForm.get('auntLastName')?.updateValueAndValidity();
      this.insurerForm.get('auntDOB')?.clearValidators();
      this.insurerForm.get('auntDOB')?.updateValueAndValidity();
      this.insurerForm.get('auntHeightFeet')?.clearValidators();
      this.insurerForm.get('auntHeightFeet')?.updateValueAndValidity();
      this.insurerForm.get('auntHeightInches')?.clearValidators();
      this.insurerForm.get('auntHeightInches')?.updateValueAndValidity();
      this.insurerForm.get('auntWeight')?.clearValidators();
      this.insurerForm.get('auntWeight')?.updateValueAndValidity();
      this.insurerForm.get('auntOccupation')?.clearValidators();
      this.insurerForm.get('auntOccupation')?.updateValueAndValidity();
      }
      //if (this.healthData?.firstSone) 
      {
      this.insurerForm.get('son1FirstName')?.clearValidators();
      this.insurerForm.get('son1FirstName')?.updateValueAndValidity();
      this.insurerForm.get('son1LastName')?.clearValidators();
      this.insurerForm.get('son1LastName')?.updateValueAndValidity();
      this.insurerForm.get('son1DOB')?.clearValidators();
      this.insurerForm.get('son1DOB')?.updateValueAndValidity();
      this.insurerForm.get('son1HeightFeet')?.clearValidators();
      this.insurerForm.get('son1HeightFeet')?.updateValueAndValidity();
      this.insurerForm.get('son1HeightInches')?.clearValidators();
      this.insurerForm.get('son1HeightInches')?.updateValueAndValidity();
      this.insurerForm.get('son1Weight')?.clearValidators();
      this.insurerForm.get('son1Weight')?.updateValueAndValidity();
      this.insurerForm.get('son1Occupation')?.clearValidators();
      this.insurerForm.get('son1Occupation')?.updateValueAndValidity();
      }
      //if (this.healthData?.secondSon) 
      {
      this.insurerForm.get('son2FirstName')?.clearValidators();
      this.insurerForm.get('son2FirstName')?.updateValueAndValidity();
      this.insurerForm.get('son2LastName')?.clearValidators();
      this.insurerForm.get('son2LastName')?.updateValueAndValidity();
      this.insurerForm.get('son2DOB')?.clearValidators();
      this.insurerForm.get('son2DOB')?.updateValueAndValidity();
      this.insurerForm.get('son2HeightFeet')?.clearValidators();
      this.insurerForm.get('son2HeightFeet')?.updateValueAndValidity();
      this.insurerForm.get('son2HeightInches')?.clearValidators();
      this.insurerForm.get('son2HeightInches')?.updateValueAndValidity();
      this.insurerForm.get('son2Weight')?.clearValidators();
      this.insurerForm.get('son2Weight')?.updateValueAndValidity();
      this.insurerForm.get('son2Occupation')?.clearValidators();
      this.insurerForm.get('son2Occupation')?.updateValueAndValidity();
      }
      //if (this.healthData?.thirdSon) 
      {
      this.insurerForm.get('son3FirstName')?.clearValidators();
      this.insurerForm.get('son3FirstName')?.updateValueAndValidity();
      this.insurerForm.get('son3LastName')?.clearValidators();
      this.insurerForm.get('son3LastName')?.updateValueAndValidity();
      this.insurerForm.get('son3DOB')?.clearValidators();
      this.insurerForm.get('son3DOB')?.updateValueAndValidity();
      this.insurerForm.get('son3HeightFeet')?.clearValidators();
      this.insurerForm.get('son3HeightFeet')?.updateValueAndValidity();
      this.insurerForm.get('son3HeightInches')?.clearValidators();
      this.insurerForm.get('son3HeightInches')?.updateValueAndValidity();
      this.insurerForm.get('son3Weight')?.clearValidators();
      this.insurerForm.get('son3Weight')?.updateValueAndValidity();
      this.insurerForm.get('son3Occupation')?.clearValidators();
      this.insurerForm.get('son3Occupation')?.updateValueAndValidity();
      }
      //if (this.healthData?.fourthSon) 
      {
      this.insurerForm.get('son4FirstName')?.clearValidators();
      this.insurerForm.get('son4FirstName')?.updateValueAndValidity();
      this.insurerForm.get('son4LastName')?.clearValidators();
      this.insurerForm.get('son4LastName')?.updateValueAndValidity();
      this.insurerForm.get('son4DOB')?.clearValidators();
      this.insurerForm.get('son4DOB')?.updateValueAndValidity();
      this.insurerForm.get('son4HeightFeet')?.clearValidators();
      this.insurerForm.get('son4HeightFeet')?.updateValueAndValidity();
      this.insurerForm.get('son4HeightInches')?.clearValidators();
      this.insurerForm.get('son4HeightInches')?.updateValueAndValidity();
      this.insurerForm.get('son4Weight')?.clearValidators();
      this.insurerForm.get('son4Weight')?.updateValueAndValidity();
      this.insurerForm.get('son4Occupation')?.clearValidators();
      this.insurerForm.get('son4Occupation')?.updateValueAndValidity();
      }
      //if (this.healthData?.firstDaughter) 
      {
      this.insurerForm.get('dau1FirstName')?.clearValidators();
      this.insurerForm.get('dau1FirstName')?.updateValueAndValidity();
      this.insurerForm.get('dau1LastName')?.clearValidators();
      this.insurerForm.get('dau1LastName')?.updateValueAndValidity();
      this.insurerForm.get('dau1DOB')?.clearValidators();
      this.insurerForm.get('dau1DOB')?.updateValueAndValidity();
      this.insurerForm.get('dau1HeightFeet')?.clearValidators();
      this.insurerForm.get('dau1HeightFeet')?.updateValueAndValidity();
      this.insurerForm.get('dau1HeightInches')?.clearValidators();
      this.insurerForm.get('dau1HeightInches')?.updateValueAndValidity();
      this.insurerForm.get('dau1Weight')?.clearValidators();
      this.insurerForm.get('dau1Weight')?.updateValueAndValidity();
      this.insurerForm.get('dau1Occupation')?.clearValidators();
      this.insurerForm.get('dau1Occupation')?.updateValueAndValidity();
      }
      //if (this.healthData?.secondDaughter) 
      {
      this.insurerForm.get('dau2FirstName')?.clearValidators();
      this.insurerForm.get('dau2FirstName')?.updateValueAndValidity();
      this.insurerForm.get('dau2LastName')?.clearValidators();
      this.insurerForm.get('dau2LastName')?.updateValueAndValidity();
      this.insurerForm.get('dau2DOB')?.clearValidators();
      this.insurerForm.get('dau2DOB')?.updateValueAndValidity();
      this.insurerForm.get('dau2HeightFeet')?.clearValidators();
      this.insurerForm.get('dau2HeightFeet')?.updateValueAndValidity();
      this.insurerForm.get('dau2HeightInches')?.clearValidators();
      this.insurerForm.get('dau2HeightInches')?.updateValueAndValidity();
      this.insurerForm.get('dau2Weight')?.clearValidators();
      this.insurerForm.get('dau2Weight')?.updateValueAndValidity();
      this.insurerForm.get('dau2Occupation')?.clearValidators();
      this.insurerForm.get('dau2Occupation')?.updateValueAndValidity();
      }
      //if (this.healthData?.thirdDaughter) 
      {
      this.insurerForm.get('dau3FirstName')?.clearValidators();
      this.insurerForm.get('dau3FirstName')?.updateValueAndValidity();
      this.insurerForm.get('dau3LastName')?.clearValidators();
      this.insurerForm.get('dau3LastName')?.updateValueAndValidity();
      this.insurerForm.get('dau3DOB')?.clearValidators();
      this.insurerForm.get('dau3DOB')?.updateValueAndValidity();
      this.insurerForm.get('dau3HeightFeet')?.clearValidators();
      this.insurerForm.get('dau3HeightFeet')?.updateValueAndValidity();
      this.insurerForm.get('dau3HeightInches')?.clearValidators();
      this.insurerForm.get('dau3HeightInches')?.updateValueAndValidity();
      this.insurerForm.get('dau3Weight')?.clearValidators();
      this.insurerForm.get('dau3Weight')?.updateValueAndValidity();
      this.insurerForm.get('dau3Occupation')?.clearValidators();
      this.insurerForm.get('dau3Occupation')?.updateValueAndValidity();
      }
      //if (this.healthData?.fourthDaughter) 
      {
      this.insurerForm.get('dau4FirstName')?.clearValidators();
      this.insurerForm.get('dau4FirstName')?.updateValueAndValidity();
      this.insurerForm.get('dau4LastName')?.clearValidators();
      this.insurerForm.get('dau4LastName')?.updateValueAndValidity();
      this.insurerForm.get('dau4DOB')?.clearValidators();
      this.insurerForm.get('dau4DOB')?.updateValueAndValidity();
      this.insurerForm.get('dau4HeightFeet')?.clearValidators();
      this.insurerForm.get('dau4HeightFeet')?.updateValueAndValidity();
      this.insurerForm.get('dau4HeightInches')?.clearValidators();
      this.insurerForm.get('dau4HeightInches')?.updateValueAndValidity();
      this.insurerForm.get('dau4Weight')?.clearValidators();
      this.insurerForm.get('dau4Weight')?.updateValueAndValidity();
      this.insurerForm.get('dau4Occupation')?.clearValidators();
      this.insurerForm.get('dau4Occupation')?.updateValueAndValidity();
      }            
  }

  insurerFormValidationAdd(){
    if (this.healthData?.selfSelected) {
      this.insurerForm.get('selfFirstName')?.addValidators(Validators.required);
      this.insurerForm.get('selfFirstName')?.updateValueAndValidity();
      this.insurerForm.get('selfLastName')?.addValidators(Validators.required);
      this.insurerForm.get('selfLastName')?.updateValueAndValidity();
      this.insurerForm.get('selfDOB')?.addValidators(Validators.required);
      this.insurerForm.get('selfDOB')?.updateValueAndValidity();
      this.insurerForm.get('selfHeightFeet')?.addValidators(Validators.required);
      this.insurerForm.get('selfHeightFeet')?.updateValueAndValidity();
      this.insurerForm.get('selfHeightInches')?.addValidators(Validators.required);
      this.insurerForm.get('selfHeightInches')?.updateValueAndValidity();
      this.insurerForm.get('selfWeight')?.addValidators(Validators.required);
      this.insurerForm.get('selfWeight')?.updateValueAndValidity();
      this.insurerForm.get('selfOccupation')?.addValidators(Validators.required);
      this.insurerForm.get('selfOccupation')?.updateValueAndValidity();
      }
    if (this.healthData?.spouseSelected) {
      this.insurerForm.get('spouseFirstName')?.addValidators(Validators.required);
      this.insurerForm.get('spouseFirstName')?.updateValueAndValidity();
      this.insurerForm.get('spouseLastName')?.addValidators(Validators.required);
      this.insurerForm.get('spouseLastName')?.updateValueAndValidity();
      this.insurerForm.get('spouseDOB')?.addValidators(Validators.required);
      this.insurerForm.get('spouseDOB')?.updateValueAndValidity();
      this.insurerForm.get('spouseHeightFeet')?.addValidators(Validators.required);
      this.insurerForm.get('spouseHeightFeet')?.updateValueAndValidity();
      this.insurerForm.get('spouseHeightInches')?.addValidators(Validators.required);
      this.insurerForm.get('spouseHeightInches')?.updateValueAndValidity();
      this.insurerForm.get('spouseWeight')?.addValidators(Validators.required);
      this.insurerForm.get('spouseWeight')?.updateValueAndValidity();
      this.insurerForm.get('spouseOccupation')?.addValidators(Validators.required);
      this.insurerForm.get('spouseOccupation')?.updateValueAndValidity();
      }
    if (this.healthData?.fatherSelected) {
      this.insurerForm.get('fatherFirstName')?.addValidators(Validators.required);
      this.insurerForm.get('fatherFirstName')?.updateValueAndValidity();
      this.insurerForm.get('fatherLastName')?.addValidators(Validators.required);
      this.insurerForm.get('fatherLastName')?.updateValueAndValidity();
      this.insurerForm.get('fatherDOB')?.addValidators(Validators.required);
      this.insurerForm.get('fatherDOB')?.updateValueAndValidity();
      this.insurerForm.get('fatherHeightFeet')?.addValidators(Validators.required);
      this.insurerForm.get('fatherHeightFeet')?.updateValueAndValidity();
      this.insurerForm.get('fatherHeightInches')?.addValidators(Validators.required);
      this.insurerForm.get('fatherHeightInches')?.updateValueAndValidity();
      this.insurerForm.get('fatherWeight')?.addValidators(Validators.required);
      this.insurerForm.get('fatherWeight')?.updateValueAndValidity();
      this.insurerForm.get('FatherOccupation')?.addValidators(Validators.required);
      this.insurerForm.get('FatherOccupation')?.updateValueAndValidity();
      }
    if (this.healthData?.motherSelected) {
      this.insurerForm.get('motherFirstName')?.addValidators(Validators.required);
      this.insurerForm.get('motherFirstName')?.updateValueAndValidity();
      this.insurerForm.get('motherLastName')?.addValidators(Validators.required);
      this.insurerForm.get('motherLastName')?.updateValueAndValidity();
      this.insurerForm.get('motherDOB')?.addValidators(Validators.required);
      this.insurerForm.get('motherDOB')?.updateValueAndValidity();
      this.insurerForm.get('motherHeightFeet')?.addValidators(Validators.required);
      this.insurerForm.get('motherHeightFeet')?.updateValueAndValidity();
      this.insurerForm.get('motherHeightInches')?.addValidators(Validators.required);
      this.insurerForm.get('motherHeightInches')?.updateValueAndValidity();
      this.insurerForm.get('motherWeight')?.addValidators(Validators.required);
      this.insurerForm.get('motherWeight')?.updateValueAndValidity();
      this.insurerForm.get('MotherOccupation')?.addValidators(Validators.required);
      this.insurerForm.get('MotherOccupation')?.updateValueAndValidity();
      }
    if (this.healthData?.grandPaSelected) {
      this.insurerForm.get('grandPaFirstName')?.addValidators(Validators.required);
      this.insurerForm.get('grandPaFirstName')?.updateValueAndValidity();
      this.insurerForm.get('grandPaLastName')?.addValidators(Validators.required);
      this.insurerForm.get('grandPaLastName')?.updateValueAndValidity();
      this.insurerForm.get('grandPaDOB')?.addValidators(Validators.required);
      this.insurerForm.get('grandPaDOB')?.updateValueAndValidity();
      this.insurerForm.get('grandPaHeightFeet')?.addValidators(Validators.required);
      this.insurerForm.get('grandPaHeightFeet')?.updateValueAndValidity();
      this.insurerForm.get('grandPaHeightInches')?.addValidators(Validators.required);
      this.insurerForm.get('grandPaHeightInches')?.updateValueAndValidity();
      this.insurerForm.get('grandPaWeight')?.addValidators(Validators.required);
      this.insurerForm.get('grandPaWeight')?.updateValueAndValidity();
      this.insurerForm.get('grandPaOccupation')?.addValidators(Validators.required);
      this.insurerForm.get('grandPaOccupation')?.updateValueAndValidity();
      }
    if (this.healthData?.grandMaSelected) {
      this.insurerForm.get('grandMaFirstName')?.addValidators(Validators.required);
      this.insurerForm.get('grandMaFirstName')?.updateValueAndValidity();
      this.insurerForm.get('grandMaLastName')?.addValidators(Validators.required);
      this.insurerForm.get('grandMaLastName')?.updateValueAndValidity();
      this.insurerForm.get('grandMaDOB')?.addValidators(Validators.required);
      this.insurerForm.get('grandMaDOB')?.updateValueAndValidity();
      this.insurerForm.get('grandMaHeightFeet')?.addValidators(Validators.required);
      this.insurerForm.get('grandMaHeightFeet')?.updateValueAndValidity();
      this.insurerForm.get('grandMaHeightInches')?.addValidators(Validators.required);
      this.insurerForm.get('grandMaHeightInches')?.updateValueAndValidity();
      this.insurerForm.get('grandMaWeight')?.addValidators(Validators.required);
      this.insurerForm.get('grandMaWeight')?.updateValueAndValidity();
      this.insurerForm.get('grandMaOccupation')?.addValidators(Validators.required);
      this.insurerForm.get('grandMaOccupation')?.updateValueAndValidity();
      }
    if (this.healthData?.fatherLawSelected) {
      this.insurerForm.get('fatherLawFirstName')?.addValidators(Validators.required);
      this.insurerForm.get('fatherLawFirstName')?.updateValueAndValidity();
      this.insurerForm.get('fatherLawLastName')?.addValidators(Validators.required);
      this.insurerForm.get('fatherLawLastName')?.updateValueAndValidity();
      this.insurerForm.get('fatherLawDOB')?.addValidators(Validators.required);
      this.insurerForm.get('fatherLawDOB')?.updateValueAndValidity();
      this.insurerForm.get('fatherLawHeightFeet')?.addValidators(Validators.required);
      this.insurerForm.get('fatherLawHeightFeet')?.updateValueAndValidity();
      this.insurerForm.get('fatherLawHeightInches')?.addValidators(Validators.required);
      this.insurerForm.get('fatherLawHeightInches')?.updateValueAndValidity();
      this.insurerForm.get('fatherLawWeight')?.addValidators(Validators.required);
      this.insurerForm.get('fatherLawWeight')?.updateValueAndValidity();
      this.insurerForm.get('fatherLawOccupation')?.addValidators(Validators.required);
      this.insurerForm.get('fatherLawOccupation')?.updateValueAndValidity();
      }
    if (this.healthData?.MotherLawSelected) {
      this.insurerForm.get('motherLawFirstName')?.addValidators(Validators.required);
      this.insurerForm.get('motherLawFirstName')?.updateValueAndValidity();
      this.insurerForm.get('motherLawLastName')?.addValidators(Validators.required);
      this.insurerForm.get('motherLawLastName')?.updateValueAndValidity();
      this.insurerForm.get('motherLawDOB')?.addValidators(Validators.required);
      this.insurerForm.get('motherLawDOB')?.updateValueAndValidity();
      this.insurerForm.get('motherLawHeightFeet')?.addValidators(Validators.required);
      this.insurerForm.get('motherLawHeightFeet')?.updateValueAndValidity();
      this.insurerForm.get('motherLawHeightInches')?.addValidators(Validators.required);
      this.insurerForm.get('motherLawHeightInches')?.updateValueAndValidity();
      this.insurerForm.get('motherLawWeight')?.addValidators(Validators.required);
      this.insurerForm.get('motherLawWeight')?.updateValueAndValidity();
      this.insurerForm.get('motherLawOccupation')?.addValidators(Validators.required);
      this.insurerForm.get('motherLawOccupation')?.updateValueAndValidity();
      }
    if (this.healthData?.uncleSelected) {
      this.insurerForm.get('uncleFirstName')?.addValidators(Validators.required);
      this.insurerForm.get('uncleFirstName')?.updateValueAndValidity();
      this.insurerForm.get('uncleLastName')?.addValidators(Validators.required);
      this.insurerForm.get('uncleLastName')?.updateValueAndValidity();
      this.insurerForm.get('uncleDOB')?.addValidators(Validators.required);
      this.insurerForm.get('uncleDOB')?.updateValueAndValidity();
      this.insurerForm.get('uncleHeightFeet')?.addValidators(Validators.required);
      this.insurerForm.get('uncleHeightFeet')?.updateValueAndValidity();
      this.insurerForm.get('uncleHeightInches')?.addValidators(Validators.required);
      this.insurerForm.get('uncleHeightInches')?.updateValueAndValidity();
      this.insurerForm.get('uncleWeight')?.addValidators(Validators.required);
      this.insurerForm.get('uncleWeight')?.updateValueAndValidity();
      this.insurerForm.get('uncleOccupation')?.addValidators(Validators.required);
      this.insurerForm.get('uncleOccupation')?.updateValueAndValidity();
      }
    if (this.healthData?.auntSelected) {
      this.insurerForm.get('auntFirstName')?.addValidators(Validators.required);
      this.insurerForm.get('auntFirstName')?.updateValueAndValidity();
      this.insurerForm.get('auntLastName')?.addValidators(Validators.required);
      this.insurerForm.get('auntLastName')?.updateValueAndValidity();
      this.insurerForm.get('auntDOB')?.addValidators(Validators.required);
      this.insurerForm.get('auntDOB')?.updateValueAndValidity();
      this.insurerForm.get('auntHeightFeet')?.addValidators(Validators.required);
      this.insurerForm.get('auntHeightFeet')?.updateValueAndValidity();
      this.insurerForm.get('auntHeightInches')?.addValidators(Validators.required);
      this.insurerForm.get('auntHeightInches')?.updateValueAndValidity();
      this.insurerForm.get('auntWeight')?.addValidators(Validators.required);
      this.insurerForm.get('auntWeight')?.updateValueAndValidity();
      this.insurerForm.get('auntOccupation')?.addValidators(Validators.required);
      this.insurerForm.get('auntOccupation')?.updateValueAndValidity();
      }
    if (this.healthData?.firstSone) {
      this.insurerForm.get('son1FirstName')?.addValidators(Validators.required);
      this.insurerForm.get('son1FirstName')?.updateValueAndValidity();
      this.insurerForm.get('son1LastName')?.addValidators(Validators.required);
      this.insurerForm.get('son1LastName')?.updateValueAndValidity();
      this.insurerForm.get('son1DOB')?.addValidators(Validators.required);
      this.insurerForm.get('son1DOB')?.updateValueAndValidity();
      this.insurerForm.get('son1HeightFeet')?.addValidators(Validators.required);
      this.insurerForm.get('son1HeightFeet')?.updateValueAndValidity();
      this.insurerForm.get('son1HeightInches')?.addValidators(Validators.required);
      this.insurerForm.get('son1HeightInches')?.updateValueAndValidity();
      this.insurerForm.get('son1Weight')?.addValidators(Validators.required);
      this.insurerForm.get('son1Weight')?.updateValueAndValidity();
      this.insurerForm.get('son1Occupation')?.addValidators(Validators.required);
      this.insurerForm.get('son1Occupation')?.updateValueAndValidity();
      }
    if (this.healthData?.secondSon) {
      this.insurerForm.get('son2FirstName')?.addValidators(Validators.required);
      this.insurerForm.get('son2FirstName')?.updateValueAndValidity();
      this.insurerForm.get('son2LastName')?.addValidators(Validators.required);
      this.insurerForm.get('son2LastName')?.updateValueAndValidity();
      this.insurerForm.get('son2DOB')?.addValidators(Validators.required);
      this.insurerForm.get('son2DOB')?.updateValueAndValidity();
      this.insurerForm.get('son2HeightFeet')?.addValidators(Validators.required);
      this.insurerForm.get('son2HeightFeet')?.updateValueAndValidity();
      this.insurerForm.get('son2HeightInches')?.addValidators(Validators.required);
      this.insurerForm.get('son2HeightInches')?.updateValueAndValidity();
      this.insurerForm.get('son2Weight')?.addValidators(Validators.required);
      this.insurerForm.get('son2Weight')?.updateValueAndValidity();
      this.insurerForm.get('son2Occupation')?.addValidators(Validators.required);
      this.insurerForm.get('son2Occupation')?.updateValueAndValidity();
      }
    if (this.healthData?.thirdSon) {
      this.insurerForm.get('son3FirstName')?.addValidators(Validators.required);
      this.insurerForm.get('son3FirstName')?.updateValueAndValidity();
      this.insurerForm.get('son3LastName')?.addValidators(Validators.required);
      this.insurerForm.get('son3LastName')?.updateValueAndValidity();
      this.insurerForm.get('son3DOB')?.addValidators(Validators.required);
      this.insurerForm.get('son3DOB')?.updateValueAndValidity();
      this.insurerForm.get('son3HeightFeet')?.addValidators(Validators.required);
      this.insurerForm.get('son3HeightFeet')?.updateValueAndValidity();
      this.insurerForm.get('son3HeightInches')?.addValidators(Validators.required);
      this.insurerForm.get('son3HeightInches')?.updateValueAndValidity();
      this.insurerForm.get('son3Weight')?.addValidators(Validators.required);
      this.insurerForm.get('son3Weight')?.updateValueAndValidity();
      this.insurerForm.get('son3Occupation')?.addValidators(Validators.required);
      this.insurerForm.get('son3Occupation')?.updateValueAndValidity();
      }
    if (this.healthData?.fourthSon) {
      this.insurerForm.get('son4FirstName')?.addValidators(Validators.required);
      this.insurerForm.get('son4FirstName')?.updateValueAndValidity();
      this.insurerForm.get('son4LastName')?.addValidators(Validators.required);
      this.insurerForm.get('son4LastName')?.updateValueAndValidity();
      this.insurerForm.get('son4DOB')?.addValidators(Validators.required);
      this.insurerForm.get('son4DOB')?.updateValueAndValidity();
      this.insurerForm.get('son4HeightFeet')?.addValidators(Validators.required);
      this.insurerForm.get('son4HeightFeet')?.updateValueAndValidity();
      this.insurerForm.get('son4HeightInches')?.addValidators(Validators.required);
      this.insurerForm.get('son4HeightInches')?.updateValueAndValidity();
      this.insurerForm.get('son4Weight')?.addValidators(Validators.required);
      this.insurerForm.get('son4Weight')?.updateValueAndValidity();
      this.insurerForm.get('son4Occupation')?.addValidators(Validators.required);
      this.insurerForm.get('son4Occupation')?.updateValueAndValidity();
      }
    if (this.healthData?.firstDaughter) {
      this.insurerForm.get('dau1FirstName')?.addValidators(Validators.required);
      this.insurerForm.get('dau1FirstName')?.updateValueAndValidity();
      this.insurerForm.get('dau1LastName')?.addValidators(Validators.required);
      this.insurerForm.get('dau1LastName')?.updateValueAndValidity();
      this.insurerForm.get('dau1DOB')?.addValidators(Validators.required);
      this.insurerForm.get('dau1DOB')?.updateValueAndValidity();
      this.insurerForm.get('dau1HeightFeet')?.addValidators(Validators.required);
      this.insurerForm.get('dau1HeightFeet')?.updateValueAndValidity();
      this.insurerForm.get('dau1HeightInches')?.addValidators(Validators.required);
      this.insurerForm.get('dau1HeightInches')?.updateValueAndValidity();
      this.insurerForm.get('dau1Weight')?.addValidators(Validators.required);
      this.insurerForm.get('dau1Weight')?.updateValueAndValidity();
      this.insurerForm.get('dau1Occupation')?.addValidators(Validators.required);
      this.insurerForm.get('dau1Occupation')?.updateValueAndValidity();
      }
    if (this.healthData?.secondDaughter) {
      this.insurerForm.get('dau2FirstName')?.addValidators(Validators.required);
      this.insurerForm.get('dau2FirstName')?.updateValueAndValidity();
      this.insurerForm.get('dau2LastName')?.addValidators(Validators.required);
      this.insurerForm.get('dau2LastName')?.updateValueAndValidity();
      this.insurerForm.get('dau2DOB')?.addValidators(Validators.required);
      this.insurerForm.get('dau2DOB')?.updateValueAndValidity();
      this.insurerForm.get('dau2HeightFeet')?.addValidators(Validators.required);
      this.insurerForm.get('dau2HeightFeet')?.updateValueAndValidity();
      this.insurerForm.get('dau2HeightInches')?.addValidators(Validators.required);
      this.insurerForm.get('dau2HeightInches')?.updateValueAndValidity();
      this.insurerForm.get('dau2Weight')?.addValidators(Validators.required);
      this.insurerForm.get('dau2Weight')?.updateValueAndValidity();
      this.insurerForm.get('dau2Occupation')?.addValidators(Validators.required);
      this.insurerForm.get('dau2Occupation')?.updateValueAndValidity();
      }
    if (this.healthData?.thirdDaughter) {
      this.insurerForm.get('dau3FirstName')?.addValidators(Validators.required);
      this.insurerForm.get('dau3FirstName')?.updateValueAndValidity();
      this.insurerForm.get('dau3LastName')?.addValidators(Validators.required);
      this.insurerForm.get('dau3LastName')?.updateValueAndValidity();
      this.insurerForm.get('dau3DOB')?.addValidators(Validators.required);
      this.insurerForm.get('dau3DOB')?.updateValueAndValidity();
      this.insurerForm.get('dau3HeightFeet')?.addValidators(Validators.required);
      this.insurerForm.get('dau3HeightFeet')?.updateValueAndValidity();
      this.insurerForm.get('dau3HeightInches')?.addValidators(Validators.required);
      this.insurerForm.get('dau3HeightInches')?.updateValueAndValidity();
      this.insurerForm.get('dau3Weight')?.addValidators(Validators.required);
      this.insurerForm.get('dau3Weight')?.updateValueAndValidity();
      this.insurerForm.get('dau3Occupation')?.addValidators(Validators.required);
      this.insurerForm.get('dau3Occupation')?.updateValueAndValidity();
      }
    if (this.healthData?.fourthDaughter) {
      this.insurerForm.get('dau4FirstName')?.addValidators(Validators.required);
      this.insurerForm.get('dau4FirstName')?.updateValueAndValidity();
      this.insurerForm.get('dau4LastName')?.addValidators(Validators.required);
      this.insurerForm.get('dau4LastName')?.updateValueAndValidity();
      this.insurerForm.get('dau4DOB')?.addValidators(Validators.required);
      this.insurerForm.get('dau4DOB')?.updateValueAndValidity();
      this.insurerForm.get('dau4HeightFeet')?.addValidators(Validators.required);
      this.insurerForm.get('dau4HeightFeet')?.updateValueAndValidity();
      this.insurerForm.get('dau4HeightInches')?.addValidators(Validators.required);
      this.insurerForm.get('dau4HeightInches')?.updateValueAndValidity();
      this.insurerForm.get('dau4Weight')?.addValidators(Validators.required);
      this.insurerForm.get('dau4Weight')?.updateValueAndValidity();
      this.insurerForm.get('dau4Occupation')?.addValidators(Validators.required);
      this.insurerForm.get('dau4Occupation')?.updateValueAndValidity();
      }
  }

  dropDown(val: string) {
    this.value = val;
    if (this.value == 'down') {
      this.down = false;
      this.Up = true;
      this.down1=true;
      this.Up1=false;
      this.down2=true;
      this.Up2=false;
      this.down3=true;
      this.Up3=false;
      this.child=false;
    }
    if (this.value == 'up') {
      this.down = true;
      this.Up = false;
    }
    if (this.value == 'down1') {
      this.down1 = false;
      this.Up1 = true;
      this.Up=false;
      this.down=true;
      this.Up3=false;
      this.down3=true;
      this.down2=true;
      this.Up2=false;
      
      this.child=false;

    }
    if (this.value == 'up1') {
      this.down1 = true;
      this.Up1 = false;
    }
    if (this.value == 'down3') {
      this.down3 = false;
      this.Up3 = true;
      this.down=true;
      this.Up=false;
      this.Up1=false;
      this.down1=true
      this.Up2=false;
      this.down2=true;
      this.child=false;
    }
    if (this.value == 'up3') {
      this.down3 = true;
      this.Up3 = false;
    }


  }
child:boolean=false;
  ChildDown() {
    this.down2 = false;
    this.Up2 = true;
    this.child=true;
    this.Up=false;
    this.Up1=false;
    this.Up3=false;
    this.down3=true;
    this.down=true;
    this.down1=true;
    
  }
  ChildUp() {
    this.down2 = true;
    this.Up2 = false;
    this.child=false;

   
  }
  tabs:string='';
next(pg:string)
{
this.tabs=pg;
if(this.tabs=='down')
{
  this.chDw=false;
  this.chUp=true;
}
if(this.tabs=='down1')
{
  this.chDw1=false;
  this.chUp1=true;
}
if(this.tabs=='down2')
{
  this.chDw2=false;
  this.chUp2=true;
}
if(this.tabs=='down3')
{
  this.chDw3=false;
  this.chUp3=true;
}
if(this.tabs=='up')
{
  this.chDw=true;
  this.chUp=false;
}
if(this.tabs=='up1')
{
  this.chDw1=true;
  this.chUp1=false;
}
if(this.tabs=='up2')
{
  this.chDw2=true;
  this.chUp2=false;
}
if(this.tabs=='up3')
{
  this.chDw3=true;
  this.chUp3=false;
}
}

get membersFormControl() {
  return this.membersForm.controls;
}
detail: string = 'proposer';
isproposal: boolean = true;
ismember: boolean = false;
ismedical: boolean = false;
isnominee: boolean = false;
submitted: boolean = false;


isproposerDetails: boolean = true;
ismemberDetails: boolean = false;
ismedicalDetails: boolean = false;
isnomineeDetails: boolean = false;
isproposerAddress: boolean = false;
proposerPancard: string = "";
insurerDetails: any[] = [];
insurerDetails1: any[] = [];
InsuranceDetails(value: any) {
  if (value == 'proposer') {

  }
  if (value == 'address') {
    this.detail = 'address';
  }
  if (value == 'members') {
    this.detail = 'members';
    this.proposerPancard = this.proposerForm?.value?.proposerPancard;
    this.insurerForm.get('selfFirstName')?.setValue(this.proposerForm?.value?.proposerFirstName);
    this.insurerForm.get('selfLastName')?.setValue(this.proposerForm?.value?.proposerLastName)

  }
  if (value == 'nominee') {
    this.submitted = true;
    if(this.insurerForm.valid){
      this.detail = 'nominee';
    }
  }
  if (value == 'medical') {
    this.detail = 'medical';
    if (this.insurerDetails.length == 0)
      this.formatInsurerDetails();
  }
}

backFunction(value: any) {
  this.detail = value;
  // if (value == 'proposer') {
  //   this.detail = 'proposer';
  // }
  // if (value == 'address') {
  //   this.detail = 'address';
  // }
  // if (value == 'member') {
  //   this.detail = 'member';
  // }
  // if (value == 'medical') {
  //   this.detail = 'medical';
  // }
  // if (value == 'nominee') {
  //   this.detail = 'nominee';
  // }
}

async continue() {
  if(this.validateData())
    {
      this.isSummary = true;
      this.fetchData();
      console.log(this.quoteData, "Quote Data");
      this.royalHealthObj = {
        proposerMobile: this.proposerForm?.value?.proposerMobile?.toString(),
        nomineeMobileNumber: this.nomineeForm?.value?.nomineeContactNumber?.toString(),
        proposerEmergencyMobile: this.proposerForm?.value?.proposerMobile?.toString(),
        proposerSelf: "true",
        nomineeContactNumber: this.nomineeForm?.value?.nomineeContactNumber?.toString(),
        nomineeDOB: this.nomineeForm?.value?.nomineeDOB?.toString(),
        proposerFirstName: this.proposerForm?.value?.proposerFirstName?.toString(),
        proposerLastName: this.proposerForm?.value?.proposerLastName?.toString(),
        proposerGender: this.healthData?.selfGender,
        proposerPancard: this.proposerForm?.value?.proposerPancard?.toString(),
        proposerEmail: this.proposerForm?.value?.proposerEmail?.toString(),
        nomineeRelationWithProposer: this.nomineeForm?.value?.nomineeRelationWithProposer?.toString(),
        nomineeFirstName: this.nomineeForm?.value?.nomineeFirstName?.toString(),
        nomineeLastName: this.nomineeForm?.value?.nomineeLastName?.toString(),
        nomineeGender: this.nomineeForm?.value?.nomineeGender?.toString(),
        QuoteId: this.selectedQuoteData?.quoteId,
        noOfAdults: this.quoteData?.noOfAdults,
        noOfChilds: this.quoteData?.noOfChilds,
        noOfPersons: this.quoteData?.noOfPersons,
        InsurerDetails: this.insurerDetails1,
        FlatNo: this.addressInfo?.value?.flatNumber,
        StreetAddress: this.addressInfo?.value?.streetAddress,
        Pincode: this.addressInfo?.value?.proposerPinCode,
        State: this.addressInfo?.value?.proposerState,
        City: this.addressInfo?.value?.proposerCity,
        District: this.addressInfo?.value?.district,
        premiumObject: this.selectedQuoteData?.priceBreakup,
        planType:this.selectedQuoteData?.planName,
        sumInsured:(this.selectedQuoteData?.sumInsurred??"0").toString(),
        policyDuration:(this.selectedQuoteData?.policyDuration??"1").toString(),
        insuranceCompany: this.selectedQuoteData?.insuranceCompany??0,
        proposerAadharNumber: "",
        proposerMaritalStatus: "",
      }
    }
  }
  formatInsurerDetails() {
      this.insurerDetails = [];
      let selfInsurer = {
        relationshipWithProposer: "Self",
        firstName: this.proposerForm?.get('proposerFirstName')?.value,
        lastName: this.proposerForm?.get('proposerLastName')?.value,
        //gender: this.proposerForm?.get('proposerGender')?.value,
        dateOfBirth: this.insurerForm.get('selfDOB')?.value,
        height: this.insurerForm.get('selfHeightFeet')?.value,
        heightFeet:this.insurerForm.get('selfHeightFeet')?.value,
        heightInches:this.insurerForm.get('selfHeightInches')?.value,
        weight: this.insurerForm.get('selfWeight')?.value?.toString(),
        occupation:this.insurerForm.get('selfOccupation')?.value?.toString(),
        for: "self",
        selfGender: this.healthData?.selfGender,
        gender: this.healthData?.selfGender,
        medicalQuestion: {}
      }
  
      this.insurerDetails.push(selfInsurer);
      
      if (this.healthData?.spouseSelected) {
        let spouseObj = {
          relationshipWithProposer: "Spouse",
          firstName: this.insurerForm.get('spouseFirstName')?.value,
          lastName: this.insurerForm.get('spouseLastName')?.value,
          gender: this.healthData?.selfGender == "Male" ? "FeMale" : "Male",
          dateOfBirth: this.insurerForm.get('spouseDOB')?.value,
          height: this.insurerForm.get('spouseHeightFeet')?.value,
          heightFeet:this.insurerForm.get('spouseHeightFeet')?.value,
          heightInches:this.insurerForm.get('spouseHeightInches')?.value,
          weight: this.insurerForm.get('spouseWeight')?.value?.toString(),
          occupation:this.insurerForm.get('spouseOccupation')?.value?.toString(),
          for: "spouse",
          medicalQuestion: {}
        }
        this.insurerDetails.push(spouseObj);
      }
  
      if (this.healthData?.selectedSonAge1 > 0) {
        let son1Obj = {
          relationshipWithProposer: "Son",
          firstName: this.insurerForm.get('son1FirstName')?.value,
          lastName: this.insurerForm.get('son1LastName')?.value,
          gender: "Male",
          dateOfBirth: this.insurerForm.get('son1DOB')?.value,
          height: this.insurerForm.get('son1HeightFeet')?.value,
          heightFeet:this.insurerForm.get('son1HeightFeet')?.value,
          heightInches:this.insurerForm.get('son1HeightInches')?.value,
          weight: this.insurerForm.get('son1Weight')?.value?.toString(),
          occupation:this.insurerForm.get('son1Occupation')?.value?.toString(),
          for: "son1",
          medicalQuestion: {}
        }
        this.insurerDetails.push(son1Obj);
      }
      if (this.healthData?.selectedSonAge2 > 0) {
        let son2Obj = {
          relationshipWithProposer: "Son",
          firstName: this.insurerForm.get('son2FirstName')?.value,
          lastName: this.insurerForm.get('son2LastName')?.value,
          gender: "Male",
          dateOfBirth: this.insurerForm.get('son2DOB')?.value,
          height: this.insurerForm.get('son2HeightFeet')?.value,
          heightFeet:this.insurerForm.get('son2HeightFeet')?.value,
          heightInches:this.insurerForm.get('son2HeightInches')?.value,
          weight: this.insurerForm.get('son2Weight')?.value?.toString(),
          occupation:this.insurerForm.get('son2Occupation')?.value?.toString(),
          for: "son2",
          medicalQuestion: {}
        }
        this.insurerDetails.push(son2Obj);
      }
      if (this.healthData?.selectedSonAge3 > 0) {
        let son3Obj = {
          relationshipWithProposer: "Son",
          firstName: this.insurerForm.get('son3FirstName')?.value,
          lastName: this.insurerForm.get('son3LastName')?.value,
          gender: "Male",
          dateOfBirth: this.insurerForm.get('son3DOB')?.value,
          height: this.insurerForm.get('son3HeightFeet')?.value,
          heightFeet:this.insurerForm.get('son3HeightFeet')?.value,
          heightInches:this.insurerForm.get('son3HeightInches')?.value,
          weight: this.insurerForm.get('son3Weight')?.value?.toString(),
          occupation:this.insurerForm.get('son3Occupation')?.value?.toString(),
          for: "son3",
          medicalQuestion: {}
        }
        this.insurerDetails.push(son3Obj);
      }
      if (this.healthData?.selectedSonAge4 > 0) {
        let son4Obj = {
          relationshipWithProposer: "Son",
          firstName: this.insurerForm.get('son4FirstName')?.value,
          lastName: this.insurerForm.get('son4LastName')?.value,
          gender: "Male",
          dateOfBirth: this.insurerForm.get('son4DOB')?.value,
          height: this.insurerForm.get('son4HeightFeet')?.value,
          heightFeet:this.insurerForm.get('son4HeightFeet')?.value,
          heightInches:this.insurerForm.get('son4HeightInches')?.value,
          weight: this.insurerForm.get('son4Weight')?.value?.toString(),
          occupation:this.insurerForm.get('son4Occupation')?.value?.toString(),
          for: "son4",
          medicalQuestion: {}
        }
        this.insurerDetails.push(son4Obj);
      }
      if (this.healthData?.selectedDauAge1 > 0) {
        let dau1Obj = {
          relationshipWithProposer: "Daughter",
          firstName: this.insurerForm.get('dau1FirstName')?.value,
          lastName: this.insurerForm.get('dau1LastName')?.value,
          gender: "Female",
          dateOfBirth: this.insurerForm.get('dau1DOB')?.value,
          height: this.insurerForm.get('dau1HeightFeet')?.value,
          heightFeet:this.insurerForm.get('dau1HeightFeet')?.value,
          heightInches:this.insurerForm.get('dau1HeightInches')?.value,
          weight: this.insurerForm.get('dau1Weight')?.value?.toString(),
          occupation:this.insurerForm.get('dau1Occupation')?.value?.toString(),
          for: "dau1",
          medicalQuestion: {}
        }
        this.insurerDetails.push(dau1Obj);
      }
      if (this.healthData?.selectedDauAge2 > 0) {
        let dau2Obj = {
          relationshipWithProposer: "Daughter",
          firstName: this.insurerForm.get('dau2FirstName')?.value,
          lastName: this.insurerForm.get('dau2LastName')?.value,
          gender: "Female",
          dateOfBirth: this.insurerForm.get('dau2DOB')?.value,
          height: this.insurerForm.get('dau2HeightFeet')?.value,
          heightFeet:this.insurerForm.get('dau2HeightFeet')?.value,
          heightInches:this.insurerForm.get('dau2HeightInches')?.value,
          weight: this.insurerForm.get('dau2Weight')?.value?.toString(),
          occupation:this.insurerForm.get('dau2Occupation')?.value?.toString(),
          for: "dau2",
          medicalQuestion: {}
        }
        this.insurerDetails.push(dau2Obj);
      }
      if (this.healthData?.selectedDauAge3 > 0) {
        let dau3Obj = {
          relationshipWithProposer: "Daughter",
          firstName: this.insurerForm.get('dau3FirstName')?.value,
          lastName: this.insurerForm.get('dau3LastName')?.value,
          gender: "Female",
          dateOfBirth: this.insurerForm.get('dau3DOB')?.value,
          height: this.insurerForm.get('dau3HeightFeet')?.value,
          heightFeet:this.insurerForm.get('dau3HeightFeet')?.value,
          heightInches:this.insurerForm.get('dau3HeightInches')?.value,
          weight: this.insurerForm.get('dau3Weight')?.value?.toString(),
          occupation:this.insurerForm.get('dau3Occupation')?.value?.toString(),
          for: "dau3",
          medicalQuestion: {}
        }
        this.insurerDetails.push(dau3Obj);
      }
      if (this.healthData?.selectedDauAge4 > 0) {
        let dau4Obj = {
          relationshipWithProposer: "Daughter",
          firstName: this.insurerForm.get('dau4FirstName')?.value,
          lastName: this.insurerForm.get('dau4LastName')?.value,
          gender: "Female",
          dateOfBirth: this.insurerForm.get('dau4DOB')?.value,
          height: this.insurerForm.get('dau4HeightFeet')?.value,
          heightFeet:this.insurerForm.get('dau4HeightFeet')?.value,
          heightInches:this.insurerForm.get('dau4HeightInches')?.value,
          weight: this.insurerForm.get('dau4Weight')?.value?.toString(),
          occupation:this.insurerForm.get('dau4Occupation')?.value?.toString(),
          for: "dau4",
          medicalQuestion: {}
        }
        this.insurerDetails.push(dau4Obj);
      }
      if (this.healthData?.fatherSelected) {
        let fatherObj = {
          relationshipWithProposer: "father",
          firstName: this.insurerForm.get('fatherFirstName')?.value,
          lastName: this.insurerForm.get('fatherLastName')?.value,
          gender: "Male",
          dateOfBirth: this.insurerForm.get('fatherDOB')?.value,
          height: this.insurerForm.get('fatherHeightFeet')?.value,
          heightFeet:this.insurerForm.get('fatherHeightFeet')?.value,
          heightInches:this.insurerForm.get('fatherHeightInches')?.value,
          weight: this.insurerForm.get('fatherWeight')?.value?.toString(),
          occupation:this.insurerForm.get('FatherOccupation')?.value?.toString(),
          for: "father",
          medicalQuestion: {}
        }
        this.insurerDetails.push(fatherObj);
      }
      if (this.healthData?.motherSelected) {
        let motherObj = {
          relationshipWithProposer: "Mother",
          firstName: this.insurerForm.get('motherFirstName')?.value,
          lastName: this.insurerForm.get('motherLastName')?.value,
          gender: "Female",
          dateOfBirth: this.insurerForm.get('motherDOB')?.value,
          height: this.insurerForm.get('motherHeightFeet')?.value,
          heightFeet:this.insurerForm.get('motherHeightFeet')?.value,
          heightInches:this.insurerForm.get('motherHeightInches')?.value,
          weight: this.insurerForm.get('motherWeight')?.value?.toString(),
          occupation:this.insurerForm.get('MotherOccupation')?.value?.toString(),
          for: "mother",
          medicalQuestion: {}
        }
        this.insurerDetails.push(motherObj);
      }
      if (this.healthData?.grandPaSelected) {
        let grandPaObj = {
          relationshipWithProposer: "GrandFather",
          firstName: this.insurerForm.get('grandPaFirstName')?.value,
          lastName: this.insurerForm.get('grandPaLastName')?.value,
          gender: "Male",
          dateOfBirth: this.insurerForm.get('grandPaDOB')?.value,
          height: this.insurerForm.get('grandPaHeightFeet')?.value,
          heightFeet:this.insurerForm.get('grandPaHeightFeet')?.value,
          heightInches:this.insurerForm.get('grandPaHeightInches')?.value,
          weight: this.insurerForm.get('grandPaWeight')?.value?.toString(),
          occupation:this.insurerForm.get('grandPaOccupation')?.value?.toString(),
          for: "grandPa",
          medicalQuestion: {}
        }
        this.insurerDetails.push(grandPaObj);
      }
      if (this.healthData?.grandMaSelected) {
        let grandMaObj = {
          relationshipWithProposer: "GrandMother",
          firstName: this.insurerForm.get('grandMaFirstName')?.value,
          lastName: this.insurerForm.get('grandMaLastName')?.value,
          gender: "Female",
          dateOfBirth: this.insurerForm.get('grandMaDOB')?.value,
          height: this.insurerForm.get('grandMaHeightFeet')?.value,
          heightFeet:this.insurerForm.get('grandMaHeightFeet')?.value,
          heightInches:this.insurerForm.get('grandMaHeightInches')?.value,
          weight: this.insurerForm.get('grandMaWeight')?.value?.toString(),
          occupation:this.insurerForm.get('grandMaOccupation')?.value?.toString(),
          for: "grandMa",
          medicalQuestion: {}
        }
        this.insurerDetails.push(grandMaObj);
      }
      if (this.healthData?.fatherLawSelected) {
        let fatherLawObj = {
          relationshipWithProposer: "Father-in-Law",
          firstName: this.insurerForm.get('fatherLawFirstName')?.value,
          lastName: this.insurerForm.get('fatherLawLastName')?.value,
          gender: "Male",
          dateOfBirth: this.insurerForm.get('fatherLawDOB')?.value,
          height: this.insurerForm.get('fatherLawHeightFeet')?.value,
          heightFeet:this.insurerForm.get('fatherLawHeightFeet')?.value,
          heightInches:this.insurerForm.get('fatherLawHeightInches')?.value,
          weight: this.insurerForm.get('fatherLawWeight')?.value?.toString(),
          occupation:this.insurerForm.get('fatherLawOccupation')?.value?.toString(),
          for: "fatherLaw",
          medicalQuestion: {}
        }
        this.insurerDetails.push(fatherLawObj);
      }
      if (this.healthData?.motherLawSelected) {
        let motherLawObj = {
          relationshipWithProposer: "Mother-in-Law",
          firstName: this.insurerForm.get('motherLawFirstName')?.value,
          lastName: this.insurerForm.get('motherLawLastName')?.value,
          gender: "Female",
          dateOfBirth: this.insurerForm.get('motherLawDOB')?.value,
          height: this.insurerForm.get('motherLawHeightFeet')?.value,
          heightFeet:this.insurerForm.get('motherLawHeightFeet')?.value,
          heightInches:this.insurerForm.get('motherLawHeightInches')?.value,
          weight: this.insurerForm.get('motherLawWeight')?.value?.toString(),
          occupation:this.insurerForm.get('motherLawOccupation')?.value?.toString(),
          for: "motherLaw",
          medicalQuestion: {}
        }
        this.insurerDetails.push(motherLawObj);
      }
      if (this.healthData?.uncleSelected) {
        let uncleObj = {
          relationshipWithProposer: "Uncle",
          firstName: this.insurerForm.get('uncleFirstName')?.value,
          lastName: this.insurerForm.get('uncleLastName')?.value,
          gender: "Male",
          dateOfBirth: this.insurerForm.get('uncleDOB')?.value,
          height: this.insurerForm.get('uncleHeightFeet')?.value,
          heightFeet:this.insurerForm.get('uncleHeightFeet')?.value,
          heightInches:this.insurerForm.get('uncleHeightInches')?.value,
          weight: this.insurerForm.get('uncleWeight')?.value?.toString(),
          occupation:this.insurerForm.get('uncleOccupation')?.value?.toString(),
          for: "uncle",
          medicalQuestion: {}
        }
        this.insurerDetails.push(uncleObj);
      }
      if (this.healthData?.auntSelected) {
        let auntObj = {
          relationshipWithProposer: "Aunt",
          firstName: this.insurerForm.get('auntFirstName')?.value,
          lastName: this.insurerForm.get('auntLastName')?.value,
          gender: "Female",
          dateOfBirth: this.insurerForm.get('auntDOB')?.value,
          height: this.insurerForm.get('auntHeightFeet')?.value,
          heightFeet:this.insurerForm.get('auntHeightFeet')?.value,
          heightInches:this.insurerForm.get('auntHeightInches')?.value,
          weight: this.insurerForm.get('auntWeight')?.value?.toString(),
          occupation:this.insurerForm.get('auntOccupation')?.value?.toString(),
          for: "aunt",
          medicalQuestion: {}
        }
        this.insurerDetails.push(auntObj);
      }
      console.log(this.insurerDetails, "Insurer Array Test");
  
      this.bindMedicalQuestions();
  
    }
fetchData() {
    this.insurerDetails1 = this.insurerDetails.map((m: any) => {
      let fields = JSON.parse(JSON.stringify(m.medicalQuestion));
      fields.for = m.for;
      //fields.formFields.forEach((v:FormField)=>
      for (let i = 0; i < fields.formFields.length; i++) {
        let v = fields.formFields[i];
        let vId = v.id;
        let vName = v.name;
        v.id = v.id.replace(m.for + '_', '');
        v.name = v.name.replace(m.for + '_', '');
        if (v.cascadeItems && v.cascadeItems.formFields && v.cascadeItems.formFields.length > 0) {
          //v.cascadeItems.formFields.forEach((v1:FormField)=>
          for (let j = 0; j < v.cascadeItems.formFields.length; j++) {
            let v1 = v.cascadeItems.formFields[j];
            let v1Id = v1.id;
            let v1Name = v1.name;
            v1.id = v1.id.replace(vId + '_', '');
            v1.name = v1.name.replace(vName + '_', '');
            if (v1.cascadeItems && v1.cascadeItems.formFields && v1.cascadeItems.formFields.length > 0) {
              //v1.cascadeItems.formFields.forEach((v2:FormField)=>
              for (let k = 0; k < v1.cascadeItems.formFields.length; k++) {
                let v2 = v1.cascadeItems.formFields[k];
                let v2Id = v2.id;
                let v2Name = v2.name;
                v2.id = v2.id.replace(v1Id + '_', '');
                v2.name = v2.name.replace(v1Name + '_', '');
                if (v2.cascadeItems && v2.cascadeItems.formFields && v2.cascadeItems.formFields.length > 0) {
                  //v1.cascadeItems.formFields.forEach((v2:FormField)=>
                  for (let l = 0; l < v2.cascadeItems.formFields.length; l++) {
                    let v3 = v2.cascadeItems.formFields[l];
                    v3.id = v3.id.replace(v2Id + '_', '');
                    v3.name = v3.name.replace(v2Name + '_', '');
                  };
                }
              };
            }
          }
        }
      }
      return { ...m, medicalQuestion: (fields) }
    });
    console.log(this.insurerDetails1);
  }

  bindMedicalQuestions() {
    this.insurerDetails = this.insurerDetails.map((m: any) => {
      let fields = JSON.parse(JSON.stringify(this.required_input_fields));
      fields.for = m.for;
      //fields.formFields.forEach((v:FormField)=>
      for (let i = 0; i < fields.formFields.length; i++) {
        let v = fields.formFields[i];
        v.id = m.for + "_" + v.id;
        v.name = m.for + "_" + v.name;
        if (v.cascadeItems && v.cascadeItems.formFields && v.cascadeItems.formFields.length > 0) {
          //v.cascadeItems.formFields.forEach((v1:FormField)=>
          for (let j = 0; j < v.cascadeItems.formFields.length; j++) {
            let v1 = v.cascadeItems.formFields[j];
            v1.id = v.id + "_" + v1.id;
            v1.name = v.name + "_" + v1.name;
            if (v1.cascadeItems && v1.cascadeItems.formFields && v1.cascadeItems.formFields.length > 0) {
              //v1.cascadeItems.formFields.forEach((v2:FormField)=>
              for (let k = 0; k < v1.cascadeItems.formFields.length; k++) {
                let v2 = v1.cascadeItems.formFields[k];
                v2.id = v1.id + "_" + v2.id;
                v2.name = v1.name + "_" + v2.name;
                if (v2.cascadeItems && v2.cascadeItems.formFields && v2.cascadeItems.formFields.length > 0) {
                  //v1.cascadeItems.formFields.forEach((v2:FormField)=>
                  for (let l = 0; l < v2.cascadeItems.formFields.length; l++) {
                    let v3 = v2.cascadeItems.formFields[l];
                    v3.id = v2.id + "_" + v3.id;
                    v3.name = v2.name + "_" + v3.name;

                  };
                }
              };
            }
          }
        }
      }
      return { ...m, medicalQuestion: (fields) }
    });
  }
 
  validateData():boolean {  
    let isMedicalAnswerValid:boolean = true;  
    this.insurerDetails.map((m: any) => 
      {
      let fields = m.medicalQuestion;
      for (let i = 0; i < fields.formFields.length; i++) {
        let v = fields.formFields[i];
        if(v.isRequired && (v.selectedValue == null || v.selectedValue == undefined || v.selectedValue == "")){
          v.isValid = false;
          isMedicalAnswerValid = false;
        }
        else{
          v.isValid = true;
        }
        if (v.cascadeItems && v.cascadeItems.formFields && v.cascadeItems.formFields.length > 0) {
          for (let j = 0; j < v.cascadeItems.formFields.length; j++) {
            let v1 = v.cascadeItems.formFields[j];
            if(v1.isRequired && v.selectedValue?.toLowerCase() == "yes" && (v1.selectedValue == null || v1.selectedValue == undefined || v1.selectedValue == "")){
              v1.isValid = false;
              isMedicalAnswerValid = false;
            }
            else{
              v1.isValid = true;
            }
            if (v1.cascadeItems && v1.cascadeItems.formFields && v1.cascadeItems.formFields.length > 0) {
              for (let k = 0; k < v1.cascadeItems.formFields.length; k++) {
                let v2 = v1.cascadeItems.formFields[k];
                if(v2.isRequired && v1.selectedValue?.toLowerCase() == "yes" && (v2.selectedValue == null || v2.selectedValue == undefined || v2.selectedValue == "")){
                  v2.isValid = false;
                  isMedicalAnswerValid = false;
                }
                else{
                  v2.isValid = true;
                }
                if (v2.cascadeItems && v2.cascadeItems.formFields && v2.cascadeItems.formFields.length > 0) {
                  for (let l = 0; l < v2.cascadeItems.formFields.length; l++) {
                    let v3 = v2.cascadeItems.formFields[l];
                    if(v3.isRequired && v2.selectedValue?.toLowerCase() == "yes" && (v3.selectedValue == null || v3.selectedValue == undefined || v3.selectedValue == "")){
                      v3.isValid = false;
                      isMedicalAnswerValid = false;
                    }
                    else{
                      v3.isValid = true;
                    }
                  };
                }
              };
            }
          }
        }
      }
      return { ...m, medicalQuestion: (fields) }
    });
    return isMedicalAnswerValid;
  }

  get valPropControls() {
    return this.proposerForm.controls;
  }

  get valAddressPropControls() {
    return this.addressInfo.controls;
  }

  get valMemControls() {
    return this.insurerForm.controls;
  }

  get valNomControls() {
    return this.nomineeForm.controls;
  }

  get userFormControl() {
    return this.userForm.controls;
  }

  get nomineeFormControl() {
    return this.nomineeForm.controls;
  }

  get proposalFormControl() {
    return this.proposalForm.controls;
  }

  loadRelationships() {
    this.RelationShipList = [
      { id: 1, name: "Father" },
      { id: 2, name: "Mother" },
      { id: 3, name: "Brother" },
      { id: 4, name: "Sister" },
      { id: 5, name: "Son" },
      { id: 6, name: "Daughter" },
      { id: 7, name: "Uncle" },
      { id: 8, name: "Aunt" },
      { id: 9, name: "Spouse" },
      { id: 11, name: "Grand Son" },
      { id: 12, name: "Grand Daughter" }
    ];
  }

async getStatesByCountry(country: string) {
    let response = await this._lookupService.getStatesListByCountry(country);
    if (response?.isSuccess) {
      this.stateList = response.result.map((item: any) => { return { text: item.name, value: item.name, id: item.name } });
    }
  }

  async getDistrictsByState(state: string) {
    let response = await this._lookupService.getDistrictsListByState(state);
    if (response?.isSuccess) {
      this.districtList = response.result.map((item: any) => { return { text: item.name, value: item.name, id: item.name } });
    }
  }

  async getPincodesByDistrict(district: string) {
    let response = await this._lookupService.getPincodeByDistrict(district);
    if (response?.isSuccess) {
      //this.pincodeList = response.result.map((item:any)=> {return {text:item.pincode,value:item.pincode,text1: item.cityVillageArea, id:item.id,pincodeMaster:item}});
      this.cityList = response.result.map((item: any) => { return { text: item.cityVillageArea, value: item.cityVillageArea, text1: item.pincode, id: item.id } });
    }
  }

  onStateSelection(data: AutoCompleteItem) {
    this.getDistrictsByState(data.text);
    this.addressInfo.get('proposerState')?.setValue(data.text);
    console.log(this.userForm.value);
  }

  onDistrictSelection(data: AutoCompleteItem) {
    this.addressInfo.get('proposerCity')?.setValue(data.text);
    this.getPincodesByDistrict(data.text);
    console.log(this.userForm.value);
  }

  onPincodeSelection(data: AutoCompleteItem) {
    this.getDistrictsByState(data.pincodeMaster?.state?.toUpperCase() ?? "");
    this.getPincodesByDistrict(data.pincodeMaster?.district?.toUpperCase() ?? "");
    this.addressInfo.get('proposerPinCode')?.setValue(data.pincodeMaster?.pincode);
    // this.addressInfo.get('pincodeId')?.setValue(data.pincodeMaster?.pincode?.toUpperCase());
    this.addressInfo.get('proposerCity')?.setValue(data.pincodeMaster?.cityVillageArea?.toUpperCase());
    this.addressInfo.get('district')?.setValue(data.pincodeMaster?.district?.toUpperCase());
    this.addressInfo.get('proposerState')?.setValue(data.pincodeMaster?.state?.toUpperCase());
  }

  onCitySelection(data: AutoCompleteItem) {

    this.addressInfo.get('proposerCity')?.setValue(data.text);
  }

  onStateBlur(data: any) {
    if (data == "") {
      this.addressInfo.get('proposerState')?.setValue(data);
    }
  }

  onPincodeBlur(data: any) {
    if (data == "") {
      this.addressInfo.get('proposerPinCode')?.setValue(data);
      //this.userForm.get('pincodeId')?.setValue(data);
    }
  }

  onCityBlur(data: any) {
    // if(data == ""){
    this.addressInfo.get('proposerCity')?.setValue(data);
    // }
  }

  onDistrictBlur(data: any) {
    this.addressInfo.get('proposerCity')?.setValue(data);
    this.addressInfo.get('district')?.setValue(data);
  }

  async getAge() {
    this.ageList = [];
    for (var i = 18; i <= 100; i++) {
      this.ageList.push(i)
    }
  }
  async getFeet() {
    this.feetList = [];
    for (var i = 1; i <= 8; i++) {
      this.feetList.push(i)
    }
  }

  async getInch() {
    this.inchList = [];
    for (var i = 0; i <= 11; i++) {
      this.inchList.push(i)
    }
  }



  getSavedData(data: any) {
    //console.log(data);
    if (data) {
      // this.insurerDetails = this.insurerDetails.map((m:any)=>{
      //   return {...m, medicalQuestion : data.data }
      // })
      ;
      for (let i = 0; i < this.insurerDetails.length; i++) {
        if (data.for == this.insurerDetails[i].for) {
          this.insurerDetails[i].medicalQuestion = data.data;
        }
      }
      console.log(this.insurerDetails);
    }
  }

  

  async getPanCardDetails() {
    this.spinner.show();
    let obj = {
      docNum: 1,
      source: '',
      id_number: this.proposerForm.get('proposerPancard')?.value,
      //insuranceCompany:this.insuranceformData.insuranceCompany,
      dob:this.proposerForm.get('selfDOB')?.value,
      registeredInTheNameOf:"",
      gstin:"",
      quoteId:this.selectedQuoteData?.quoteId
    }

    const response = await this.commonService.GetKYCDetails(obj);
    if (response?.isSuccess) {
      this.spinner.hide();

      this.defaultDate = response.result.dob;
      let panDetails = response.result;;
      this.proposerForm.get('selfDOB')?.setValue(panDetails?.dob ?? '');
      if (panDetails?.full_name != null && panDetails?.full_name != undefined && panDetails?.full_name.length > 5) {
        let nameArray = panDetails?.full_name.split(' ');
        switch (nameArray.length) {
          case 2:
            this.proposerForm.get('proposerFirstName')?.setValue(this.generalService.toTitleCase(nameArray[0]));
            this.proposerForm.get('proposerLastName')?.setValue(this.generalService.toTitleCase(nameArray[1]));
            break;
          case 3:
            this.proposerForm.get('proposerFirstName')?.setValue(this.generalService.toTitleCase(nameArray[0]) + ' ' + this.generalService.toTitleCase(nameArray[1]));
            this.proposerForm.get('proposerLastName')?.setValue(this.generalService.toTitleCase(nameArray[2]));
            break;
          case 4:
            this.proposerForm.get('proposerFirstName')?.setValue(this.generalService.toTitleCase(nameArray[0]) + ' ' + this.generalService.toTitleCase(nameArray[1]) + ' ' + (nameArray[2]));
            this.proposerForm.get('proposerLastName')?.setValue(this.generalService.toTitleCase(nameArray[3]));
            break;
        }
      }

      this.addressInfo.get("flatNumber")?.setValue(this.generalService.toTitleCase(panDetails?.permanentAddress1??""));
      let address = this.generalService.toTitleCase((panDetails?.permanentAddress2??""))  + (((panDetails?.permanentAddress3??"") != "") ? this.generalService.toTitleCase(( ", " +panDetails?.permanentAddress3)) : "")
      this.addressInfo.get("streetAddress")?.setValue(this.generalService.toTitleCase(address??""));
      this.addressInfo.get("proposerPinCode")?.setValue(this.generalService.toTitleCase(panDetails?.permanentPincode??""));
      this.addressInfo.get("proposerCity")?.setValue(this.generalService.toTitleCase(panDetails?.permanentCity??""));
      this.addressInfo.get("district")?.setValue(this.generalService.toTitleCase(panDetails?.permanentCity??""));
      this.addressInfo.get("proposerState")?.setValue(this.generalService.toTitleCase(panDetails?.state??""));

      if(panDetails?.email != undefined && panDetails?.email != null && panDetails?.email != "")
      this.proposerForm.get("proposerEmail")?.setValue(panDetails?.email??"");
      if(panDetails?.mobile != undefined && panDetails?.mobile != null && panDetails?.mobile != "")
      this.proposerForm.get("proposerMobile")?.setValue(panDetails?.mobile??"");
    }
    else {
      this.spinner.hide();
    }
  }

  async onPincodeChange(data: any) {
    if (data.length >= 3) {
      const res = await this._lookupService.pincodeMasterDetails(data);
      if (res != null && res.result) {
        this.pincodeList = res.result.map((item: any) => { return { text: item.pincode, value: item.pincode, text1: item.cityVillageArea, id: item.id, pincodeMaster: item } });
      }
    }
  }


  async paySecurly() {
    this.spinner.show();
    const response: any = await this.insuranceService.HealthCreateQuote(this.royalHealthObj)
    this.readResponse(response);
    this.spinner.hide();
  }

  readResponse(response:any){
    if (response?.isSuccess && response?.result?.code == 1) {
      if(response.result.isKycVerified == false && response.result.kycLink!=null &&  response.result.kycLink!=undefined && response.result.kycLink!="")
      {      
        this.kycUrl= this.sanitizer.bypassSecurityTrustResourceUrl(response.result.kycLink);  
        if(response.result.kycLink.toLowerCase().indexOf('https')==-1 || this.selectedQuoteData.insuranceCompany==14 || this.selectedQuoteData.insuranceCompany==9 || this.selectedQuoteData.insuranceCompany==7)
        {
            this.showhttpKYCPopup=true;
            this.setShowNegativeKycInfo(true);
        }
        else
        {
            this.setShowNegativeKycInfo(true);
            this.showKYCPopup=true;           
        }          
      }
      else if (response?.result?.paymentObject != null && response?.result?.paymentObject?.paymentUrl != null && response?.result?.paymentObject?.paymentUrl != undefined && response?.result?.paymentObject?.paymentUrl != '') {
        this.paymentObject = response?.result?.paymentObject;
        this.isPaymentLink = true;
      }
      else
      if (response?.result?.paymentLink) {
        swal.fire({
          position: 'center',
          icon: 'success',
          title: response?.result?.Message,
          showConfirmButton: false,
          timer: 3000,
        });
        window.open(response?.result?.paymentLink, "_self")       
      }       
      else {
        swal.fire({
          position: 'center',
          icon: 'success',
          title: `Policy Created Successfully!`,
          showConfirmButton: false,
          timer: 5000,
        });
        this.router.navigate(['policyDetails']);
      }
    }
    else {
      if(response?.result?.isKycVerified == false && response?.result?.kycLink!=null &&  response?.result?.kycLink!=undefined && response?.result?.kycLink!="")
      {        
        this.kycUrl= this.sanitizer.bypassSecurityTrustResourceUrl(response.result.kycLink); 
        if(response.result.kycLink.toLowerCase().indexOf('https')==-1|| this.selectedQuoteData.insuranceCompany==14 || this.selectedQuoteData.insuranceCompany==9 || this.selectedQuoteData.insuranceCompany==7)
        {
            this.showhttpKYCPopup=true;
            this.setShowNegativeKycInfo(true);
        }
        else
        {
          this.showKYCPopup=true;       
          this.setShowNegativeKycInfo(true);
        }
        // this.insuranceformData.ApplicationId=response.result.applicationId;
        // this.insuranceformData.PolicyNumber=response.result.policyNumber;
        // this.insuranceformData.url=response.result.kycLink;
      }
      else
      {
        swal.fire({
          position: 'center',
          imageUrl: "../../assets/alerts/warning.png",
          imageHeight: 89,
          // icon: 'error',
          title: '',
          text: response?.result?.message ?? "Something went wrong",
          showCancelButton: true,
          showConfirmButton: false,
          cancelButtonText: 'Ok',
          cancelButtonColor: "#DF3A4D",
          customClass: {
            confirmButton: "error_btn",
          }
        });
      }
    }
  }

  activePanel: string = "";
  toggleMembersQuestion(panel: string) {
    this.activePanel = this.activePanel == panel ? "" : panel;;
  }

  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode >= 48 && charCode <= 57) {
      return true;
    }
    return false;
  }

  alphabetOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122) || charCode == 32) {
      return true;
    }
    return false;
  }

  alphaNumericOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode >= 48 && charCode <= 57) || ((charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122))) {
      return true;
    }
    return false;
  }

  onInputChange(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.value.length > 6) {
      input.value = input.value.slice(0, 6);
      event.stopPropagation();
    }
  }
  onlyNumbers(event: KeyboardEvent) {
    const inputChar = String.fromCharCode(event.charCode);

    if (!/^\d+$/.test(inputChar)) {
      event.preventDefault();
    }
  }
  editClick(data:string){

  }

  goTo() {
    let healthObj:any = {};
    let healthObjStr = this.generalService.getDataToLocalStorage("healthObj");
    if(healthObjStr != null && healthObjStr != undefined && healthObjStr != ''){
      healthObj = JSON.parse(healthObjStr);
      healthObj.quoteData = this.quoteData;
      healthObj.healthData = this.healthData;
      healthObj.proposerForm = this.proposalForm?.value;
      healthObj.addressInfo = this.addressInfo?.value;
      healthObj.nomineeForm = this.nomineeForm?.value;
      //healthObj.selectedQuoteData = ""
    }
    else
    {
      healthObj ={
        quoteData : healthObj.quoteData,
        healthData : healthObj.healthData,
        proposerForm : this.proposalForm?.value,
        addressInfo : this.addressInfo?.value,
        nomineeForm : this.nomineeForm?.value,
        //selectedQuoteData :""
      }; 
    }
    this.generalService.setDataToLocalStorage("healthObj",JSON.stringify(healthObj));
    if(this.uId != null && this.uId != undefined && this.uId != ''){
      this.router.navigate(['/health-quotes',{q:this.rId,u:this.uId}]);
    }
    else
    {
      this.router.navigate(['/health-quotes',{q:this.rId}]);
    }
  }

  setShowNegativeKycInfo(val:boolean){
    this.showNegativeKycInfo = val;
  }

  closeKYCModal()
  {
    this.showKYCPopup= false;
  }

  closehttpKYCModal()
  {
    this.showhttpKYCPopup= false;
  }
}
