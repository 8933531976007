import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './authinterceptor/auth_guard';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './Login/login.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SignupComponent } from './signup/signup.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { RoleComponent } from './role/role.component';
import { ProfileComponent } from './profile/profile.component';
import { BusinessInfoComponent } from './business-info/business-info.component'
import { DocumentsComponent } from './documents/documents.component';
import { UsersComponent } from './users/users.component';
import { InsuranceComponent } from './insurance/insurance.component';
import { PolicyDetailsComponent } from './policy-details/policy-details.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { PolicyPaymentComponent } from './policypayment/policypayment.component';
import { HomeComponent } from './home/home.component';
import { PrivacypolicyComponent } from './privacypolicy/privacypolicy.component';
import { TermsconditionsComponent } from './termsconditions/termsconditions.component';
import { SettingsComponent } from './settings/settings.component';
import { QuoteDetailsComponent } from './quote-details/quote-details.component';
import { HealthComponent } from './health/health.component';
import { PropertyComponent } from './property/property.component';
import { AssetsComponent } from './assets/assets.component';
import { BikeComponent } from './vehicleinsurance/bike/bike.component';
import { CarComponent } from './vehicleinsurance/car/car.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { SitemapComponent } from './sitemap/sitemap.component';
import { CareerComponent } from './career/career.component';
import { TermsConditionComponent } from './terms-condition/terms-condition.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { CustomerComponent } from './customer/customer.component';
import { CancellationrefundComponent } from './cancellationrefund/cancellationrefund.component';
import { ContentComponent } from './content/content.component';
import { FaqsComponent } from './faqs/faqs.component';
import { GrivanceredressalComponent } from './grivanceredressal/grivanceredressal.component';
import { GroupTermInsuranceComponent } from './lifeinsurance/group-term-insurance/group-term-insurance.component';
import { TermInsuranceComponent } from './lifeinsurance/term-insurance/term-insurance.component';
import { SinglepayinsuranceComponent } from './lifeinsurance/singlepayinsurance/singlepayinsurance.component';
import{RoyalSundaramPaymentComponent} from './vehicleinsurance/royal-sundaram-payment/royal-sundaram-payment.component';
import { CarInsuranceComponent } from './motorinsurance/car-insurance/car-insurance.component';
import { TaxiInsuranceComponent } from './motorinsurance/taxi-insurance/taxi-insurance.component';
import { CommercialVehicleInsuranceComponent } from './motorinsurance/commercial-vehicle-insurance/commercial-vehicle-insurance.component';
import { ThirdPartyCarInsuranceComponent } from './motorinsurance/third-party-car-insurance/third-party-car-insurance.component';
import { ThirdPartyTwoWheelerInsuranceComponent } from './motorinsurance/third-party-two-wheeler-insurance/third-party-two-wheeler-insurance.component';
import { TwoWheelerInsuranceComponent } from './motorinsurance/two-wheeler-insurance/two-wheeler-insurance.component';
import { FamilyHealthPolicyComponent } from './healthinsurance/family-health-policy/family-health-policy.component';
import { IndividualHealthPolicyComponent } from './healthinsurance/individual-health-policy/individual-health-policy.component';
import { GroupHealthInsuranceComponent } from './healthinsurance/group-health-insurance/group-health-insurance.component';
import { SeniorHealthPolicyComponent } from './healthinsurance/senior-health-policy/senior-health-policy.component';
import { CriticalIllnessPolicyComponent } from './healthinsurance/critical-illness-policy/critical-illness-policy.component';
import { ArogyaSanjivniPolicyComponent } from './healthinsurance/arogya-sanjivni-policy/arogya-sanjivni-policy.component';
import { TaxSavingPlanComponent } from './savingplan/tax-saving-plan/tax-saving-plan.component';
import { MoneyBackPlanComponent } from './savingplan/money-back-plan/money-back-plan.component';
import { ChildPlanComponent } from './savingplan/child-plan/child-plan.component';
import { PensionPlanComponent } from './savingplan/pension-plan/pension-plan.component';
import { ULIPComponent } from './savingplan/ulip/ulip.component';
import { GuaranteedReturnPlanComponent } from './savingplan/guaranteed-return-plan/guaranteed-return-plan.component';
import { ContactusComponent } from './contactus/contactus.component';
import{RoyalSundaramPolicyComponent} from './vehicleinsurance/royal-sundaram-payment/royal-sundaram-policy/royal-sundaram-policy.component';
import { BikeInsuranceQuoteComponent } from './bought-new-bike/bike-insurance-quote/bike-insurance-quote.component';
import { InsuranceOwnerDetailComponent } from './bought-new-bike/insurance-owner-detail/insurance-owner-detail.component';
import { InsurancePolicyDetailComponent } from './bought-new-bike/insurance-policy-detail/insurance-policy-detail.component';
import { CarQuoteDetailComponent } from './bought-new-car/car-quote-detail/car-quote-detail.component';
import { CarInsuranceQuoteComponent } from './bought-new-car/car-insurance-quote/car-insurance-quote.component';
import { carInsurancePolicyDetailComponent} from './bought-new-car/carInsurance-policy-detail/carInsurance-policy-detail.component';
import {carInsuranceOwnerDetailComponent} from './bought-new-car/carInsurance-owner-detail/carInsurance-owner-detail.component'
import { AddTestimonialComponent } from './home/testimonial/add-testimonial/add-testimonial.component';
import { PaymentCardComponent } from './payment/bike/payment-card/payment-card.component';
import { SuccessComponent } from './payment/bike/success/success.component';
import { FailureComponent } from './payment/bike/failure/failure.component';
import { ComparisonComponent } from './vehicleinsurance/bike/comparison/comparison.component';
import { BikeInsuranceDetailComponent } from './vehicleinsurance/bike/bike-insurance-detail/bike-insurance-detail.component';
import { BikeInsuranceOwnerDetailComponent } from './vehicleinsurance/bike/bike-insurance-owner-detail/bike-insurance-owner-detail.component';
import { BikeInsurancePolicyDetailComponent } from './vehicleinsurance/bike/bike-insurance-policy-detail/bike-insurance-policy-detail.component';
import { VacancyComponent } from './career/vacancy/vacancy.component';
import { PersonalInfoComponent } from './eKYC/personal-info/personal-info.component';
import { HelpComponent } from './help/help.component';
import { VerifyComponent } from './eKYC/verify/verify.component';
import { HealthinsurancePolicyComponent } from './healthinsurance/healthinsurance-policy/healthinsurance-policy.component';
import { HealthinsurancePlanListComponent } from './healthinsurance/healthinsurance-plan-list/healthinsurance-plan-list.component';
import { HealthinsurancePlanComponent } from './healthinsurance/healthinsurance-plan/healthinsurance-plan.component';
import { HealthinsuranceDetailsComponent } from './healthinsurance/healthinsurance-details/healthinsurance-details.component';
import { RegisterbusinessComponent } from './registerbusiness/registerbusiness.component';
import { BusinesshomepageComponent } from './agent/businesshomepage/businesshomepage.component';
import { AgentProfileComponent } from './agent/agent-profile/agent-profile.component';
import { AgentKycVerificationComponent } from './agent/agent-kyc-verification/agent-kyc-verification.component';
import { AgentBankDetailsComponent } from './agent/agent-bank-details/agent-bank-details.component';
import { AgentDashboardComponent } from './agent/agent-dashboard/agent-dashboard.component';
import { LiPosptrainingComponent } from './agent/agenttraining/li-posptraining/li-posptraining.component';
import { GiPosptrainingComponent } from './agent/agenttraining/gi-posptraining/gi-posptraining.component';
import { AgenttrainingComponent } from './agent/agenttraining/agenttraining.component';
import { LiexamComponent } from './agent/agenttraining/liexam/liexam.component';
import { CommercialVechiclesComponent } from './commercial/commercial-vechicles/commercial-vechicles.component';
import { VehicleOwnerDetailsComponent } from './vehicleinsurance/vehicle-owner-details/vehicle-owner-details.component';
import { CommercialPlanListComponent } from './commercial/commercial-plan-list/commercial-plan-list.component';
import { DontKnowCommercialVechicleComponent } from './dont-know-commercial-vechicle/dont-know-commercial-vechicle.component';
import { BoughtNewCommercialVechiclesComponent } from './bought-new-commercial-vechicles/bought-new-commercial-vechicles.component';
import { DetailsComponent } from './commercial/details/details.component';
import { SummaryPageComponent } from './commercial/summary-page/summary-page.component';
import { HealthSummaryComponent } from './health/health-summary/health-summary.component';
import { PropertyInsuranceComponent } from './Property_Insurance/property-insurance/property-insurance.component';
import { PropertyPolicyComponent } from './Property_Insurance/property-policy/property-policy.component';
import { PropertySummaryComponent } from './Property_Insurance/property-summary/property-summary.component';
import { PolicyQuotesComponent } from './travelinsurance/policy-quotes/policy-quotes.component';
import { PartnerHomeComponent } from './partner-home/partner-home.component';
import { TravelDetailsComponent } from './travelinsurance/travel-details/travel-details.component';
import { TravelSummaryComponent } from './travelinsurance/travel-summary/travel-summary.component';
import { HospitalComponent } from './Bussiness_Ragistration/hospital/hospital.component';
import { HospitalBussinessVarificationComponent } from './Bussiness_Ragistration/hospital-bussiness-varification/hospital-bussiness-varification.component';
import { HospitalBankdetailsComponent } from './Bussiness_Ragistration/hospital-bankdetails/hospital-bankdetails.component';
import { DoctorComponent } from './Bussiness_Ragistration/Doctor_Ragistration/doctor/doctor.component';
import { VerificationComponent } from './Bussiness_Ragistration/Doctor_Ragistration/verification/verification.component';
import { DoctorBankDetailsComponent } from './Bussiness_Ragistration/Doctor_Ragistration/doctor-bank-details/doctor-bank-details.component';
import { MedicalProfileComponent } from './Bussiness_Ragistration/Madical_Ragistration/medical-profile/medical-profile.component';
import { MedicalVerificationComponent } from './Bussiness_Ragistration/Madical_Ragistration/medical-verification/medical-verification.component';
import { MedicalBankDetailsComponent } from './Bussiness_Ragistration/Madical_Ragistration/medical-bank-details/medical-bank-details.component';
import { GarageProfileComponent } from './Bussiness_Ragistration/Garage_Registration/garage-profile/garage-profile.component';
import { GatageVarificationComponent } from './Bussiness_Ragistration/Garage_Registration/garage-varification/garage-varification.component';
import { GatageBankDetailsComponent } from './Bussiness_Ragistration/Garage_Registration/garage-bank-details/garage-bank-details.component';
import { InfluencerProfileComponent } from './Bussiness_Ragistration/Influencer_Registration/influencer-profile/influencer-profile.component';
import { InfluencerVerficationComponent } from './Bussiness_Ragistration/Influencer_Registration/influencer-verfication/influencer-verfication.component';
import { InfluencerBankComponent } from './Bussiness_Ragistration/Influencer_Registration/influencer-bank/influencer-bank.component';
import { VehiclequotesComponent } from './vehicleinsurance/vehiclequotes/vehiclequotes.component';
import { AddPolicyComponent } from './components/add-policy/add-policy.component';
import { PaymentProgressComponent } from './payment/bike/payment-progress/payment-progress.component';
import { CustomerPolicyListComponent } from './components/customer-policy-list/customer-policy-list.component';
import { AgentCommissionComponent } from './agent/agent-commission/agent-commission.component';
import { KycResponseComponent } from './payment/bike/kyc-response/kyc-response.component';
const routes: Routes = [
  {path: "BoughtNewCommercialVechicles" ,component:BoughtNewCommercialVechiclesComponent },
  
  {path: "DontKnowCommercialVechicle" ,component:DontKnowCommercialVechicleComponent },
    {path: "CommercialPlanList" ,component:CommercialPlanListComponent },
  {path: "VehicleOwnerDetails" ,component:VehicleOwnerDetailsComponent },
  {path: "CommercialVechicles" ,component:CommercialVechiclesComponent },
  
  { path: 'agent-bank', component: AgentBankDetailsComponent},
  { path: 'agent-kyc', component: AgentKycVerificationComponent},
  { path: 'agent-profile', component: AgentProfileComponent},
  { path: 'agent-homepage', component: BusinesshomepageComponent},
  {path: "agent-dashboard" ,component:AgentDashboardComponent },
  {path: "agent-training" ,component:AgenttrainingComponent },
  {path: "li-training" ,component:LiPosptrainingComponent },
  {path: "li-exam" ,component:LiexamComponent },
  {path: "gi-training" ,component:GiPosptrainingComponent },
  { path: 'register-business', component: RegisterbusinessComponent},
  {path: "HealthinsuranceDetails" ,component:HealthinsuranceDetailsComponent },
  {path: "HealthinsurancePlan" ,component:HealthinsurancePlanComponent },
  {path: "HealthinsurancePlanList" ,component:HealthinsurancePlanListComponent },
  {path: "HealthinsurancePolicy" ,component:HealthinsurancePolicyComponent },
  { path: 'help', component:  HelpComponent},  
  { path: 'Verify', component:  VerifyComponent},  
  { path: 'PersonalInfo', component:  PersonalInfoComponent},

  { path: 'Vacancy', component: VacancyComponent},
  { path: 'Comparison', component: ComparisonComponent},
  // { path: 'details', component: AddTestimonialComponent},
  { path: 'PaymentCard', component: PaymentCardComponent},
  { path: 'Success', component: SuccessComponent},
  { path: 'Failure', component: FailureComponent},
  { path: 'kycresponse', component: KycResponseComponent},
{ path: 'carInsuranceOwnerDetail', component: carInsuranceOwnerDetailComponent},
{ path: 'carInsurancePolicyDetail', component:  carInsurancePolicyDetailComponent},  

{ path: 'CarInsuranceQuotes', component: CarInsuranceQuoteComponent},
{ path: 'CarQuoteDetail', component:  CarQuoteDetailComponent},  
{ path: 'BikeInsuranceQuotes', component: BikeInsuranceQuoteComponent},
{ path: 'InsuranceOwnerDetail', component: InsuranceOwnerDetailComponent},
{ path: 'InsurancePolicyDetail', component: InsurancePolicyDetailComponent
},
  { path: '', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'signup/referral/:ReferralCode', component: SignupComponent },
  { path: 'forgotpassword', component: ResetPasswordComponent },
  { path: 'unauthorize', component: UnauthorizedComponent },
  { path: 'privacy-policy', component: PrivacypolicyComponent },
  { path: 'terms-of-use', component: TermsconditionsComponent },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'role', component: RoleComponent, canActivate: [AuthGuard] },
  { path: 'profile', component: ProfileComponent },
  { path: 'vehicleInfo', component: AssetsComponent, canActivate: [AuthGuard] },
  { path: 'businessInfo', component: BusinessInfoComponent, canActivate: [AuthGuard] },
  { path: 'documents', component: DocumentsComponent, canActivate: [AuthGuard] },
  { path: 'users', component: UsersComponent, canActivate: [AuthGuard] },
  { path: 'insuranceplans', component: InsuranceComponent },
  { path: 'policyDetails', component: PolicyDetailsComponent, canActivate: [AuthGuard] },
  { path: 'quotesinfo', component: QuoteDetailsComponent },
  { path: 'forgotPassword', component: ForgotPasswordComponent },
  { path: 'payment', component: PolicyPaymentComponent, canActivate: [AuthGuard] },
  { path: 'settings', component: SettingsComponent },
  { path: 'health-quotes', component: HealthComponent},
  { path: 'property', component: PropertyComponent},
  { path: 'bike', component: BikeComponent},
  { path: 'car', component: CarComponent},
  { path: 'customer', component: CustomerComponent},
  //New Routes
  
  { path: 'healthOwnerDetails', component: HealthSummaryComponent },
    { path: 'aboutus', component: AboutusComponent },
    { path: 'add-testimonial', component: AddTestimonialComponent},
    { path: 'sitemap', component: SitemapComponent },
    { path: 'career', component: CareerComponent },
    { path: 'disclaimer', component: DisclaimerComponent },
    { path: 'term-condition', component: TermsConditionComponent },
    { path: 'cancellation-refund', component: CancellationrefundComponent },
    { path: 'content', component: ContentComponent },
    { path: 'faqs', component: FaqsComponent},
    { path: 'grivance-redressal', component: GrivanceredressalComponent },
    { path: 'term-insurance', component: TermInsuranceComponent },
    { path: 'group-term-insurance', component: GroupTermInsuranceComponent },
    { path: 'single-pay-insurance', component: SinglepayinsuranceComponent },
    { path: 'royal', component: RoyalSundaramPaymentComponent},
    { path: 'contactus', component: ContactusComponent},
  
    
    { path: 'car-insurance', component: CarInsuranceComponent},
    { path: 'taxi-insurance', component: TaxiInsuranceComponent},
    { path: 'commercial-vehicle-insurance', component: CommercialVehicleInsuranceComponent},
    { path: 'third-party-car-insurance', component: ThirdPartyCarInsuranceComponent},
    { path: 'third-party-two-wheeler-insurance', component: ThirdPartyTwoWheelerInsuranceComponent},
    { path: 'two-wheeler-insurance', component: TwoWheelerInsuranceComponent},

    { path: 'family-health-insurance', component: FamilyHealthPolicyComponent},
    { path: 'individual-health-insurance', component: IndividualHealthPolicyComponent},
    { path: 'group-health-insurance', component: GroupHealthInsuranceComponent},
    { path: 'senior-citizen-health-insurance', component: SeniorHealthPolicyComponent},
    { path: 'critical-illness-insurance', component: CriticalIllnessPolicyComponent},
    { path: 'arogya-sanjivni-insurance', component: ArogyaSanjivniPolicyComponent},
    { path: 'tax-saving-insurance', component: TaxSavingPlanComponent},
    { path: 'money-back-insurance', component: MoneyBackPlanComponent},
    { path: 'guaranteed-return-insurance', component: GuaranteedReturnPlanComponent},
    { path: 'ulip-insurance', component: ULIPComponent},
    { path: 'pension-insurance', component: PensionPlanComponent},
    { path: 'child-insurance', component: ChildPlanComponent},
    { path: 'bike-insurance-details', component: BikeInsuranceDetailComponent},
    { path: 'bike-insurance-owner-details', component: BikeInsuranceOwnerDetailComponent},
    { path: 'bike-insurance-policy-details', component: BikeInsurancePolicyDetailComponent},
    { path: 'royal-details', component: RoyalSundaramPolicyComponent},
     { path: 'commercial-details', component: DetailsComponent},
     { path: 'summary-details', component: SummaryPageComponent},
     
  { path: 'property-insurance', component: PropertyInsuranceComponent},
  
  { path: 'property-policy', component: PropertyPolicyComponent},
  { path: 'property-summary', component: PropertySummaryComponent},
  { path: 'travel-policy', component: PolicyQuotesComponent},
  { path: 'partner-home', component: PartnerHomeComponent},
  { path: 'travel-detail', component: TravelDetailsComponent},
  { path: 'travel-summary', component: TravelSummaryComponent},
  { path: 'hospital', component: HospitalComponent},
  { path: 'hospital-varification', component: HospitalBussinessVarificationComponent},
  { path: 'hospital-bankDetails', component: HospitalBankdetailsComponent},
  { path: 'doctor-profile', component: DoctorComponent},
  { path: 'doctor-verfication', component: VerificationComponent},
  { path: 'doctor-bank-detail', component: DoctorBankDetailsComponent},
  { path: 'medical-profile', component: MedicalProfileComponent},
  { path: 'medical-verification', component: MedicalVerificationComponent},
  { path: 'medical-bank', component: MedicalBankDetailsComponent},
  { path: 'garage-profile', component: GarageProfileComponent},
  { path: 'garage-verification', component: GatageVarificationComponent},
  { path: 'garage-bank', component: GatageBankDetailsComponent},
  { path: 'influencer-profile', component: InfluencerProfileComponent },
  { path: 'influencer-verification', component: InfluencerVerficationComponent },
  { path: 'influencer-bank', component: InfluencerBankComponent },
  { path: 'vehicle-quotes', component: VehiclequotesComponent },
  { path: 'ImportPolicy', component: AddPolicyComponent},
  { path: 'payment-progress', component: PaymentProgressComponent},
  { path: 'sales', component: CustomerPolicyListComponent, canActivate: [AuthGuard]},
  { path: 'agent-commission', component: AgentCommissionComponent, canActivate: [AuthGuard]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
