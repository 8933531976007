<!-- <div class="container" style="background-color: #f3f3f4;"> -->
    <div class="row">
        <div class="col-md-12">
            <div class="card " style="padding: 3px;">
                <div class="row align-items-center ">
                    <div class="col-auto p-1">
                        <button class="btn-primary btn btn-sm px-3"><img class="text-white svg_white"
                                src="../../../assets/svgIcon/ArrowLeft.svg" alt=""></button>
                    </div>
                    <div class="col-2 text-center">
                        <label for="" class="p-h">Property Type</label>
                        <p class="p-align mb-0">{{requestData?.propertyType}} </p>
                    </div>
                    <div class="col-3 text-center" *ngIf="requestData?.insuranceFor == 'shop'">
                        <label for="" class="p-h">Shop Name</label>
                        <p class="p-align mb-0">{{requestData?.shopeExpiry?.Shopname}}</p>
                    </div>
                    <div class="col-3 text-center" *ngIf="requestData?.insuranceFor == 'business'">
                        <label for="" class="p-h">Company Name</label>
                        <p class="p-align mb-0">{{requestData?.officeExpiry?.NameCompany}}</p>
                    </div>
                    <div class="col-2 text-center">
                        <label for="" class="p-h">Cover Type</label>
                        <p class="p-align mb-0">{{requestData?.cover?.toString()}}</p>
                    </div>
                    <div class="col-2 text-center">
                        <label for="" class="p-h">Location</label>
                        <p class="p-align mb-0">{{showLocation()}}</p>
                    </div>
                    <div class="col-1 text-center">
                        <label for="" class="p-h">Total Value</label>
                        <p class="p-align mb-0">₹ {{showTotalValue()}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-3" *ngIf="isQuote">
        <div class="col-md-4 mb-12">
            <div class="card p-2 ">
                <div class="row scroll  ">
                    <div class="col-md-12 mt-3">
                        <h4 style="font-size: 15px;font-weight: 600;">Claim Excess Amount &nbsp;&nbsp;<img
                                src="../../../assets/svgIcon/Info.svg" alt=""></h4>
                        <hr>
                    </div>
                    <div class="col-md-12 mt-2">
                        <h4 style="font-size: 15px;">Sum Insured &nbsp;&nbsp;<img src="../../../assets/svgIcon/Info.svg"
                                alt=""></h4>
                    </div>
                    <div class="col-md-12 mt-2" *ngIf="isBuilding">
                        <div class="row">
                            <p> <b>Building</b> </p>
                            <div class="col-md-3  mt-2">
                                <p class="sumMin">₹ {{structureSumMin | thousandSeparator}} </p>
                            </div>
                            <div class="col-md-6 mt-2">
                                <input type="range" name="customRange" id="customRange1"
                                    class="  form-range text-main ng-pristine ng-valid ng-touched" [min]="structureSumMin"
                                    [max]="structureSumMax">
                            </div>
                            <div class="col-md-3  mt-2">
                                <p class="sumMax">₹ {{structureSumMax | thousandSeparator}} </p>
                            </div>
                            <div class="col-md-3 mt-2 val-l">
                                <p>or enter value</p>
                            </div>
                            <div class="col-md-6 mt-2 val">
                                <input type="number" name="" class="in" id="" [value]="structureSumCur">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mt-2" *ngIf="isContant">
                        <div class="row">
                            <p><b>Content </b></p>
                            <div class="col-md-3 mt-2">
                                <p class="sumMin">₹ {{contentSumMin | thousandSeparator}} </p>
                            </div>
                            <div class="col-md-6 mt-2">
                                <input _ngcontent-gmd-c75="" type="range" name="customRange" id="customRange1"
                                    class="form-range text-main ng-pristine ng-valid ng-touched" [min]="contentSumMin"
                                    [max]="contentSumMax">
                            </div>
                            <div class="col-md-3 mt-2">
                                <p class="sumMax">₹ {{contentSumMax | thousandSeparator}} </p>
                            </div>

                            <div class="col-md-3 mt-2 val-l">
                                <p>or enter value</p>
                            </div>
                            <div class="col-md-6 mt-2 val">
                                <input type="number" name="" class="in" id="" [value]="contentSumCur">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mt-2" *ngIf="isStock">
                        <div class="row">
                            <p><b>Stock </b></p>
                            <div class="col-md-3 mt-2">
                                <p class="sumMin">₹ {{stockSumMin | thousandSeparator}} </p>
                            </div>
                            <div class="col-md-6 mt-2">
                                <input _ngcontent-gmd-c75="" type="range" name="customRange" id="customRange1"
                                    class="form-range text-main ng-pristine ng-valid ng-touched" [min]="stockSumMin"
                                    [max]="stockSumMax">
                            </div>
                            <div class="col-md-3 mt-2">
                                <p class="sumMax">₹ {{stockSumMax | thousandSeparator}} </p>
                            </div>

                            <div class="col-md-3 mt-2 val-l">
                                <p>or enter value</p>
                            </div>
                            <div class="col-md-6 mt-2 val">
                                <input type="number" name="" class="in" id="" [value]="stockSumCur">
                            </div>
                        </div>
                        <hr>
                    </div>
                    <div class="col-md-12 mt-2">
                        <div class="row">
                            <h4 style="font-size: 15px;">Do you have a previous claim history?</h4>
                            <div class="col-md-4 mt-2">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" value="Yes" name="claimMade" id="claimMade1">
                                    <p class="p-mg">Yes</p>
                                </div>
                            </div>
                            <div class="col-md-4 mt-2">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" value="No" name="claimMade" id="claimMade2">
                                    <p class="p-mg">No</p>
                                </div>
                            </div>
                        </div>
                        <hr>
                    </div>
                    <div class="col-md-12  mt-5 text-start">
                        <p class="p-h"> Policy Term</p>
                        <div class="col-md-4  mt-2">
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="policyTerm"
                                    id="flexRadioDefault1">
                                <p class="p-mg">1 Year</p>
                            </div>
                        </div>
                        <div class="col-md-4  mt-2">
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="policyTerm"
                                    id="flexRadioDefault1">
                                <p class="p-mg">2 Year</p>
                            </div>
                        </div>
                        <div class="col-md-4  mt-2">
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="policyTerm"
                                    id="flexRadioDefault1">
                                <p class="p-mg">3 Year</p>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-md-8 mt-5">
                        <select class="stl" aria-label="Default select example">
                            <option selected>Select Policy Term</option>
                            <option value="1">1 year</option>
                            <option value="2">2 year</option>
                            <option value="3">3 year</option>
                        </select>
                    </div> -->
                    <!-- <div class="col-md-12 mt-5">
                        <label for="">Building Age</label>
                        <select class="form-select" aria-label="Default select example">
                            <option selected>Open this select menu</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </select>
                        <hr>
                    </div> -->

                    <div class="col-md-12 mt-5">
                        <hr>
                        <h4>Shop on Finance</h4>

                    </div>
                    <div class="col-md-12 mt-2">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"
                                (click)="check()">
                            <p class="p-tg b-mr">Is Shop currently on finance?</p>
                        </div>

                    </div>
                    <div class="col-md-12 mt-3" *ngIf="checked">
                        <div class="row">
                            <div class="col-md-12 mt-3">
                                <label for="">Financier Name</label>
                                <input type="text" name="" class="form-control" id="" placeholder="Financier Name">
                            </div>
                            <div class="col-md-12 mt-3 ">
                                <label for="">Financed Amount</label>
                                <input type="text" name="" class="form-control" placeholder="₹ Amount" id="">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-4">
                    <button class="btn btn-primary w-100 rounded-top-0 position-absolute b-1 r-0" (click)="getQuotes(this.requestData)">Apply</button>
                </div>
            </div>
        </div>
        <div class="col-md-8 mg">
            <div class="row">
                <div class="col-md-9 mt-5">
                    <h2>Insurance plans for you</h2>
                    <span class="spn">Showing {{quoteList?.length??0}} plans for you</span>
                </div>
                <div class="col-md-3 mt-5">
                    <div class="card p-2 cd">
                        <div class="row">
                            <div class="col-md-3 mt-2 c2">
                                <img src="../../assets/svgIcon/SortAscending.svg" alt="SortAscending" width="22">
                            </div>
                            <div class="col-md-9 mt-2 c6 ">
                                <select name="" id="" class="sort">
                                    <option selected>Sort by</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <ng-container *ngFor="let quote of quoteList">
                    <div class="col-md-12 mt-2">
                        <div class="card px-5">                            
                                <div class="row align-items-center">
                                    <div class="col">
                                        <img class="image-stl" src="/assets/images/insurance_companies/{{quote.insuranceCompany}}.png" alt="">
                                    </div>    
                                    <div class="col mt-2">
                                        <span class="spn">Plan Name</span>
                                        <p class="p-h">{{quote.planName}}</p>
                                    </div>    
                                    <!-- <div class="col mt-2">
                                        <span class="spn">Medical Expenses</span>
                                        <p class="p-h">{{quote.medicalExpenses}}</p>
                                    </div>
                                    <div class="col mt-2">
                                        <span class="spn">Loss Of Passport</span>
                                        <p class="p-h">{{quote.lossOfPassport}}</p>
                                    </div>     -->
                                    <div class="col mt-2 text-end">
                                        <button class="btn btn-primary btn-sm py-1" (click)="getDetails()">₹
                                            {{quote.totalPremiumAmount}} <span class="gst-span">GST Included</span>
                                        </button>
                                        <span class="span-link">View Breakup</span>
                                    </div>
                                </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="row mt-5" *ngIf="isDetail">
        <div class="col-md-4">
            <div class="card p-3">
                <div class="row">
                    <div class="col-md-6">
                        <img src="../../../assets/images/united.png" width="100%" alt="">
                    </div>
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-4"><span>
                                    Plan:
                                </span></div>
                            <div class="col-md-8">
                                <label for="">Comprehensive</label>
                            </div>
                            <div class="col-md-4"><span>
                                    IDV:
                                </span></div>
                            <div class="col-md-8">
                                <label for="">₹15,186</label>
                            </div>
                            <div class="col-md-5"><span>
                                    Tenure:
                                </span></div>
                            <div class="col-md-7">
                                <label for="">1 Year</label>
                            </div>

                        </div>

                    </div>

                    <div class="col-md-12">
                        <hr>
                        <h5 class="h">Premium Breakup</h5>
                        <hr>
                    </div>
                    <div class="col-md-12">

                        <div class="row">
                            <div class="col-md-9 hf-cl">
                                <span>Basic Own Damage Premium</span>
                            </div>
                            <div class="col-md-3 hf-cl">
                                <span>₹10,252</span>
                            </div>
                        </div>
                        <hr>
                    </div>
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-9">
                                <span style="color:black"><b>Net Premium</b></span>
                            </div>
                            <div class="col-md-3">
                                <span style="color:black"><b>₹12,250</b></span>
                            </div>
                            <div class="col-md-9 mt-2 hf-cl">
                                <span>GST (18%)</span>
                            </div>
                            <div class="col-md-3 mt-2 hf-cl">
                                <span>+ ₹699</span>
                            </div>
                        </div>
                        <hr>
                    </div>
                    <div class="col-md-12 mt-2">
                        <div class="row">
                            <div class="col-md-9 hf-cl">
                                <h5 class="h" style="color:black ">Total Premium</h5>
                            </div>
                            <div class="col-md-3 hf-cl">
                                <h5 class="h" style="color:black">₹12,250</h5>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="col-md-8">
            <div class="card p-5">
                <div class="row">
                    <div class="col-md-4 tabs col4">
                        <div class="card crd1  " [ngClass]="{'selected':tabs==''}" (click)="nextTab('')">
                            <p class="main mt-2" [ngClass]="{'p-cl':tabs==''}">Proposer Details</p>
                        </div>
                    </div>
                    <div class="col-md-4 tabs col4">
                        <div class="card crd1  " [ngClass]="{'selected':tabs=='Address'}" (click)="nextTab('Address')">
                            <p class="main mt-2" [ngClass]="{'p-cl':tabs=='Address'}">Address Details</p>
                        </div>
                    </div>
                    <div class="col-md-4 tabs col4">
                        <div class="card crd1  " [ngClass]="{'selected':tabs=='Nominee'}" (click)="nextTab('Nominee')">
                            <p class="main mt-2" [ngClass]="{'p-cl':tabs=='Nominee'}">Nominee Details</p>
                        </div>
                    </div>


                </div>
                <hr style="    position: relative;
                bottom: 11px;">

                <div class="row mt-3">
                    <div class="col-md-12">
                        <div class="content">
                            <div *ngIf="tabs==''">
                                <div class="row ">
                                    <div class="col-md-6 mt-3">
                                        <label class="required" for="">PAN Card Number</label>
                                        <input type="text" name="" class="form-control in-h" placeholder="Eg-AAAAA1111A"
                                            id="">
                                    </div>
                                    <div class="col-md-6 mt-3">
                                        <label class="required" for="">Date of Birth</label>
                                        <input type="date" placeholder="DD/MM/YYYY" name="" class="in-h form-control"
                                            id="">
                                    </div>
                                    <div class="col-md-2 mt-3">
                                        <label class="required" for="">Title</label>
                                        <select class="form-select in-h" aria-label="Default select example"
                                            style="height: 50%;">
                                            <option selected value="Mr.">Mr.</option>
                                            <option value="Miss.">Miss.</option>
                                            <option value="Mrs.">Mrs.</option>
                                        </select>
                                    </div>
                                    <div class="col-md-4 mt-3">
                                        <label class="required" for="">First Name</label>
                                        <input type="text" name="" class="form-control in-h"
                                            placeholder="Enter first name">
                                    </div>
                                    <div class="col-md-6 mt-3">
                                        <label class="required" for="">Last Name</label>
                                        <input type="text" name="" class="form-control in-h"
                                            placeholder="Enter last name">
                                    </div>
                                    <!-- <div class="col-md-6 mt-3">
                                        <div class="row">
                                            <label class="required" for="">Gender</label>
                                            <div class="col-md-4 mt-2"><input type="radio" name="" value="gander"
                                                    id="">&nbsp;Male</div>
                                            <div class="col-md-4 mt-2"><input type="radio" name="" value="gander"
                                                    id="">&nbsp;Female</div>
                                            <div class="col-md-4 mt-2"><input type="radio" name="" value="gander"
                                                    id="">&nbsp;Other</div>
                                        </div>
                                    </div> -->


                                    <!-- <div class="col-md-6 mt-3">
                                        <label class="required" for="">CKYC Number &nbsp;<img src="../../../assets/svgIcon/Info1.svg"
                                                width="12%" alt=""></label>
                                        <input type="text" name="" class="form-control"
                                            placeholder="Auto Fetch from PAN" id="">
                                    </div> -->

                                    <div class="col-md-6 mt-3">
                                        <label class="required" for="">Your Email Id</label>
                                        <input type="email" name="" class="form-control in-h" placeholder="Email" id="">
                                    </div>
                                    <div class="col-md-6 mt-3">
                                        <label class="required" for="">Your mobile no.</label>
                                        <input type="number" name="" class="form-control in-h"
                                            placeholder="Mobile number" id="">
                                    </div>

                                </div>
                                <div class="row mt-4">
                                    <div class="col-md-12 " style="    position: relative;
                                    right: -88%;">
                                        <button class="btn btn-sm btn-primary"
                                            (click)="nextTab('Address')">Next</button>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="tabs=='Address'">
                                <div class="row">
                                    <div class="col-md-12 mt-3   ">
                                        <h4>Property Address</h4>
                                    </div>
                                    <div class="col-md-6 mt-3">
                                        <label for="" class="required">Flat Number/Street Number
                                        </label>
                                        <input type="number" placeholder="Flat number/Street number" name=""
                                            class="form-control in-h" id="">
                                    </div>
                                    <div class="col-md-6 mt-3">
                                        <label for="" class="required"> Street Address
                                        </label>
                                        <input type="number" placeholder="Street address" name=""
                                            class="form-control in-h" id="">
                                    </div>


                                    <div class="col-md-6    mt-3">
                                        <label for="" class="required">PIN Code</label>
                                        <input type="number" placeholder="PIN code" name="" class="form-control in-h"
                                            id="">
                                    </div>
                                    <div class="col-md-6    mt-3">
                                        <label for="" class="required">State</label>
                                        <input type="number" placeholder=" State" name="" class="form-control in-h"
                                            id="">
                                    </div>
                                    <div class="col-md-6    mt-3">
                                        <label for="" class="required">City</label>
                                        <input type="number" placeholder="City" name="" class="form-control in-h" id="">
                                    </div>
                                    <div class="col-md-12 mt-3">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value=""
                                                id="flexCheckDefault">
                                            <span style="position: relative;
                                            top: 5px;">
                                                My communication address is same as property address.
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-12 mt-3   ">
                                        <h4>Property Address</h4>
                                    </div>
                                    <div class="col-md-6 mt-3">
                                        <label for="" class="required">Flat Number/Street Number
                                        </label>
                                        <input type="number" placeholder="Flat number/Street number" name=""
                                            class="form-control in-h" id="">
                                    </div>
                                    <div class="col-md-6 mt-3">
                                        <label for="" class="required"> Street Address
                                        </label>
                                        <input type="number" placeholder="  Street address" name=""
                                            class="form-control in-h " id="">
                                    </div>

                                    <div class="col-md-6    mt-3">
                                        <label for="" class="required">PIN Code</label>
                                        <input type="number" placeholder="PIN code" name="" class="form-control in-h"
                                            id="">
                                    </div>
                                    <div class="col-md-6    mt-3">
                                        <label for="" class="required">State</label>
                                        <input type="number" placeholder="State" name="" class="form-control in-h"
                                            id="">
                                    </div>
                                    <div class="col-md-6    mt-3">
                                        <label for="" class="required">City</label>
                                        <input type="number" placeholder="City" name="" class="form-control in-h" id="">
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-md-10">
                                        <button class="btn" (click)="nextTab('')">Back</button>
                                    </div>
                                    <div class="col-md-2">
                                        <button class="btn btn-primary btn-sm"
                                            (click)="nextTab('Nominee')">Next</button>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="tabs=='Nominee'">
                                <div class="row">
                                    <div class="col-md-6 mt-3">
                                        <label for="" class="required">Nominee Full Name</label>
                                        <input type="text" name="" class="form-control in-h" id=""
                                            placeholder="Full Name">
                                    </div>
                                    <div class="col-md-6 mt-3">
                                        <label for="" class="required">Relation</label>
                                        <select class="form-select in-h" aria-label="Default select example"
                                            style="height: 50%;">
                                            <option selected value="select">Select</option>
                                            <option value="Father">Father</option>
                                            <option value="Mother">Mother</option>
                                            <option value="Son">Son</option>
                                            <option value="Daughter">Daughter</option>
                                            <option value="Uncle">Uncle</option>
                                            <option value="Aunt">Aunt</option>
                                            <option value="Brother">Brother</option>
                                            <option value="Sister">Sister</option>
                                            <option value="Spouse">Spouse</option>
                                            <option value="Grand Son">Grand Son</option>
                                            <option value="Grand Daughter">Grand Daughter</option>

                                        </select>
                                    </div>

                                    <div class="col-md-6 mt-3">
                                        <label for="" class="required">Age</label>
                                        <select class="form-select in-h" aria-label="Default select example"
                                            style="height: 50%;">
                                            <option value="select" Selected>Select Age</option>
                                            <option *ngFor="let age of ageList" value="{{age}}">{{age}}</option>
                                        </select>
                                    </div>
                                    <div class="col-md-6 mt-3">
                                        <div class="row">
                                            <label for="" class="required">Gender</label>
                                            <div class="col-md-4 mt-2"><input type="radio" name="" value="gander"
                                                    id="">&nbsp;Male</div>
                                            <div class="col-md-4 mt-2"><input type="radio" name="" value="gander"
                                                    id="">&nbsp;Female</div>
                                            <div class="col-md-4 mt-2"><input type="radio" name="" value="gander"
                                                    id="">&nbsp;Other</div>
                                        </div>
                                    </div>

                                </div>
                                <div class="row mt-3">
                                    <div class="col-md-10">
                                        <button class="btn" (click)="nextTab('Address')">Back</button>
                                    </div>
                                    <div class="col-md-2">
                                        <button class="btn btn-primary btn-sm" (click)="getSummary()">Next</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

<!-- </div> -->
<div class="overlay verifybikedetailsdiv verifybikedetailsdiv1 modal right" *ngIf="PopUPCoverage">
    <div class="popup1 " style=" overflow-x: unset;">
        <div class="modal-header tooltip_">
            <h4 class="modal-title">
                Coverage
            </h4>
            <button type="button" class="close pull-right btn" aria-label="Close" (click)=" closeModal()">
                <img src="../../../assets/svgIcon/Crosss.svg " alt="">
            </button>
        </div>
        <div class="modal-body heigth ">
            <div class="row l-bm">
                <div class="col-md-6 tbs  " [ngClass]="{'selectTab':coversVal=='inbuilt'}"
                    (click)="Covertabs('inbuilt')">
                    <h4><b>Inbuilt Coverage</b></h4>
                </div>
                <div class="col-md-6 tbs " (click)="Covertabs('addOns')"
                    [ngClass]="{'selectTab':coversVal=='addOns'}">
                    <h4><b>Add-On Covers</b></h4>
                </div>
            </div>
            <div class="row" *ngIf="coversVal=='inbuilt'">
                <div class="col-md-12 mt-2">
                    <div class="form-check">
                        <p class="cvr-tx">
                            Terrorism Cover
                        </p>
                    </div>
                </div>
            </div>

            <div class="row scroll" *ngIf="coversVal=='addOns'">
                <div class="col-md-12 mt-2">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                        <p class="cvr-tx">
                            Plant and Machinery Cover
                        </p>
                    </div>
                </div>
                <div class="col-md-12 mt-2">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                        <p class="cvr-tx">
                            F&F and Other Equipment Cover
                        </p>
                    </div>
                </div>
                <div class="col-md-12 mt-2">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                        <p class="cvr-tx">
                            Raw material cover
                        </p>
                    </div>
                </div>
                <div class="col-md-12 mt-2">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                        <p class="cvr-tx">
                            Stock in Process Cover
                        </p>
                    </div>
                </div>
                <div class="col-md-12 mt-2">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                        <p class="cvr-tx">
                            Finished Stock Cover
                        </p>
                    </div>
                </div>
                <div class="col-md-12 mt-2">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                        <p class="cvr-tx">
                            Other Contents Cover
                        </p>
                    </div>
                </div>
                <div class="col-md-12 mt-2">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                        <p class="cvr-tx">
                            Cover for Valuable Contents Cover
                        </p>
                    </div>
                </div>
                <div class="col-md-12 mt-2">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                        <p class="cvr-tx">
                            STFI Cover
                        </p>
                    </div>
                </div>
                <div class="col-md-12 mt-2">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                        <p class="cvr-tx">
                            EQ Cover
                        </p>
                    </div>
                </div>
                <div class="col-md-12 mt-2">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                        <p class="cvr-tx">
                            Declaration Stock Cover Cover
                        </p>
                    </div>
                </div>
                <div class="col-md-12 mt-2">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                        <p class="cvr-tx">
                            Flexa Discount Cover
                        </p>
                    </div>
                </div>
                <div class="col-md-12 mt-2">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                        <p class="cvr-tx">
                            Floater Cover
                        </p>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>