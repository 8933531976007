<div class="container " *ngIf="!isQuotes">
  <div class="row mt-15">
    <div class="col-lg-6 col-md-6 col-sm-6 col-xl-6 col-12">
      <img src="../../../assets/images/banner/health.png" width="100%" height="100%" alt="">
    </div>
    <!-- <div class="col-md-6" style="text-align:center">
      <img src="../../../assets/images/newicons/helt.svg" width="70%" height="100%" alt="">
    </div> -->
    <div class="col-md-6 mt-10" *ngIf="divNumber=='first'">
      <div class="card p-3 bg-transparent">
        <div>
          <h2 class="heading text-left">Get best Health Insurance for you <br>
            and your family.</h2>
          <h5 class="text-left text-grey4 fw-medium mt-3">Select for whom you want to buy Insurance</h5>
        </div>
        <br>
        <div class="row ">
          <!-- <div class="col-md-11">
            <div class="col-md-6 mt-2">
              <div class="card crd p-3" [ngClass]="{'active_tab': activeTabs == 1}"
                (click)="insuranceTab('individual')">
                <h4 class="text-center hd">
                  Individual
                </h4>
              </div>
            </div>
          </div> -->
          <div class="col-md-6">
            <div class="card crd p-3 mb-0 h-100" [ngClass]="{'active_tab': activeTabs == 2}" (click)="insuranceTab('family')">
              <h4 class="text-center mb-0">
                Select Member(s)
              </h4>
            </div>
          </div>
        </div> 
      </div>
    </div>
    <div class="col-md-6 mt-10" *ngIf="divNumber=='second'">
      <div class="card mt-5 p-4">
        <div class="row">
          <div class="col-md-12">
            <h4>Tell us about your existing illnesss</h4>
          </div>
          <div class="col-md-6">
            <div class="card crd mt-3 p-3">
              <div class="row">
                <div class="col-md-8 mt-1">
                  <p>
                    Diabetes
                  </p>
                </div>
                <div class="col-md-4">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" (click)="change()">

                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="col-md-6">
            <div class="card crd mt-3 p-3">
              <div class="row">
                <div class="col-md-8 mt-1">
                  <p>
                    BP / Hypertension
                  </p>
                </div>
                <div class="col-md-4">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" (click)="change()">

                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="col-md-6">
            <div class="card crd mt-3 p-3">
              <div class="row">
                <div class="col-md-8 mt-1">
                  <p>
                    Heart Ailments
                  </p>
                </div>
                <div class="col-md-4">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" (click)="change()">

                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="col-md-6">
            <div class="card crd mt-3 p-3">
              <div class="row">
                <div class="col-md-8 mt-1">
                  <p>
                    Other health issues
                  </p>
                </div>
                <div class="col-md-4">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" [checked]="div"
                      (click)="others()">

                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div class="row">
          <div *ngIf="div">
            <div class="col-md-12 mt-6">
              <h2>
                Your health issues
              </h2>
            </div>
            <div class="col-md-12 mt-2">
              <textarea name="other" cols="85" rows="4" style="border-radius: 10px;" class="textarea p-2"
                onkeypress="if(this.value.length==50) return false"></textarea>
            </div>
          </div>
          <div class="col-md-6 mt-5 ">

            <button type="button" class="btn btn-outline-secondary" (click)="Divs('first')">Back</button>
          </div>
          <div class="col-md-6 mt-5 text-end">
            <button class="btn btn-primary " (click)="getHealthQuotes()">
              View Plans
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div *ngIf="isQuotes">
    <app-healthinsurance-plan-list [quoteData] = "premiumRequest.value"></app-healthinsurance-plan-list>
</div> -->
<div *ngIf="isQuotes">
  <app-health [quoteData]="premiumRequest.value" [healthData]="healthData"></app-health>
</div>

<div class="overlay verifybikedetailsdiv verifybikedetailsdiv1 modal right" *ngIf="healthinsurancepolicyfamily">
  <div class="popup2 col-md-4 col-12" style=" overflow-x: unset;">
    <div class="modal-header tooltip_">
      <h2 class="modal-title">
        <!-- <h2 class="">Member(s)
        </h2> -->
      </h2>
      <button type="button" class="close pull-right" aria-label="Close" (click)=" closeModal()">
        <span></span>
      </button>
    </div>
    <div class="modal-body heigth py-3 px-5">
      <div *ngIf="isFamilyTab=='1'">
        <h4 class="mb-3 border-bottom pb-2">Who would you like to insure?</h4>
        <div class="row">
          <div class="col-5 " *ngIf="!famShoww" (click)="selectedCard('Self')" (click)="show()">
            <div class="p-4 mb-2 selectedTab " [ngClass]="{'active_tab': selectCard1 }">
              <li class="d-flex justify-content-between">
                <h5 class="mb-0">Self</h5>
                <!-- <input type="checkbox" > -->
              </li>
            </div>
          </div>
          <div class="col-5" *ngIf="famShoww" (click)="selectedCard('Self')" (click)="show()">
            <div class="p-4 mb-2 selectedTab " [ngClass]="{'active_tab': selectCard1 }">
              <li class="d-flex justify-content-between">
                <h5 class="mb-0">Self</h5>
                <!-- <input type="checkbox" > -->
              </li>
            </div>
          </div>
          <div class="col-3">
            <div class="gender_div d-flex" *ngIf="isGenderDiv">
              <ul class="ul-t pl-0 m-0">
                <li><input type="radio" name="gender" [(ngModel)]="gender" value="male"
                    [checked]="isChecked" />
                  Male</li>
                <li><input type="radio" name="gender" [(ngModel)]="gender" value="female"
                    [checked]="isChecked" /> Female</li>
              </ul>
            </div>
          </div>
          <div class="col-4 age" *ngIf="age1">
            <select class="form-select" [(ngModel)]="selfAge1" name="selfAge1" id="selfAge1"
              (input)="check(1,$event)">
              <option value=""> Select Age</option>
              <option *ngFor="let age of ageList; let i = index;" [value]="age">
                {{age}}
              </option>
            </select>
          </div>
        </div>
        <div class="row">  
          <div class="col-5 mt-2" (click)="selectedCard('Wife')">
            <div class="p-4 mb-2 selectedTab " [ngClass]="{'active_tab': selectCard2 }" *ngIf="gender == 'male'">
              <li class="d-flex justify-content-between">
                <h5 class="mb-0">Wife</h5>
                <!-- <input type="checkbox" > -->
              </li>
            </div>
            <div class="p-4 selectedTab " [ngClass]="{'active_tab': selectCard2 }" *ngIf="gender == 'female'">
              <li class="d-flex justify-content-between" (click)="selectedCard('Husband')">
                <h5 class="mb-0">Husband</h5>
                <!-- <input type="checkbox" > -->
              </li>
            </div>
          </div>
          <div class="col-5 age  mt-2 age-m" *ngIf="age2">
            <select class="form-select" [(ngModel)]="spouseAge1" name="spouseAge1" id="spouseAge1"
              (input)="check(2,$event)">
              <option value=""> Select Age</option>
              <option *ngFor="let age1 of ageList; let i = index;" [value]="age1">
                {{age1}}
              </option>
            </select>
          </div>
        </div>
        <div class="row">  
          <div class="col-5 mt-2" (click)="selectedCard('Son')">
            <div class="p-4 mb-2 selectedTab " [ngClass]="{'active_tab': selectCard3 }">
              <li class="d-flex justify-content-between">
                <h5 class="mb-0">Son</h5>
                <!-- <input type="checkbox" > -->
              </li>
            </div>
          </div>
          <div class="col-5 mt-2">
            <div class="counter_div conter_div1" *ngIf="isSonsDiv">
              <li>
                <span class="minus_span" (click)="counterFunction('decrement')"> - </span>
              </li>
              <li>
                <span class="number_span"> {{counterValue}} </span>
              </li>
              <li>
                <span class="minus_span" (click)="counterFunction('increment')"> + </span>
              </li>
            </div>
          </div>
          <div class="col-6 my-2" *ngIf="age3">
            <label for=""> Son 1</label>
            <select class="form-select" [(ngModel)]="selectedSonAge1" name="selectedSonAge1"
              id="selectedSonAge1" (input)="check(3,$event)">
              <option value=""> Select Age</option>
              <option *ngFor="let age2 of childrenAgeList; let i = index;" [value]="age2">
                {{age2}}
              </option>
            </select>
          </div>
          <div class="col-6 my-2" *ngIf="age13">
            <label for=""> Son 2</label>
            <select class="form-select" [(ngModel)]="selectedSonAge2" name="selectedSonAge2"
              id="selectedSonAge2" (input)="check(4,$event)">
              <option value=""> Select Age</option>
              <option *ngFor="let age12 of childrenAgeList; let i = index;" [value]="age12">
                {{age12}}
              </option>
            </select>
          </div>
          <div class="col-6 my-2" *ngIf="age14">
            <label for=""> Son 3</label>
            <select class="form-select" [(ngModel)]="selectedSonAge3" name="selectedSonAge3"
              id="selectedSonAge3" (input)="check(5,$event)">
              <option value=""> Select Age</option>
              <option *ngFor="let age13 of childrenAgeList; let i = index;" [value]="age13">
                {{age13}}
              </option>
            </select>
          </div>
          <div class="col-6 my-2" *ngIf="age15">
            <label for=""> Son 4</label>
            <select class="form-select" [(ngModel)]="selectedSonAge4" name="selectedSonAge4"
              id="selectedSonAge4" (input)="check(6,$event)">
              <option value=""> Select Age</option>
              <option *ngFor="let age14 of childrenAgeList; let i = index;" [value]="age14">
                {{age14}}
              </option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-5 mt-2" (click)="selectedCard('Daughter')">
            <div class="p-4 mb-2 selectedTab " [ngClass]="{'active_tab': selectCard4 }">
              <li class="d-flex justify-content-between">
                <h5 class="mb-0">Daughter</h5>
                <!-- <input type="checkbox" > -->
              </li>
            </div>
          </div>
          <div class="col-5 mt-2">
            <div class="counter_div conter_div1" *ngIf="isDaughtersDiv">
              <li>
                <span class="minus_span" (click)="counterFunctionDoughter('decrement')"> - </span>
              </li>
              <li>
                <span class="number_span"> {{doughtercounterValue}} </span>
              </li>
              <li>
                <span class="minus_span" (click)="counterFunctionDoughter('increment')"> + </span>
              </li>
            </div>
          </div>
          <div class="col-6 my-2" *ngIf="age4">
            <label for=""> Daughter 1</label>
            <select class="form-select" [(ngModel)]="selectedDauAge1" name="selectedDauAge1"
              id="selectedDauAge1" (input)="check(7,$event)">
              <option value=""> Select Age</option>
              <option *ngFor="let age3 of childrenAgeList; let i = index;" [value]="age3">
                {{age3}}
              </option>
            </select>
          </div>

          <div class="col-6 my-2" *ngIf="age16">
            <label for=""> Daughter 2</label>
            <select class="form-select" [(ngModel)]="selectedDauAge2" name="selectedDauAge2"
              id="selectedDauAge2" (input)="check(8,$event)">
              <option value=""> Select Age</option>
              <option *ngFor="let age15 of childrenAgeList; let i = index;" [value]="age15">
                {{age15}}
              </option>
            </select>
          </div>
          <div class="col-6 my-2" *ngIf="age17">
            <label for=""> Daughter 3</label>
            <select class="form-select" [(ngModel)]="selectedDauAge3" name="selectedDauAge3"
              id="selectedDauAge3" (input)="check(9,$event)">
              <option value=""> Select Age</option>
              <option *ngFor="let age16 of childrenAgeList; let i = index;" [value]="age16">
                {{age16}}
              </option>
            </select>
          </div>
          <div class="col-6 my-2" *ngIf="age18">
            <label for=""> Daughter 4</label>
            <select class="form-select" [(ngModel)]="selectedDauAge4" name="selectedDauAge4"
              id="selectedDauAge4" (input)="check(10,$event)">
              <option value=""> Select Age</option>
              <option *ngFor="let age17 of childrenAgeList; let i = index;" [value]="age17">
                {{age17}}
              </option>
            </select>
          </div>
        </div>
        <div class="row">   
          <div class="col-5 mt-2" *ngIf="father" (click)="selectedCard('Father')">
            <div class="p-4 mb-2 selectedTab " [ngClass]="{'active_tab': selectCard5 }">
              <li class="d-flex justify-content-between">
                <h5 class="mb-0">Father</h5>
                <!-- <input type="checkbox" > -->
              </li>
            </div>

          </div>
          <div class="col-7 mt-2" *ngIf="!father">
            <div class="p-4 unselect ">
              <li class="d-flex justify-content-between">
                <h5 class="mb-0">Father</h5>
              </li>
            </div>

          </div>
          <div class="col-5 age age-m mt-2" *ngIf="age5 && father">
            <select class="form-select" [(ngModel)]="fatherAge1" name="fatherAge1" id="fatherAge1"
              (input)="check(11,$event)">
              <option value=""> Select Age</option>
              <option *ngFor="let age4 of ageList; let i = index;" [value]="age4">
                {{age4}}
              </option>
            </select>
          </div>
        </div>
        <div class="row">   
          <div class="col-5 my-2" *ngIf="mother" (click)="selectedCard('Mother')">
            <div class="p-4 selectedTab " [ngClass]="{'active_tab': selectCard6 }">
              <li class="d-flex justify-content-between">
                <h5 class="mb-0">Mother</h5>
                <!-- <input type="checkbox" > -->
              </li>
            </div>
          </div>
          <div class="col-5 mt-2" *ngIf="!mother">
            <div class="p-4 mb-2 unselect ">
              <li class="d-flex justify-content-between">
                <h5 class="mb-0">Mother</h5>
                <!-- <input type="checkbox" > -->
              </li>
            </div>
          </div>
          <div class="col-5 age-m age mt-2" *ngIf="age6 && mother">
            <select class="form-select" [(ngModel)]="motherAge1" name="motherAge1" id="motherAge1"
              (input)="check(12,$event)">
              <option value=""> Select Age</option>
              <option *ngFor="let age5 of ageList; let i = index;" [value]="age5">
                {{age5}}
              </option>
            </select>
          </div>
        </div>
        <div class="row"> 
          <div class="col-5" *ngIf="!isOthersDiv" (click)="more$Less()">
            <div class="p-4 mb-2">
              <li class="d-flex justify-content-between">
                <h5 class="mb-0" style="font-weight: 100; color: #10A0C1; cursor: pointer;">More</h5>
                <!-- <input type="checkbox" > -->
              </li>
            </div>
          </div>
        </div>  
        <ng-container  *ngIf="isOthersDiv">
          <div class="row ">
            <div class="col-5 mt-2" (click)="selectedCard('Grand Mother')">
              <div class="p-4 mb-2 selectedTab " [ngClass]="{'active_tab': selectCard27 }">
                <li class="d-flex justify-content-between">
                  <h5 class="mb-0">Grand Mother</h5>
                  <!-- <input type="checkbox" > -->
                </li>
              </div>
            </div>
            <div class="col-5 age age-m  mt-2" *ngIf="age7">
              <select class="form-select" [(ngModel)]="grandmaAge1" name="grandmaAge1"
                id="grandmaAge1" (input)="check(13,$event)">
                <option value=""> Select Age</option>
                <option *ngFor="let age6 of ageList; let i = index;" [value]="age6">
                  {{age6}}
                </option>
              </select>
            </div>
          </div>
          <div class="row">  
            <div class="col-5 mt-2" (click)="selectedCard('Grand Father')">
              <div class="p-4 mb-2 selectedTab " [ngClass]="{'active_tab': selectCard28 }">
                <li class="d-flex justify-content-between">
                  <h5 class="mb-0">Grand Father</h5>
                  <!-- <input type="checkbox" > -->
                </li>
              </div>
            </div>
            <div class="col-5 age age-m mt-2" *ngIf="age8">
              <select class="form-select" [(ngModel)]="grandpaAge1" name="grandpaAge1"
                id="grandpaAge1" (input)="check(14,$event)">
                <option value=""> Select Age</option>
                <option *ngFor="let age7 of ageList; let i = index;" [value]="age7">
                  {{age7}}
                </option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col-5 mt-2" *ngIf="fatherInLaw" (click)="selectedCard('Fater-in-law')">
              <div class="p-4 mb-2 selectedTab " [ngClass]="{'active_tab': selectCard29 }">
                <li class="d-flex justify-content-between">
                  <h5 class="mb-0">Father-in-law</h5>
                  <!-- <input type="checkbox" > -->
                </li>
              </div>
            </div>
            <div class="col-5 mt-2  " *ngIf="!fatherInLaw">
              <div class="p-4 mb-2   unselect">
                <li class="d-flex justify-content-between">
                  <h5 class="mb-0">Father-in-law</h5>
                  <!-- <input type="checkbox" > -->
                </li>
              </div>
            </div>
            <div class="col-5 age-m  age mt-2" *ngIf="age9 && fatherInLaw">
              <select class="form-select" [(ngModel)]="fatherLawAge1" name="fatherLawAge1"
                id="fatherLawAge1" (input)="check(15,$event)">
                <option value=""> Select Age</option>
                <option *ngFor="let age8 of ageList; let i = index;" [value]="age8">
                  {{age8}}
                </option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col-5 my-2" *ngIf="motherLaw" (click)="selectedCard('Mother-in-law')">
              <div class="p-4 mb-2 selectedTab " [ngClass]="{'active_tab': selectCard30 }">
                <li class="d-flex justify-content-between">
                  <h5 class="mb-0">Mother-in-law</h5>
                  <!-- <input type="checkbox" > -->
                </li>
              </div>
            </div>
            <div class="col-5 mt-2" *ngIf="!motherLaw">
              <div class="p-4 mb-2 unselect ">
                <li class="d-flex justify-content-between">
                  <h5 class="mb-0">Mother-in-law</h5>
                </li>
              </div>
            </div>
            <div class="col-5 age age-m mt-2" *ngIf="age10 && motherLaw">
              <select class="form-select" [(ngModel)]="motherLawAge1" name="motherLawAge1"
                id="motherLawAge1" (input)="check(16,$event)">
                <option value=""> Select Age</option>
                <option *ngFor="let age9 of ageList; let i = index;" [value]="age9">
                  {{age9}}
                </option>
              </select>
            </div>
          </div>
          <div class="row">  
            <div class="col-5" (click)="selectedCard('Uncle')">
              <div class="p-4 mb-2 selectedTab " [ngClass]="{'active_tab': selectCard31 }">
                <li class="d-flex justify-content-between">
                  <h5 class="mb-0">Uncle</h5>
                  <!-- <input type="checkbox" > -->
                </li>
              </div>
            </div>
            <div class="col-5 age age-m mt-2" *ngIf="age11">
              <select class="form-select" [(ngModel)]="uncleAge1" name="uncleAge1" id="uncleAge1"
                (input)="check(17,$event)">
                <option value=""> Select Age</option>
                <option *ngFor="let age10 of ageList; let i = index;" [value]="age10">
                  {{age10}}
                </option>
              </select>
            </div>
          </div>
          <div class="row">  
            <div class="col-5 mt-2" (click)="selectedCard('Aunt')">
              <div class="p-4 mb-2 selectedTab " [ngClass]="{'active_tab': selectCard32 }">
                <li class="d-flex justify-content-between">
                  <h5 class="mb-0">Aunt</h5>
                  <!-- <input type="checkbox" > -->
                </li>
              </div>
            </div>
            <div class="col-5 age age-m mt-2" *ngIf="age12">
              <select class="form-select" [(ngModel)]="auntAge1" name="auntAge1" id="auntAge1"
                (input)="check(18,$event)">
                <option value=""> Select Age</option>
                <option *ngFor="let age11 of ageList; let i = index;" [value]="age11">
                  {{age11}}
                </option>
              </select>
            </div>
          </div>
        </ng-container>          
        <div class="row">
          <div class="col-7" *ngIf="isOthersDiv" (click)="more$Less()">
            <div class="p-4 mb-2">
              <li class="d-flex justify-content-between">
                <h5 class="mb-0" style="font-weight: 100; color: #10A0C1; cursor: pointer;">Less</h5>
                <!-- <input type="checkbox" > -->
              </li>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="isFamilyTab=='2'">
        <div class="row">
          <div class="col-md-12 d-flex align-items-center mb-1 border-bottom pb-2">
            <img src="../../../assets/svgIcon/arrow_back_ios.svg" alt="" (click)="FamilyNextTabs('1')">
            <h4 class="mb-0 ml-2">Select Coverage Amount</h4>
          </div>          
        </div>
        <div class="row">
          <div class="col-12">
            <h4 class="text-start">Sum Insured</h4>
            <select class="form-select" (change)="selectedCovergageAmount($event)" [(ngModel)]="amountSelected">
                <option *ngFor="let sumInsured of SumInsuredList" [value]="sumInsured.value">{{sumInsured.text}}</option>
            </select>
          </div> 
        </div>     
      </div>
      <div *ngIf="isFamilyTab=='3'" class="row">
        <div class="col-md-12 d-flex align-items-center mb-2 border-bottom pb-2">
          <img src="../../../assets/svgIcon/arrow_back_ios.svg" alt="" (click)="FamilyNextTabs('2')">
          <h4 class="mb-0 ml-2">Does any of the members have disease?</h4>
        </div>
        <div class="col-12">Declaring this will help us find the best policy and avoid claim
          rejections. Don’t worry, your information will be kept confidential.</div>
        <div class="col-md-6 my-2">
          <div class="card p-3 cd">
            <!-- <input type="radio" name="disease" [(ngModel)]="disease" value="yes"
                [checked]="isChecked1" /> Yes <br> -->
            <!-- <input type="radio" name="" id=""> &nbsp;&nbsp;<span>Yes</span> -->
            <div class="row">
              <div class="col-md-4 c4"><input type="radio" [name]="'disease'" id="diseaseYes" (click)="gotoFam('yes')" [value]="'yes'" [(ngModel)]="this.Famillnesss"></div>
              <div class="col-md-8 c8"><span style="position: relative;
                  color: black;  top: 15%;">Yes</span></div>
            </div>
          </div>
        </div>
        <div class="col-md-6 my-2">
          <div class="card p-3 cd">
            <div class="row">
              <div class="col-md-4 c4"><input type="radio" [name]="'disease'" id="diseaseNo" (click)="gotoFam('no')" [value]="'no'" [(ngModel)]="this.Famillnesss"></div>
              <div class="col-md-8 c8"><span style="position: relative;
                  color: black;  top: 15%;"> No</span></div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="isFamilyTab=='4'" class="row">
        <div class="col-md-12 d-flex align-items-center mb-2 border-bottom pb-2">
          <img src="../../../assets/svgIcon/arrow_back_ios.svg" alt="" (click)="FamilyNextTabs('3')">
          <h4 class="mb-0 ml-2">Tell us about your existing illnesss</h4>
        </div>
        <div class="row justify-content-between">
          <div class="col-12">
            <div class="justify-content-between flex-row mb-2" *ngIf="isotherhealthIssueDiv">
              <div class="p-4 mb-2">
                <input type="text" name="" placeholder="Enter Other Health Issue" class="form-control" />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <ng-container *ngIf="isSelf">
            <div class="col-md-7">
              <label>Self</label>
              <app-multi-dropdown class="w-100 multiselect_custom" [items]="illnessItemsSelf" [showSearch]="showSearch"
                [showAll]="showAll" [showStatus]="showStatus" [showError]="showError"
                (itemChange)="onMultiSelectChange(1, $event)" placeholder="Select diseases" id="self">
              </app-multi-dropdown>
            </div>
          </ng-container>
          <ng-container *ngIf="isSpouse">
            <div class="col-md-7 mt-3">
              <label for="age" class="d-flex">Wife</label>
              <app-multi-dropdown class="w-100 multiselect_custom" [items]="illnessItemsSpouse"
                [showSearch]="showSearch" [showAll]="showAll" [showStatus]="showStatus" [showError]="showError"
                (itemChange)="onMultiSelectChange(2, $event)" placeholder="Select diseases" id="isSpouse">
              </app-multi-dropdown>
            </div>
          </ng-container>
          <ng-container *ngIf="isHusband">
            <div class="col-md-7 mt-3">
              <label for="age" class="d-flex">Husband</label>
              <app-multi-dropdown class="w-100 multiselect_custom" [items]="illnessItemsHusband"
                [showSearch]="showSearch" [showAll]="showAll" [showStatus]="showStatus" [showError]="showError"
                (itemChange)="onMultiSelectChange(3, $event)" placeholder="Select diseases" id="isHusband">
              </app-multi-dropdown>
            </div>
          </ng-container>
          <ng-container *ngIf="firstSon">
            <div class="col-md-7 mt-3">
              <label for="age" class="d-flex">1st Son </label>
              <app-multi-dropdown class="w-100 multiselect_custom" [items]="illnessItemsSon1" [showSearch]="showSearch"
                [showAll]="showAll" [showStatus]="showStatus" [showError]="showError"
                (itemChange)="onMultiSelectChange(4, $event)" placeholder="Select  diseases" id="son_1">
              </app-multi-dropdown>
            </div>
          </ng-container>
          <ng-container *ngIf="secondSon">
            <div class="col-md-7 mt-3">
              <label for="age" class="d-flex"> 2nd Son </label>
              <app-multi-dropdown class="w-100 multiselect_custom" [items]="illnessItemsSon2" [showSearch]="showSearch"
                [showAll]="showAll" [showStatus]="showStatus" [showError]="showError"
                (itemChange)="onMultiSelectChange(5, $event)" placeholder="Select  diseases" id="son_2">
              </app-multi-dropdown>
            </div>
          </ng-container>
          <ng-container *ngIf="thirdSon">
            <div class="col-md-7 mt-3">
              <label for="age" class="d-flex">
                3rd Son </label>
              <app-multi-dropdown class="w-100 multiselect_custom" [items]="illnessItemsSon3" [showSearch]="showSearch"
                [showAll]="showAll" [showStatus]="showStatus" [showError]="showError"
                (itemChange)="onMultiSelectChange(6, $event)" placeholder="Select  diseases" id="son_3">
              </app-multi-dropdown>
            </div>
          </ng-container>
          <ng-container *ngIf="fourthSon">
            <div class="col-md-7 mt-3">
              <label for="age" class="d-flex">
                4th Son </label>
              <app-multi-dropdown class="w-100 multiselect_custom" [items]="illnessItemsSon4" [showSearch]="showSearch"
                [showAll]="showAll" [showStatus]="showStatus" [showError]="showError"
                (itemChange)="onMultiSelectChange(7, $event)" placeholder="Select  diseases" id="son_4">
              </app-multi-dropdown>
            </div>
          </ng-container>
          <ng-container *ngIf="firstDaughter">
            <div class="col-md-7 mt-3">
              <label for="age" class="d-flex"> 1st Daughter </label>
              <app-multi-dropdown class="w-100 multiselect_custom" [items]="illnessItemsDaughter1"
                [showSearch]="showSearch" [showAll]="showAll" [showStatus]="showStatus" [showError]="showError"
                (itemChange)="onMultiSelectChange(8, $event)" placeholder="Select diseases" id="daughter_1">
              </app-multi-dropdown>
            </div>
          </ng-container>
          <ng-container *ngIf="secondDaughter">
            <div class="col-md-7 mt-3">
              <label for="age" class="d-flex"> 2nd Daughter </label>
              <app-multi-dropdown class="w-100 multiselect_custom" [items]="illnessItemsDaughter2"
                [showSearch]="showSearch" [showAll]="showAll" [showStatus]="showStatus" [showError]="showError"
                (itemChange)="onMultiSelectChange(9, $event)" placeholder="Select diseases" id="daughter_2">
              </app-multi-dropdown>
            </div>
          </ng-container>
          <ng-container *ngIf="thirdDaughter">
            <div class="col-md-7 mt-3">
              <label for="age" class="d-flex"> 3rd Daughter </label>
              <app-multi-dropdown class="w-100 multiselect_custom" [items]="illnessItemsDaughter3"
                [showSearch]="showSearch" [showAll]="showAll" [showStatus]="showStatus" [showError]="showError"
                (itemChange)="onMultiSelectChange(10, $event)" placeholder="Select diseases" id="daughter_3">
              </app-multi-dropdown>
            </div>
          </ng-container>
          <ng-container *ngIf="fourthDaughter">
            <div class="col-md-7 mt-3">
              <label for="age" class="d-flex"> 4th Daughter </label>
              <app-multi-dropdown class="w-100 multiselect_custom" [items]="illnessItemsDaughter4"
                [showSearch]="showSearch" [showAll]="showAll" [showStatus]="showStatus" [showError]="showError"
                (itemChange)="onMultiSelectChange(11, $event)" placeholder="Select diseases" id="daughter_4">
              </app-multi-dropdown>
            </div>
          </ng-container>
          <!-- <div class="col">
              <label for="age" class="d-flex">Second Child's Age</label>
              <select class="form-select form-select-custom" name="age" id="age">
                <option value="select"> Age</option>
                <option *ngFor="let age of childrenAgeList; let i = index;" [value]="age">
                  {{age}}
                </option>
              </select>
            </div> -->
          <ng-container *ngIf="isFather">
            <div class="col-md-7 mt-3">
              <label for="age" class="d-flex">Father</label>
              <app-multi-dropdown class="w-100 multiselect_custom" [items]="illnessItemsFather"
                [showSearch]="showSearch" [showAll]="showAll" [showStatus]="showStatus" [showError]="showError"
                (itemChange)="onMultiSelectChange(12, $event)" placeholder="Select diseases" id="isFather">
              </app-multi-dropdown>
            </div>
          </ng-container>
          <ng-container *ngIf="isMother">
            <div class="col-md-7 mt-3">
              <label for="age" class="d-flex">Mother</label>
              <app-multi-dropdown class="w-100 multiselect_custom" [items]="illnessItemsMother"
                [showSearch]="showSearch" [showAll]="showAll" [showStatus]="showStatus" [showError]="showError"
                (itemChange)="onMultiSelectChange(13, $event)" placeholder="Select diseases" id="isMother">
              </app-multi-dropdown>
            </div>
          </ng-container>
          <ng-container *ngIf="isgrandFather">
            <div class="col-md-7 mt-3">
              <label for="age" class="d-flex">Grand Father</label>
              <app-multi-dropdown class="w-100 multiselect_custom" [items]="illnessItemsgrandFather"
                [showSearch]="showSearch" [showAll]="showAll" [showStatus]="showStatus" [showError]="showError"
                (itemChange)="onMultiSelectChange(14, $event)" placeholder="Select diseases" id="Grand_Father">
              </app-multi-dropdown>
            </div>
          </ng-container>
          <ng-container *ngIf="isgrandMother">
            <div class="col-md-7 mt-3">
              <label for="age" class="d-flex">Grand Mother</label>
              <app-multi-dropdown class="w-100 multiselect_custom" [items]="illnessItemsgrandMother"
                [showSearch]="showSearch" [showAll]="showAll" [showStatus]="showStatus" [showError]="showError"
                (itemChange)="onMultiSelectChange(15, $event)" placeholder="Select diseases" id="Grand_Mother">
              </app-multi-dropdown>
            </div>
          </ng-container>
          <ng-container *ngIf="isfatherInLaw">
            <div class="col-md-7 mt-3">
              <label for="age" class="d-flex">Father-in-law</label>
              <app-multi-dropdown class="w-100 multiselect_custom" [items]="illnessItemsFotherInLaw"
                [showSearch]="showSearch" [showAll]="showAll" [showStatus]="showStatus" [showError]="showError"
                (itemChange)="onMultiSelectChange(16, $event)" placeholder="Select diseases" id="Father-in-law">
              </app-multi-dropdown>
            </div>
          </ng-container>
          <ng-container *ngIf="ismotherInLaw">
            <div class="col-md-7 mt-3">
              <label for="age" class="d-flex">Mother-in-law</label>
              <app-multi-dropdown class="w-100 multiselect_custom" [items]="illnessItemsMotherInLaw"
                [showSearch]="showSearch" [showAll]="showAll" [showStatus]="showStatus" [showError]="showError"
                (itemChange)="onMultiSelectChange(17, $event)" placeholder="Select diseases" id="Mother-in-law">
              </app-multi-dropdown>
            </div>
          </ng-container>
          <ng-container *ngIf="isUncle">
            <div class="col-md-7 mt-3">
              <label for="age" class="d-flex">Uncle</label>
              <app-multi-dropdown class="w-100 multiselect_custom" [items]="illnessItemsUncle" [showSearch]="showSearch"
                [showAll]="showAll" [showStatus]="showStatus" [showError]="showError"
                (itemChange)="onMultiSelectChange(18, $event)" placeholder="Select diseases" id="Uncle">
              </app-multi-dropdown>
            </div>
          </ng-container>
          <ng-container *ngIf="isAunt">
            <div class="col-md-7 mt-3">
              <label for="age" class="d-flex">Aunt</label>
              <app-multi-dropdown class="w-100 multiselect_custom" [items]="illnessItemsAunt" [showSearch]="showSearch"
                [showAll]="showAll" [showStatus]="showStatus" [showError]="showError"
                (itemChange)="onMultiSelectChange(19, $event)" placeholder="Select diseases" id="Aunt">
              </app-multi-dropdown>
            </div>
          </ng-container>
        </div>
      </div>
      <div *ngIf="isFamilyTab=='5'" class="row">
        <div class="col-md-12 d-flex align-items-center mb-2 border-bottom pb-2">
          <img src="../../../assets/svgIcon/arrow_back_ios.svg" alt="" (click)="GoBack()">
          <h4 class="mb-0 ml-2">Where do you live?</h4>
        </div>
        <div class="row justify-content-between">
          <div class="col-md-7">
            <div class="d-flex">
              <label for="pincode" class="d-flex">Your Pincode</label>
              <!-- <p class="link-primary">Don't Know Pincode</p> -->
            </div>
            <input type="number" class="form-control" [(ngModel)]="pincode" (blur)="pincodeUserChangeEvent()" value=""
              placeholder="e.g. 457114" onkeypress="if(this.value.length=='6') return false"
              (keydown)="validateNumberInput($event)" (input)="onInput($event)">
          </div>
          <div class="col-5"></div>
        </div>
        <div class="row justify-content-between mt-5">
          <div class="col-md-7">
            <label for="address" class="d-flex">Select Location</label>
            <select class="form-select form-select-custom" name="loca" id="loca" [(ngModel)]="mycity"
              (change)='changeCity()'>
              <option value="select">Select Location</option>
              <option *ngFor="let pincode of pincodeMaster" [value]="pincode.id">
                {{pincode.cityVillageArea}}
              </option>

            </select>
          </div>
          <div class="col-5"></div>
        </div>
        <div class="row justify-content-between">
          <div class="col">
          </div>
        </div>
      </div>

    </div>
    <div class="row btn-mrg">
      <button class="btn btn-primary btn-mrg w-100 rounded-top-0 position-absolute b-0 r-0" *ngIf="FamilybtnFirst"
        (click)="FamilyNextTabs('2')" [disabled]="ageValidation()">
        Continue
      </button>
      <button class="btn btn-primary btn-mrg w-100 rounded-top-0 position-absolute b-0 r-0" *ngIf="FamilybtnSecond"
        (click)="FamilyNextTabs('3')" [disabled]="isFamBtn2">
        Continue
      </button>
      <button class="btn btn-primary btn-mrg w-100 rounded-top-0 position-absolute b-0 r-0" *ngIf="FamilybtnThird"
        (click)="FamilyNextTabs('4')" [disabled]="isDone1">
        Continue
      </button>
      <button class="btn btn-primary    btn-mrg w-100 rounded-top-0 position-absolute b-0 r-0" *ngIf="FamilybtnFourth"
        (click)="FamilyNextTabs('5')" [disabled]="familyValidationButton && NoDiseaseFam">
        Continue
      </button>
      <button class="btn btn-primary btn-mrg w-100 rounded-top-0 position-absolute b-0 r-0" *ngIf="FamilyVarifyBtn"
        (click)="getHealthPlans()" [disabled]="isFamBtn4">View Quotes</button>

    </div>
  </div>
</div>
<div class="overlay verifybikedetailsdiv verifybikedetailsdiv1 modal right" *ngIf="healthinsurancepolicyindividual">
  <div class="popup2 col-md-3 col-12" style=" overflow-x: unset;">
    <div class="modal-header tooltip_">
      <h2 class="modal-title">
        <h2 class="">Individual
        </h2>
      </h2>
      <!-- <button type="button" class="close btn pull-right" aria-label="Close" (click)=" closeModal()">
        <img src="../../../assets/svgIcon/crs.svg" alt="">      </button> -->
      <button type="button" class="close pull-right" aria-label="Close" (click)=" closeModal()">
        <span></span>
      </button>
    </div>
    <div class="modal-body heigth p-5 ">
      <div class="row pd" *ngIf="isTabs=='1'">
        <h4>Who would you like to insure ?</h4>
        <div class="row bt-mr">
          <div class="col-md-7 " *ngIf="!showw" (click)="selectedCard('Self')" (click)="show()">
            <div class="p-4 mb-2 selectedTab ">
              <li class="d-flex justify-content-between">
                <h5 class="mb-0">Self</h5>
                <!-- <input type="checkbox" > -->
              </li>
            </div>
          </div>

          <div class="col-md-5 " *ngIf="showw" (click)="selectedCard('Self')" (click)="show()">
            <div class="p-4 mb-2 selectedTab " [ngClass]="{'active_tab': isSelectedPersion=='Self' }">
              <li class="d-flex justify-content-between">
                <h5 class="mb-0">Self</h5>
                <!-- <input type="checkbox" > -->
              </li>
            </div>
          </div>
          <div class="col-md-7">
            <div class="gender_div d-flex" *ngIf=" isSelectedPersion=='Self'">
              <ul class="ul-t">
                <li> &nbsp;&nbsp; <input type="radio" name="gender" [(ngModel)]="gender" value="male"
                    [checked]="isChecked" />
                  Male</li>
                <li> &nbsp;&nbsp; <input type="radio" name="gender" [(ngModel)]="gender" value="female"
                    [checked]="isChecked" /> Female</li>
              </ul>
            </div>
          </div>
          <div class="col-md-6 age  mt-3" *ngIf=" isSelectedPersion=='Self'">
            <select class="form-select form-select-custom" [(ngModel)]="isSelectAge" name="age" id="age">
              <option selected> Age</option>
              <option *ngFor="let age of ageList; let i = index;" [value]="age">
                {{age}}
              </option>
            </select>
          </div>
          <div class="col-md-7 mt-2" (click)="selectedCard('Wife')">
            <!-- <div class="p-4 mb-2 selectedTab " [ngClass]="{'active_tab': selectCard2 }" *ngIf="gender == 'male'">
                <li class="d-flex justify-content-between">
                  <h5 class="mb-0">Wife</h5>
                </li>
              </div>
              <div class="p-4 mb-2 selectedTab " [ngClass]="{'active_tab': selectCard2 }"
                *ngIf="gender == 'female'">
                <li class="d-flex justify-content-between" (click)="selectedCard('Husband')">
                  <h5 class="mb-0">Husband</h5>
                </li>
              </div> -->
            <div class="p-4 mb-2 selectedTab " [ngClass]="{'active_tab':  isSelectedPersion=='Wife' }">
              <li class="d-flex justify-content-between">
                <h5 class="mb-0">Spouse</h5>
              </li>
            </div>
          </div>
          <div class="col-md-5 age  mt-5 age-m" *ngIf="isSelectedPersion=='Wife'">
            <select class="form-select form-select-custom" [(ngModel)]="isSelectAge" name="age" id="age">
              <option selected> Age</option>
              <option *ngFor="let age1 of ageList; let i = index;" [value]="age1">
                {{age1}}
              </option>
            </select>
          </div>

          <div class="col-md-7 mt-2" (click)="selectedCard('Son')">
            <div class="p-4 mb-2 selectedTab " [ngClass]="{'active_tab': isSelectedPersion=='Son' }">
              <li class="d-flex justify-content-between">
                <h5 class="mb-0">Son</h5>
                <!-- <input type="checkbox" > -->
              </li>
            </div>
          </div>
          <!-- <div class="col-md-5 mt-2">
              <div class="counter_div conter_div1 count" *ngIf="isSonsDiv">
                <li>
                  <span class="minus_span" (click)="counterFunction('decrement')"> - </span>
                </li>
                <li>
                  <span class="number_span"> {{counterValue}} </span>
                </li>
                <li>
                  <span class="minus_span" (click)="counterFunction('increment')"> + </span>
                </li>
              </div>
            </div> -->
          <div class="col-md-5 age  mt-5 age-m" *ngIf="isSelectedPersion=='Son'">
            <select class="form-select form-select-custom" [(ngModel)]="isSelectAge" name="age" id="age">
              <option selected> Age</option>
              <option *ngFor="let age2 of childrenAgeList; let i = index;" [value]="age2">
                {{age2}}
              </option>
            </select>
          </div>
          <!-- <div class="col-md-6  mt-2" *ngIf="age13">
              <label for=""> Son 2</label>
              <select class="form-select form-select-custom" name="age" id="age" [(ngModel)]="selectedSonAge2">
                <option selected> Age</option>
                <option *ngFor="let age12 of childrenAgeList; let i = index;" [value]="age12">
                  {{age12}}
                </option>
              </select>
            </div>
            <div class="col-md-6  mt-2" *ngIf="age14">
              <label for=""> Son 3</label>
              <select class="form-select form-select-custom" name="age" id="age" [(ngModel)]="selectedSonAge3">
                <option selected> Age</option>
                <option *ngFor="let age13 of childrenAgeList; let i = index;" [value]="age13">
                  {{age13}}
                </option>
              </select>
            </div>
            <div class="col-md-6  mt-2" *ngIf="age15">
              <label for=""> Son 4</label>
              <select class="form-select form-select-custom" name="age" id="age" [(ngModel)]="selectedSonAge4">
                <option selected> Age</option>
                <option *ngFor="let age14 of childrenAgeList; let i = index;" [value]="age14">
                  {{age14}}
                </option>
              </select>
            </div> -->
          <div class="col-md-7 mt-2" (click)="selectedCard('Daughter')">
            <div class="p-4 mb-2 selectedTab " [ngClass]="{'active_tab': isSelectedPersion=='Daughter' }">
              <li class="d-flex justify-content-between">
                <h5 class="mb-0">Daughter</h5>
                <!-- <input type="checkbox" > -->
              </li>
            </div>
          </div>
          <!-- <div class="col-md-5 mt-2">
              <div class="counter_div conter_div1 count" *ngIf="isDaughtersDiv">
                <li>
                  <span class="minus_span" (click)="counterFunctionDoughter('decrement')"> - </span>
                </li>
                <li>
                  <span class="number_span"> {{doughtercounterValue}} </span>
                </li>
                <li>
                  <span class="minus_span" (click)="counterFunctionDoughter('increment')"> + </span>
                </li>
              </div>
            </div> -->
          <div class="col-md-5  age  mt-5 age-m" *ngIf=" isSelectedPersion=='Daughter'">
            <select class="form-select form-select-custom" name="age" id="age" [(ngModel)]="isSelectAge">
              <option value="select"> Age</option>
              <option *ngFor="let age3 of childrenAgeList; let i = index;" [value]="age3">
                {{age3}}
              </option>
            </select>
          </div>

          <!-- <div class="col-md-6  mt-2" *ngIf="age16">
              <label for=""> Daughter 2</label>
              <select class="form-select form-select-custom" name="age" id="age" [(ngModel)]="selectedDauAge2">
                <option value="select"> Age</option>
                <option *ngFor="let age15 of childrenAgeList; let i = index;" [value]="age15">
                  {{age15}}
                </option>
              </select>
            </div>
            <div class="col-md-6  mt-2" *ngIf="age17">
              <label for=""> Daughter 3</label>
              <select class="form-select form-select-custom" name="age" id="age" [(ngModel)]="selectedDauAge3">
                <option value="select"> Age</option>
                <option *ngFor="let age16 of childrenAgeList; let i = index;" [value]="age16">
                  {{age16}}
                </option>
              </select>
            </div>
            <div class="col-md-6   mt-2" *ngIf="age18">
              <label for=""> Daughter 4</label>
              <select class="form-select form-select-custom" name="age" id="age" [(ngModel)]="selectedDauAge4">
                <option value="select"> Age</option>
                <option *ngFor="let age17 of childrenAgeList; let i = index;" [value]="age17">
                  {{age17}}
                </option>
              </select>
            </div> -->
          <div class="col-md-7 mt-2" *ngIf="father" (click)="selectedCard('Father')">
            <div class="p-4 mb-2 selectedTab " [ngClass]="{'active_tab':  isSelectedPersion=='Father' }">
              <li class="d-flex justify-content-between">
                <h5 class="mb-0">Father</h5>
                <!-- <input type="checkbox" > -->
              </li>
            </div>

          </div>

          <div class="col-md-5 age  age-m  mt-5" *ngIf="isSelectedPersion=='Father' && father">
            <select class="form-select form-select-custom" name="age" id="age" [(ngModel)]="isSelectAge">
              <option selected> Age</option>
              <option *ngFor="let age4 of ageList; let i = index;" [value]="age4">
                {{age4}}
              </option>
            </select>
          </div>


          <div class="col-md-7" *ngIf="mother" (click)="selectedCard('Mother')">
            <div class="p-4 mb-2 selectedTab " [ngClass]="{'active_tab': isSelectedPersion=='Mother' }">
              <li class="d-flex justify-content-between">
                <h5 class="mb-0">Mother</h5>
                <!-- <input type="checkbox" > -->
              </li>
            </div>
          </div>

          <div class="col-md-5  age-m age mt-3" *ngIf="isSelectedPersion=='Mother' && mother">
            <select class="form-select form-select-custom" name="age" id="age" [(ngModel)]="isSelectAge">
              <option selected> Age</option>
              <option *ngFor="let age5 of ageList; let i = index;" [value]="age5">
                {{age5}}
              </option>
            </select>
          </div>

          <div class="col-md-7" *ngIf="!isOthersDiv" (click)="more$Less()">
            <div class="p-4 mb-2">
              <li class="d-flex justify-content-between">
                <h5 class="mb-0" style="font-weight: 100; color: #10A0C1; cursor: pointer;">More</h5>
                <!-- <input type="checkbox" > -->
              </li>
            </div>

          </div>
          <div class="row " *ngIf="isOthersDiv">
            <div class="col-md-7" (click)="selectedCard('Grand Mother')">
              <div class="p-4 mb-2 selectedTab " [ngClass]="{'active_tab': isSelectedPersion=='Grand Mother' }">
                <li class="d-flex justify-content-between">
                  <h5 class="mb-0">Grand Mother</h5>
                  <!-- <input type="checkbox" > -->
                </li>
              </div>
            </div>
            <div class="col-md-5 age  age-m  mt-3" *ngIf=" isSelectedPersion=='Grand Mother'">
              <select class="form-select form-select-custom" name="age" id="age" [(ngModel)]="isSelectAge">
                <option selected> Age</option>
                <option *ngFor="let age6 of ageList; let i = index;" [value]="age6">
                  {{age6}}
                </option>
              </select>
            </div>
            <div class="col-md-7 mt-2"></div>
            <div class="col-md-7" (click)="selectedCard('Grand Father')">
              <div class="p-4 mb-2 selectedTab " [ngClass]="{'active_tab':  isSelectedPersion=='Grand Father' }">
                <li class="d-flex justify-content-between">
                  <h5 class="mb-0">Grand Father</h5>
                  <!-- <input type="checkbox" > -->
                </li>
              </div>
            </div>
            <div class="col-md-5 age age-m mt-3" *ngIf=" isSelectedPersion=='Grand Father'">
              <select class="form-select form-select-custom" name="age" id="age" [(ngModel)]="isSelectAge">
                <option selected> Age</option>
                <option *ngFor="let age7 of ageList; let i = index;" [value]="age7">
                  {{age7}}
                </option>
              </select>
            </div>

            <div class="col-md-7 mt-2" *ngIf="fatherInLaw" (click)="selectedCard('Fater-in-law')">
              <div class="p-4 mb-2 selectedTab " [ngClass]="{'active_tab':  isSelectedPersion=='Fater-in-law' }">
                <li class="d-flex justify-content-between">
                  <h5 class="mb-0">Father-in-law</h5>
                  <!-- <input type="checkbox" > -->
                </li>
              </div>
            </div>

            <div class="col-md-5 age-m  age mt-5" *ngIf="isSelectedPersion=='Fater-in-law' && fatherInLaw">
              <select class="form-select form-select-custom" name="age" id="age" [(ngModel)]="isSelectAge">
                <option selected> Age</option>
                <option *ngFor="let age8 of ageList; let i = index;" [value]="age8">
                  {{age8}}
                </option>
              </select>
            </div>

            <div class="col-md-7 mt-2" *ngIf="motherLaw" (click)="selectedCard('Mother-in-law')">
              <div class="p-4 mb-2 selectedTab " [ngClass]="{'active_tab': isSelectedPersion=='Mother-in-law' }">
                <li class="d-flex justify-content-between">
                  <h5 class="mb-0">Mother-in-law</h5>
                  <!-- <input type="checkbox" > -->
                </li>
              </div>
            </div>

            <div class="col-md-5 age age-m mt-3" *ngIf="isSelectedPersion=='Mother-in-law' && motherLaw">
              <select class="form-select form-select-custom" name="age" id="age" [(ngModel)]="isSelectAge">
                <option selected> Age</option>
                <option *ngFor="let age9 of ageList; let i = index;" [value]="age9">
                  {{age9}}
                </option>
              </select>
            </div>

            <div class="col-md-7" (click)="selectedCard('Uncle')">
              <div class="p-4 mb-2 selectedTab " [ngClass]="{'active_tab': isSelectedPersion=='Uncle' }">
                <li class="d-flex justify-content-between">
                  <h5 class="mb-0">Uncle</h5>
                  <!-- <input type="checkbox" > -->
                </li>
              </div>
            </div>
            <div class="col-md-5 age age-m mt-3" *ngIf="isSelectedPersion=='Uncle'">
              <select class="form-select form-select-custom" name="age" id="age" [(ngModel)]="isSelectAge">
                <option selected> Age</option>
                <option *ngFor="let age10 of ageList; let i = index;" [value]="age10">
                  {{age10}}
                </option>
              </select>
            </div>

            <div class="col-md-7 mt-2" (click)="selectedCard('Aunt')">
              <div class="p-4 mb-2 selectedTab " [ngClass]="{'active_tab': isSelectedPersion=='Aunt' }">
                <li class="d-flex justify-content-between">
                  <h5 class="mb-0">Aunt</h5>
                  <!-- <input type="checkbox" > -->
                </li>
              </div>
            </div>
            <div class="col-md-5 age age-m mt-3" *ngIf="isSelectedPersion=='Aunt'">
              <select class="form-select form-select-custom" name="age" id="age" [(ngModel)]="isSelectAge">
                <option selected> Age</option>
                <option *ngFor="let age11 of ageList; let i = index;" [value]="age11">
                  {{age11}}
                </option>
              </select>
            </div>

            <div class="col-md-7" *ngIf="isOthersDiv" (click)="more$Less()">
              <div class="p-4 mb-2">
                <li class="d-flex justify-content-between">
                  <h5 class="mb-0" style="font-weight: 100; color: #10A0C1; cursor: pointer;">Less</h5>
                  <!-- <input type="checkbox" > -->
                </li>
              </div>

            </div>
          </div>
          <div class="col-6">

            <!-- <div class="counter_div">
                  <li>
                    <span class="minus_span"> - </span>
                  </li>
                  <li>
                    <span class="number_span"> 1 </span>
                  </li>
                  <li>
                    <span class="minus_span"> + </span>
                  </li>
                </div> -->
          </div>
        </div>
      </div>


      <div class="row pd" style="height: 103%;" *ngIf="isTabs=='2'">
        <div class="col-md-2 lg"> <img src="../../../assets/svgIcon/arrow_back_ios.svg" alt=""
            (click)="IndividualNextTabs('1')"></div>
        <div class="col-md-10 tx">
          <h4>Select Coverage Amount</h4>

        </div>
        <div class="row mt-3">
          <div class="col-12">
            <div class="p-4 mb-2 selectedTab1 " (click)="selectedCovergageAmount('1lakh')"
              [ngClass]="{'active_tab11': selectamount1 }">
              <li class="d-flex justify-content-between">
                <h5 class="mb-0">1 Lakh</h5>
                <!-- <input type="checkbox" > -->
              </li>
            </div>

          </div>
          <div class="col-12">
            <div class="p-4 mb-2 selectedTab1" (click)="selectedCovergageAmount('2lakh')"
              [ngClass]="{'active_tab11': selectamount2 }">
              <li class="d-flex justify-content-between">
                <h5 class="mb-0">2 Lakhs</h5>
                <!-- <input type="checkbox" > -->
              </li>
            </div>

          </div>
          <div class="col-12">
            <div class="p-4 mb-2 selectedTab1" (click)="selectedCovergageAmount('3lakh')"
              [ngClass]="{'active_tab11': selectamount3 }">
              <li class="d-flex justify-content-between">
                <h5 class="mb-0">3 Lakhs</h5>
                <!-- <input type="checkbox" > -->
              </li>
            </div>

          </div>
          <div class="col-12">
            <div class="p-4 mb-2 selectedTab1" (click)="selectedCovergageAmount('4lakh')"
              [ngClass]="{'active_tab11': selectamount4 }">
              <li class="d-flex justify-content-between">
                <h5 class="mb-0">4 Lakhs</h5>
                <!-- <input type="checkbox" > -->
              </li>
            </div>

          </div>
          <div class="col-12">
            <div class="p-4 mb-2 selectedTab1" (click)="selectedCovergageAmount('5lakh')"
              [ngClass]="{'active_tab11': selectamount5 }">
              <li class="d-flex justify-content-between">
                <h5 class="mb-0">5 Lakhs</h5>
                <!-- <input type="checkbox" > -->
              </li>
            </div>

          </div>
          <div class="col-12">
            <div class="p-4 mb-2 selectedTab1" (click)="selectedCovergageAmount('6lakh')"
              [ngClass]="{'active_tab11': selectamount6 }">
              <li class="d-flex justify-content-between">
                <h5 class="mb-0">6 Lakhs</h5>
                <!-- <input type="checkbox" > -->
              </li>
            </div>

          </div>
          <div class="col-12">
            <div class="p-4 mb-2 selectedTab1" (click)="selectedCovergageAmount('7lakh')"
              [ngClass]="{'active_tab11': selectamount7 }">
              <li class="d-flex justify-content-between">
                <h5 class="mb-0">7 Lakhs</h5>
                <!-- <input type="checkbox" > -->
              </li>
            </div>

          </div>
          <div class="col-12">
            <div class="p-4 mb-2 selectedTab1" (click)="selectedCovergageAmount('8lakh')"
              [ngClass]="{'active_tab11': selectamount8 }">
              <li class="d-flex justify-content-between">
                <h5 class="mb-0">8 Lakhs</h5>
                <!-- <input type="checkbox" > -->
              </li>
            </div>

          </div>
          <div class="col-12">
            <div class="p-4 mb-2 selectedTab1" (click)="selectedCovergageAmount('9lakh')"
              [ngClass]="{'active_tab11': selectamount9 }">
              <li class="d-flex justify-content-between">
                <h5 class="mb-0">9 Lakhs</h5>
                <!-- <input type="checkbox" > -->
              </li>
            </div>

          </div>
          <div class="col-12">
            <div class="p-4 mb-2 selectedTab1" (click)="selectedCovergageAmount('10lakh')"
              [ngClass]="{'active_tab11': selectamount10 }">
              <li class="d-flex justify-content-between">
                <h5 class="mb-0">10 Lakhs</h5>
                <!-- <input type="checkbox" > -->
              </li>
            </div>

          </div>
          <!-- <div class="col-12">
              <div class="p-4 mb-2 selectedTab1" [ngClass]="{'active_tab11': selectamount1 }">
                <li class="d-flex justify-content-between" (click)="selectedCovergageAmount('1lakh')">
                  <h5 class="mb-0">1 Lakhs</h5>
                </li>
              </div>

            </div> -->
        </div>
      </div>


      <div class="row mt-3  pd" *ngIf="isTabs=='3'">
        <div class="col-md-2 lg"> <img src="../../../assets/svgIcon/arrow_back_ios.svg" alt=""
            (click)="IndividualNextTabs('2')"></div>
        <div class="col-md-10 tx">
          <h4>Does any of the members have disease?</h4>

        </div>

        <p style="font-weight: normal;" class="text-start mt-3">Declaring this will help us find the best policy and
          avoid claim
          rejections. Don’t worry, your information will be kept confidential.</p>

        <div class="col-md-6 c6 p-2 mb-1">
          <div class="card p-3 cd">
            <!-- <input type="radio" name="disease" [(ngModel)]="disease" value="yes"
                [checked]="isChecked1" /> Yes <br> -->
            <!-- <input type="radio" name="" id=""> &nbsp;&nbsp;<span>Yes</span> -->
            <div class="row">
              <div class="col-md-4 c4"><input type="radio" name="disease" id="" (click)="goTo('yes')"></div>
              <div class="col-md-8 c8"><span style="position: relative;
                  color: black;  top: 15%;">Yes</span></div>
            </div>

          </div>

          <!-- <div class="d-flex align-items-baseline">
              <div class="p-4 mb-2 selectedTab" [ngClass]="{'active_tab': selectCard20 }">
                <li class=" justify-content-between" (click)="selectedCard('Existing Illness')">
                  <h5 class="mb-0">Existing illness</h5>
                  <span class="font-body text-left">Blood pressure, Diabetes, Heart condition, Asthma,
                    Thyroid, Cancer etc.</span>
                  
                </li>
              </div>
            </div> -->
        </div>
        <div class="col-md-6 c6 p-2  mb-1">
          <div class="card p-3 cd">
            <!-- <input type="radio" name="disease" [(ngModel)]="disease" value="no"
                [checked]="isChecked1" /> No -->
            <div class="row">
              <div class="col-md-4 c4"><input type="radio" name="disease" id="" (click)="goTo('no')"></div>
              <div class="col-md-8 c8"><span style="position: relative;
                  color: black;  top: 15%;"> No</span></div>
            </div>
          </div>
        </div>
      </div>

      <div class="row pd" *ngIf="isTabs=='4'">
        <div class="col-md-2 lg"> <img src="../../../assets/svgIcon/arrow_back_ios.svg" alt=""
            (click)="IndividualNextTabs('3')"></div>
        <div class="col-md-10 tx">
          <h4>Tell us about your existing illness</h4>

        </div>

        <div class="row justify-content-between">
          <div class="col-12">
            <div class="justify-content-between flex-row mb-2" *ngIf="isotherhealthIssueDiv">
              <div class="p-4 mb-2">
                <input type="text" name="" placeholder="Enter Other Health Issue" class="form-control" />
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <ng-container *ngIf=" isSelectedPersion=='Self'">
            <div class="col-md-7">
              <label>Self</label>
              <app-multi-dropdown class="w-100 multiselect_custom" [items]="illnessItemsSelf" [showSearch]="showSearch"
                [showAll]="showAll" [showStatus]="showStatus" [showError]="showError" placeholder="Select diseases"
                (itemChange)="existing_illnesss($event)">
              </app-multi-dropdown>
            </div>

          </ng-container>
          <ng-container *ngIf=" isSelectedPersion=='Wife'">
            <div class="col-md-7">
              <label for="age" class="d-flex">Spouse</label>
              <app-multi-dropdown class="w-100 multiselect_custom" [items]="illnessItemsSpouse"
                [showSearch]="showSearch" [showAll]="showAll" [showStatus]="showStatus" [showError]="showError"
                placeholder="Select diseases" (itemChange)="existing_illnesss($event)">
              </app-multi-dropdown>
            </div>
          </ng-container>
          <!-- <ng-container *ngIf="isHusband">
              <div class="col-md-7">
                <label for="age" class="d-flex">Husband</label>
                <app-multi-dropdown class="w-100 multiselect_custom" [items]="illnessItemsHusband"
                  [showSearch]="showSearch" [showAll]="showAll" [showStatus]="showStatus" [showError]="showError"
                  placeholder="Select diseases" >
                </app-multi-dropdown>
              </div>
            </ng-container> -->
        </div>
        <div class="row">
          <ng-container *ngIf="isSelectedPersion=='Son'">
            <div class="col-md-7" *ngFor="let child of sonList">
              <label for="age" class="d-flex"> Son </label>
              <app-multi-dropdown class="w-100 multiselect_custom" [items]="illnessItemsSon1" [showSearch]="showSearch"
                [showAll]="showAll" [showStatus]="showStatus" [showError]="showError" placeholder="Select diseases"
                (itemChange)="existing_illnesss($event)">
              </app-multi-dropdown>
            </div>

          </ng-container>

        </div>
        <div class="row mt-3">
          <ng-container *ngIf="isSelectedPersion=='Daughter'">
            <div class="col-md-7" *ngFor="let child of daughterList">
              <label for="age" class="d-flex">
                Daughter </label>
              <app-multi-dropdown class="w-100 multiselect_custom" [items]="illnessItemsDaughter1"
                [showSearch]="showSearch" [showAll]="showAll" [showStatus]="showStatus" [showError]="showError"
                (itemChange)="existing_illnesss($event)" placeholder="Select diseases">
              </app-multi-dropdown>
            </div>

          </ng-container>
          <!-- <div class="col">
              <label for="age" class="d-flex">Second Child's Age</label>
              <select class="form-select form-select-custom" name="age" id="age">
                <option value="select"> Age</option>
                <option *ngFor="let age of childrenAgeList; let i = index;" [value]="age">
                  {{age}}
                </option>
              </select>
            </div> -->
        </div>
        <div class="row mt-3">
          <ng-container *ngIf="isSelectedPersion=='Father'">
            <div class="col-md-7">
              <label for="age" class="d-flex">Father</label>
              <app-multi-dropdown class="w-100 multiselect_custom" [items]="illnessItemsFather"
                [showSearch]="showSearch" [showAll]="showAll" [showStatus]="showStatus" [showError]="showError"
                (itemChange)="existing_illnesss($event)" placeholder="Select diseases">
              </app-multi-dropdown>
            </div>

          </ng-container>
          <ng-container *ngIf="isSelectedPersion=='Mother'">
            <div class="col-md-7">
              <label for="age" class="d-flex">Mother</label>
              <app-multi-dropdown class="w-100 multiselect_custom" [items]="illnessItemsMother"
                [showSearch]="showSearch" [showAll]="showAll" [showStatus]="showStatus" [showError]="showError"
                (itemChange)="existing_illnesss($event)" placeholder="Select diseases">
              </app-multi-dropdown>
            </div>

          </ng-container>
          <ng-container *ngIf="isSelectedPersion=='Grand Father'">
            <div class="col-md-7">
              <label for="age" class="d-flex">Grand Father</label>
              <app-multi-dropdown class="w-100 multiselect_custom" [items]="illnessItemsgrandFather"
                [showSearch]="showSearch" [showAll]="showAll" [showStatus]="showStatus" [showError]="showError"
                (itemChange)="existing_illnesss($event)" placeholder="Select diseases">
              </app-multi-dropdown>
            </div>

          </ng-container>
          <ng-container *ngIf="isSelectedPersion=='Grand Mother'">
            <div class="col-md-7">
              <label for="age" class="d-flex">Grand Mother</label>
              <app-multi-dropdown class="w-100 multiselect_custom" [items]="illnessItemsgrandMother"
                [showSearch]="showSearch" [showAll]="showAll" [showStatus]="showStatus" [showError]="showError"
                (itemChange)="existing_illnesss($event)" placeholder="Select diseases">
              </app-multi-dropdown>
            </div>

          </ng-container>
        </div>
        <div class="row mt-3">
          <ng-container *ngIf="isSelectedPersion=='Fater-in-law'">
            <div class="col-md-7">
              <label for="age" class="d-flex">Father-in-law</label>
              <app-multi-dropdown class="w-100 multiselect_custom" [items]="illnessItemsFotherInLaw"
                [showSearch]="showSearch" [showAll]="showAll" [showStatus]="showStatus" [showError]="showError"
                (itemChange)="existing_illnesss($event)" placeholder="Select diseases">
              </app-multi-dropdown>
            </div>

          </ng-container>
          <ng-container *ngIf="isSelectedPersion=='Mother-in-law'">
            <div class="col-md-7">
              <label for="age" class="d-flex">Mother-in-law</label>
              <app-multi-dropdown class="w-100 multiselect_custom" [items]="illnessItemsMotherInLaw"
                [showSearch]="showSearch" [showAll]="showAll" [showStatus]="showStatus" [showError]="showError"
                (itemChange)="existing_illnesss($event)" placeholder="Select diseases">
              </app-multi-dropdown>
            </div>

          </ng-container>
          <ng-container *ngIf="isSelectedPersion=='Uncle'">
            <div class="col-md-7">
              <label for="age" class="d-flex">Uncle</label>
              <app-multi-dropdown class="w-100 multiselect_custom" [items]="illnessItemsUncle" [showSearch]="showSearch"
                [showAll]="showAll" [showStatus]="showStatus" [showError]="showError"
                (itemChange)="existing_illnesss($event)" placeholder="Select diseases">
              </app-multi-dropdown>
            </div>

          </ng-container>
        </div>
        <div class="row mt-3">
          <ng-container *ngIf="isSelectedPersion=='Aunt'">


            <div class="col-md-7">
              <label for="age" class="d-flex">Aunt</label>
              <app-multi-dropdown class="w-100 multiselect_custom" [items]="illnessItemsAunt" [showSearch]="showSearch"
                [showAll]="showAll" [showStatus]="showStatus" [showError]="showError"
                (itemChange)="existing_illnesss($event)" placeholder="Select diseases">
              </app-multi-dropdown>
            </div>

          </ng-container>
        </div>

      </div>

      <div class="row pd " *ngIf="isTabs=='5'">
        <div class="col-md-2 lg" (click)="GoBack()"> <img src="../../../assets/svgIcon/arrow_back_ios.svg" alt=""></div>
        <div class="col-md-10 tx">
          <h4>Where do you live?</h4>
        </div>

        <div class="row justify-content-between">
          <div class="col-md-7 ">
            <div class="d-flex ">
              <label for="pincode" class="d-flex">Your Pincode</label>
              <!-- <p class="link-primary">Don't Know Pincode</p> -->
            </div>
            <!-- <input type="number" class="form-control l-w" #pinCode (blur)="pincodeUserChangeEvent(pinCode)" value=""
              placeholder="e.g. 457114" onkeypress="if(this.value.length=='6') return false"
              (keydown)="validateNumberInput($event)" (input)="onInput($event)"> -->
          </div>
          <div class="col-5"></div>
        </div>
        <div class="row justify-content-between mt-5">
          <div class="col-md-7">
            <label for="address" class=" d-flex">Select Location</label>
            <!-- <select class="form-select l-w form-select-custom" name="loca" id="loca" #mycity
              (change)='changeCity(mycity.value)'>
              <option value="select">Select Location</option>
              <option *ngFor="let pincode of pincodeMaster" [value]="pincode.id">
                {{pincode.cityVillageArea}}
              </option>

            </select> -->
          </div>
          <div class="col-5"></div>
        </div>
        <div class="row justify-content-between">
          <div class="col">
          </div>
        </div>
      </div>

    </div>
    <div class="row btn-mrg">
      <button class="btn btn-primary btn-mrg w-100 rounded-top-0 position-absolute b-0 r-0" *ngIf="btnFirst"
        (click)="IndividualNextTabs('2')" [disabled]="isBtn1 || isSelectAge=='Age'">
        Continue
      </button>
      <button class="btn btn-primary btn-mrg w-100 rounded-top-0 position-absolute b-0 r-0" *ngIf="btnSecond"
        (click)="IndividualNextTabs('3')" [disabled]="isBtn2">
        Continue
      </button>
      <button class="btn btn-primary btn-mrg w-100 rounded-top-0 position-absolute b-0 r-0" *ngIf="btnThird"
        (click)="IndividualNextTabs('4')" [disabled]="isDone">
        Continue
      </button>
      <button class="btn btn-primary   btn-mrg w-100 rounded-top-0 position-absolute b-0 r-0" *ngIf="btnFourth"
        (click)="IndividualNextTabs('5')" [disabled]="InllesButton && NoDisease">
        Continue
      </button>
      <button class="btn btn-primary btn-mrg w-100 rounded-top-0 position-absolute b-0 r-0" *ngIf="VarifyBtn"
        (click)="getHealthPlans()" [disabled]="isBtn4">View Quotes</button>

    </div>
  </div>
</div>




<!-- 
<div class="banner">
  <div class="container mt-5 mb-5 ">
    <div style="display: flex; justify-content: space-between;  width: 87%;margin-left: 6%;">
      <div
        style="display: flex; padding: 17px 30px 17px 30px; background-color: #FCF1D6; border-radius: 12px;column-gap: 21px;">
        <div><img src="./assets/images/newicons/insurance-claim.svg"></div>
        <div class="bannerText">Claim 2 wheeler<br> Insurance Now</div>
      </div>
      <div style="display: flex; padding: 22px;  background-color: #F6D1E5; border-radius: 12px;column-gap: 21px;">
        <div><img src="./assets/images/newicons/garage.svg"></div>
        <div class="bannerText">Search nearby<br> cashless garages</div>
      </div>
      <div style="display: flex; padding: 22px;  background-color: #D1F1DF; border-radius: 12px;column-gap: 21px;">
        <div><img src="./assets/images/newicons/repeat-message.svg"></div>
        <div class="bannerText">Earn with Us, Be a<br> Reseller with Us</div>
      </div>
      <div style="display: flex; padding: 22px;  background-color: #D2DAF8; border-radius: 12px;column-gap: 21px;">
        <div><img src="./assets/images/newicons/cash-money-rupee-coin.svg"></div>
        <div class="bannerText">Renewal your 2 <br>wheeler insurance</div>
      </div>
    </div>
  </div>

</div>


<div class="whatins">
  <div class="container mt-5 mb-5 ">
    <div class="row">
      <div class="col-md-7">
        <h2 style="font-size: 35px; margin-top: 30px; font-weight: 600; letter-spacing: 1px;">What is Bike Insurance?
        </h2>
        <span>
          <p style="padding-top: 15px;" class="insText">A bike insurance policy offers comprehensive protection and
            coverage for your bike. It covers repair costs for accidental damages and provides financial security
            against theft, natural disasters, vandalism, and other unforeseen incidents. . With optional add-ons for
            enhanced coverage, such as engine protection and roadside assistance, you can tailor your policy to your
            specific needs. Secure your ride with a bike insurance policy and enjoy peace of mind on every journey.</p>

        </span> 
      </div>
      <div class="col-md-5" style="text-align: right;">
        <img src="./assets/images/newicons/bike.svg" style="width:65%">
      </div>
    </div>
  </div>
</div>

<div class="whyins">
  <div class="container mt-5 mb-5 ">
    <div class="row">
      <div class="col-md-5" style="text-align: left; padding-top: 40px;  ">
        <img src="./assets/images/newicons/video.svg" class="videoClass">
      </div>
      <div class="col-md-7">
        <h2 style="font-size: 35px; margin-top: 30px; font-weight: 600; text-align: right; letter-spacing: 1px;">
          Everything about Bike Insurance</h2>
        <span>
          <p style="padding-top: 20px;" class="insText">
            1. Bike insurance provides coverage for accidental damages, theft, natural disasters, and vandalism,
            including repair costs. <br>
            2. It also offers protection against legal and financial responsibilities for injuries or property damage
            caused to others.<br>
            3. Covers personal accidents, offering compensation for injuries or death resulting from bike accidents.<br>
            4. Allows for policy customization with various plans and add-ons to match individual needs and preferences.
          </p>
        </span> 

      </div>

    </div>
  </div>
</div>


<div class="click4bima">
  <div class="container mt-5 mb-5 ">
    <div class="row" style="padding-top: 50px;">
      <div class="col-md-12">
        <div class="">
          <h1 style="font-size: 40px;; color: #fff; font-weight: 200;">Types of Two-Wheeler Insurance</h1>
        </div>
        <div style="margin-top: 35px;">
          <table>
            <thead>
              <tr>
                <th class="key-features">Key Features</th>
                <th>Comprehensive</th>
                <th>Third-Party(TP)</th>
                <th>Own Damage(OD)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="leftHead">Description</td>
                <td>A comprehensive bike insurance policy covers your own motorcycle or scooter and third-party
                  liabilities. It ensures wide-ranging protection for both your vehicle and legal responsibilities.</td>
                <td>A third-party bike insurance policy covers the cost of damages or injuries you cause to someone
                  else's property or person while riding your bike. It doesn't cover any damages to your own bike.</td>
                <td>An own damage bike insurance policy covers repair or replacement costs if your bike gets damaged. It
                  ensures financial protection for your vehicle against unforeseen damages.</td>
              </tr>
              <tr>
                <td class="leftHead">Coverage</td>
                <td>
                  <ul>
                    <li>Own Damage</li>
                    <li>Third-Party Liabilities</li>
                    <li>Accident, Theft, Fire, Natural Calamities</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Third-Party Liabilities</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Own Damage</li>
                    <li>Accident, Theft, Fire, Natural Calamities</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td class="leftHead">Add-ons Availability</td>
                <td>
                  <ul>
                    <li>Zero/Nil Dep</li>
                    <li>Consumable Cover</li>
                    <li>RTI</li>
                    <li>RSA</li>
                    <li>Engine & Gear Box</li>
                    <li>Daily Allowance</li>
                  </ul>
                </td>
                <td>Not Available</td>
                <td>
                  <ul>
                    <li>Zero/Nil Dep</li>
                    <li>Consumable Cover</li>
                    <li>RTI</li>
                    <li>RSA</li>
                    <li>Engine & Gear Box</li>
                    <li>Daily Allowance</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td class="leftHead">Premium</td>
                <td>High</td>
                <td>Low</td>
                <td>Moderate</td>
              </tr>
              <tr>
                <td class="leftHead">Pros</td>
                <td>
                  <ul>
                    <li>Wide Coverage</li>
                    <li>Additional Benefits with Add-ons</li>
                    <li>Financial Security for Own and Third-Party Damage</li>
                    <li>Legal Compliance</li>
                    <li>PA Cover</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Financial Security for Third-Party Damage</li>
                    <li>Legal Compliance</li>
                    <li>Lower Premium</li>
                    <li>PA Cover</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Covers Accidental Damage</li>
                    <li>Easy Claim Settlement</li>
                    <li>Financial Security for Own Vehicle Damage</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td class="leftHead">Cons</td>
                <td>
                  <ul>
                    <li>Higher Premiums</li>
                    <li>Depreciation Deduction</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Limited Coverage</li>
                    <li>No Financial Protection for Own Damage</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Depreciation Deduction</li>
                    <li>No PA Cover</li>
                    <li>No Financial Protection for Third-Party Damage</li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<div>
  <div style="text-align: center;margin-top: 50px;">
    <h2 style="font-size: 40px; font-weight: 600;">Add-On We Provide for Two-Wheelers</h2>
  </div>
  <div
    style="text-align: center;margin-top: 50px; display: flex;width: 80%; margin-left: 10%; justify-content: space-around;">
    <div class="addonDIv" [ngClass]="{ 'activeDIv': type === 1 }" (click)="getValue(1)">
      <div>
        <img src="./assets/images/newicons/b1.svg">
      </div>
      <div>
        Zero/Nil<br>
        Depreciation
      </div>
    </div>
    <div class="addonDIv" [ngClass]="{ 'activeDIv': type === 2 }" (click)="getValue(2)">
      <div>
        <img src="./assets/images/newicons/b2.svg">
      </div>
      <div>
        Consumable<br>
        Cover
      </div>
    </div>
    <div class="addonDIv" [ngClass]="{ 'activeDIv': type === 3 }" (click)="getValue(3)">
      <div>
        <img src="./assets/images/newicons/b3.svg">
      </div>
      <div>
        Return to<br>
        Invoice
      </div>
    </div>
    <div class="addonDIv" [ngClass]="{ 'activeDIv': type === 4 }" (click)="getValue(4)">
      <div>
        <img src="./assets/images/newicons/b4.svg">
      </div>
      <div>
        Road Side<br>
        Assistance
      </div>
    </div>
    <div class="addonDIv" [ngClass]="{ 'activeDIv': type === 5 }" (click)="getValue(5)">
      <div>
        <img src="./assets/images/newicons/b5.svg">
      </div>
      <div>
        Engine & Gear<br>
        Box Protect
      </div>
    </div>
    <div class="addonDIv" [ngClass]="{ 'activeDIv': type === 6 }" (click)="getValue(6)">
      <div>
        <img src="./assets/images/newicons/b6.svg">
      </div>
      <div>
        Daily<br>
        Allowance
      </div>
    </div>

  </div>
  <div
    style="text-align: center;margin-top: 50px;width: 80%; margin-left: 10%; background-color: var(--grey1); padding:15px 50px 15px 50px; border-radius: 12px;     font-size: large;">
    {{text}}
  </div>
</div>

<div class="become">
  <div class="container mt-5 mb-5 ">
    <div class="row" style="padding-top: 50px;">
      <div class="col-md-12 ">
        <div class="">
          <h1 style="font-size: 40px;;">How to choose best two-wheeler Insurance?</h1>
        </div>
        <div style="margin-top: 35px;">
          <img src="./assets/images/newicons/group.svg">
        </div>
      </div>
    </div>
  </div>
</div>

<div class="whatins">
  <div class="container mt-5 mb-5 ">
    <div class="row">
      <div class="col-md-7">
        <h2 style="font-size: 35px; margin-top: 30px; font-weight: 600; letter-spacing: 1px;">What is covered ?</h2>
        <span>
          <p style="padding-top: 15px;" class="insText">
            1. Coverage for damage to your two-wheeler due to accidents, including collisions, overturning, and other
            mishaps.<br>
            2. Compensation in case of theft or total loss of the vehicle.<br>
            3. Covers damages or injuries caused to third parties, including property damage and bodily injury.<br>
            4. Provides financial protection for the owner-rider in case of accidental death or disability.<br>
            5. Discounts on premium for claim-free years, which can be accumulated over time.

          </p>

        </span> 
      </div>
      <div class="col-md-5" style="text-align: right; padding-top: 40px;">
        <img src="./assets/images/newicons/video.svg" class="videoClass">
      </div>
    </div>
  </div>
</div>

<div class="whyins1">
  <div class="container mt-5 mb-5 ">
    <div class="row">
      <div class="col-md-5" style="text-align: left; padding-top: 40px;">
        <img src="./assets/images/newicons/video.svg" class="videoClass">
      </div>
      <div class="col-md-7">
        <h2 style="font-size: 35px; margin-top: 30px; font-weight: 600; text-align: right;margin-right: 17%;
    letter-spacing: 1px;">
          What is not Covered?</h2>
        <span>
          <p style="padding-top: 20px;" class="insText">
            1. Damage due to regular use, aging, and wear and tear is not covered.<br>
            2. Issues arising from mechanical or electrical failures that are not related to accidents.<br>
            3. Damage caused intentionally or due to reckless behavior is not covered.<br>
            4. Accidents occurring while the rider is under the influence of alcohol or drugs are generally
            excluded.<br>
            5. Damage to or loss of a vehicle that is not properly registered.
          </p>
        </span> 

      </div>

    </div>
  </div>
</div>

<div class="faq">
  <div class="container mt-5 mb-5 ">
    <div class="row" style="padding-top: 50px;">
      <h2>FAQ</h2>
    </div>
    <div class="row" style="padding-top: 15px;">
      <div class="col-md-6">

        <div class="faq">
          <div class="faq-item">
            <div class="faq-question" (click)="toggleAnswer(0)">
              <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 0, 'fa-minus': openItemIndex === 0 }"
                aria-hidden="true"></i>
              1. What does bike insurance cover??
            </div>
            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 0 }"> Bike insurance covers damages to your
              bike from accidents, theft, fire, natural and man-made calamities, and third-party liabilities. It may
              also include personal accident cover and various add-ons.</div>
          </div>

          <div class="faq-item">
            <div class="faq-question" (click)="toggleAnswer(1)">
              <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 1, 'fa-minus': openItemIndex === 1 }"
                aria-hidden="true"></i>
              2. What is the difference between third-party and comprehensive bike insurance?
            </div>
            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 1 }">Third-party insurance covers damages and
              liabilities to third parties caused by your bike. Comprehensive insurance covers both third-party
              liabilities and damages to your own bike..</div>
          </div>

          <div class="faq-item">
            <div class="faq-question" (click)="toggleAnswer(3)">
              <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 3, 'fa-minus': openItemIndex === 3 }"
                aria-hidden="true"></i>
              3. What are add-ons in bike insurance?
            </div>
            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 3 }"> Add-ons are additional coverages you
              can purchase to enhance your policy, such as zero depreciation cover, engine protection, roadside
              assistance, and more.</div>
          </div>

          <div class="faq-item">
            <div class="faq-question" (click)="toggleAnswer(4)">
              <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 4, 'fa-minus': openItemIndex === 4 }"
                aria-hidden="true"></i>
              4. How can I file a claim?
            </div>
            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 4 }">To file a claim, notify your insurer
              immediately after the incident, provide necessary documents (e.g., FIR, repair bills), and follow the
              insurer’s claim procedure, which may include an inspection of the damage.</div>
          </div>

          <div class="faq-item">
            <div class="faq-question" (click)="toggleAnswer(5)">
              <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 5, 'fa-minus': openItemIndex === 5 }"
                aria-hidden="true"></i>
              5. Can I renew my bike insurance online?
            </div>
            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 5 }">Yes, you can renew your policy by
              visiting
              our website, paying the premium, and updating all necessary information.</div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="faq">
          <div class="faq-item">
            <div class="faq-question" (click)="toggleAnswer(6)">
              <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 6, 'fa-minus': openItemIndex === 6 }"
                aria-hidden="true"></i>
              6. How is the premium for bike insurance calculated?
            </div>
            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 6 }"> Premiums are calculated based on
              factors such as the bike's make and model, age, RTO, coverage type, and add-ons.</div>
          </div>

          <div class="faq-item">
            <div class="faq-question" (click)="toggleAnswer(7)">
              <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 7, 'fa-minus': openItemIndex === 7 }"
                aria-hidden="true"></i>
              7. What is a No Claim Bonus (NCB)?
            </div>
            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 7 }">NCB is a discount on your premium for
              each claim-free year. It rewards safe riding and reduces your insurance cost upon renewal.</div>
          </div>

          <div class="faq-item">
            <div class="faq-question" (click)="toggleAnswer(8)">
              <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 8, 'fa-minus': openItemIndex === 8 }"
                aria-hidden="true"></i>
              8. What documents are required for buying bike insurance?
            </div>
            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 8 }"> Common documents include the bike's
              registration certificate (RC), driving license, and identity proof. Some insurers may also require a
              recent photograph of the bike.</div>
          </div>

          <div class="faq-item">
            <div class="faq-question" (click)="toggleAnswer(9)">
              <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 9, 'fa-minus': openItemIndex === 9 }"
                aria-hidden="true"></i>
              9. How can I renew my bike insurance?
            </div>
            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 9 }">Bike insurance can be renewed online
              through the insurer’s website, mobile app, or by visiting an insurance office. Ensure to renew it before
              the expiry date to avoid lapses in coverage.</div>
          </div>

          <div class="faq-item">
            <div class="faq-question" (click)="toggleAnswer(10)">
              <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 10, 'fa-minus': openItemIndex === 10 }"
                aria-hidden="true"></i>
              10. What are the exclusions in bike insurance?
            </div>
            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 10 }">Common exclusions include normal wear
              and tear, mechanical breakdowns, damage caused under the influence of alcohol or drugs, and damage due to
              illegal activities.</div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid background">
  <div class="container partners_section">
    <div class="card-container ">
      <div class="justify-content">
        <div class="text " style="margin-top: 3%;"> 
          <h1 class="heading">Our 2 Wheeler Insurance Partners</h1>
          <span class="font-body">We work together to provide you the best services.</span>
        </div>

        <div class="container ">
          <div style="text-align: center;">
            <div class="image-row">
              <img *ngFor="let img of images" [src]="img" alt="">
            </div>
            <div class="image-row">
              <img *ngFor="let img of images1" [src]="img" alt="">
            </div>
            <div style="width: 100%; text-align: center;margin-bottom: 20px;">
              <a *ngIf="!showAllImages" class="butn" (click)="showAllImages = true">Show More</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->