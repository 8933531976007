import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../Shared/AppConfig';
import { ApiResponse, ApiResponseNew } from '../Models/ApiResponse';
import { Subject, ReplaySubject, BehaviorSubject, Observable } from 'rxjs';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NativeEventSource, EventSourcePolyfill } from 'event-source-polyfill';
import { GeneralService } from './general.service';
@Injectable(
  {
    providedIn: 'root'
  }
)
export class InsuranceService {
  EventSource = NativeEventSource || EventSourcePolyfill;
  protected _appset = AppConfig.settings;
  public dataSubject = new ReplaySubject<any>();
  public dataState = this.dataSubject.asObservable();
  constructor(private http: HttpClient, private zone: NgZone,private generalService :GeneralService) {
  }

  QuotesValue(data:any) {
    this.dataSubject.next(data);
  }

  async AcquireInsurancePlans(data: any) {
    return this.http.post<ApiResponse>(`${environment.APIUrl}quote/GetQuotes`, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }

  async GetOpenQuotes(data: any) {
    return this.http.post<ApiResponse>(`${environment.APIUrl}quote/GetOpenQuotes`, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('NoAuthorization', 'True')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }

  async GetKycDetails(registrationNumber: string) {
    return this.http.get<ApiResponse>(`${environment.APIUrl}vehicleInfo/VerifyKYCDetails` + '?registrationNumber=' + registrationNumber, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('NoAuthorization', 'True')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }

  async GetTransactionHistory(quoteID: string) {
    return this.http.post<ApiResponse>(`${environment.APIUrl}quote/getTransactionHistory` + '?request=' + quoteID, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('NoAuthorization', 'True')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      ;
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }

  async GetUserDetails() {
    return this.http.get<ApiResponse>(`${environment.APIUrl}user/UniqueIdUserInfo`, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }

  async CreateQuote(data: any) {
    return this.http.post<ApiResponse>(`${environment.APIUrl}quote/CreateQuote`, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });

      
  }
  async PostTestimonials(data: any) {
    return this.http.post<ApiResponse>(`${environment.APIUrl}quote/PostTestimonials`, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('NoAuthorization', 'True')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });  
}

async GetAddOnsByCategory(data: any) {
  return this.http.get<ApiResponseNew>(`${environment.APIUrl}mobile/GetAddOnsByCategory?category=`+data, {
    headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('NoAuthorization', 'True')
  }).toPromise().catch(async (errorRes: ApiResponseNew) => {
    let res = new ApiResponseNew();
    res.code = errorRes.code;
    res.message = errorRes.message;
    return res;
  });
}

async GetHealtQuotes(data: any) {
  return this.http.post<ApiResponse>(`${environment.APIUrl}quote/GetHealtQuotes`, data, {
    headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
       //.set('NoAuthorization', 'True')
  }).toPromise().catch(async (errorRes: ApiResponse) => {
    let res = new ApiResponse();
    res.isSuccess = false;
    res.message = errorRes.message;
    return res;
  });  
}

async GetOpenHealthQuotes(data: any) {
  return this.http.post<ApiResponse>(`${environment.APIUrl}quote/GetOpenHealthQuotes`, data, {
    headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('NoAuthorization', 'True')
  }).toPromise().catch(async (errorRes: ApiResponse) => {
    let res = new ApiResponse();
    res.isSuccess = false;
    res.message = errorRes.message;
    return res;
  });
}

async HealthCreateQuote(data: any) {
  return this.http.post<ApiResponse>(`${environment.APIUrl}quote/HealthCreateQuote`, data, {
    headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      // .set('NoAuthorization', 'True')
  }).toPromise().catch(async (errorRes: ApiResponse) => {
    let res = new ApiResponse();
    res.isSuccess = false;
    res.message = errorRes.message;
    return res;
  });  
}

async GetTravelQuotes(data: any) {
  return this.http.post<ApiResponse>(`${environment.APIUrl}quote/TravelQuotes`, data, {
    headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('NoAuthorization', 'True')
  }).toPromise().catch(async (errorRes: ApiResponse) => {
    let res = new ApiResponse();
    res.isSuccess = false;
    res.message = errorRes.message;
    return res;
  });
}

async CreateTravelQuotes(data: any) {
  return this.http.post<ApiResponse>(`${environment.APIUrl}quote/CreateTravelQuotes`, data, {
    headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
  }).toPromise().catch(async (errorRes: ApiResponse) => {
    let res = new ApiResponse();
    res.isSuccess = false;
    res.message = errorRes.message;
    return res;
  });
}

async GetPropertyQuote(data: any) {
  return this.http.post<ApiResponse>(`${environment.APIUrl}quote/PropertyQuickQuote`, data, {
    headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('NoAuthorization', 'True')
  }).toPromise().catch(async (errorRes: ApiResponse) => {
    let res = new ApiResponse();
    res.isSuccess = false;
    res.message = errorRes.message;
    return res;
  });
}

async GetTempQuotesActualRequestByRequestId(requestId: string, quoteId:string) {
  return this.http.get<ApiResponse>(`${environment.APIUrl}quote/GetTempQuotesActualRequestByRequestId` + '?requestId=' + requestId +'&quoteId='+ quoteId, {
    headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('NoAuthorization', 'True')
  }).toPromise().catch(async (errorRes: ApiResponse) => {
    let res = new ApiResponse();
    res.isSuccess = false;
    res.message = errorRes.message;
    return res;
  });
}

async ShareQuotes(data: any) {
  return this.http.post<ApiResponse>(`${environment.APIUrl}quote/ShareQuotes`, data, {
    headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('NoAuthorization', 'True')
  }).toPromise().catch(async (errorRes: ApiResponse) => {
    let res = new ApiResponse();
    res.isSuccess = false;
    res.message = errorRes.message;
    return res;
  });
}

async SendPolicyUserOtp(data: any) {
  return this.http.post<ApiResponse>(`${environment.APIUrl}quote/SendPolicyUserOtp`, data, {
    headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
  }).toPromise().catch(async (errorRes: ApiResponse) => {
    let res = new ApiResponse();
    res.isSuccess = false;
    res.message = errorRes.message;
    return res;
  });
}

async ValidatePolicyUserOtp(data: any) {
  return this.http.post<ApiResponse>(`${environment.APIUrl}quote/ValidatePolicyUserOtp`, data, {
    headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
  }).toPromise().catch(async (errorRes: ApiResponse) => {
    let res = new ApiResponse();
    res.isSuccess = false;
    res.message = errorRes.message;
    return res;
  });
}

async PreInspStatus(data: any) {
  return this.http.post<ApiResponse>(`${environment.APIUrl}quote/PreInspStatus`, data, {
    headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
  }).toPromise().catch(async (errorRes: ApiResponse) => {
    let res = new ApiResponse();
    res.isSuccess = false;
    res.message = errorRes.message;
    return res;
  });
}

async ContinuePolicyJourney(data: any) {
  return this.http.post<ApiResponse>(`${environment.APIUrl}quote/ContinuePolicyJourney`, data, {
    headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
  }).toPromise().catch(async (errorRes: ApiResponse) => {
    let res = new ApiResponse();
    res.isSuccess = false;
    res.message = errorRes.message;
    return res;
  });
}

//Save Request Data to server
async SaveQuoteRequest(data: any) {
  return this.http.post<ApiResponse>(`${environment.APIUrl}quote/SaveQuoteRequest`, data, {
    headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('NoAuthorization', 'True')
  }).toPromise().catch(async (errorRes: ApiResponse) => {
    let res = new ApiResponse();
    res.isSuccess = false;
    res.message = errorRes.message;
    return res;
  });
}

// Connect to SSE and listen to the stream
getQuotesAsync(rid:any,typeOfCover:any,isTPPD:boolean = false,isShared:boolean = false): Observable<string> {
  return new Observable(observer => {
    const eventSource = new EventSourcePolyfill(`${environment.APIUrl}quote/GetQuotesAsync?id=${rid}&typeOfCover=${typeOfCover}&isTPPD=${isTPPD}&isShared=`+isShared,
      {
        headers: {
                'Authorization': 'Bearer '+ this.generalService.getAccessToken()
            }
        }
    );

    // Listen for messages from the server
    eventSource.onmessage = (event:any) => {
      // NgZone.run is used to ensure updates are in Angular's zone for UI update
      this.zone.run(() => {
        try {
          // Parse the received data as JSON
          const data = JSON.parse(event.data);
          observer.next(data);
        } catch (error) {
          observer.error(`Failed to parse JSON: ${error}`);
        }
      });
    };

    // Handle errors
    eventSource.onerror = (error:any) => {
      this.zone.run(() => {
        observer.error(error);
      });
    };

    // Close connection when no longer used
    return () => eventSource.close();
  });
}

//Health Save Request Data to server
async SaveHealthQuoteRequest(data: any) {
  return this.http.post<ApiResponse>(`${environment.APIUrl}quote/SaveHealthQuoteRequest`, data, {
    headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('NoAuthorization', 'True')
  }).toPromise().catch(async (errorRes: ApiResponse) => {
    let res = new ApiResponse();
    res.isSuccess = false;
    res.message = errorRes.message;
    return res;
  });
}

// Connect to SSE and listen to the stream
getHealthQuotesAsync(rid:any,typeOfCover:any,isTPPD:boolean = false,isShared:boolean = false): Observable<string> {
  return new Observable(observer => {
    const eventSource = new EventSourcePolyfill(`${environment.APIUrl}quote/GetHealthQuotesAsync?id=${rid}&typeOfCover=${typeOfCover}&isTPPD=${isTPPD}&isShared=`+isShared,
      {
        headers: {
                'Authorization': 'Bearer '+ this.generalService.getAccessToken()
            }
        }
    );

    // Listen for messages from the server
    eventSource.onmessage = (event:any) => {
      // NgZone.run is used to ensure updates are in Angular's zone for UI update
      this.zone.run(() => {
        try {
          // Parse the received data as JSON
          const data = JSON.parse(event.data);
          observer.next(data);
        } catch (error) {
          observer.error(`Failed to parse JSON: ${error}`);
        }
      });
    };

    // Handle errors
    eventSource.onerror = (error:any) => {
      this.zone.run(() => {
        observer.error(error);
      });
    };

    // Close connection when no longer used
    return () => eventSource.close();
  });
}

//Travel Save Request Data to server
async SaveTravelQuoteRequest(data: any) {
  return this.http.post<ApiResponse>(`${environment.APIUrl}quote/SaveTravelQuoteRequest`, data, {
    headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('NoAuthorization', 'True')
  }).toPromise().catch(async (errorRes: ApiResponse) => {
    let res = new ApiResponse();
    res.isSuccess = false;
    res.message = errorRes.message;
    return res;
  });
}

// Connect to SSE and listen to the stream
GetTravelQuotesAsync(rid:any,typeOfCover:any,isTPPD:boolean = false,isShared:boolean = false): Observable<string> {
  return new Observable(observer => {
    const eventSource = new EventSourcePolyfill(`${environment.APIUrl}quote/GetTravelQuotesAsync?id=${rid}&typeOfCover=${typeOfCover}&isTPPD=${isTPPD}&isShared=`+isShared,
      {
        headers: {
                'Authorization': 'Bearer '+ this.generalService.getAccessToken()
            }
        }
    );

    // Listen for messages from the server
    eventSource.onmessage = (event:any) => {
      // NgZone.run is used to ensure updates are in Angular's zone for UI update
      this.zone.run(() => {
        try {
          // Parse the received data as JSON
          const data = JSON.parse(event.data);
          observer.next(data);
        } catch (error) {
          observer.error(`Failed to parse JSON: ${error}`);
        }
      });
    };

    // Handle errors
    eventSource.onerror = (error:any) => {
      this.zone.run(() => {
        observer.error(error);
      });
    };

    // Close connection when no longer used
    return () => eventSource.close();
  });
}
}
