import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../Shared/AppConfig';
import { ApiResponse } from '../Models/ApiResponse';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LookUpService {
  protected _appset = AppConfig.settings;
  constructor(private http: HttpClient) { }

  async GetVehicleCompanyDetails() {
    return this.http.get<ApiResponse>(`${environment.APIUrl}lookUpInfo/GetVehicleCompanyDetails`, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }

  async GetVehicleModelDetails(vehiclecompanyId: number) {
    return this.http.get<ApiResponse>(`${environment.APIUrl}lookUpInfo/GetVehicleModelDetails` + '?vehiclecompanyId=' + vehiclecompanyId , {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }

  async GetVehicleVariantDetails(vehiclemodelId: number) {
    return this.http.get<ApiResponse>(`${environment.APIUrl}lookUpInfo/GetVehicleVariantDetails` + '?vehiclemodelId=' + vehiclemodelId, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }

  async stateDetails(countryId: number) {
    return this.http.get<ApiResponse>(`${environment.APIUrl}lookUpInfo/StateDetails` + '?countryId=' + countryId, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }

  async pincodeMasterDetails(pinCode: string) {
    return this.http.get<ApiResponse>(`${environment.APIUrl}lookUpInfo/PinCodeDetails` + '?pinCode=' + pinCode, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('NoAuthorization', 'True')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }

  async countryDetails() {
    return this.http.get<ApiResponse>(`${environment.APIUrl}lookUpInfo/CountryDetails`, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }

  async SaveLeadsDetail(data: any) {
    return this.http.post<ApiResponse>(`${environment.APIUrl}lookUpInfo/SaveLeadsDetail`, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('NoAuthorization', 'True')
      }).toPromise().catch(async (errorRes: ApiResponse) => {
        let res = new ApiResponse();
        res.isSuccess = false;
        res.message = errorRes.message;
        return res;
      });
  }

  async SaveCareerDetail(data: any) {
    return this.http.post<ApiResponse>(`${environment.APIUrl}lookUpInfo/SaveCareerDetail`, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('NoAuthorization', 'True')
      }).toPromise().catch(async (errorRes: ApiResponse) => {
        let res = new ApiResponse();
        res.isSuccess = false;
        res.message = errorRes.message;
        return res;
      });
  }

  async GetInsurancePartnersList(type:string = 'a') {
    return this.http.get<ApiResponse>(`${environment.APIUrl}lookUpInfo/GetInsurancePartners?type=${type}`, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('NoAuthorization', 'True')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }

  async getStatesListByCountry(country:string) {
    return this.http.get<ApiResponse>(`${environment.APIUrl}lookUpInfo/GetStatesByCountry?country=`+country, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }

  async getDistrictsListByState(state:string) {
    return this.http.get<ApiResponse>(`${environment.APIUrl}lookUpInfo/GetDistrictsByState?state=`+state, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }

  async getPincodeByDistrict(district:string) {
    return this.http.get<ApiResponse>(`${environment.APIUrl}lookUpInfo/GetPincodeByDistrict?district=`+district, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }
  async GetLanguageDetails() {
    return this.http.get<ApiResponse>(`${environment.APIUrl}lookUpInfo/LanguageDetails`, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }
  
  async GetVehicleBodyType(category:string) {
    return this.http.get<ApiResponse>(`${environment.APIUrl}lookUpInfo/GetVehicleBodyType?category=${category}`, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('NoAuthorization', 'True')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });
  }

  async GetHealthSumInsuredRanges() {
    return this.http.get<ApiResponse>(`${environment.APIUrl}lookUpInfo/GetHealthSumInsuredRanges`, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('NoAuthorization', 'True')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });
  }

  async GetTravelRelationship() {
    return this.http.get<ApiResponse>(`${environment.APIUrl}lookUpInfo/GetTravelRelationship`, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('NoAuthorization', 'True')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }

  async GetTravelNomineeRelationship() {
    return this.http.get<ApiResponse>(`${environment.APIUrl}lookUpInfo/GetTravelNomineeRelationship`, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('NoAuthorization', 'True')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }
}
