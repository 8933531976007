<app-navbar></app-navbar>
<div class="container mt-3">
    <div class="row">
        <div class="col-md-12">
            <div class="card px-3 py-1" >
                <div class="row align-items-center">
                    <div class="col-auto align ">
                        <button class="btn-primary btn btn-sm p-1" (click)="back()"><img class="text-white svg_white"
                                src="../../../assets/svgIcon/ArrowLeft.svg" alt=""></button>
                    </div>
                    <div class="col align">
                        <label for="" class="p-h mb-0">Destination</label>
                        <p class="p-align mb-0">{{requesrData?.destination??'Arab'}}
                        </p>
                    </div>
                    <div class="col align">
                        <label for="" class="p-h mb-0">Date</label>
                        <p class="p-align mb-0"> {{ moment(requesrData?.travelStartDate).format("DD MMM, YYYY") ?? moment('2024-03-29T18:23').format("DD MMM, YYYY")}} To {{ moment(requesrData?.travelEndDate).format("DD MMM, YYYY")?? moment('2024-03-29T18:23').format("DD MMM, YYYY")}}
                        </p>
                    </div>
                    <div class="col  align">
                        <label for="" class="p-h mb-0">Members</label>
                        <p class="p-align mb-0">{{requesrData?.members?.length??5}}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-3 mb-12">
        <div class="col-md-4">
            <div class="card p-2 ">
                <div class="row scroll  ">                    
                    <div class="col-md-12">
                        <h4>Inbuilt Coverage</h4>
                        <hr>
                    </div>
                    <div class="col-md-12 mt-2">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                            <p class="p-tg b-mr">Rent for alternate accomodation</p>
                        </div>
                    </div>
                    <div class="col-md-12 mt-4">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                            <p class="p-tg b-mr">Loss of Rent</p>
                        </div>
                    </div>
                    <div class="col-md-12 mt-4">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                            <p class="p-tg b-mr">I voluntarily opt-out from the Loss of Rent and Rent for Alternate
                                Accommodation inbuilt coverage</p>
                        </div>
                    </div>
                    <div class="col-md-12 mt-2">
                        <h4>Add-On Covers</h4>
                        <hr>
                    </div>
                    <div class="col-md-12 mt-2">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                            <p class="p-tg b-mr">Terrorism</p>
                        </div>
                        <hr>
                    </div>
                    <div class="col-md-12 mt-2">
                        <div class="row">
                            <div class="col-md-4 mt-2">
                                <label class="mt-2" >Medical Expanses</label>
                            </div>
                            <div class="col-md-6 mt-2">
                                <input type="text" name="" class="form-control" id="">
                            </div>
                            <div class="col-md-4 mt-4">
                                <label class="mt-2">Baggage Loss</label>
                            </div>
                            
                            <div class="col-md-4 mt-4">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                    <p class="p-mg">
                                        Yes
                                    </p>
                                  </div>
                            </div>
                            <div class="col-md-4 mt-4">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                    <p class="p-mg">
                                   No                                    </p>
                                  </div>
                            </div>
                            <div class="col-md-4 mt-4">
                                <label class="mt-2">Loss of Passport</label>
                            </div>
                            
                            <div class="col-md-4 mt-4">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                    <p class="p-mg">
                                        Yes
                                    </p>
                                  </div>
                            </div>
                            <div class="col-md-4 mt-4">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                    <p class="p-mg">
                                   No                                    </p>
                                  </div>
                            </div>
                        </div>
                    </div>                    
                </div>
                <div class="row mt-4">
                    <button class="btn btn-primary w-100 rounded-top-0 position-absolute b-1 r-0" (click)="this.reCalculateQuote()">Apply</button>
                </div>
            </div>
        </div>
        <div class="col-md-8 mg">
            <div class="row">
                <div class="col-md-9 mt-5">
                    <h2>Insurance plans for you</h2>
                    <span class="spn">Showing {{quoteList?.length??0}} plans for you</span>
                </div>
                <div class="col-3 c3">
                    <div class="card p-3">
                        <div class="row">
                            <div class="col-md-2">
                                <img src="../../assets/svgIcon/SortAscending.svg" alt="SortAscending"
                                    width="22">
                            </div>
                            <div class="col-md-10 align-items-center d-flex justify-content-end  pl-0">
                                <select [(ngModel)]="sort_quote" (change)="sortQuote()"
                                    class="w-100 border-0 py-0 px-2 sort-ddl">
                                    <option *ngFor="let item of sortQuoteList; let i = index;"
                                        value="{{item.value}}">{{item.text}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <ng-container *ngFor="let quote of quoteList">
                    <div class="col-md-12 mt-2">
                        <div class="card px-5">                            
                                <div class="row align-items-center">
                                    <div class="col-auto">
                                        <img class="image-stl" src="/assets/images/insurance_companies/{{quote.insuranceCompany}}.png" alt="">
                                    </div>    
                                    <div class="col mt-2">
                                        <span class="spn">Plan Name</span>
                                        <p class="p-h">{{quote.planName}}</p>
                                    </div>    
                                    <div class="col mt-2">
                                        <span class="spn">Medical Expenses</span>
                                        <p class="p-h">{{quote.medicalExpenses}}</p>
                                    </div>
                                    <div class="col mt-2">
                                        <span class="spn">Loss Of Passport</span>
                                        <p class="p-h">{{quote.lossOfPassport}}</p>
                                    </div> 
                                    <div class="col mt-2 text-center">
                                        <span class="text-main font-17-13">₹{{quote.totalPremiumAmount}}</span>  
                                        <span class="gst-span">(GST Included)</span>
                                        <span class="span-link" (click)="showPremiumBreakupPopup(quote)">View Breakup</span>
                                    </div>
                                    <div class="col-auto mt-2 text-end">
                                        <button class="btn btn-primary btn-sm" (click)="getDetails(quote)">
                                            Buy Now
                                        </button>                                        
                                    </div>
                                </div>
                        </div>
                    </div>
                </ng-container> -->
                <ng-container *ngIf="!isQuoteFound">
                    <div class="card mt-3">
                      <div class="row ">
                        <div class="col-12 text-center py-2 d-flex justify-content-center">
                          <img class="image-stl" src="/assets/svgIcon/no_quote_found.svg" alt="No Quote Found">
                        </div>
                      </div>
                  </div>
                </ng-container>
                <div class="" *ngFor="let company of groupedQuotesArray">
                    <div *ngFor="let plan of company.quotes;let ind = index" class="card mt-3 col-12" [ngClass]="{'d-none':company.isExpanded == false && ind > 0}">
                        <div class="row align-value min-height78">
                            <div class="col">
                                <img class="image-stl" src="/assets/images/insurance_companies/{{plan.insuranceCompany}}.png" alt="">
                            </div>
                            <div class="col text-center">
                                <p class="text-grey4 mb-0 text-center">Plan Name</p>
                                <h4 class="mb-0 lh-sm">{{plan?.planName}}</h4>
                            </div>
                            <div class="col mt-2 text-center">
                                <span class="spn">Medical Expenses</span>
                                <p class="p-h">{{plan.medicalExpenses}}</p>
                            </div>
                            <div class="col mt-2 text-center">
                                <span class="spn">Loss Of Passport</span>
                                <p class="p-h">{{plan.lossOfPassport}}</p>
                            </div> 
                            <div class="col text-center">
                                <p class="text-center text-main mb-0 font-17-13"> ₹{{plan?.priceBreakUp?.grossPremium}}
                                    <span class="gst-span text-grey4">(GST Included)</span>
                                </p>
                                <span _ngcontent-uwr-c79="" class="span-link" (click)="showPremiumBreakupPopup(plan)">View Breakup</span>
                            </div>
                            <div class="col">
                                <button class="btn btn-primary bt my-2" id="btn"
                                    (click)="getDetails(plan)">Buy Now</button>
                            </div>
                        </div>
                    </div>
                    <div class="text-center bg-white text-main rounded rounded-top1-0 fw-bold cursor-pointer see-more-less" *ngIf="(company?.quotes?.length??0) > 1" (click)="togglePlans(company.companyKey)"> {{company.isExpanded == false ? 'Show ' + ((company?.quotes?.length??0)-1) + ' more ' + (((company?.quotes?.length??0)-1) > 1 ? 'plans' : 'plan') : 'See less'}} </div>
                </div>
                  <skeleton-loader className="mt-3" *ngIf="showLoader"></skeleton-loader>
            </div>
        </div>
    </div>


</div>
<div class="overlay" *ngIf="premiumBreakupPopup">
    <div class="popup2 col-lg-4 col-md-6 col-11">
      <div class="modal-header tooltip_">
        <h4 class="modal-title">Premium Breakup</h4>  
        <button type="button" class="close pull-right " aria-label="Close" (click)="hidePremiumBreakupPopup()">
          <span aria-hidden="true"></span>
        </button>
      </div>
      <div class="modal-body pt-0">
        <div class="row">
          <div class="col-auto border-right ">
            <img class="image-stl" src="/assets/images/insurance_companies/{{priceBreakup.insuranceCompany}}.png" alt="">
          </div>
          <div class="col d-flex flex-column align-self-center">
            <span class="d-block mb-1"><span class="spn">Plan Name : </span> {{priceBreakup.planName}}</span>
            <span class="d-block"><span class="spn">Product Name : </span> {{priceBreakup.productName}}</span>
          </div>
        </div>            
        <hr class="mt-0 mb-1">
        <div class="row">
          <div class="col-8 mt-1">
            <h5>Net Premium {{checkValueNotZero(priceBreakup.ownDriverCover??'0') && checkValueNotZero(priceBreakup.thirdPartyCoverPremium??'0') ? '(A + B)' : ''}}</h5>
          </div>
          <div class="col-4 mt-1 text-right">
            <h5>₹ {{priceBreakup.netPremium??'0' | thousandSeparator}}</h5>
          </div>
        </div>
        <div class="row">
          <div class="col-8 mt-1">
            <span>GST(18%)</span>
          </div>
          <div class="col-4 mt-1 text-right" >
            <h5 class="text-danger">+ ₹ {{priceBreakup.totalTax??'0' | thousandSeparator}}</h5>
          </div>
        </div>
        <hr class="my-1">
        <div class="row">
          <div class="col-8 mt-1">
            <h5>Final Premium</h5>
          </div>
          <div class="col-4 mt-1 text-right">
            <h5>₹ {{priceBreakup.grossPremium ??'0' | thousandSeparator}}</h5>
          </div>
        </div>
      </div>
  </div>
</div>