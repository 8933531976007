import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute} from '@angular/router';
import { InsuranceService } from 'src/app/Services/insurance.service';
import * as moment from 'moment';
import { GeneralService } from 'src/app/Services/general.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { QuotesLoginComponent } from 'src/app/Login/quotes-login/quotes-login.component';
import { Subject, Subscription } from 'rxjs';
@Component({
  selector: 'app-policy-quotes',
  templateUrl: './policy-quotes.component.html',
  styleUrls: ['./policy-quotes.component.css']
})
export class PolicyQuotesComponent implements OnInit {
  ageList:any[];
  requesrData : any;
  quoteList:any=[];
  moment: any = moment;
  premiumBreakupPopup:boolean = false;
  priceBreakup:any;
  modalRef: BsModalRef;
  private sseSubscription!: Subscription;
  rId:any;
  uId:any;
  baseUrl:any;
  isShared:boolean = false;
  showLoader:boolean = false;
  isQuoteFound:boolean = true;
  groupedQuotesArray:any = [];
  isAgent: boolean = this.generalService.getIsAgent() === 'true' ? true : false;
  sortQuoteList:any = [
    { value: "", text: 'Sort By' },
    { value: "sort_i", text: 'IDV high to low' },
    { value: "sort_c", text: 'Claim Settled high to low' },
    { value: "sort_p", text: 'Premium low to high' }
  ];
  sort_quote: string = "";
  constructor(private router:Router, private insuranceService : InsuranceService,private generalService : GeneralService,private modalService : BsModalService,private route : ActivatedRoute){
    const parsedUrl = new URL(window.location.href);
    this.baseUrl = parsedUrl.origin;

    this.rId = this.route.snapshot.queryParams['q'];      
    this.uId = this.route.snapshot.queryParams['u'];
    if(this.rId == undefined || this.rId == null){
      this.rId = this.route.snapshot.paramMap.get('q')??"";
      this.uId = this.route.snapshot.paramMap.get('u')??undefined;
    }

    if(this.rId == undefined || this.rId == null || this.rId == ""){
      this.rId = moment(new Date()).format("YYYYMMDDHHmmssSSSSSS");
    }
    this.sortQuoteList =[
      {value:"",text:'Sort By'},
      {value:"sort_i",text:'Sum Insured high to low'},
      {value:"sort_c",text:'Claim Settled high to low'}, 
      {value:"sort_p",text:'Premium low to high'}
    ];
    if(this.isAgent){
      this.sortQuoteList.push({value:"sort_ac",text:'Points high to low'});
    }
  } 
  
  ngOnInit(): void {    
    let data = this.generalService.getDataToLocalStorage('quoteDataTravel');
    if(data!= ""){
      let allData = JSON.parse(data);
      if(allData.quoteList != null && allData.quoteList != undefined){
        this.quoteList = allData.quoteList;
        this.requesrData = allData.memberDetails;
        this.groupQuotesByInsuranceCompany();
      }
      else
      {
        this.requesrData = allData.memberDetails;
        this.getQuotes();
      }
    }
  }
checked:boolean=false;
isDetailsPopup:boolean=false;
check()
{
 if(!this.checked)
 {
  this.checked=true;
 }
 else{
  this.checked=false;
 }
 
}

close()
{
  
this.isDetailsPopup=false;
}
tabs:string='';


async getAge()
{
  this.ageList = [];
  for (var i = 18; i <= 100; i++) {
    this.ageList.push(i)
  }
}
getDetails(quote:any){
  debugger;
  if (this.generalService.getAccessToken() != "") {
    this.proceedToPurchase(quote);
  }
  else {
    const initialState: any = {
      list: [
        {
          "quote": quote
          // ,
          // "vehicleData": this.vehicleData,
          // "IsMockup": IsMockup,
          // "proposerDetails" : this.proposerDetails
        }
      ]
    };
    let config = {
      animated: false,
      initialState : initialState
    };
    this.modalRef = this.modalService.show(QuotesLoginComponent,config);
    this.modalRef.content.action.subscribe(async (data: any) => {
      if (data) {
        this.proceedToPurchase(quote);
      }
    });
  }


}

proceedToPurchase(quote:any){
  this.generalService.setDataToLocalStorage('quoteDataTravel',JSON.stringify ({memberDetails:this.requesrData,quoteList:this.quoteList,quote:quote}));
  this.router.navigate(['/travel-detail'])
}

  reCalculateQuote() {
    this.quoteList = [];
    this.groupedQuotesArray = [];
    this.isQuoteFound = true;
    this.rId = moment(new Date()).format("YYYYMMDDHHmmssSSSSSS");
    this.requesrData.requestId = this.rId;
    this.getQuotes() ;
  }

async getQuotes(){
  let response = await this.insuranceService.SaveTravelQuoteRequest(this.requesrData);
  if (response?.isSuccess && response?.result?.code == 1) {      
    this.getQuotesAsync("",false);
  }
  else{
    this.isQuoteFound = false;
  }
}

getQuotesAsync(typeOfCover:string,isTPPD:boolean = false) {
  // if(this.insuranceformData?.vehicleDetails?.typeOfCover != "Standalone" && this.insuranceformData?.vehicleDetails?.show_TP_QuotesOnly != "Yes"){
  //   this.showTPLoader = true;
  // }
  this.showLoader = true;
  this.sseSubscription = this.insuranceService.GetTravelQuotesAsync(this.rId,"",false,this.isShared)
    .subscribe({
      next: (message: any) => {
        //this.messages.push(message);
        console.log(message);
        if(message?.quoteId != null && message?.quoteId != undefined && message?.quoteId != null)
        {
          this.quoteList.push(message);
          // if(this.quoteList?.length > 0)
          //   this.quoteList = this.quoteList.sort((a:any,b:any)=> b.sumInsurred < a.sumInsurred ? 1 : -1);            
          
          this.groupQuotesByInsuranceCompany();
        }
        if(this.isShared){

        }
    },
      error: (error) => {
        console.info('Request Completed:', error);
        this.showLoader = false;
        this.isQuoteFound = this.quoteList.length > 0;
        console.log(this.quoteList);
        // if(typeOfCover != "LiabilityOnly"){
        //   this.compAfterQouteBound();
        // }
        // else if(typeOfCover == "LiabilityOnly" && isTPPD == true){
        //   this.tpptdAfterQouteBound();
        // }
        // else if(typeOfCover == "LiabilityOnly" && isTPPD == false){
        //   this.tpAfterQouteBound();
        // }
      }
    });
}

ngOnDestroy(): void {
  if (this.sseSubscription) {
    this.sseSubscription.unsubscribe();
  }
}

back(){
  this.router.navigate(['/'])
}

showPremiumBreakupPopup(data:any){

  this.priceBreakup = data.priceBreakUp;
  this.premiumBreakupPopup = true;
}

hidePremiumBreakupPopup(){
  this.premiumBreakupPopup = false;
}

checkValueNotZero(data:string)
{
  if(data != null && data != undefined && data != "")
  {
    return parseFloat(data) > 0 ? true : false;
  }
  else
  {
    return false;
  }
}

groupQuotesByInsuranceCompany(): void {
  const groupedQuotes: Record<number, any[]> = this.quoteList.reduce(
    (acc: Record<number, any[]>, quote:any) => {
      const company = quote.insuranceCompany??0;
      if (!acc[company]) {
        acc[company] = [];
      }
      acc[company].push(quote);
      return acc;
    },
    {}
  );
  // Convert groupedQuotes object to an array of key-value pairs
  this.groupedQuotesArray = Object.keys(groupedQuotes).map((key) => ({
    companyKey: Number(key),
    quotes: groupedQuotes[Number(key)],
    isExpanded: false
  }));
}

togglePlans(insuranceComapny:number){
  this.groupedQuotesArray.forEach((group:any) => {
    if (group.companyKey == insuranceComapny) {
      group.isExpanded = !group.isExpanded;
    }
  });
}
sortQuote(){
  if(this.sort_quote == "sort_i")
  {
    this.quoteList = this.quoteList.sort((a:any,b:any)=> parseFloat(b.sumInsurred??"0") > parseFloat(a.sumInsurred??"0") ? 1 : -1);
  }
  else if(this.sort_quote == "sort_c")
  {
    this.quoteList = this.quoteList.sort((a:any,b:any)=> parseFloat(b.claimSettlementRatio??"0") > parseFloat(a.claimSettlementRatio??"0") ? 1 : -1);
  }
  else if(this.sort_quote == "sort_p")
  {
    this.quoteList = this.quoteList.sort((a:any,b:any)=> parseFloat(a.grossPremium??"0") > parseFloat(b.grossPremium??"0") ? 1 : -1);
  }
  else if(this.sort_quote == "sort_ac")
  {
    this.quoteList = this.quoteList.sort((a:any,b:any)=> parseFloat(b.agentCommission??"0") > parseFloat(a.agentCommission??"0") ? 1 : -1);
  }
  this.groupQuotesByInsuranceCompany();
}
}
