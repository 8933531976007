<ng-container *ngIf="required_input_fields && required_input_fields.memberFormFields && required_input_fields.memberFormFields.length > 0">
    <fieldset [disabled]="isReadOnly">
        <div class="row">
            <ng-container *ngFor="let field of required_input_fields.memberFormFields">
                <hr class="my-1" *ngIf="field.isVisible && (field.id?.indexOf('NomineeFirstname') != -1 || field.id?.indexOf('GuardianFirstname') != -1 || field.id?.indexOf('AddressLine1') != -1 || field.id?.indexOf('UniversityName') != -1 || field.id?.indexOf('SponsorName') != -1)">
                <div class="my-2 dynamic-field-container {{field.className != null && field.className != undefined && field.className != '' ? field.className : containerClass}}"  [ngClass]="{'d-none':!field.isVisible}">
                    <label class="mb-0" [ngClass]="{'d-none':field.type == 'checkbox', 'required':field.isRequired}">{{ field.title }}</label>
                    <ng-container [ngSwitch]="field.type">
                        <ng-container *ngSwitchCase="'radio'">
                            <div class="d-flex align-items-center gap-5">
                                <ng-container *ngFor="let option of field.options">
                                    <span class="d-flex align-items-center">
                                        <input type="radio" class="mt-0 scale-1_3" [id]="field.id + option" [name]="field.name"  [value]="option" [checked]="field.selectedValue ==option ? 'checked' :''" (change)="onChange(field.id??'',$event)"/><label [for]="field.id + option" class="ml-2 mb-0"> {{ option }}</label>
                                    </span>
                                </ng-container>
                            </div>                
                        </ng-container>
                        <ng-container *ngSwitchCase="'checkbox'">
                            <span class="d-flex align-items-center">
                                <input type="checkbox" class="mt-0 scale-1_3" value="" [checked]="field.selectedValue == 'Yes'" (click)="onChange(field.id??'',$event, field.type, field.id)"><label [for]="field.id" class="ml-2 mb-0"  [ngClass]="{'required':field.isRequired}"> {{ field.title }}</label>
                            </span>
                        </ng-container>
                        <select *ngSwitchCase="'select'" class="form-select py-2" (change)="onChange(field.id??'',$event)" [ngClass]="{'border border-danger': field.isValid == false && field.isRequired && field.selectedValue ==''}">
                            <option *ngFor="let option of field.options" [value]="option" [selected]="field.selectedValue ==option ? 'selected' :''" >{{ option }}</option>
                        </select>
                        <input *ngSwitchCase="'text'" type="text" class="form-control" [placeholder]="field.placeholder" [value]="field.selectedValue" (blur)="onChange(field.id??'',$event)" [ngClass]="{'border border-danger': field.isValid == false && field.isRequired && (field.selectedValue =='' || (field.id?.toLowerCase()?.indexOf('name') != -1 && (field?.pattern??'') != '' && !field?.selectedValue?.match(field?.pattern??'')))}" [disabled]="field.isDisable??false"/>
                        <input *ngSwitchCase="'number'" type="number"  class="form-control" [placeholder]="field.placeholder" [value]="field.selectedValue" (blur)="onChange(field.id??'',$event)" [ngClass]="{'border border-danger': field.isValid == false && field.isRequired && (field.selectedValue =='' || field.selectedValue == '0')}"/>        
                        <textarea *ngSwitchCase="'textarea'" class="form-control" rows="3" [placeholder]="field.placeholder" [value]="field.selectedValue" (blur)="onChange(field.id??'',$event)" [ngClass]="{'border border-danger': field.isValid == false && field.isRequired && field.selectedValue ==''}"></textarea>
                        <input *ngSwitchCase="'date'" type="date" class="form-control" [value]="field.selectedValue" (blur)="onChange(field.id??'',$event)" [ngClass]="{'border border-danger': field.isValid == false && field.isRequired && field.selectedValue ==''}" [max]="field.id?.indexOf('Nomineedob') != -1 || field.id?.indexOf('SponsorDateofBirth') != -1 ? nomineeMaxDOB : maxDOB" onkeydown="return false"/>
                        <app-autocomplete *ngSwitchCase="'autoComplete'"  class="w-100" (itemSelected)="onPincodeSelection(field.id??'',$event)"
                            [items]="pincodeList" placeholder="Pincode" [supportingText]="true"
                            [userInput]="field.selectedValue??''"
                            [showError]=" field.isValid == false && field.isRequired??false && field.selectedValue ==''"
                            (onKeyUpEvent)="onPincodeChange($event)"  (input)="onInputChange($event)">
                        </app-autocomplete>
                        <input *ngSwitchCase="'phone'" type="text" class="form-control" [placeholder]="field.placeholder" [value]="field.selectedValue" (blur)="onChange(field.id??'',$event)" [ngClass]="{'border border-danger': field.isValid == false && field.isRequired && field.selectedValue?.length != 10}" 
                        [disabled]="field.isDisable??false" minlength="10" maxlength="10" onkeypress="if(this.value.length==10) return false" (keydown)="validateNumberInput($event)" (input)="onInputPhone($event)"/>
                        
                        <input *ngSwitchCase="'email'" type="text" class="form-control" [placeholder]="field.placeholder" [value]="field.selectedValue" (blur)="onChange(field.id??'',$event)" [ngClass]="{'border border-danger': field.isValid == false && field.isRequired && (field.selectedValue =='' || ((field?.pattern??'') != '' && !field?.selectedValue?.match(field?.pattern??'')))}" [disabled]="field.isDisable??false" oninput="this.value = this.value.toLowerCase()" />
                        <input *ngSwitchCase="'passport'" type="text" class="form-control" [placeholder]="field.placeholder" [value]="field.selectedValue" (blur)="onChange(field.id??'',$event)" [ngClass]="{'border border-danger': field.isValid == false && field.isRequired && (field.selectedValue =='' || ((field?.pattern??'') != '' && !field?.selectedValue?.match(field?.pattern??'')))}" [disabled]="field.isDisable??false" oninput="this.value = this.value.toUpperCase()" onkeypress="if(this.value.length==8) return false"/>
                        <span class="text-danger" *ngIf="field.isValid == false && field.isRequired && (field.selectedValue =='' || field.selectedValue == '0')">
                            {{field.title}} is required
                        </span>
                        <span class="text-danger" *ngIf="field.isValid == false && field.isRequired && (field.selectedValue?.length??0) > 0 && ((field.id?.toLowerCase()?.indexOf('phonenumber') != -1 && field.selectedValue?.length != 10) || ((field.id?.toLowerCase()?.indexOf('email') != -1 || field.id?.toLowerCase()?.indexOf('passport') != -1 || field.id?.toLowerCase()?.indexOf('name') != -1) && (field?.pattern??'') != '' && !field?.selectedValue?.match(field?.pattern??'')))">
                            {{field.title}} is not valid.
                        </span>  
                    </ng-container>
                </div>
            </ng-container >
        </div> 
    </fieldset>   
</ng-container>
