import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { PasswordType } from '../Models/login.component.model';
import { LoginService } from '../Services/login.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import swal from 'sweetalert2';
import { MenuService } from '../Services/menu.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject, Subscription } from 'rxjs';
import { InsuranceService } from 'src/app/Services/insurance.service';
import { NavbarComponent } from 'src/app/navbar/navbar.component';
import { AutoCompleteItem, Item } from '../Models/Item';
import { LookUpService } from '../Services/lookup.service';
import { PincodeMaster } from 'src/app/Models/signUp.component.model';
import { GeneralService } from '../Services/general.service';
import { HealthQuestionForm } from '../Models/HeathQuestionForm';
import { HealthPriceBreakup } from '../Models/vehicle-component.model';
import { AgentprofileService } from '../Services/agentprofile.service';
import * as moment from 'moment';
import { HealtQuoteResponseModel } from '../Models/HealtQuoteModel';
import { QuotesLoginComponent } from '../Login/quotes-login/quotes-login.component';
@Component({
  selector: 'app-health',
  templateUrl: './health.component.html',
  styleUrls: ['./health.component.css']
})
export class HealthComponent {
  @Output() gotoEvent = new EventEmitter<any>();
  //#Added By Dinesh
  @Input() healthData: any;
  pincodeMaster: PincodeMaster[] = [];
  planList: HealtQuoteResponseModel[] = [];
  finalPremium: string = "";

  isPayment: boolean = false;
  royalDetails: any={};

  //Dinesh End
  today = new Date();
  maxDate = '';
  defaultDate = "";
  cashlesshospitalsCount: number = 0;
  @Input() quoteData: any
  priceBreakup: HealthPriceBreakup = {};

  insuranceDetailsScreen_one: boolean = true;
  insuranceDetailsScreen_two: boolean = false;
  addressInfo: FormGroup;
  @Input() isBike: boolean;
  @Output() action = new EventEmitter();
  isAgent: boolean = this.generalService.getIsAgent() === 'true' ? true : false;
  quoteObj:HealtQuoteResponseModel;
  private sseSubscription!: Subscription;
  rId:any;
  uId:any;
  baseUrl:any;
  isShared:boolean = false;
  showLoader:boolean = false;
  isQuoteFound:boolean = true;
  SumInsuredList:any =[]; 
  // [
  //   {text:"Choose Sum Insured",value:"0"},
  //   {text:"Below 5 Lakhs",value:"0-400000"},
  //   {text:"5 to 9 Lakhs",value:"500000-900000"},
  //   {text:"10 to 24 Lakhs",value:"1000000-2400000"},
  //   {text:"25 to 99 Lakhs",value:"2500000-9900000"},
  //   {text:"1cr+",value:"10000000-100000000"}
  // ]
  constructor(private loginService: LoginService, private router: Router, private fb: FormBuilder,
    private spinner: NgxSpinnerService, private _menuservice: MenuService, public modalRef: BsModalRef,
    private insuranceService: InsuranceService, private _lookupService: LookUpService, private generalService: GeneralService, private agentprofileService: AgentprofileService,private route : ActivatedRoute,
    private modalService: BsModalService) {
      this.GetHealthSumInsuredRanges();
      const parsedUrl = new URL(window.location.href);
      this.baseUrl = parsedUrl.origin;

      this.rId = this.route.snapshot.queryParams['q'];      
      this.uId = this.route.snapshot.queryParams['u'];
      if(this.rId == undefined || this.rId == null){
        this.rId = this.route.snapshot.paramMap.get('q')??"";
        this.uId = this.route.snapshot.paramMap.get('u')??undefined;
      }

      if(this.rId == undefined || this.rId == null || this.rId == ""){
        this.rId = moment(new Date()).format("YYYYMMDDHHmmssSSSSSS");
      }

    this.maxDate = moment(this.today).format('YYYY-MM-DD');

    this.loginForm = this.fb.group({
      "PhoneNumber": ['', Validators.required],
      "Password": ['', [Validators.required]],
    });
    
    let healthObjStr = this.generalService.getDataToLocalStorage("healthObj");
    if(healthObjStr != null && healthObjStr != undefined && healthObjStr != ''){
      let healthObj = JSON.parse(healthObjStr);
      this.quoteData = healthObj.quoteData;
      this.healthData = healthObj.healthData;
      this.planList = healthObj.planList;
    }

    this.sortQuoteList =[
      {value:"",text:'Sort By'},
      {value:"sort_i",text:'Sum Insured high to low'},
      {value:"sort_c",text:'Claim Settled high to low'}, 
      {value:"sort_p",text:'Premium low to high'}
    ];
    if(this.isAgent){
      this.sortQuoteList.push({value:"sort_ac",text:'Points high to low'});
    }
  }

  setData(data:any){
    console.log(data);
  }
  ngOnInit() {
    //#region  Added By Dinesh B
    this.premiumRequestForm = this.fb.group({
      "noOfPersons": [""],
      "noOfAdults": [""],
      "noOfChilds": [""],
      "planType": [""],
      "sumInsured": [""],
      "city": [""],
      "state": [],
      "policyDuration":["1"]
    });
    this.quoteData.policyDuration = this.quoteData.policyDuration??"1";
    this.quoteData.planType = this.quoteData.planType??"";
    let ls = this.quoteData.policyDuration;
    let dd = this.quoteData.planType;
    this.premiumRequestForm.patchValue(this.quoteData);

    if(this.planList?.length >0){
      this.groupQuotesByInsuranceCompany();
    }
    else{
      this.getHealthQuotes();
    }             

    //#endregion 

    // if (this.healthData.spouseSelected) {
    //   this.insurerForm.get('spouseFirstName')?.setValidators([Validators.required]);
    //   this.insurerForm.get('spouseLastName')?.setValidators([Validators.required]);
    //   this.insurerForm.get('spouseDOB')?.setValidators([Validators.required]);
    //   this.insurerForm.get('spouseOccupation')?.setValidators([Validators.required]);
    //   this.insurerForm.get('spouseHeightFeet')?.setValidators([Validators.required]);
    //   this.insurerForm.get('spouseHeightInches')?.setValidators([Validators.required]);
    //   this.insurerForm.get('spouseWeight')?.setValidators([Validators.required]);
    // }

    // if (this.healthData.firstSon) {
    //   this.insurerForm.get('son1FirstName')?.setValidators([Validators.required]);
    //   this.insurerForm.get('son1LastName')?.setValidators([Validators.required]);
    //   this.insurerForm.get('son1DOB')?.setValidators([Validators.required]);
    //   this.insurerForm.get('son1Occupation')?.setValidators([Validators.required]);
    //   this.insurerForm.get('son1HeightFeet')?.setValidators([Validators.required]);
    //   this.insurerForm.get('son1HeightInches')?.setValidators([Validators.required]);
    //   this.insurerForm.get('son1Weight')?.setValidators([Validators.required]);
    // }
    // if (this.healthData.secondSon) {
    //   this.insurerForm.get('son2FirstName')?.setValidators([Validators.required]);
    //   this.insurerForm.get('son2LastName')?.setValidators([Validators.required]);
    //   this.insurerForm.get('son2DOB')?.setValidators([Validators.required]);
    //   this.insurerForm.get('son2Occupation')?.setValidators([Validators.required]);
    //   this.insurerForm.get('son2HeightFeet')?.setValidators([Validators.required]);
    //   this.insurerForm.get('son2HeightInches')?.setValidators([Validators.required]);
    //   this.insurerForm.get('son2Weight')?.setValidators([Validators.required]);
    // }

    // if (this.healthData.thirdSon) {
    //   this.insurerForm.get('son3FirstName')?.setValidators([Validators.required]);
    //   this.insurerForm.get('son3LastName')?.setValidators([Validators.required]);
    //   this.insurerForm.get('son3DOB')?.setValidators([Validators.required]);
    //   this.insurerForm.get('son3Occupation')?.setValidators([Validators.required]);
    //   this.insurerForm.get('son3HeightFeet')?.setValidators([Validators.required]);
    //   this.insurerForm.get('son3HeightInches')?.setValidators([Validators.required]);
    //   this.insurerForm.get('son3Weight')?.setValidators([Validators.required]);
    // }

    // if (this.healthData.fourthSon) {
    //   this.insurerForm.get('son4FirstName')?.setValidators([Validators.required]);
    //   this.insurerForm.get('son4LastName')?.setValidators([Validators.required]);
    //   this.insurerForm.get('son4DOB')?.setValidators([Validators.required]);
    //   this.insurerForm.get('son4Occupation')?.setValidators([Validators.required]);
    //   this.insurerForm.get('son4HeightFeet')?.setValidators([Validators.required]);
    //   this.insurerForm.get('son4HeightInches')?.setValidators([Validators.required]);
    //   this.insurerForm.get('son4Weight')?.setValidators([Validators.required]);
    // }
    // if (this.healthData.firstdaughter) {
    //   this.insurerForm.get('dau1FirstName')?.setValidators([Validators.required]);
    //   this.insurerForm.get('dau1LastName')?.setValidators([Validators.required]);
    //   this.insurerForm.get('dau1DOB')?.setValidators([Validators.required]);
    //   this.insurerForm.get('dau1Occupation')?.setValidators([Validators.required]);
    //   this.insurerForm.get('dau1HeightFeet')?.setValidators([Validators.required]);
    //   this.insurerForm.get('dau1HeightInches')?.setValidators([Validators.required]);
    //   this.insurerForm.get('dau1Weight')?.setValidators([Validators.required]);
    // }

    // if (this.healthData.secondDaughter) {
    //   this.insurerForm.get('dau2FirstName')?.setValidators([Validators.required]);
    //   this.insurerForm.get('dau2LastName')?.setValidators([Validators.required]);
    //   this.insurerForm.get('dau2DOB')?.setValidators([Validators.required]);
    //   this.insurerForm.get('dau2Occupation')?.setValidators([Validators.required]);
    //   this.insurerForm.get('dau2HeightFeet')?.setValidators([Validators.required]);
    //   this.insurerForm.get('dau2HeightInches')?.setValidators([Validators.required]);
    //   this.insurerForm.get('dau2Weight')?.setValidators([Validators.required]);
    // }
    // if (this.healthData.thirdDaughter) {
    //   this.insurerForm.get('dau3FirstName')?.setValidators([Validators.required]);
    //   this.insurerForm.get('dau3LastName')?.setValidators([Validators.required]);
    //   this.insurerForm.get('dau3DOB')?.setValidators([Validators.required]);
    //   this.insurerForm.get('dau3Occupation')?.setValidators([Validators.required]);
    //   this.insurerForm.get('dau3HeightFeet')?.setValidators([Validators.required]);
    //   this.insurerForm.get('dau3HeightInches')?.setValidators([Validators.required]);
    //   this.insurerForm.get('dau3Weight')?.setValidators([Validators.required]);
    // }
    // if (this.healthData.fourthDaughter) {
    //   this.insurerForm.get('dau4FirstName')?.setValidators([Validators.required]);
    //   this.insurerForm.get('dau4LastName')?.setValidators([Validators.required]);
    //   this.insurerForm.get('dau4DOB')?.setValidators([Validators.required]);
    //   this.insurerForm.get('dau4Occupation')?.setValidators([Validators.required]);
    //   this.insurerForm.get('dau4HeightFeet')?.setValidators([Validators.required]);
    //   this.insurerForm.get('dau4HeightInches')?.setValidators([Validators.required]);
    //   this.insurerForm.get('dau4Weight')?.setValidators([Validators.required]);
    // }
    // if (this.healthData.fatherSelected) {
    //   this.insurerForm.get('fatherFirstName')?.setValidators([Validators.required]);
    //   this.insurerForm.get('fatherLastName')?.setValidators([Validators.required]);
    //   this.insurerForm.get('fatherDOB')?.setValidators([Validators.required]);
    //   this.insurerForm.get('FatherOccupation')?.setValidators([Validators.required]);
    //   this.insurerForm.get('fatherHeightFeet')?.setValidators([Validators.required]);
    //   this.insurerForm.get('fatherHeightInches')?.setValidators([Validators.required]);
    //   this.insurerForm.get('fatherWeight')?.setValidators([Validators.required]);
    // }
    // if (this.healthData.motherSelected) {
    //   this.insurerForm.get('motherFirstName')?.setValidators([Validators.required]);
    //   this.insurerForm.get('motherLastName')?.setValidators([Validators.required]);
    //   this.insurerForm.get('motherDOB')?.setValidators([Validators.required]);
    //   this.insurerForm.get('MotherOccupation')?.setValidators([Validators.required]);
    //   this.insurerForm.get('motherHeightFeet')?.setValidators([Validators.required]);
    //   this.insurerForm.get('motherHeightInches')?.setValidators([Validators.required]);
    //   this.insurerForm.get('motherWeight')?.setValidators([Validators.required]);
    // }
    
    // if (this.healthData.grandPaSelected) {
    //   this.insurerForm.get('grandPaFirstName')?.setValidators([Validators.required]);
    //   this.insurerForm.get('grandPaLastName')?.setValidators([Validators.required]);
    //   this.insurerForm.get('grandPaDOB')?.setValidators([Validators.required]);
    //   this.insurerForm.get('grandPaOccupation')?.setValidators([Validators.required]);
    //   this.insurerForm.get('grandPaHeightFeet')?.setValidators([Validators.required]);
    //   this.insurerForm.get('grandPaHeightInches')?.setValidators([Validators.required]);
    //   this.insurerForm.get('grandPaWeight')?.setValidators([Validators.required]);
    // }
    
    // if (this.healthData.grandMaSelected) {
    //   this.insurerForm.get('grandMaFirstName')?.setValidators([Validators.required]);
    //   this.insurerForm.get('grandMaLastName')?.setValidators([Validators.required]);
    //   this.insurerForm.get('grandMaDOB')?.setValidators([Validators.required]);
    //   this.insurerForm.get('grandMaOccupation')?.setValidators([Validators.required]);
    //   this.insurerForm.get('grandMaHeightFeet')?.setValidators([Validators.required]);
    //   this.insurerForm.get('grandMaHeightInches')?.setValidators([Validators.required]);
    //   this.insurerForm.get('grandMaWeight')?.setValidators([Validators.required]);
    // }
    // if (this.healthData.fatherLawSelected) {
    //   this.insurerForm.get('fatherLawFirstName')?.setValidators([Validators.required]);
    //   this.insurerForm.get('fatherLawLastName')?.setValidators([Validators.required]);
    //   this.insurerForm.get('fatherLawDOB')?.setValidators([Validators.required]);
    //   this.insurerForm.get('fatherLawOccupation')?.setValidators([Validators.required]);
    //   this.insurerForm.get('fatherLawHeightFeet')?.setValidators([Validators.required]);
    //   this.insurerForm.get('fatherLawHeightInches')?.setValidators([Validators.required]);
    //   this.insurerForm.get('fatherLawWeight')?.setValidators([Validators.required]);
    // }
    // if (this.healthData.MotherLawSelected) {
    //   this.insurerForm.get('motherLawFirstName')?.setValidators([Validators.required]);
    //   this.insurerForm.get('motherLawLastName')?.setValidators([Validators.required]);
    //   this.insurerForm.get('motherLawDOB')?.setValidators([Validators.required]);
    //   this.insurerForm.get('motherLawOccupation')?.setValidators([Validators.required]);
    //   this.insurerForm.get('motherLawHeightFeet')?.setValidators([Validators.required]);
    //   this.insurerForm.get('motherLawHeightInches')?.setValidators([Validators.required]);
    //   this.insurerForm.get('motherLawWeight')?.setValidators([Validators.required]);
    // }

    // if (this.healthData.uncleSelected) {
    //   this.insurerForm.get('uncleFirstName')?.setValidators([Validators.required]);
    //   this.insurerForm.get('uncleLastName')?.setValidators([Validators.required]);
    //   this.insurerForm.get('uncleDOB')?.setValidators([Validators.required]);
    //   this.insurerForm.get('uncleOccupation')?.setValidators([Validators.required]);
    //   this.insurerForm.get('uncleHeightFeet')?.setValidators([Validators.required]);
    //   this.insurerForm.get('uncleHeightInches')?.setValidators([Validators.required]);
    //   this.insurerForm.get('uncleWeight')?.setValidators([Validators.required]);
    // }
    // if (this.healthData.auntSelected) {
    //   this.insurerForm.get('auntFirstName')?.setValidators([Validators.required]);
    //   this.insurerForm.get('auntLastName')?.setValidators([Validators.required]);
    //   this.insurerForm.get('auntDOB')?.setValidators([Validators.required]);
    //   this.insurerForm.get('auntOccupation')?.setValidators([Validators.required]);
    //   this.insurerForm.get('aunteHeightFeet')?.setValidators([Validators.required]);
    //   this.insurerForm.get('aunteHeightInches')?.setValidators([Validators.required]);
    //   this.insurerForm.get('aunteWeight')?.setValidators([Validators.required]);
    // }
    // this.vehicleForm = this.fb.group({
    //   "city": [""],
    //   "rTO": [""],
    //   "make": [""],
    //   "model": [""],
    //   "variant": [""],
    //   "registrationYear": [""],
    //   "registrationNumber": ["", [Validators.required, Validators.pattern('^[A-Za-z]{2}[0-9]{2}[A-Za-z0-9]{2}[0-9]{4}$')]],
    //   "chasisNumber": ["", [Validators.required]],
    //   "engineNumber": ["", [Validators.required]],
    //   "registrationDate": ["", [Validators.required]],
    //   "claimsMadeInPreviousPolicy": ["No"],
    //   "previousPolicyExpiryDate": ["", [Validators.required]],
    //   "ncbprevious": [""],
    //   "typeOfCover": [""],
    //   "vehicleIdv": [""],
    //   "previousPolicyCompany": ["", [Validators.required]],
    //   "previousPolicyNumber": ["", [Validators.required]],
    //   "ncbCurrent": [""],
    //   "firstName": [""],
    //   "lastName": [""],
    //   "vehiclecategory": [""],
    //   "isPreviousPolicyHolder": [""],
    //   "previousPolicyType": ["", [Validators.required]],
    //   "fuelType": [""],
    //   "engineCapacityAmount": [""],
    //   "PACover": [""],
    //   "RegisteredInTheNameOf": [""],
    //   "isValidDrivingLicenseAvailable": [""],
    //   "premium": [""],
    //   "isFinanced": [""],
    //   "financedValue": [""],
    //   "financierName": [""],
    // })

    // this.loadRelationships();
    // this.userForm.get("firstName")?.setValue(this.ownerDetails?.firstName);
    //   this.userForm.get("lastName")?.setValue(this.ownerDetails?.lastName);
    //   if( sessionStorage.getItem('uniqueuserid')){
    //     this.isUserLogged = true;
    //   }
    //   this.loadInsurancePartners();

    //  this.getStatesByCountry('India');
  }

  async pincodeUserChangeEvent(pincode: any) {
    this.addressInfo.get("proposerState")?.patchValue("");
    this.addressInfo.get("proposerCity")?.patchValue("");
    // this.proposerForm.get("proposerPinCode")
    const res = await this._lookupService.pincodeMasterDetails(pincode.value);
    if (res != null && res.result) {
      this.pincodeMaster = res.result;
      this.addressInfo.get("proposerState")?.patchValue(this.pincodeMaster[0].state);
      this.addressInfo.get("proposerCity")?.patchValue(this.pincodeMaster[0].district);

      // this.pincodeMasterId = this.pincodeMaster?.id;
    }
  }
  quoteId: string = "";
  async getHealthQuotes() {
    this.showLoader = true;
    this.isQuoteFound = true;
    this.planList = [];
    this.groupedQuotesArray = [];
    this.spinner.show();
    // 4	Health Individual
    // 5	Health Family
    this.quoteData.vehicleType = 5;
    this.quoteData.isShared = false;
    this.quoteData.isAgent = this.isAgent;
    this.quoteData.requestId = this.rId;
    this.quoteData.sumInsured = this.premiumRequestFormControl['sumInsured'].value;
    let response = await this.insuranceService.SaveHealthQuoteRequest(this.quoteData);
    if (response?.isSuccess && response?.result?.code == 1) {      
      this.getQuotesAsync("",false);
    }
    else{
      this.isQuoteFound = false;
    }
  }

  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode >= 48 && charCode <= 57) {
      return true;
    }
    return false;
  }

  alphabetOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122) || charCode == 32) {
      return true;
    }
    return false;
  }

  alphaNumericOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode >= 48 && charCode <= 57) || ((charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122))) {
      return true;
    }
    return false;
  }
  // Details(name:string):void{
  //   if(name == 'members' && this.detail =='proposer')
  //   {
  //     this.submitted = true;
  //     if(this.userFormControl['firstName'].valid && 
  //     this.userFormControl['phoneNumber'].valid && this.userFormControl['email'].valid &&
  //     this.userFormControl['panNumber'].valid  )
  //     {
  //       this.detail=name;
  //     }
  //   }
  //   else if(name == 'medical' && this.detail =='members')
  //   {
  //     this.submittedAddress = true;
  //     this.submittedNominee = true;
  //     // if(this.nomineeFormControl['spouseName'].valid )
  //     // {
  //     //   this.detail=name;
  //     // }
  //     this.detail=name;
  //   }
  //   // else if(name == 'nominee' && this.detail =='nominee')
  //   // {
  //   //   this.submittedNominee = true;
  //   //   // if(this.nomineeForm.valid)
  //   //   // {
  //   //   //   this.detail=name;
  //   //   // }
  //   // }

  // }


  // get vehicleFormControl() {
  //   return this.vehicleForm.controls;
  // }

  async loadInsurancePartners() {
    // let response = await this._lookupService.GetInsurancePartnersList();
    //   if (response?.isSuccess) {
    //     this.insurancePartnersList = response.result.map((m:any)=> {return {text:m.insuranceName,value:m.insuranceName,id:m.insuranceName}});

    //   }
  }

  // onPrevCompSelection(data: AutoCompleteItem) {
  //   this.vehicleForm.get('previousPolicyCompany')?.setValue(data.text);
  // }

  // onPrevCompBlur(data: any) {
  //   this.vehicleForm.get('previousPolicyCompany')?.setValue(data);
  // }

  activeTabs: number = 1;
  insurancePlan(val: any) {
    if (val == 'plan1') {
      this.activeTabs = 1;
    }
    if (val == 'plan2') {
      this.activeTabs = 2;
    }
    if (val == 'plan3') {
      this.activeTabs = 3;
    }
  }
  mobileVerificationDiv: boolean = false;
  isInsurancePlan: boolean = false;
  isviewbreakupplan: boolean = false;
  isviewFeatures: boolean = false;

  buynow(quote:HealtQuoteResponseModel) {    
    if (this.generalService.getAccessToken() != "") {
      this.proceedToGenerateProposal(quote);
    }
    else {
      const initialState: any = {
        list: [
          {
            "quote": quote
          }
        ]
      };
      let config = {
        animated: false,
        initialState : initialState
      };            
      this.modalRef = this.modalService.show(QuotesLoginComponent,config);
      this.modalRef.content.action.subscribe(async (data: any) => {
        if (data) {
          this.modalRef.hide();
          this.proceedToGenerateProposal(quote);
        }
      });
    }
  }

  proceedToGenerateProposal(quote:any){
    this.quoteObj = quote;
    let healthObj:any ={};

    let healthObjStr = this.generalService.getDataToLocalStorage("healthObj");
    if(healthObjStr != null && healthObjStr != undefined && healthObjStr != ''){
      healthObj = JSON.parse(healthObjStr);
      healthObj.quoteData = this.quoteData;
      healthObj.healthData = this.healthData;
      healthObj.selectedQuoteData = quote;
      healthObj.planList= this.planList;
    }
    else
    {
      healthObj ={
        quoteData : this.quoteData,
        healthData : this.healthData,
        proposerForm : "",
        addressInfo : "",
        nomineeForm : "",
        selectedQuoteData :quote,
        planList : this.planList
      }; 
    }
    this.generalService.setDataToLocalStorage("healthObj",JSON.stringify(healthObj));
    this.router.navigate(['/healthOwnerDetails',{q:this.rId}]);
  }

  closeModal() {
    this.mobileVerificationDiv = false;
    this.isviewbreakupplan = false;
    this.isviewFeatures = false;
  }
  viewBreakupPlan(quote:HealtQuoteResponseModel ) {
    this.isviewbreakupplan = true;
    this.quoteObj = quote;
  }
  loginForm: FormGroup;
  proposerForm: FormGroup;
  //insurerForm: FormGroup;
  AlertMessage: string = '';
  passwordShow: boolean = false;
  IsAlert: boolean = false;
  public passwordType: string = PasswordType.pwd;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  separateDialCode = true;
  ServicesDown: boolean = false;
  signSubject: Subject<any> = new Subject<any>();
  list: any = [];
  premiumRequestForm: FormGroup;


  async StatusCheck() {
    this.spinner.show();
    try {
      const response = await this.loginService.StatusCheck();
      if (response === ``) {
        swal.fire({
          position: 'center',
          icon: 'error',
          title: `Website currently down, apologize for the inconvenience.`,
          showConfirmButton: false,
          timer: 3000,
        });
        this.ServicesDown = true;
      }

    }
    catch (error) {
      console.log(error);
    }
    this.spinner.hide();
  }

  // async submit() {
  //   try {
  //     this.spinner.show();

  //     if (this.loginForm.get('PhoneNumber')?.value != null && typeof (this.loginForm.get('PhoneNumber')?.value) == 'object') {
  //       let phone = this.loginForm.get('PhoneNumber')?.value;
  //       this.loginForm.get('PhoneNumber')?.setValue(phone.e164Number.replace(phone.dialCode, ''));
  //     }

  //     const request = { PhoneNumber: this.loginForm.get('PhoneNumber')?.value, Password: window.btoa(this.loginForm.get('Password')?.value), OTP_FOR: "CUST" }
  //     const res = await this.loginService.AuthenticateUser(request);

  //     if (res?.isSuccess) {
  //       this.mobileVerificationDiv = false;
  //       this.isInsurancePlan = true;
  //       let data = res.result;
	// 	    this.generalService.setIsAgent(data.agentId > 0 ? 'true' : 'false');
  //       this.generalService.setAccessToken(data.accessToken);
  //       this.generalService.setUniqueuserid(data.uniqueUserId);
  //       this.generalService.setEmployeeFlag(data.employee);
  //       await this.getDistributorSettings();
  //       //this.router.navigate(['/dashboard']);
  //       ///if(this.list[0].vehicleData.vehiclecategory =="2WN")
  //       //  this.router.navigate(['bike']);
  //       //  else
  //       //  this.router.navigate(['car']);
  //       sessionStorage.setItem('quotes_data', JSON.stringify(this.list[0].quote));
  //       sessionStorage.setItem('vehicle_data', JSON.stringify(this.list[0].vehicleData));
  //       sessionStorage.setItem('IsMockup', JSON.stringify(this.list[0].IsMockup));
  //       ;
  //       //this.navbarComponent.isUserLogged = true;
  //       //this.headerMenuComponent.isUserLogged = true;
  //       this.action.emit(true);

  //       this.modalRef.hide();
  //       this.mobileVerificationDiv = false;
  //       this.isInsurancePlan = true;
  //     } else {
  //       swal.fire({
  //         position: 'center',
  //         icon: 'error',
  //         title: res?.message,
  //         showConfirmButton: false,
  //         timer: 3000,
  //       });
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     this.spinner.hide();
  //   }
  // }


  async getDistributorSettings() {
    const response = await this._menuservice.GetDistributorSettings();
    if (response?.isSuccess) {
      sessionStorage.setItem('distributor_settings', response.result);
    }
  }
  ShowPassword() {
    if (!this.passwordShow) {
      this.passwordType = PasswordType.txt;
      this.passwordShow = true;
    }
    else {
      this.passwordType = PasswordType.pwd;
      this.passwordShow = false;
    }
  }
  onCountryChangeNew(e: any) {
    this.loginForm.get('phoneNumber')?.setValue("");
  }

  // closeModal() {
  //   this.modalRef.hide();
  // }

  signUp: string = 'quote-signUp';

  quoteSignup() {
    localStorage.setItem('routeName', this.signUp);
  }

  confirmdetails() {
    // this.dataSubject.next();

  }
  showIDVTooltip: boolean = false;
  minIdv: number = 0;
  addOnnsItems: Item[] = [];
  selectedAddOnIds?: any[] = [];

  sort_quote: string = "";
  quoteList: any = [];
  quoteListTP: any = [];
  quoteListTP1: any = [];
  policyFor: string = "Individual";

  sortQuoteList:any = [
    { value: "", text: 'Sort By' },
    { value: "sort_i", text: 'IDV high to low' },
    { value: "sort_c", text: 'Claim Settled high to low' },
    { value: "sort_p", text: 'Premium low to high' }
  ];

  sortQuote(){
    if(this.sort_quote == "sort_i")
    {
      this.planList = this.planList.sort((a:any,b:any)=> parseFloat(b.sumInsurred??"0") > parseFloat(a.sumInsurred??"0") ? 1 : -1);
    }
    else if(this.sort_quote == "sort_c")
    {
      this.planList = this.planList.sort((a:any,b:any)=> parseFloat(b.claimSettlementRatio??"0") > parseFloat(a.claimSettlementRatio??"0") ? 1 : -1);
    }
    else if(this.sort_quote == "sort_p")
    {
      this.planList = this.planList.sort((a:any,b:any)=> parseFloat(a.grossPremium??"0") > parseFloat(b.grossPremium??"0") ? 1 : -1);
    }
    else if(this.sort_quote == "sort_ac")
    {
      this.planList = this.planList.sort((a:any,b:any)=> parseFloat(b.agentCommission??"0") > parseFloat(a.agentCommission??"0") ? 1 : -1);
    }
    this.groupQuotesByInsuranceCompany();
  }

  openIDVModal() {
    this.showIDVTooltip = true;
  }
  onAddOnnChange(event: any) {
    let selectedAddOnn = event.target.value;
    if (event.target.checked) {
      if (this.selectedAddOnIds?.includes(selectedAddOnn) == false) {
        this.selectedAddOnIds.push(selectedAddOnn)
      }
    }
    else {
      if (this.selectedAddOnIds?.includes(selectedAddOnn)) {
        this.selectedAddOnIds = this.selectedAddOnIds.filter((f: any) => { return f != selectedAddOnn });
      }
    }
  }
  reCalculateQuote() {
    this.rId = moment(new Date()).format("YYYYMMDDHHmmssSSSSSS");
    this.getHealthQuotes() ;
  }
  goTo() {
    // let healthObj = {};
    // let healthObjStr = this.generalService.getDataToLocalStorage("healthObj");
    // if(healthObjStr != null && healthObjStr != undefined && healthObjStr != ''){
    //   let healthObj = JSON.parse(healthObjStr);
    //   healthObj.quoteData = "";
    //   healthObj.healthData = "";
    //   healthObj.planList =[];
    // }
    // else
    // {
    //   healthObj ={
    //     quoteData : "",
    //     healthData : "",
    //     proposerForm : "",
    //     addressInfo : "",
    //     nomineeForm : "",
    //     selectedQuoteData :""
    //   }; 
    // }
    // this.generalService.setDataToLocalStorage("healthObj",JSON.stringify(healthObj));
    this.generalService.removeDataFromLocalStorage("healthObj");
    this.router.navigate(['/']);
    // if(this.uId != null && this.uId != undefined && this.uId != ''){
    //   this.router.navigate(['/',{q:this.rId,u:this.uId}]);
    // }
    // else
    // {
    //   this.router.navigate(['/',{q:this.rId}]);
    // }
  }
  isPopupFormVisible: boolean = false;
  cutomerDetail: boolean = false;

  openPopupForm() {
    this.isPopupFormVisible = true;
  }

  closePopupForm() {
    this.isPopupFormVisible = false;
  }

  updateVehicleDetailsForm(data: any) {


    {
      //this.insuranceformData.vehicleDetails.=data.varient;
      this.isPopupFormVisible = false;
    }

    // .vehicleDetails.city  = 
    // data.manufacture
    // data.model
    // data.rto
    // data.varient
    // data.year
  }
 
  viewFeatures() {
    this.isviewFeatures = true;
  }
  iscoveragefeatures: boolean = true;
  iswaitingperiodfeatures: boolean = false;
  isadditionalfeatures: boolean = false;
  isvalueaddedfeatures: boolean = false;
  iscashlesshospitals = false;
  isdocumentsupload = false;
  tab1: boolean = true;
  tab2: boolean = false;
  tab3: boolean = false;
  tab4: boolean = false;
  tab5: boolean = false;
  tab6: boolean = false;

  featuresList(val: any) {
    if (val == 'coverage') {
      this.iscoveragefeatures = true;
      this.iswaitingperiodfeatures = false;
      this.isadditionalfeatures = false;
      this.isvalueaddedfeatures = false;
      this.iscashlesshospitals = false;
      this.isdocumentsupload = false;
      this.tab1 = true;
      this.tab2 = false;
      this.tab3 = false;
      this.tab4 = false;
      this.tab5 = false;
      this.tab6 = false;
    }
    if (val == 'waiting period') {
      this.iscoveragefeatures = false;
      this.iswaitingperiodfeatures = true;
      this.isadditionalfeatures = false;
      this.isvalueaddedfeatures = false;
      this.iscashlesshospitals = false;
      this.isdocumentsupload = false;
      this.tab1 = false;
      this.tab2 = true;
      this.tab3 = false;
      this.tab4 = false;
      this.tab5 = false;
      this.tab6 = false;
    }
    if (val == 'Additional Features') {
      this.iscoveragefeatures = false;
      this.iswaitingperiodfeatures = false;
      this.isadditionalfeatures = true;
      this.isvalueaddedfeatures = false;
      this.iscashlesshospitals = false;
      this.isdocumentsupload = false;
      this.tab1 = false;
      this.tab2 = false;
      this.tab3 = true;
      this.tab4 = false;
      this.tab5 = false;
      this.tab6 = false;
    }
    if (val == 'Value Added Service') {
      this.iscoveragefeatures = false;
      this.iswaitingperiodfeatures = false;
      this.isadditionalfeatures = false;
      this.isvalueaddedfeatures = true;
      this.iscashlesshospitals = false;
      this.isdocumentsupload = false;
      this.tab1 = false;
      this.tab2 = false;
      this.tab3 = false;
      this.tab4 = true;
      this.tab5 = false;
      this.tab6 = false;
    }
    if (val == 'Cashless Hospitals') {
      this.iscoveragefeatures = false;
      this.iswaitingperiodfeatures = false;
      this.isadditionalfeatures = false;
      this.isvalueaddedfeatures = false;
      this.iscashlesshospitals = true;
      this.isdocumentsupload = false;
      this.tab1 = false;
      this.tab2 = false;
      this.tab3 = false;
      this.tab4 = false;
      this.tab5 = true;
      this.tab6 = false;
    }
    if (val == 'Documents Upload') {
      this.iscoveragefeatures = false;
      this.iswaitingperiodfeatures = false;
      this.isadditionalfeatures = false;
      this.isvalueaddedfeatures = false;
      this.iscashlesshospitals = false;
      this.isdocumentsupload = true;
      this.tab1 = false;
      this.tab2 = false;
      this.tab3 = false;
      this.tab4 = false;
      this.tab5 = false;
      this.tab6 = true;
    }
  }

  openItemIndex = -1;

  toggleAnswer(index: number): void {
    if (this.openItemIndex === index) {
      this.openItemIndex = -1; // Clicking the open item again should close it
    } else {
      this.openItemIndex = index; // Open the clicked item
    }
  }
  selectCard: boolean = false;
  selectCard1: boolean = false;
  selectCard2: boolean = false;
  selectCard3: boolean = false;
  selectCard4: boolean = false;
  selectCard5: boolean = false;
  selectCard6: boolean = false;
  selectCard7: boolean = false;
  selectCard8: boolean = false;
  selectCard9: boolean = false;
  selectCard10: boolean = false;
  selectCard22: boolean = false;
  selectCard23: boolean = false;
  selectedCard(val: any) {
    if (val == 'selectCard1') {
      this.selectCard1 = !this.selectCard1;

    }
    if (val == 'selectCard2') {
      this.selectCard2 = !this.selectCard2;
    }
    if (val == 'selectCard3') {
      this.selectCard3 = !this.selectCard3;
    }
    if (val == 'selectCard4') {
      this.selectCard4 = !this.selectCard4;
    }
    if (val == 'selectCard5') {
      this.selectCard5 = !this.selectCard5;
    }
    if (val == 'selectCard6') {
      this.selectCard6 = !this.selectCard6;
    }
    if (val == 'selectCard7') {
      this.selectCard7 = !this.selectCard7;
    }
    if (val == 'selectCard8') {
      this.selectCard8 = !this.selectCard8;
    }
    if (val == 'selectCard9') {
      this.selectCard9 = !this.selectCard9;
    }
    if (val == 'person Ten') {
      this.selectCard10 = !this.selectCard10;
    }
    if (val == 'Self') {
      this.selectCard22 = true;
      this.selectCard23 = false;
    }
    if (val == 'Spouse') {
      this.selectCard23 = true;
      this.selectCard22 = false;
    }
  }
  Up: boolean = false;
  Up1: boolean = false;
  Up2: boolean = false;
  Up3: boolean = false;
  down: boolean = true;
  down1: boolean = true;
  down2: boolean = true;
  down3: boolean = true;
  chUp: boolean = false;
  chDw: boolean = true;

  chilVal: string = '';
  value: string = '';
  dropDown(val: string) {
    this.value = val;
    if (this.value == 'down') {
      this.down = false;
      this.Up = true;
    }
    if (this.value == 'up') {
      this.down = true;
      this.Up = false;
    }
    if (this.value == 'down1') {
      this.down1 = false;
      this.Up1 = true;
    }
    if (this.value == 'up1') {
      this.down1 = true;
      this.Up1 = false;
    }


  }
  child: boolean = false;
  ChildDown() {
    this.down2 = false;
    this.Up2 = true;
    this.child = true;

  }
  ChildUp() {
    this.down2 = true;
    this.Up2 = false;
    this.child = false;
  }
  firstPage: boolean = false;
  fisrtDetail() {
    this.firstPage = true;
    alert(this.firstPage);
  }
  
  
  onInputChange(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.value.length > 6) {
      input.value = input.value.slice(0, 6);
      event.stopPropagation();
    }
  }
  onlyNumbers(event: KeyboardEvent) {
    const inputChar = String.fromCharCode(event.charCode);

    if (!/^\d+$/.test(inputChar)) {
      event.preventDefault();
    }
  }

  getQuotesAsync(typeOfCover:string,isTPPD:boolean = false) {
    // if(this.insuranceformData?.vehicleDetails?.typeOfCover != "Standalone" && this.insuranceformData?.vehicleDetails?.show_TP_QuotesOnly != "Yes"){
    //   this.showTPLoader = true;
    // }
    
    this.sseSubscription = this.insuranceService.getHealthQuotesAsync(this.rId,"",false,this.isShared)
      .subscribe({
        next: (message: any) => {
          //this.messages.push(message);
          
          if(message?.quoteId != null && message?.quoteId != undefined && message?.quoteId != null)
          {
            this.planList.push(message);
            if(this.planList?.length > 0)
              this.planList = this.planList.sort((a:any,b:any)=> b.sumInsurred < a.sumInsurred ? 1 : -1);            
            
            this.groupQuotesByInsuranceCompany();
            // var groupedPlanList = {};
            // data.forEach(function (a) {
            //     groupByName [a.first_name] = groupByName [a.first_name] || [];
            //     groupByName [a.first_name].push({ date: a.date, is_present: a.is_present });
            // });

            // console.log(groupByName);
            // if(quoteResult?.typeOfCover == "Comprehensive" || quoteResult?.typeOfCover == "Standalone"){
            //   //this.quoteList.push(quoteResult);
            //   if(this.quoteList?.length == 0 || (this.quoteList?.length > 0 && this.quoteList.filter((a:any)=>{return a.insuranceCompany === quoteResult.insuranceCompany && a.typeOfCover === quoteResult.typeOfCover && a.isTPPD === quoteResult.isTPPD}) == false))
            //     {
            //       this.quoteList.push(quoteResult);
            //     }
            // }
            // else if(quoteResult?.typeOfCover == "LiabilityOnly" && quoteResult?.isTPPD == false){
            //   let fd = this.quoteListTP.map((a:any)=>{return a.insuranceCompany == quoteResult.insuranceCompany && a.typeOfCover == quoteResult.typeOfCover && a.isTPPD == quoteResult.isTPPD });
            //   if(this.quoteListTP?.length == 0 || (this.quoteListTP?.length > 0 && this.quoteListTP.filter((a:any)=>{return a.insuranceCompany === quoteResult.insuranceCompany && a.typeOfCover === quoteResult.typeOfCover && a.isTPPD === quoteResult.isTPPD }) == false))
            //   {
            //     this.quoteListTP.push(quoteResult);
            //   }
            // }
            // else if(quoteResult?.typeOfCover == "LiabilityOnly" && quoteResult?.isTPPD == true){
            //   //this.quoteListTP1.push(quoteResult);
            //   if(this.quoteListTP1?.length == 0 || (this.quoteListTP1?.length > 0 && this.quoteListTP1.filter((a:any)=>{return a.insuranceCompany === quoteResult.insuranceCompany && a.typeOfCover === quoteResult.typeOfCover && a.isTPPD === quoteResult.isTPPD }) == false))
            //     {
            //       this.quoteListTP1.push(quoteResult);
            //     }
            // }
          }
          if(this.isShared){
          //   if(this.insuranceformData?.vehicleDetails?.show_TP_QuotesOnly == "Yes"){
          //     this.activeTab = 2;
          //   }
          //   this.showCompLoader = false;
          //   this.showTPLoader = false;
          //   this.showTPPDLoader = false;
          }
      },
        error: (error) => {
          console.info('Request Completed:', error);
          this.showLoader = false;
          this.isQuoteFound = this.planList.length > 0;
          console.log(this.planList);
          // if(typeOfCover != "LiabilityOnly"){
          //   this.compAfterQouteBound();
          // }
          // else if(typeOfCover == "LiabilityOnly" && isTPPD == true){
          //   this.tpptdAfterQouteBound();
          // }
          // else if(typeOfCover == "LiabilityOnly" && isTPPD == false){
          //   this.tpAfterQouteBound();
          // }
        }
      });
  }
  
  ngOnDestroy(): void {
    if (this.sseSubscription) {
      this.sseSubscription.unsubscribe();
    }
  }

  get premiumRequestFormControl(){
    return this.premiumRequestForm.controls;
  }

  quotes1 = [
    {
      quoteId: 'RSAIIHPC236316',
      sumInsurred: 750000,
      grossPremium: '10100.2',
      insuranceCompany: 2,
      planName: 'Supreme',
    },
    {
      quoteId: 'RSAIIHPC236317',
      sumInsurred: 500000,
      grossPremium: '9776.2',
      insuranceCompany: 2,
      planName: 'Supreme',
    },
    {
      quoteId: 'RSAIIHPC236317',
      sumInsurred: 500000,
      grossPremium: '9776.2',
      insuranceCompany: 3,
      planName: 'Supreme',
    },
    {
      quoteId: 'RSAIIHPC236317',
      sumInsurred: 500000,
      grossPremium: '9776.2',
      insuranceCompany: 3,
      planName: 'Supreme',
    },
  ];

  quotes =[
    {
        "quoteId": "RSAIIHPC236316",
        "sumInsurred": "750000",
        "grossPremium": "10116.2",
        "insuranceCompany": 2,
        "logo": "",
        "claimSettlementRatio": "96",
        "priceBreakup": {
            "otherDiscounts": "0",
            "netPremium": "8284.92",
            "serviceTaxPerc": "0",
            "serviceTaxValue": "1491.28",
            "finalPremium": "10100.2"
        },
        "agentCommission": "0",
        "planName": "Supreme"
    },
    {
        "quoteId": "RSAIIHPC236317",
        "sumInsurred": "500000",
        "grossPremium": "9776.2",
        "insuranceCompany": 2,
        "logo": "",
        "claimSettlementRatio": "92",
        "priceBreakup": {
            "otherDiscounts": "0",
            "netPremium": "8284.92",
            "serviceTaxPerc": "0",
            "serviceTaxValue": "1491.28",
            "finalPremium": "9776.2"
        },
        "agentCommission": "0",
        "planName": "Supreme"
    },
    {
      "quoteId": "RSAIIHPC236318",
      "sumInsurred": "600000",
      "grossPremium": "10118.2",
      "insuranceCompany": 2,
      "logo": "",
      "claimSettlementRatio": "96",
      "priceBreakup": {
          "otherDiscounts": "0",
          "netPremium": "8284.92",
          "serviceTaxPerc": "0",
          "serviceTaxValue": "1491.28",
          "finalPremium": "10100.2"
      },
      "agentCommission": "0",
      "planName": "Supreme"
  },
  {
      "quoteId": "RSAIIHPC236319",
      "sumInsurred": "650000",
      "grossPremium": "9719.2",
      "insuranceCompany": 2,
      "logo": "",
      "claimSettlementRatio": "92",
      "priceBreakup": {
          "otherDiscounts": "0",
          "netPremium": "8284.92",
          "serviceTaxPerc": "0",
          "serviceTaxValue": "1491.28",
          "finalPremium": "9776.2"
      },
      "agentCommission": "0",
      "planName": "Supreme"
  },
  {
    "quoteId": "RSAIIHPC236321",
    "sumInsurred": "750000",
    "grossPremium": "10021.2",
    "insuranceCompany": 3,
    "logo": "",
    "claimSettlementRatio": "96",
    "priceBreakup": {
        "otherDiscounts": "0",
        "netPremium": "8284.92",
        "serviceTaxPerc": "0",
        "serviceTaxValue": "1491.28",
        "finalPremium": "10100.2"
    },
    "agentCommission": "0",
    "planName": "Supreme"
},
{
    "quoteId": "RSAIIHPC236322",
    "sumInsurred": "500000",
    "grossPremium": "10022.2",
    "insuranceCompany": 3,
    "logo": "",
    "claimSettlementRatio": "92",
    "priceBreakup": {
        "otherDiscounts": "0",
        "netPremium": "8284.92",
        "serviceTaxPerc": "0",
        "serviceTaxValue": "1491.28",
        "finalPremium": "9776.2"
    },
    "agentCommission": "0",
    "planName": "Supreme"
},
{
  "quoteId": "RSAIIHPC236323",
  "sumInsurred": "600000",
  "grossPremium": "10023.2",
  "insuranceCompany": 3,
  "logo": "",
  "claimSettlementRatio": "96",
  "priceBreakup": {
      "otherDiscounts": "0",
      "netPremium": "8284.92",
      "serviceTaxPerc": "0",
      "serviceTaxValue": "1491.28",
      "finalPremium": "10100.2"
  },
  "agentCommission": "0",
  "planName": "Supreme"
},
{
  "quoteId": "RSAIIHPC236324",
  "sumInsurred": "650000",
  "grossPremium": "10024.2",
  "insuranceCompany": 3,
  "logo": "",
  "claimSettlementRatio": "92",
  "priceBreakup": {
      "otherDiscounts": "0",
      "netPremium": "8284.92",
      "serviceTaxPerc": "0",
      "serviceTaxValue": "1491.28",
      "finalPremium": "9776.2"
  },
  "agentCommission": "0",
  "planName": "Supreme"
},
];

  groupedQuotesArray: { companyKey: number; quotes: any[];isExpanded:boolean }[] = [];
  expandedPanels: Set<number> = new Set();
  expanded = false;
  expandedPanel:any;
  expandedQuotes: Map<number, string> = new Map(); // Map of companyKey to expanded quoteId


  groupQuotesByInsuranceCompany(): void {
    const groupedQuotes: Record<number, any[]> = this.planList.reduce(
      (acc: Record<number, any[]>, quote) => {
        const company = quote.insuranceCompany??0;
        if (!acc[company]) {
          acc[company] = [];
        }
        acc[company].push(quote);
        return acc;
      },
      {}
    );
    // Convert groupedQuotes object to an array of key-value pairs
    this.groupedQuotesArray = Object.keys(groupedQuotes).map((key) => ({
      companyKey: Number(key),
      quotes: groupedQuotes[Number(key)],
      isExpanded: false
    }));
  }

  togglePlans(insuranceComapny:number){
    this.groupedQuotesArray.forEach((group) => {
      if (group.companyKey == insuranceComapny) {
        group.isExpanded = !group.isExpanded;
      }
    });
  }

  async GetHealthSumInsuredRanges(){
    const res = await this._lookupService.GetHealthSumInsuredRanges();
    if (res != null && res.result) {
      this.SumInsuredList = res.result;
    }
  }
}