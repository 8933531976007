<ng-container *ngIf="required_input_fields && required_input_fields.formFields && required_input_fields.formFields.length > 0">
    <fieldset [disabled]="isReadOnly">
    <div class="my-2 dynamic-field-container" *ngFor="let field of required_input_fields.formFields">
        <label class="mb-0" [ngClass]="{'d-none':field.type == 'checkbox', 'required':field.isRequired}">{{ field.title }}</label>
        <ng-container [ngSwitch]="field.type">
            <ng-container *ngSwitchCase="'radio'">
                <div class="d-flex align-items-center gap-5">
                    <ng-container *ngFor="let option of field.options">
                        <span class="d-flex align-items-center">
                            <input type="radio" class="mt-0 scale-1_3" [id]="field.id + option" [name]="field.name"  [value]="option" [checked]="field.selectedValue ==option ? 'checked' :''" (change)="onChange(field.id??'',$event)"/><label [for]="field.id + option" class="ml-1 mb-0 fw-normal"> {{ option }}</label>
                        </span>
                    </ng-container>
                </div>              
                <div class="px-4 border card my-2" *ngIf="field.cascadeItems && field.cascadeItems.formFields && field.cascadeItems.formFields.length > 0 && field.selectedValue == 'Yes'">
                    <app-dynamic-components [required_input_fields]="field.cascadeItems"></app-dynamic-components>
                </div>
            </ng-container>
            <ng-container *ngSwitchCase="'checkbox'">
                <span class="d-flex align-items-center">
                    <input type="checkbox" class="mt-0 scale-1_3" value="" [checked]="field.selectedValue == 'Yes'" (click)="onChange(field.id??'',$event, field.type, field.id)"><label [for]="field.id" class="ml-1 mb-0 fw-normal"  [ngClass]="{'required':field.isRequired}"> {{ field.title }}</label>
                </span>
                <div class="px-4 border card my-2" *ngIf="field.cascadeItems && field.cascadeItems.formFields && field.cascadeItems.formFields.length > 0 && field.selectedValue == 'Yes'">
                    <app-dynamic-components [required_input_fields]="field.cascadeItems"></app-dynamic-components>
                </div>
            </ng-container>
            <select *ngSwitchCase="'select'" class="form-select" (change)="onChange(field.id??'',$event)" [ngClass]="{'border border-danger': field.isValid == false && field.isRequired && field.selectedValue ==''}">
                <option *ngFor="let option of field.options" [value]="option" [selected]="field.selectedValue ==option ? 'selected' :''" >{{ option }}</option>
            </select>
            <input *ngSwitchCase="'text'" type="text" class="form-control" [value]="field.selectedValue" (blur)="onChange(field.id??'',$event)" [ngClass]="{'border border-danger':field.isValid == false && field.isRequired && field.selectedValue ==''}"/>
            <input *ngSwitchCase="'number'" type="number"  class="form-control"  [value]="field.selectedValue" (blur)="onChange(field.id??'',$event)" [ngClass]="{'border border-danger':field.isValid == false && field.isRequired && field.selectedValue ==''}"/>        
            <textarea *ngSwitchCase="'textarea'" class="form-control" rows="3"  [value]="field.selectedValue" (blur)="onChange(field.id??'',$event)" [ngClass]="{'border border-danger':field.isValid == false && field.isRequired && field.selectedValue ==''}"></textarea>
            <input *ngSwitchCase="'date'" type="date" class="form-control" [value]="field.selectedValue" (blur)="onChange(field.id??'',$event)" [ngClass]="{'border border-danger':field.isValid == false && field.isRequired && field.selectedValue ==''}"/>
            <span class="text-danger" *ngIf="field.isValid == false && field.isRequired && field.selectedValue ==''">
                Field is required
            </span>  
        </ng-container>
      </div>
    </fieldset>  
</ng-container>
