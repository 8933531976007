<app-navbar></app-navbar>
    <div class="container">
        <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#009ef7"  type="ball-spin-clockwise-fade-rotating"></ngx-spinner>
        <ng-container *ngIf="!isPaymentLink">
            <div class="mt-3">
                <div class="">
                    <form #vehical_details="ngForm" class="vehicle-form">
                        <div class="row card m-0 flex-row py-1">
                            <div class="col-auto d-flex align-items-center">
                                <button type="button" class="btn btn-primary px-1 py-1"><img
                                        src="../../assets/svgIcon/ArrowLeft.svg" alt="ArrowLeft"
                                        class="cursor-pointer svg_white edit-icon" (click)="goTo()"></button>
                            </div>
                            <div class="col">
                                <div class="d-flex  details-panel" [ngClass]="(this.quoteData?.noOfPersons??'0') == '2' ? 'justify-content-evenly' :'justify-content-between' ">
                                    <div class="col-auto" *ngIf="healthData.selfSelected">
                                        <label>Self Age</label>
                                        <span>{{healthData.selfAge}}yrs</span>
                                    </div>
                                    <div class="col-auto" *ngIf="healthData.spouseSelected">
                                        <label>Spouse Age</label>
                                        <span>{{healthData.spouseAge}}yrs</span>
                                    </div>
                                    <div class="col-auto" *ngIf="healthData.selectedSonAge1>0">
                                        <label>1st Son Age</label>
                                        <span>{{healthData.selectedSonAge1}}yrs</span>
                                    </div>
                                    <div class="col-auto" *ngIf="healthData.selectedSonAge2>0">
                                        <label>2nd Son Age</label>
                                        <span>{{healthData.selectedSonAge2}}yrs</span>
                                    </div>
                                    <div class="col-auto" *ngIf="healthData.selectedSonAge3>0">
                                        <label>3rd Son Age</label>
                                        <span>{{healthData.selectedSonAge3}}yrs</span>
                                    </div>
                                    <div class="col-auto" *ngIf="healthData.selectedSonAge4>0">
                                        <label>4th Son Age</label>
                                        <span>{{healthData.selectedSonAge4}}yrs</span>
                                    </div>
                                    <div class="col-auto" *ngIf="healthData.selectedSonAge5>0">
                                        <label>5th Son Age</label>
                                        <span>{{healthData.selectedSonAge5}}yrs</span>
                                    </div>
                                    <div class="col-auto" *ngIf="healthData.selectedSonAge6>0">
                                        <label>6th Son Age</label>
                                        <span>{{healthData.selectedSonAge6}}yrs</span>
                                    </div>
                                    <div class="col-auto" *ngIf="healthData.selectedDauAge1>0">
                                        <label>1st Daughter Age</label>
                                        <span>{{healthData.selectedDauAge1}}yrs</span>
                                    </div>
                                    <div class="col-auto" *ngIf="healthData.selectedDauAge2>0">
                                        <label>2nd Daughter Age</label>
                                        <span>{{healthData.selectedDauAge2}}yrs</span>
                                    </div>
                                    <div class="col-auto" *ngIf="healthData.selectedDauAge3>0">
                                        <label>3rd Daughter Age</label>
                                        <span>{{healthData.selectedDauAge3}}yrs</span>
                                    </div>
                                    <div class="col-auto" *ngIf="healthData.selectedDauAge4>0">
                                        <label>4th Daughter Age</label>
                                        <span>{{healthData.selectedDauAge4}}yrs</span>
                                    </div>
                                    <div class="col-auto" *ngIf="healthData.selectedDauAge5>0">
                                        <label>5th Daughter Age</label>
                                        <span>{{healthData.selectedDauAge5}}yrs</span>
                                    </div>
                                    <div class="col-auto" *ngIf="healthData.selectedDauAge6>0">
                                        <label>6th Daughter Age</label>
                                        <span>{{healthData.selectedDauAge6}}yrs</span>
                                    </div>
                                    <div class="col-auto" *ngIf="healthData.fatherSelected">
                                        <label>Father Age</label>
                                        <span>{{healthData.fatherAge}}yrs</span>
                                    </div>
                                    <div class="col-auto" *ngIf="healthData.motherSelected">
                                        <label>Mother Age</label>
                                        <span>{{healthData.motherAge}}yrs</span>
                                    </div>
                                    <div class="col-auto" *ngIf="healthData.grandPaSelected">
                                        <label>Grand Father Age</label>
                                        <span>{{healthData.grandPaAge}}yrs</span>
                                    </div>
                                    <div class="col-auto" *ngIf="healthData.grandMaSelected">
                                        <label>Grand Mother Age</label>
                                        <span>{{healthData.grandMaAge}}yrs</span>
                                    </div>
                                    <div class="col-auto" *ngIf="healthData.fatherLawSelected">
                                        <label>Father-in-Law Age</label>
                                        <span>{{healthData.fatherLawAge}}yrs</span>
                                    </div>
                                    <div class="col-auto" *ngIf="healthData.motherLawSelected">
                                        <label>Mother-in-Law Age</label>
                                        <span>{{healthData.motherLawAge}}yrs</span>
                                    </div>
                                    <div class="col-auto" *ngIf="healthData.uncleSelected">
                                        <label>Uncle Age</label>
                                        <span>{{healthData.uncleAge}}yrs</span>
                                    </div>
                                    <div class="col-auto" *ngIf="healthData.auntSelected">
                                        <label>Aunt Age</label>
                                        <span>{{healthData.auntAge}}yrs</span>
                                    </div>
                                </div>
                            </div>                        
                            <!-- <div class="col buttons_div_save" style="text-align: right;">
                                <button *ngIf="!isPopupFormVisible" type="button" class="btn btn-primary py-1" (click)="openPopupForm()"><img src="../../assets/svgIcon/PencilLineBlack.svg" alt="Edit" class="svg_white edit-icon cursor-pointer"></button>
                                <button *ngIf="isPopupFormVisible" type="button" class="btn btn-primary px-2 py-1" (click)="updateVehicleDetailsForm(vehical_details.value)"><img class="svg_white edit-icon cursor-pointer" src="../../assets/svgIcon/FloppyDisk.svg" alt="Apply"></button>
                                <button *ngIf="isPopupFormVisible" type="button" class="btn btn-warning px-2 py-1 ml-2 btn-active" (click)="closePopupForm()"><img class="svg_white edit-icon cursor-pointer" src="../../assets/svgIcon/close-secondary.svg" alt="Cancel"></button>
                                
                            </div> -->
                        </div>
                    </form>

                </div>
            </div>
            <div class="row mt-3">
                <div class="col-md-4">
                    <div class="card p-3">
                        <div class="row">
                            <div class="col-md-4">
                                <img class="image-stl" src="/assets/images/insurance_companies/{{selectedQuoteData.insuranceCompany}}.png" alt="">
                            </div>
                            <div class="col-md-8 d-flex align-items-center" >
                                <div class="row text-right">
                                    <div class="col-md-12">
                                        <span>Plan : </span>
                                        <label for="">{{this.selectedQuoteData?.planName}}</label>
                                    </div>
                                    <div class="col-md-12">
                                        <span>Sum Insured : </span>
                                        <label for="">{{(this.selectedQuoteData?.sumInsurred??"0") | thousandSeparator}}</label>
                                    </div>
                                    <div class="col-md-12">
                                        <span>Tenure : </span><label for="">{{(this.selectedQuoteData?.policyDuration??"0")}} Year</label>
                                    </div>
                                </div>
                            </div> 
                        </div>     
                        <hr class="premium_breakup my-0">
                        <div class="row">
                            <div class="col-md-12 text-center">                            
                                <h4 class="my-1">Premium Breakup</h4>
                            </div>      
                        </div>    
                        <hr class="premium_breakup my-0">
                        <div class="row">
                            <div class="col-8 mt-2">
                                <span >Net Premium - 1 year</span>
                            </div>
                            <div class="col-4 mt-2 text-right">
                                <h5 >₹ {{(this.selectedQuoteData?.priceBreakup?.netPremium??"0") | thousandSeparator}}</h5>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-8 mt-2">
                                <span >GST(18%)</span>
                            </div>
                            <div class="col-4 mt-2 text-right">
                                <h5 class="text-danger">+ ₹ {{(this.selectedQuoteData?.priceBreakup?.serviceTaxValue??"0") | thousandSeparator}}</h5>
                            </div>
                        </div>
                        <hr class="premium_breakup my-1">
                        <div class="row">
                            <div class="col-8 mt-2">
                                <h5 >Total Premium</h5>
                            </div>
                            <div class="col-4 mt-2 text-right">
                                <h5 >₹ {{(this.selectedQuoteData?.priceBreakup?.finalPremium??"0") | thousandSeparator}}</h5>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2" *ngIf="isSummary">
                        <div class="col-12">
                        <button class="btn btn-primary w-100" (click)="paySecurly()">Generate Proposal</button>
                        </div>
                    </div>
                </div>
                <div class="col-md-8">
                    <ng-container *ngIf="!isSummary">
                        <div class="row">
                            <div class="col-md-12">
                                <ul
                                    class="card-item card d-flex justify-content-between align-items-center mb-0 flex-row p-3 text-center">
                                    <li class="list-item col" [ngClass]="{'active': detail == 'proposer'}">
                                        Proposer
                                    </li>
                                    <li class="list-item col" [ngClass]="{'active': detail == 'address'}">
                                        Address Details
                                    </li>
                                    <li class="list-item  col" [ngClass]="{'active': detail == 'members'}">
                                        Members
                                    </li>
                                    <li class="list-item  col" [ngClass]="{'active': detail == 'nominee'}">
                                        Nominee
                                    </li>
                                    <li class="list-item  col" [ngClass]="{'active': detail == 'medical'}" (click)="detail = 'medical'">
                                        Medical
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="tab-content clearfix mb-5">
                            <div id="proposer" class="col-12 c-pl c-pr" *ngIf="detail == 'proposer'">
                                <ng-container>
                                    <div class="card p-5 row br-top-0">
                                        <div class="details_card">
                                            <form id="kt_account_profile_details_form" class="form"
                                                [formGroup]="proposerForm" novalidate="novalidate">
                                                <div class="row justify-content-between">
                                                    <div class="col-md-6">
                                                        <label for="name" class="required lb">PAN Number</label>
                                                        <div class="d-flex"> 
                                                        <input type="text" class="form-control rounded-right1-0"
                                                            name="pancard" formControlName="proposerPancard"
                                                            placeholder="PAN Number" minlength="10" maxlength="10"
                                                            (keypress)="alphaNumericOnly($event)"
                                                            [ngClass]="{'border border-danger': ((valPropControls['proposerPancard'].touched ) && !valPropControls['proposerPancard'].valid)}">
                                                            <button  disabled="" class="btn btn-primary py-0 px-2 rounded-left1-0 sp" [disabled]="!valPropControls['proposerPancard'].valid" (click)="getPanCardDetails()">
                                                                <img  src="../../assets/svgIcon/fetch.svg" alt="Fetch" class="icon-white" title="Fetch Details">
                                                            </button>
                                                            </div>
                                                            <Span class="text-danger "
                                                            *ngIf="((valPropControls['proposerPancard'].touched) && valPropControls['proposerPancard'].errors?.['required'])">
                                                            PAN Number is required.
                                                        </Span>
                                                        <Span class="text-danger"
                                                            *ngIf="((valPropControls['proposerPancard'].touched  ) && valPropControls['proposerPancard'].errors?.['pattern'])">
                                                            Provide valid PAN number.
                                                        </Span>
                                                    </div>    
                                                    <div class=" col-md-6">
                                                        <label class="required lb" for="dob" >Date of Birth</label>
                                                        <input type="date"  [max]="maxDOB" name="selfDOB"
                                                            formControlName="selfDOB" autocomplete="off"
                                                            class="new-user form-control bg-transparent in-h"
                                                            [ngClass]="{'border border-danger': ((valMemControls['selfDOB'].touched ) && !valMemControls['selfDOB'].valid)}"[(ngModel)]="defaultDate" [max]="maxDOB"/>
                                                        <Span class="text-danger"
                                                            *ngIf="((valMemControls['selfDOB'].touched) && !valMemControls['selfDOB'].valid)">
                                                            Date of birth is required.
                                                        </Span>
                                                    </div>
                                                    <div class="col-md-3 mt-3">
                                                        <label class="required lb">Titile</label>
                                                        <select name="title" id="title" class="form-select form-select-custom"
                                                            formControlName="proposerTitle">
                                                            <option *ngFor="let title of titleList" [value]="title">
                                                                {{title}}
                                                            </option>
                                                        </select> 
                                                    </div>
                                                    <div class="col-md-9">
                                                        <div class="row">
                                                            <div class="mt-3 col-md-6">
                                                                <label for="name" class="lb required">First Name</label>
                                                                <input type="text" class="form-control"
                                                                    name="proposerFirstName" (keypress)="alphabetOnly($event)"
                                                                    formControlName="proposerFirstName" placeholder="First Name"
                                                                    [ngClass]="{'border border-danger': ((valPropControls['proposerFirstName'].touched) && !valPropControls['proposerFirstName'].valid)}">
                                                                <Span class="text-danger"
                                                                    *ngIf="((valPropControls['proposerFirstName'].touched ) && !valPropControls['proposerFirstName'].valid)">
                                                                    First Name is required.
                                                                </Span>
                                                            </div>
                                                            <div class="col-md-6 mt-3">
                                                                <label for="name" class="lb lb required">Last Name </label>
                                                                <input type="text" class="form-control"
                                                                    (keypress)="alphabetOnly($event)" name="proposerLastName"
                                                                    formControlName="proposerLastName" placeholder="Last Name"
                                                                    [ngClass]="{'border border-danger': ((valPropControls['proposerLastName'].touched) && !valPropControls['proposerLastName'].valid)}">
                                                                <Span class="text-danger"
                                                                    *ngIf="((valPropControls['proposerLastName'].touched ) && !valPropControls['proposerLastName'].valid)">
                                                                    Last Name is required.
                                                                </Span>
                                                            </div>
                                                        </div>                                                    
                                                    </div>                                                                                        
                                                    <div class="mt-3 col-md-6">
                                                        <label for="name" class="lb required ">Email Address</label>
                                                        <input type="text" class="form-control" name="email"
                                                            formControlName="proposerEmail" placeholder="User Email Address"
                                                            [ngClass]="{'border border-danger': ((valPropControls['proposerEmail'].touched) && !valPropControls['proposerEmail'].valid)}">
                                                        <Span class="text-danger"
                                                            *ngIf="((valPropControls['proposerEmail'].touched ) && valPropControls['proposerEmail'].errors?.['required'])">
                                                            Email address is required.
                                                        </Span>
                                                        <Span class="text-danger"
                                                            *ngIf="((valPropControls['proposerEmail'].touched ) && valPropControls['proposerEmail'].errors?.['pattern'])">
                                                            Please provide valid email address.
                                                        </Span>
                                                    </div>
                                                    <div class="mt-3 col-md-6">
                                                        <label for="name" class="lb required">Mobile Number</label>
                                                        <input type="text" class="form-control" name="mobile"
                                                            formControlName="proposerMobile"
                                                            placeholder="User Mobile Number" minlength="10" maxlength="10"
                                                            (keypress)="numberOnly($event)"
                                                            [ngClass]="{'border border-danger': ((valPropControls['proposerMobile'].touched ) && !valPropControls['proposerMobile'].valid)}">
                                                        <Span class="text-danger"
                                                            *ngIf="((valPropControls['proposerMobile'].touched) && valPropControls['proposerMobile'].errors?.['required'])">
                                                            Mobile number is required.
                                                        </Span>
                                                        <Span class="text-danger"
                                                            *ngIf="((valPropControls['proposerMobile'].touched) && valPropControls['proposerMobile'].errors?.['pattern'])">
                                                            Provide valid Mobile number.
                                                        </Span>
                                                    </div>
                                                    <!-- <div class="mt-3 col-md-6">
                                                        <label for="name" class="lb required">Marital Status</label>
                                                        <select name="maritalStatus" id="maritalStatus" class="form-select form-select-custom">
                                                            <option *ngFor="let item of maritalStatusList" [value]="item">
                                                                {{item}}
                                                            </option>
                                                        </select>  
                                                    </div> -->
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <!-- [disabled]="proposerForm.invalid" -->
                                        <div class="col-md-12 mt-5  text-end">
                                            <button class="btn btn-primary"
                                                (click)="InsuranceDetails('address')" [disabled]="proposerForm.invalid">Next</button>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                            <div id="address" class="col-12 c-pl c-pr" *ngIf="detail=='address'">
                                <ng-container>
                                    <div class="card p-5 row br-top-0">
                                        <div class="details_card">
                                            <form id="kt_account_profile_details_form" class="form"
                                                [formGroup]="addressInfo" novalidate="novalidate">
                                                <div class="row">
        
                                                    <div class="mt-3 col-md-6">
                                                        <label for="name" class="lb required "
                                                            style="font-weight: 700 !important; font-size: 14px !important;">Flat
                                                            Number/Street Number</label>
                                                        <input type="text" class="form-control in-h" name="flatNumber"
                                                            formControlName="flatNumber"
                                                            placeholder="Flat Number/Street Number">
                                                    </div>
                                                    <div class="mt-3 col-md-6">
                                                        <label for="name" class="lb required"
                                                            style="font-weight: 700 !important; font-size: 14px !important;">Street
                                                            Address</label>
                                                        <input type="text" class="form-control in-h" name="streetAddress"
                                                            formControlName="streetAddress" placeholder="Street Address">
                                                    </div>
                                                    <div class="mt-3 col-md-6">
                                                        <label for="name" class="lb required"
                                                            style="font-weight: 700 !important; font-size: 14px !important;">PIN
                                                            Code</label>
                                                            <app-autocomplete class="w-100" (itemSelected)="onPincodeSelection($event)"
                                                                [items]="pincodeList" placeholder="proposerPinCode" [supportingText]="true"
                                                                [userInput]="valAddressPropControls['proposerPinCode'].value"
                                                                [showError]="((valAddressPropControls['proposerPinCode'].touched  || submittedAddress) && !valAddressPropControls['proposerPinCode'].valid)"
                                                                (onKeyUpEvent)="onPincodeChange($event)"  (input)="onInputChange($event) "  (keypress)="onlyNumbers($event)">
                                                            </app-autocomplete>
                                                            <Span class="text-danger"
                                                                *ngIf="((valAddressPropControls['proposerPinCode'].touched  || submittedAddress) && valAddressPropControls['proposerPinCode'].errors?.['required'])">
                                                                Pincode is required.
                                                            </Span>
                                                            <Span class="text-danger"
                                                                *ngIf="((valAddressPropControls['proposerPinCode'].touched  || submittedAddress) && valAddressPropControls['proposerPinCode'].errors?.['pattern'])">
                                                                Provide valid pincode.
                                                            </Span>
        
                                                    </div>
                                                    <div class="col-md-6 mt-3">
                                                        <label for="name" class="lb required"
                                                            style="font-weight: 700 !important; font-size: 14px !important;">State
                                                        </label>
                                                            <app-autocomplete class="w-100" [items]="stateList" placeholder="State"
                                                                (itemSelected)="onStateSelection($event)"
                                                                (onBlurEvent)="onStateBlur($event)"
                                                                [userInput]="valAddressPropControls['proposerState'].value"
                                                                [showError]="((valAddressPropControls['proposerState'].touched  || submittedAddress) && !valAddressPropControls['proposerState'].valid)" [readonly]="'readonly'"></app-autocomplete>
                                                            <Span class="text-danger"
                                                                *ngIf="((valAddressPropControls['proposerState'].touched  || submittedAddress) && valAddressPropControls['proposerState'].errors?.['required'])">
                                                                State is required.
                                                            </Span>
                                                    </div>
                                                    <div class="mt-3 col-md-6">
                                                        <label for="name" class="lb required"
                                                            style="font-weight: 700 !important; font-size: 14px !important;">District</label>
                                                        <!-- <input type="text" class="form-control in-h" name="district"
                                                            formControlName="district" placeholder="District"> -->
                                                            <app-autocomplete class="w-100" [items]="districtList" placeholder="District" [readonly]="'readonly'"
                                                            (itemSelected)="onDistrictSelection($event)"
                                                            (onBlurEvent)="onDistrictBlur($event)"
                                                            [userInput]="valAddressPropControls['district'].value"
                                                            [showError]="((valAddressPropControls['district'].touched  || submittedAddress) && !valAddressPropControls['district'].valid)"></app-autocomplete>
                                                        <Span class="text-danger"
                                                            *ngIf="((valAddressPropControls['district'].touched  || submittedAddress) && valAddressPropControls['district'].errors?.['required'])">
                                                            State is required.
                                                        </Span>
                                                    </div>
                                                    <div class="col-md-6 mt-3">
                                                        <label for="name" class="lb required"
                                                            style="font-weight: 700 !important; font-size: 14px !important;">City/Talluka/Village
                                                        </label>
                                                        <!-- <input type="text" class="form-control in-h" name="proposerCity"
                                                            formControlName="proposerCity"
                                                            placeholder="Auto Fetch From PIN Code"
                                                            [ngClass]="{'border border-danger': ((valAddressPropControls['proposerCity'].touched) && !valAddressPropControls['proposerCity'].valid)}"> -->
                                                            <app-autocomplete class="w-100" (itemSelected)="onCitySelection($event)"
                                                            [items]="cityList" placeholder="City/Talluka/Village"
                                                            [supportingText]="true"
                                                            [userInput]="valAddressPropControls['proposerCity'].value"
                                                            [showError]="((valAddressPropControls['proposerCity'].touched  || submittedAddress) && !valAddressPropControls['proposerCity'].valid)"
                                                            (onBlurEvent)="onCityBlur($event)"></app-autocomplete>
                                                        <Span class="text-danger"
                                                            *ngIf="((valAddressPropControls['proposerCity'].touched  || submittedAddress) && valAddressPropControls['proposerCity'].errors?.['required'])">
                                                            City/Talluka/Village is required.
                                                        </Span>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6 mt-5  text-start">
                                            <button class="btn btn-primary-outline btn-outline"
                                                (click)="backFunction('proposer')">Back</button>
                                        </div>
                                        <div class="col-md-6 mt-5  text-end">
                                            <button class="btn btn-primary"
                                                (click)="InsuranceDetails('members')"   [disabled]="addressInfo.invalid" >Next</button>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                            <div id="members" class="col-12 c-pl c-pr" *ngIf="detail == 'members'">
                                <ng-container>
                                    <div class="card p-5 row br-top-0">
                                        <div class="details_card">
                                            <form id="kt_account_profile_details_form" class="form"
                                                [formGroup]="insurerForm" novalidate="novalidate">
                                                <ng-container  *ngIf="healthData?.selfSelected">
                                                    <h4 class="text-start member_heading">Self Information</h4>
                                                    <hr class="premium_breakup my-0">
                                                    <div class="row mb-3">
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="dob">First Name</label>
                                                            <input type="text" [value]="valMemControls['selfFirstName'].value"
                                                                class="form-control " formControlName="selfFirstName"
                                                                [ngClass]="{'border border-danger': ((valMemControls['selfFirstName'].touched || submitted) && !valMemControls['selfFirstName'].valid)}"
                                                                name="selfFirstName" />                                                        
                                                            <Span class="text-danger" *ngIf="((valMemControls['selfFirstName'].touched || submitted) && !valMemControls['selfFirstName'].valid)">
                                                                First Name is required.
                                                            </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="dob"> Last Name</label>
                                                            <input type="text" [value]="valMemControls['selfLastName'].value"
                                                                name="selfLastName" class="form-control "
                                                                [ngClass]="{'border border-danger': ((valMemControls['selfLastName'].touched || submitted) && !valMemControls['selfLastName'].valid)}"
                                                                formControlName="selfLastName" />
                                                            <Span class="text-danger" *ngIf="((valMemControls['selfLastName'].touched || submitted) && !valMemControls['selfLastName'].valid)">
                                                                Last Name is required.
                                                            </Span>    
                                                        </div>    
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required"
                                                                style="font-weight: 700 !important; font-size: 14px !important;"
                                                                for="dob">Date of birth</label>
                                                            <input type="date" name="selfDOB"
                                                                formControlName="selfDOB" autocomplete="off"
                                                                class="new-user form-control bg-transparent "
                                                                [ngClass]="{'border border-danger': ((valMemControls['selfDOB'].touched || submitted) && !valMemControls['selfDOB'].valid)}" [max]="maxDOB" [(ngModel)]="defaultDate" />
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['selfDOB'].touched || submitted) && !valMemControls['selfDOB'].valid)">
                                                                Date of birth is required.
                                                            </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label for="name" class="lb required">Occupation</label>
                                                            <select class="form-select form-select-custom" formControlName="selfOccupation"
                                                            [ngClass]="{'border border-danger': ((valMemControls['selfOccupation'].touched || submitted) && !valMemControls['selfOccupation'].valid)}">
                                                                <option *ngFor="let occupation of occupationList" value="{{occupation.value}}">{{occupation.text}}</option>                                                        
                                                            </select>
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['selfOccupation'].touched || submitted) && !valMemControls['selfOccupation'].valid)">
                                                                Occupation is required.
                                                            </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="height">Height</label>
                                                            <div class="row">
                                                                <div class="col-6">
                                                                    <select class="form-select form-select-custom" name="selfHeightFeet"                                               
                                                                        formControlName="selfHeightFeet"
                                                                        [ngClass]="{'border border-danger': ((valMemControls['selfHeightFeet'].touched || submitted) && !valMemControls['selfHeightFeet'].valid)}">
                                                                        <option value="">Feets</option>
                                                                        <option *ngFor="let feet of feetList" value="{{feet}}">{{feet}} Feet</option>
                                                                    </select>
                                                                    <Span class="text-danger"
                                                                        *ngIf="((valMemControls['selfHeightFeet'].touched || submitted) && !valMemControls['selfHeightFeet'].valid)">
                                                                        Height feet is required.
                                                                    </Span>
                                                                </div>
                                                                <div class="col-6">
                                                                    <select class="form-select form-select-custom" name="selfHeightInches"                                                    
                                                                        formControlName="selfHeightInches"
                                                                        [ngClass]="{'border border-danger': ((valMemControls['selfHeightInches'].touched || submitted) && !valMemControls['selfHeightInches'].valid)}">
                                                                        <option value="">Inches</option>
                                                                        <option *ngFor="let inch of inchList" value="{{inch}}">{{inch}} Inch</option>
                                                                    </select>
                                                                    <Span class="text-danger"
                                                                        *ngIf="((valMemControls['selfHeightInches'].touched || submitted) && !valMemControls['selfHeightInches'].valid)">
                                                                        Height inch(es) is required.
                                                                    </Span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="weight">Weight (Kg)</label>
                                                            <input type="number" class="form-control" name="selfWeight"
                                                                formControlName="selfWeight" placeholder="Enter KGs"
                                                                [ngClass]="{'border border-danger': ((valMemControls['selfWeight'].touched || submitted) && !valMemControls['selfWeight'].valid)}"   onkeypress="if(this.value.length=='3') return false"/>
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['selfWeight'].touched || submitted) && !valMemControls['selfWeight'].valid)">
                                                                Weight is required.
                                                            </Span>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                                <ng-container  *ngIf="healthData?.spouseSelected">
                                                    <hr class="premium_breakup my-0">
                                                    <h5 class="text-start mt-5 member_heading">Spouse's Information</h5>
                                                    <hr class="premium_breakup my-0">
                                                    <div class="row mb-3">
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="sname">Enter First Name</label>
                                                            <input type="text" class="form-control"
                                                                (keypress)="alphabetOnly($event)" name="spouseFirstName"
                                                                formControlName="spouseFirstName"
                                                                placeholder="Spouse's First Name"
                                                                [ngClass]="{'border border-danger': ((valMemControls['spouseFirstName'].touched || submitted) && !valMemControls['spouseFirstName'].valid)}">
                                                            <Span class="text-danger" *ngIf="((valMemControls['spouseFirstName'].touched || submitted) && !valMemControls['spouseFirstName'].valid)">
                                                                First Name is required.
                                                            </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="sname">Enter Last Name</label>
                                                            <input type="text" class="form-control"
                                                                (keypress)="alphabetOnly($event)" name="spouseLastName"
                                                                formControlName="spouseLastName"
                                                                placeholder="Spouse's Last Name"
                                                                [ngClass]="{'border border-danger': ((valMemControls['spouseLastName'].touched  || submitted) && !valMemControls['spouseLastName'].valid)}">
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['spouseLastName'].touched || submitted) && !valMemControls['spouseLastName'].valid)">
                                                                Last Name is required.
                                                            </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="dob">Date of Birth</label>
                                                            <input type="date" name="dob"
                                                                formControlName="spouseDOB" autocomplete="off"
                                                                class="new-user form-control bg-transparent" onkeydown="return false" 
                                                                [ngClass]="{'border border-danger': ((valMemControls['spouseDOB'].touched || submitted) && !valMemControls['spouseDOB'].valid)}" [max]="maxDOB" />
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['spouseDOB'].touched || submitted) && !valMemControls['spouseDOB'].valid)">
                                                                Date of birth is required.
                                                            </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label for="name" class="lb required" >Occupation</label>
                                                            <select class="form-select form-select-custom" aria-label="Default select example" formControlName="spouseOccupation"
                                                            [ngClass]="{'border border-danger': ((valMemControls['spouseOccupation'].touched || submitted) && !valMemControls['spouseOccupation'].valid)}">
                                                                <option *ngFor="let occupation of occupationList" value="{{occupation.value}}">{{occupation.text}}</option>
                                                            </select>
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['spouseOccupation'].touched || submitted) && !valMemControls['spouseOccupation'].valid)">
                                                                Occupation is required.
                                                            </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="height">Height</label>
                                                            <div class="row">
                                                                <div class="col-6">
                                                                    <select class="form-select form-select-custom" name="spouseHeightFeet"
                                                                        formControlName="spouseHeightFeet"
                                                                        [ngClass]="{'border border-danger': ((valMemControls['spouseHeightFeet'].touched  || submitted) && !valMemControls['spouseHeightFeet'].valid)}">
                                                                        <option value="">Feets</option>    
                                                                        <option *ngFor="let feet of feetList" value="{{feet}}">{{feet}}
                                                                            Feet</option>
                                                                    </select>
                                                                    <Span class="text-danger"
                                                                        *ngIf="((valMemControls['spouseHeightFeet'].touched  || submitted) && !valMemControls['spouseHeightFeet'].valid)">
                                                                        Height feet is required.
                                                                    </Span>
                                                                </div>
                                                                <div class="col-6"> 
                                                                    <select class="form-select form-select-custom"
                                                                        name="spouseHeightInches" formControlName="spouseHeightInches"
                                                                        [ngClass]="{'border border-danger': ((valMemControls['spouseHeightInches'].touched || submitted) && !valMemControls['spouseHeightInches'].valid)}">
                                                                        <option value="">Inches</option>
                                                                        <option *ngFor="let inch of inchList" value="{{inch}}">{{inch}}
                                                                            Inch</option>    
                                                                    </select>
                                                                    <Span class="text-danger"
                                                                        *ngIf="((valMemControls['spouseHeightInches'].touched || submitted) && !valMemControls['spouseHeightInches'].valid)">
                                                                        Height inch(es) is required.
                                                                    </Span>
                                                                </div>    
                                                            </div>
                                                        </div>    
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="weight">Weight (Kg)</label>
                                                            <input type="number" class="form-control" name="spouseWeight"
                                                                formControlName="spouseWeight" placeholder="Enter KGs"
                                                                [ngClass]="{'border border-danger': ((valMemControls['spouseWeight'].touched || submitted) && !valMemControls['spouseWeight'].valid)}"  onkeypress="if(this.value.length=='3') return false">
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['spouseWeight'].touched || submitted) && !valMemControls['spouseWeight'].valid)">
                                                                Weight is required.
                                                            </Span>
                                                        </div>    
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngIf="healthData?.firstSone">
                                                    <hr class="premium_breakup my-0">
                                                    <h5 class="text-start mt-5 member_heading">1st Son's Information</h5>
                                                    <hr class="premium_breakup my-0">
                                                    <div class="row mb-3">
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="name">Enter Son First Name</label>
                                                            <input type="text" class="form-control" name="son1FirstName"
                                                                formControlName="son1FirstName"
                                                                [ngClass]="{'border border-danger': ((valMemControls['son1FirstName'].touched || submitted) && !valMemControls['son1FirstName'].valid)}">
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['son1FirstName'].touched || submitted) && !valMemControls['son1FirstName'].valid)">
                                                                First Name is required.
                                                            </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="name">Enter Son Last Name</label>
                                                            <input type="text" class="form-control" name="son1LastName"
                                                                formControlName="son1LastName"
                                                                [ngClass]="{'border border-danger': ((valMemControls['son1LastName'].touched || submitted) && !valMemControls['son1LastName'].valid)}">
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['son1LastName'].touched || submitted) && !valMemControls['son1LastName'].valid)">
                                                                Last Name is required.
                                                            </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="name">Date of Birth</label>
                                                            <input type="date" class="form-control" name="son1DOB"
                                                                formControlName="son1DOB" onkeydown="return false" 
                                                                [ngClass]="{'border border-danger': ((valMemControls['son1DOB'].touched || submitted) && !valMemControls['son1DOB'].valid)}"  [max]="maxDate">
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['son1DOB'].touched || submitted) && !valMemControls['son1DOB'].valid)">
                                                                Date of birth is required.
                                                            </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label for="name" class="lb required">Occupation</label>
                                                            <select class="form-select form-select-custom" aria-label="Default select example" formControlName="son1Occupation"
                                                            [ngClass]="{'border border-danger': ((valMemControls['son1Occupation'].touched || submitted) && !valMemControls['son1Occupation'].valid)}">
                                                                <option *ngFor="let occupation of occupationList" value="{{occupation.value}}">{{occupation.text}}</option>
                                                            </select>
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['son1Occupation'].touched || submitted) && !valMemControls['son1Occupation'].valid)">
                                                                Occupation is required.
                                                            </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="height">Height</label>
                                                            <div class="row">
                                                                <div class="col-6">
                                                                    <select class="form-select form-select-custom" name="son1HeightFeet"
                                                                        formControlName="son1HeightFeet"
                                                                        [ngClass]="{'border border-danger': ((valMemControls['son1HeightFeet'].touched || submitted) && !valMemControls['son1HeightFeet'].valid)}">
                                                                        <option value="">Feets</option>    
                                                                        <option *ngFor="let feet of feetList" value="{{feet}}">{{feet}}
                                                                            Feet</option>
                                                                    </select>
                                                                    <Span class="text-danger"
                                                                        *ngIf="((valMemControls['son1HeightFeet'].touched || submitted) && !valMemControls['son1HeightFeet'].valid)">
                                                                        Height feet is required.
                                                                    </Span>
                                                                </div>
                                                                <div class="col-6">
                                                                        <select class="form-select form-select-custom" name="son1HeightInches"
                                                                        formControlName="son1HeightInches"
                                                                        [ngClass]="{'border border-danger': ((valMemControls['son1HeightInches'].touched || submitted) && !valMemControls['son1HeightInches'].valid)}">
                                                                        <option value="">Inches</option>
                                                                        <option *ngFor="let inch of inchList" value="{{inch}}">{{inch}}
                                                                        Inch</option>    
                                                                    </select>
                                                                    <Span class="text-danger"
                                                                        *ngIf="((valMemControls['son1HeightInches'].touched || submitted) && !valMemControls['son1HeightInches'].valid)">
                                                                        Height inch(es) is required.
                                                                    </Span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="weight">Weight (Kg)</label>
                                                            <input type="number" class="form-control" placeholder="Enter KGs"
                                                                name="son1Weight" formControlName="son1Weight"
                                                                [ngClass]="{'border border-danger': ((valMemControls['son1Weight'].touched || submitted) && !valMemControls['son1Weight'].valid)}"  onkeypress="if(this.value.length=='3') return false">
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['son1Weight'].touched || submitted) && !valMemControls['son1Weight'].valid)">
                                                                Weight is required.
                                                            </Span>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngIf="healthData?.secondSon">
                                                    <hr class="premium_breakup my-0">
                                                    <h5 class="text-start mt-5 member_heading">2nd Son's Information</h5>
                                                    <hr class="premium_breakup my-0">
                                                    <div class="row mb-3">
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="name">Enter Son First Name</label>
                                                            <input type="text" class="form-control" name="son2FirstName"
                                                                formControlName="son2FirstName"
                                                                [ngClass]="{'border border-danger': ((valMemControls['son2FirstName'].touched || submitted) && !valMemControls['son2FirstName'].valid)}">
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['son2FirstName'].touched || submitted) && !valMemControls['son2FirstName'].valid)">
                                                                First Name is required.
                                                            </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="name">Enter Son Last Name</label>
                                                            <input type="text" class="form-control" name="son2LastName"
                                                                formControlName="son2LastName"
                                                                [ngClass]="{'border border-danger': ((valMemControls['son2LastName'].touched  || submitted) && !valMemControls['son2LastName'].valid)}">
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['son2LastName'].touched || submitted) && !valMemControls['son2LastName'].valid)">
                                                                Last Name is required.
                                                            </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="name">Date of Birth</label>
                                                            <input type="date" class="form-control" name="son2DOB"
                                                                formControlName="son2DOB" onkeydown="return false"
                                                                [ngClass]="{'border border-danger': ((valMemControls['son2DOB'].touched || submitted) && !valMemControls['son2DOB'].valid)}"  [max]="maxDate">
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['son2DOB'].touched || submitted) && !valMemControls['son2DOB'].valid)">
                                                                Date of birth is required.
                                                            </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label for="name" class="lb required">Occupation</label>
                                                                <select class="form-select form-select-custom" aria-label="Default select example"  formControlName="son2Occupation"
                                                                [ngClass]="{'border border-danger': ((valMemControls['son2Occupation'].touched || submitted) && !valMemControls['son2Occupation'].valid)}" >
                                                                    <option *ngFor="let occupation of occupationList" value="{{occupation.value}}">{{occupation.text}}</option>
                                                                </select>
                                                                <Span class="text-danger"
                                                                    *ngIf="((valMemControls['son2Occupation'].touched || submitted) && !valMemControls['son2Occupation'].valid)">
                                                                    Occupation is required.
                                                                </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="height">Height</label>
                                                            <div class="row">
                                                                <div class="col-6">
                                                                    <select class="form-select form-select-custom" name="son2HeightFeet"
                                                                        formControlName="son2HeightFeet"
                                                                        [ngClass]="{'border border-danger': ((valMemControls['son2HeightFeet'].touched || submitted) && !valMemControls['son2HeightFeet'].valid)}">
                                                                        <option value="">Feets</option>                
                                                                        <option *ngFor="let feet of feetList" value="{{feet}}">{{feet}}
                                                                            Feet</option>
                                                                    </select>
                                                                    <Span class="text-danger"
                                                                        *ngIf="((valMemControls['son2HeightFeet'].touched || submitted) && !valMemControls['son2HeightFeet'].valid)">
                                                                        Height feet is required.
                                                                    </Span>
                                                                </div>
                                                                <div class="col-6">
                                                                    <select class="form-select form-select-custom" name="son2HeightInches"
                                                                        formControlName="son2HeightInches"
                                                                        [ngClass]="{'border border-danger': ((valMemControls['son2HeightInches'].touched || submitted) && !valMemControls['son2HeightInches'].valid)}">
                                                                        <option value="">Inches</option>
                                                                        <option *ngFor="let inch of inchList" value="{{inch}}">{{inch}}
                                                                            Inch</option>    
                                                                    </select>
                                                                    <Span class="text-danger"
                                                                        *ngIf="((valMemControls['son2HeightInches'].touched || submitted) && !valMemControls['son2HeightInches'].valid)">
                                                                        Height inch(es) is required.
                                                                    </Span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="weight">Weight (Kg)</label>
                                                            <input type="number" class="form-control" placeholder="Enter KGs"
                                                                name="son2Weight" formControlName="son2Weight"
                                                                [ngClass]="{'border border-danger': ((valMemControls['son2Weight'].touched || submitted) && !valMemControls['son2Weight'].valid)}"  onkeypress="if(this.value.length=='3') return false">
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['son2Weight'].touched || submitted) && !valMemControls['son2Weight'].valid)">
                                                                Weight is required.
                                                            </Span>
                                                        </div>
                                                    </div>
                                                </ng-container> 
                                                <ng-container *ngIf="healthData?.thirdSon">
                                                    <hr class="premium_breakup my-0">                                           
                                                    <h5 class="text-start mt-5 member_heading"> 3rd Son's Information</h5>
                                                    <hr class="premium_breakup my-0">
                                                    <div class="row mb-3">
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="name">Enter Son First Name</label>
                                                            <input type="text" class="form-control" name="son3FirstName"
                                                                formControlName="son3FirstName"
                                                                [ngClass]="{'border border-danger': ((valMemControls['son3FirstName'].touched || submitted) && !valMemControls['son3FirstName'].valid)}">
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['son3FirstName'].touched || submitted) && !valMemControls['son3FirstName'].valid)">
                                                                First Name is required.
                                                            </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="name">Enter Son Last Name</label>
                                                            <input type="text" class="form-control" name="son3LastName"
                                                                formControlName="son3LastName"
                                                                [ngClass]="{'border border-danger': ((valMemControls['son3LastName'].touched || submitted) && !valMemControls['son3LastName'].valid)}">
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['son3LastName'].touched || submitted) && !valMemControls['son3LastName'].valid)">
                                                                Last Name is required.
                                                            </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="name">Date of Birth</label>
                                                            <input type="date" class="form-control" name="son3DOB"
                                                                formControlName="son3DOB" onkeydown="return false"
                                                                [ngClass]="{'border border-danger': ((valMemControls['son3DOB'].touched || submitted) && !valMemControls['son3DOB'].valid)}"  [max]="maxDate">
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['son3DOB'].touched  || submitted) && !valMemControls['son3DOB'].valid)">
                                                                Date of birth is required.
                                                            </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label for="name" class="lb required" >Occupation</label>
                                                                <select class="form-select form-select-custom" aria-label="Default select example" formControlName="son3Occupation"
                                                                [ngClass]="{'border border-danger': ((valMemControls['son3Occupation'].touched || submitted) && !valMemControls['son3Occupation'].valid)}">
                                                                    <option *ngFor="let occupation of occupationList" value="{{occupation.value}}">{{occupation.text}}</option>
                                                                </select>
                                                                <Span class="text-danger"
                                                                    *ngIf="((valMemControls['son3Occupation'].touched || submitted) && !valMemControls['son3Occupation'].valid)">
                                                                    Occupation is required.
                                                                </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="height">Height</label>
                                                            <div class="row">
                                                                <div class="col-6">
                                                                    <select class="form-select form-select-custom" name="son3HeightFeet"
                                                                        formControlName="son3HeightFeet"
                                                                        [ngClass]="{'border border-danger': ((valMemControls['son3HeightFeet'].touched || submitted) && !valMemControls['son3HeightFeet'].valid)}">
                                                                        <option value="">Feets</option>
                                                                        <option *ngFor="let feet of feetList" value="{{feet}}">{{feet}}
                                                                            Feet</option>
                                                                    </select>
                                                                    <Span class="text-danger"
                                                                        *ngIf="((valMemControls['son3HeightFeet'].touched || submitted) && !valMemControls['son3HeightFeet'].valid)">
                                                                        Height feet is required.
                                                                    </Span>
                                                                </div>
                                                                <div class="col-6">
                                                                    <select class="form-select form-select-custom" name="son3HeightInches"
                                                                        formControlName="son3HeightInches"
                                                                        [ngClass]="{'border border-danger': ((valMemControls['son3HeightInches'].touched || submitted) && !valMemControls['son3HeightInches'].valid)}">
                                                                        <option value="">Inches</option>
                                                                        <option *ngFor="let inch of inchList" value="{{inch}}">{{inch}}
                                                                            Inch</option>        
                                                                    </select>
                                                                    <Span class="text-danger"
                                                                        *ngIf="((valMemControls['son3HeightInches'].touched || submitted) && !valMemControls['son3HeightInches'].valid)">
                                                                        Height inch(es) is required.
                                                                    </Span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="weight">Weight (Kg)</label>
                                                            <input type="number" class="form-control" placeholder="Enter KGs"
                                                                name="son3Weight" formControlName="son3Weight" onkeydown="return false"
                                                                [ngClass]="{'border border-danger': ((valMemControls['son3Weight'].touched  || submitted) && !valMemControls['son3Weight'].valid)}"  onkeypress="if(this.value.length=='3') return false">
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['son3Weight'].touched) && !valMemControls['son3Weight'].valid)">
                                                                Weight is required.
                                                            </Span>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngIf="healthData?.fourthSon">
                                                    <hr class="premium_breakup my-0">
                                                    <h5 class="text-start mt-5 member_heading">4th Son's Information</h5>
                                                    <hr class="premium_breakup my-0">
                                                    <div class="row mb-3">
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="name">Enter Son First Name</label>
                                                            <input type="text" class="form-control" name="son4FirstName"
                                                                formControlName="son4FirstName"
                                                                [ngClass]="{'border border-danger': ((valMemControls['son4FirstName'].touched || submitted) && !valMemControls['son4FirstName'].valid)}">
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['son4FirstName'].touched || submitted) && !valMemControls['son4FirstName'].valid)">
                                                                First Name is required.
                                                            </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="name">Enter Son Last Name</label>
                                                            <input type="text" class="form-control" name="son4LastName"
                                                                formControlName="son4LastName"
                                                                [ngClass]="{'border border-danger': ((valMemControls['son4LastName'].touched || submitted) && !valMemControls['son4LastName'].valid)}">
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['son4LastName'].touched || submitted) && !valMemControls['son4LastName'].valid)">
                                                                Last Name is required.
                                                            </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="name">Date of Birth</label>
                                                            <input type="date" class="form-control" name="son4DOB"
                                                                formControlName="son4DOB" onkeydown="return false"
                                                                [ngClass]="{'border border-danger': ((valMemControls['son4DOB'].touched || submitted) && !valMemControls['son4DOB'].valid)}"  [max]="maxDate">
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['son4DOB'].touched || submitted) && !valMemControls['son4DOB'].valid)">
                                                                Date of birth is required.
                                                            </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label for="name" class="lb required" >Occupation</label>
                                                                <select class="form-select form-select-custom" aria-label="Default select example" formControlName="son4Occupation"
                                                                [ngClass]="{'border border-danger': ((valMemControls['son4Occupation'].touched || submitted) && !valMemControls['son4Occupation'].valid)}">
                                                                    <option *ngFor="let occupation of occupationList" value="{{occupation.value}}">{{occupation.text}}</option>
                                                                </select>
                                                                <Span class="text-danger"
                                                                    *ngIf="((valMemControls['son4Occupation'].touched || submitted) && !valMemControls['son4Occupation'].valid)">
                                                                    Occupation is required.
                                                                </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="height">Height</label>
                                                            <div class="row">
                                                                <div class="col-6">
                                                                    <select class="form-select form-select-custom" name="son4HeightFeet"
                                                                        formControlName="son4HeightFeet"
                                                                        [ngClass]="{'border border-danger': ((valMemControls['son4HeightFeet'].touched || submitted) && !valMemControls['son4HeightFeet'].valid)}">
                                                                        <option value="">Feets</option>
                                                                        <option *ngFor="let feet of feetList" value="{{feet}}">{{feet}}
                                                                            Feet</option>
                                                                    </select>
                                                                    <Span class="text-danger"
                                                                        *ngIf="((valMemControls['son4HeightFeet'].touched || submitted) && !valMemControls['son4HeightFeet'].valid)">
                                                                        Height feet is required.
                                                                    </Span>
                                                                </div>
                                                                <div class="col-6">
                                                                    <select class="form-select form-select-custom" name="son4HeightInches"
                                                                        formControlName="son4HeightInches"
                                                                        [ngClass]="{'border border-danger': ((valMemControls['son4HeightInches'].touched || submitted) && !valMemControls['son4HeightInches'].valid)}">
                                                                        <option value="">Inches</option>
                                                                        <option *ngFor="let inch of inchList" value="{{inch}}">{{inch}}
                                                                            Inch</option>
                                                                    </select>
                                                                    <Span class="text-danger"
                                                                        *ngIf="((valMemControls['son4HeightInches'].touched || submitted) && !valMemControls['son4HeightInches'].valid)">
                                                                        Height inch(es) is required.
                                                                    </Span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="weight">Weight (Kg)</label>
                                                            <input type="number" class="form-control" placeholder="Enter KGs"
                                                                name="son4Weight" formControlName="son4Weight"
                                                                [ngClass]="{'border border-danger': ((valMemControls['son4Weight'].touched || submitted) && !valMemControls['son4Weight'].valid)}"  onkeypress="if(this.value.length=='3') return false">
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['son4Weight'].touched || submitted) && !valMemControls['son4Weight'].valid)">
                                                                Weight is required.
                                                            </Span>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngIf="healthData?.firstDaughter">                                            
                                                    <hr class="premium_breakup my-0"> 
                                                    <h5 class="text-start mt-5 member_heading">1st Daughter Information</h5>
                                                    <hr class="premium_breakup my-0"> 
                                                    <div class="row mb-3">
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="name">Enter Daughter First
                                                                Name</label>
                                                            <input type="text" class="form-control" name="dau1FirstName"
                                                                formControlName="dau1FirstName"
                                                                [ngClass]="{'border border-danger': ((valMemControls['dau1FirstName'].touched || submitted) && !valMemControls['dau1FirstName'].valid)}">
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['dau1FirstName'].touched || submitted) && !valMemControls['dau1FirstName'].valid)">
                                                                First Name is required.
                                                            </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="name">Enter Daughter Last
                                                                Name</label>
                                                            <input type="text" class="form-control" name="dau1LastName"
                                                                formControlName="dau1LastName"
                                                                [ngClass]="{'border border-danger': ((valMemControls['dau1LastName'].touched  || submitted) && !valMemControls['dau1LastName'].valid)}">
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['dau1LastName'].touched || submitted) && !valMemControls['dau1LastName'].valid)">
                                                                Last Name is required.
                                                            </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="name">Date of Birth</label>
                                                            <input type="date" class="form-control" name="dau1DOB"
                                                                formControlName="dau1DOB" onkeydown="return false"
                                                                [ngClass]="{'border border-danger': ((valMemControls['dau1DOB'].touched || submitted) && !valMemControls['dau1DOB'].valid)}"  [max]="maxDate">
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['dau1DOB'].touched || submitted) && !valMemControls['dau1DOB'].valid)">
                                                                Date of birth is required.
                                                            </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label for="name" class="lb required" >Occupation</label>
                                                                <select class="form-select form-select-custom" aria-label="Default select example" formControlName="dau1Occupation"
                                                                [ngClass]="{'border border-danger': ((valMemControls['dau1Occupation'].touched || submitted) && !valMemControls['dau1Occupation'].valid)}">
                                                                    <option *ngFor="let occupation of occupationList" value="{{occupation.value}}">{{occupation.text}}</option>
                                                                </select>
                                                                <Span class="text-danger"
                                                                    *ngIf="((valMemControls['dau1Occupation'].touched || submitted) && !valMemControls['dau1Occupation'].valid)">
                                                                    Occupation is required.
                                                                </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="height">Height</label>
                                                            <div class="row">
                                                                <div class="col-6">
                                                                    <select class="form-select form-select-custom" name="dau1HeightFeet"
                                                                        formControlName="dau1HeightFeet"
                                                                        [ngClass]="{'border border-danger': ((valMemControls['dau1HeightFeet'].touched || submitted) && !valMemControls['dau1HeightFeet'].valid)}">
                                                                        <option value="">Feets</option>
                                                                        <option *ngFor="let feet of feetList" value="{{feet}}">{{feet}}
                                                                            Feet</option>
                                                                    </select>
                                                                    <Span class="text-danger"
                                                                        *ngIf="((valMemControls['dau1HeightFeet'].touched || submitted) && !valMemControls['dau1HeightFeet'].valid)">
                                                                        Height feet is required.
                                                                    </Span>
                                                                </div>
                                                                <div class="col-6">
                                                                    <select class="form-select form-select-custom" name="dau1HeightInches"
                                                                        formControlName="dau1HeightInches"
                                                                        [ngClass]="{'border border-danger': ((valMemControls['dau1HeightInches'].touched || submitted) && !valMemControls['dau1HeightInches'].valid)}">
                                                                        <option value="">Inches</option>
                                                                        <option *ngFor="let inch of inchList" value="{{inch}}">{{inch}}
                                                                            Inch</option>
                                                                    </select>
                                                                    <Span class="text-danger"
                                                                        *ngIf="((valMemControls['dau1HeightInches'].touched || submitted) && !valMemControls['dau1HeightInches'].valid)">
                                                                        Height inch(es) is required.
                                                                    </Span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="weight">Weight (Kg)</label>
                                                            <input type="number" class="form-control" placeholder="Enter KGs"
                                                                name="dau1Weight" formControlName="dau1Weight"
                                                                [ngClass]="{'border border-danger': ((valMemControls['dau1Weight'].touched || submitted) && !valMemControls['dau1Weight'].valid)}"  onkeypress="if(this.value.length=='3') return false">
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['dau1Weight'].touched || submitted) && !valMemControls['dau1Weight'].valid)">
                                                                Weight is required.
                                                            </Span>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngIf="healthData?.secondDaughter">
                                                    <hr class="premium_breakup my-0"> 
                                                    <h5 class="text-start mt-5 member_heading"> 2nd Daughter Information</h5>
                                                    <hr class="premium_breakup my-0"> 
                                                    <div class="row mb-3">
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="name">Enter Daughter First
                                                                Name</label>
                                                            <input type="text" class="form-control" name="dau2FirstName"
                                                                formControlName="dau2FirstName"
                                                                [ngClass]="{'border border-danger': ((valMemControls['dau2FirstName'].touched || submitted) && !valMemControls['dau2FirstName'].valid)}">
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['dau2FirstName'].touched || submitted) && !valMemControls['dau2FirstName'].valid)">
                                                                First Name is required.
                                                            </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="name">Enter Daughter Last
                                                                Name</label>
                                                            <input type="text" class="form-control" name="dau2LastName"
                                                                formControlName="dau2LastName"
                                                                [ngClass]="{'border border-danger': ((valMemControls['dau2LastName'].touched || submitted) && !valMemControls['dau2LastName'].valid)}">
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['dau2LastName'].touched || submitted) && !valMemControls['dau2LastName'].valid)">
                                                                Last Name is required.
                                                            </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="name">Date of Birth</label>
                                                            <input type="date" class="form-control" name="dau2DOB"
                                                                formControlName="dau2DOB" onkeydown="return false"
                                                                [ngClass]="{'border border-danger': ((valMemControls['dau2DOB'].touched || submitted) && !valMemControls['dau2DOB'].valid)}"  [max]="maxDate">
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['dau2DOB'].touched || submitted) && !valMemControls['dau2DOB'].valid)">
                                                                Date of birth is required.
                                                            </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label for="name" class="lb required">Occupation</label>
                                                                <select class="form-select form-select-custom" aria-label="Default select example"  formControlName="dau2Occupation"
                                                                [ngClass]="{'border border-danger': ((valMemControls['dau2Occupation'].touched || submitted) && !valMemControls['dau2Occupation'].valid)}">
                                                                    <option *ngFor="let occupation of occupationList" value="{{occupation.value}}">{{occupation.text}}</option>
                                                                </select>
                                                                <Span class="text-danger"
                                                                    *ngIf="((valMemControls['dau2Occupation'].touched || submitted) && !valMemControls['dau2Occupation'].valid)">
                                                                    Occupation is required.
                                                                </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="height">Height</label>
                                                            <div class="row">
                                                                <div class="col-6">
                                                                    <select class="form-select form-select-custom" name="dau2HeightFeet"
                                                                        formControlName="dau2HeightFeet"
                                                                        [ngClass]="{'border border-danger': ((valMemControls['dau2HeightFeet'].touched || submitted) && !valMemControls['dau2HeightFeet'].valid)}">
                                                                        <option value="">Feets</option>
                                                                        <option *ngFor="let feet of feetList" value="{{feet}}">{{feet}}
                                                                            Feet</option>
                                                                    </select>
                                                                    <Span class="text-danger"
                                                                        *ngIf="((valMemControls['dau2HeightFeet'].touched || submitted) && !valMemControls['dau2HeightFeet'].valid)">
                                                                        Height feet is required.
                                                                    </Span>
                                                                </div>
                                                                <div class="col-6">
                                                                    <select class="form-select form-select-custom" name="dau2HeightInches"
                                                                        formControlName="dau2HeightInches"
                                                                        [ngClass]="{'border border-danger': ((valMemControls['dau2HeightInches'].touched || submitted) && !valMemControls['dau2HeightInches'].valid)}">
                                                                        <option value="">Inches</option>
                                                                        <option *ngFor="let inch of inchList" value="{{inch}}">{{inch}}
                                                                            Inch</option>
                                                                    </select>
                                                                    <Span class="text-danger"
                                                                        *ngIf="((valMemControls['dau2HeightInches'].touched || submitted) && !valMemControls['dau2HeightInches'].valid)">
                                                                        Height inch(es) is required.
                                                                    </Span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="weight">Weight (Kg)</label>
                                                            <input type="number" class="form-control" placeholder="Enter KGs"
                                                                name="dau2Weight" formControlName="dau2Weight"
                                                                [ngClass]="{'border border-danger': ((valMemControls['dau2Weight'].touched ) && !valMemControls['dau2Weight'].valid)}"  onkeypress="if(this.value.length=='3') return false">
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['dau2Weight'].touched) && !valMemControls['dau2Weight'].valid)">
                                                                Weight is required.
                                                            </Span>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngIf="healthData?.thirdDaughter">
                                                    <hr class="premium_breakup my-0">
                                                    <h5 class="text-start mt-5 member_heading">3rd Daughter Information</h5>
                                                    <hr class="premium_breakup my-0">
                                                    <div class="row mb-3">
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="name">Enter Daughter First
                                                                Name</label>
                                                            <input type="text" class="form-control" name="dau3FirstName"
                                                                formControlName="dau3FirstName"
                                                                [ngClass]="{'border border-danger': ((valMemControls['dau3FirstName'].touched || submitted) && !valMemControls['dau3FirstName'].valid)}">
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['dau3FirstName'].touched || submitted) && !valMemControls['dau3FirstName'].valid)">
                                                                First Name is required.
                                                            </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="name">Enter Daughter Last
                                                                Name</label>
                                                            <input type="text" class="form-control" name="dau3LastName"
                                                                formControlName="dau3LastName"
                                                                [ngClass]="{'border border-danger': ((valMemControls['dau3LastName'].touched || submitted) && !valMemControls['dau3LastName'].valid)}">
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['dau3LastName'].touched || submitted) && !valMemControls['dau3LastName'].valid)">
                                                                Last Name is required.
                                                            </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="name">Date of Birth</label>
                                                            <input type="date" class="form-control" name="dau3DOB"
                                                                formControlName="dau3DOB" onkeydown="return false"
                                                                [ngClass]="{'border border-danger': ((valMemControls['dau3DOB'].touched || submitted) && !valMemControls['dau3DOB'].valid)}"  [max]="maxDate">
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['dau3DOB'].touched || submitted) && !valMemControls['dau3DOB'].valid)">
                                                                Date of birth is required.
                                                            </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label for="name" class="lb required">Occupation</label>
                                                                <select class="form-select form-select-custom" aria-label="Default select example"  formControlName="dau3Occupation"
                                                                [ngClass]="{'border border-danger': ((valMemControls['dau3Occupation'].touched || submitted) && !valMemControls['dau3Occupation'].valid)}">
                                                                    <option *ngFor="let occupation of occupationList" value="{{occupation.value}}">{{occupation.text}}</option>
                                                                </select>
                                                                <Span class="text-danger"
                                                                    *ngIf="((valMemControls['dau3Occupation'].touched || submitted) && !valMemControls['dau3Occupation'].valid)">
                                                                    Occupation is required.
                                                                </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="height">Height</label>
                                                            <div class="row">
                                                                <div class="col-6">
                                                                    <select class="form-select form-select-custom" name="dau3HeightFeet"
                                                                        formControlName="dau3HeightFeet"
                                                                        [ngClass]="{'border border-danger': ((valMemControls['dau3HeightFeet'].touched || submitted) && !valMemControls['dau3HeightFeet'].valid)}">
                                                                        <option value="">Feets</option>
                                                                        <option *ngFor="let feet of feetList" value="{{feet}}">{{feet}}
                                                                            Feet</option>
                                                                    </select>
                                                                    <Span class="text-danger"
                                                                        *ngIf="((valMemControls['dau3HeightFeet'].touched || submitted) && !valMemControls['dau3HeightFeet'].valid)">
                                                                        Height feet is required.
                                                                    </Span>
                                                                </div>
                                                                <div class="col-6">
                                                                    <select class="form-select form-select-custom" name="dau3HeightInches"
                                                                        formControlName="dau3HeightInches"
                                                                        [ngClass]="{'border border-danger': ((valMemControls['dau3HeightInches'].touched || submitted) && !valMemControls['dau3HeightInches'].valid)}">
                                                                        <option value="">Inches</option>
                                                                        <option *ngFor="let inch of inchList" value="{{inch}}">{{inch}}
                                                                            Inch</option>
                                                                    </select>
                                                                    <Span class="text-danger"
                                                                        *ngIf="((valMemControls['dau3HeightInches'].touched || submitted) && !valMemControls['dau3HeightInches'].valid)">
                                                                        Height inch(es) is required.
                                                                    </Span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="weight">Weight (Kg)</label>
                                                            <input type="number" class="form-control" placeholder="Enter KGs"
                                                                name="dau3Weight" formControlName="dau3Weight"
                                                                [ngClass]="{'border border-danger': ((valMemControls['dau3Weight'].touched || submitted) && !valMemControls['dau3Weight'].valid)}"  onkeypress="if(this.value.length=='3') return false">
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['dau3Weight'].touched || submitted) && !valMemControls['dau3Weight'].valid)">
                                                                Weight is required.
                                                            </Span>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngIf="healthData?.fourthDaughter">
                                                    <hr class="premium_breakup my-0">
                                                    <h5 class="text-start mt-5 member_heading">4th Daughter Information</h5>
                                                    <hr class="premium_breakup my-0">
                                                    <div class="row mb-3">
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="name">Enter Daughter First
                                                                Name</label>
                                                            <input type="text" class="form-control" name="dau4FirstName"
                                                                formControlName="dau4FirstName"
                                                                [ngClass]="{'border border-danger': ((valMemControls['dau4FirstName'].touched || submitted) && !valMemControls['dau4FirstName'].valid)}">
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['dau4FirstName'].touched || submitted) && !valMemControls['dau4FirstName'].valid)">
                                                                First Name is required.
                                                            </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="name">Enter Daughter Last
                                                                Name</label>
                                                            <input type="text" class="form-control" name="dau4LastName"
                                                                formControlName="dau4LastName"
                                                                [ngClass]="{'border border-danger': ((valMemControls['dau4LastName'].touched || submitted) && !valMemControls['dau4LastName'].valid)}">
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['dau4LastName'].touched || submitted) && !valMemControls['dau4LastName'].valid)">
                                                                Last Name is required.
                                                            </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="name">Date of Birth</label>
                                                            <input type="date" class="form-control" name="dau4DOB"
                                                                formControlName="dau4DOB" onkeydown="return false"
                                                                [ngClass]="{'border border-danger': ((valMemControls['dau4DOB'].touched || submitted) && !valMemControls['dau4DOB'].valid)}"  [max]="maxDate">
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['dau4DOB'].touched || submitted) && !valMemControls['dau4DOB'].valid)">
                                                                Date of birth is required.
                                                            </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label for="name" class="lb required">Occupation</label>
                                                                <select class="form-select form-select-custom" aria-label="Default select example"  formControlName="dau4Occupation"
                                                                [ngClass]="{'border border-danger': ((valMemControls['dau4Occupation'].touched || submitted) && !valMemControls['dau4Occupation'].valid)}">
                                                                    <option *ngFor="let occupation of occupationList" value="{{occupation.value}}">{{occupation.text}}</option>
                                                                </select>
                                                                <Span class="text-danger"
                                                                    *ngIf="((valMemControls['dau4Occupation'].touched || submitted) && !valMemControls['dau4Occupation'].valid)">
                                                                    Occupation is required.
                                                                </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="height">Height</label>
                                                            <div class="row">
                                                                <div class="col-6">
                                                                    <select class="form-select form-select-custom" name="dau4HeightFeet"
                                                                    formControlName="dau4HeightFeet"
                                                                    [ngClass]="{'border border-danger': ((valMemControls['dau4HeightFeet'].touched || submitted) && !valMemControls['dau4HeightFeet'].valid)}">
                                                                    <option value="">Feets</option>
                                                                    <option *ngFor="let feet of feetList" value="{{feet}}">{{feet}}
                                                                        Feet</option>
                                                                </select>
                                                                <Span class="text-danger"
                                                                    *ngIf="((valMemControls['dau4HeightFeet'].touched || submitted) && !valMemControls['dau4HeightFeet'].valid)">
                                                                    Height feet is required.
                                                                </Span>
                                                                </div>
                                                                <div class="col-6">
                                                                    <select class="form-select form-select-custom" name="dau4HeightInches"
                                                                        formControlName="dau4HeightInches"
                                                                        [ngClass]="{'border border-danger': ((valMemControls['dau4HeightInches'].touched || submitted) && !valMemControls['dau4HeightInches'].valid)}">
                                                                        <option value="">Inches</option>
                                                                        <option *ngFor="let inch of inchList" value="{{inch}}">{{inch}}
                                                                            Inch</option>
                                                                    </select>
                                                                    <Span class="text-danger"
                                                                        *ngIf="((valMemControls['dau4HeightInches'].touched || submitted) && !valMemControls['dau4HeightInches'].valid)">
                                                                        Height inch(es) is required.
                                                                    </Span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="weight">Weight (Kg)</label>
                                                            <input type="number" class="form-control" placeholder="Enter KGs"
                                                                name="dau4Weight" formControlName="dau4Weight"
                                                                [ngClass]="{'border border-danger': ((valMemControls['dau4Weight'].touched || submitted) && !valMemControls['dau4Weight'].valid)}"  onkeypress="if(this.value.length=='3') return false">
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['dau4Weight'].touched || submitted) && !valMemControls['dau4Weight'].valid)">
                                                                Weight is required.
                                                            </Span>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngIf="healthData?.fatherSelected">
                                                    <hr class="premium_breakup my-0">
                                                    <h5 class="text-start mt-5 member_heading">Father's Information</h5>
                                                    <hr class="premium_breakup my-0">
                                                    <div class="row mb-3">
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="sname">Enter First Name</label>
                                                            <input type="text" class="form-control"
                                                                (keypress)="alphabetOnly($event)" name="fatherFirstName"
                                                                formControlName="fatherFirstName"
                                                                placeholder="Father's First Name"
                                                                [ngClass]="{'border border-danger': ((valMemControls['fatherFirstName'].touched || submitted) && !valMemControls['fatherFirstName'].valid)}">
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['fatherFirstName'].touched || submitted) && !valMemControls['fatherFirstName'].valid)">
                                                                First Name is required.
                                                            </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="sname">Enter Last Name</label>
                                                            <input type="text" class="form-control"
                                                                (keypress)="alphabetOnly($event)" name="fatherLastName"
                                                                formControlName="fatherLastName"
                                                                placeholder="Father's Last Name"
                                                                [ngClass]="{'border border-danger': ((valMemControls['fatherLastName'].touched || submitted) && !valMemControls['fatherLastName'].valid)}">
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['fatherLastName'].touched || submitted) && !valMemControls['fatherLastName'].valid)">
                                                                Last Name is required.
                                                            </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="dob">Date of birth</label>
                                                            <input type="date" name="dob"
                                                                formControlName="fatherDOB" autocomplete="off"
                                                                class="new-user form-control bg-transparent" onkeydown="return false"
                                                                [ngClass]="{'border border-danger': ((valMemControls['fatherDOB'].touched || submitted) && !valMemControls['fatherDOB'].valid)}" [max]="maxDOB"/>
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['fatherDOB'].touched || submitted) && !valMemControls['fatherDOB'].valid)">
                                                                Date of birth is required.
                                                            </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label for="name" class="lb required">Occupation</label>
                                                                <select class="form-select form-select-custom" aria-label="Default select example"  formControlName="FatherOccupation"
                                                                [ngClass]="{'border border-danger': ((valMemControls['FatherOccupation'].touched || submitted) && !valMemControls['FatherOccupation'].valid)}">
                                                                    <option *ngFor="let occupation of occupationList" value="{{occupation.value}}">{{occupation.text}}</option>
                                                                </select>
                                                                <Span class="text-danger"
                                                                    *ngIf="((valMemControls['FatherOccupation'].touched || submitted) && !valMemControls['FatherOccupation'].valid)">
                                                                    Occupation is required.
                                                                </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="height">Height</label>
                                                            <div class="row">
                                                                <div class="col-6">
                                                                    <select class="form-select form-select-custom" name="fatherHeightFeet"
                                                                        formControlName="fatherHeightFeet"
                                                                        [ngClass]="{'border border-danger': ((valMemControls['fatherHeightFeet'].touched  || submitted) && !valMemControls['fatherHeightFeet'].valid)}">
                                                                        <option value="">Feets</option>
                                                                        <option *ngFor="let feet of feetList" value="{{feet}}">{{feet}}
                                                                            Feet</option>
                                                                    </select>
                                                                    <Span class="text-danger"
                                                                        *ngIf="((valMemControls['fatherHeightFeet'].touched  || submitted) && !valMemControls['fatherHeightFeet'].valid)">
                                                                        Height feet is required.
                                                                    </Span>
                                                                </div>
                                                                <div class="col-6">
                                                                    <select class="form-select form-select-custom"
                                                                        name="fatherHeightInches" formControlName="fatherHeightInches"
                                                                        [ngClass]="{'border border-danger': ((valMemControls['fatherHeightInches'].touched  || submitted) && !valMemControls['fatherHeightInches'].valid)}">
                                                                        <option value="">Inches</option>
                                                                        <option *ngFor="let inch of inchList" value="{{inch}}">{{inch}}
                                                                            Inch</option>
                                                                    </select>
                                                                    <Span class="text-danger"
                                                                        *ngIf="((valMemControls['fatherHeightInches'].touched  || submitted) && !valMemControls['fatherHeightInches'].valid)">
                                                                        Height inch(es) is required.
                                                                    </Span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="weight">Weight (Kg)</label>
                                                            <input type="number" class="form-control" name="fatherWeight"
                                                                formControlName="fatherWeight" placeholder="Enter KGs"
                                                                [ngClass]="{'border border-danger': ((valMemControls['fatherWeight'].touched  || submitted) && !valMemControls['fatherWeight'].valid)}"  onkeypress="if(this.value.length=='3') return false">
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['fatherWeight'].touched  || submitted) && !valMemControls['fatherWeight'].valid)">
                                                                Weight is required.
                                                            </Span>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngIf="healthData?.motherSelected">
                                                    <hr class="premium_breakup my-0"> 
                                                    <h5 class="text-start mt-5 member_heading">Mother's Information</h5>
                                                    <hr class="premium_breakup my-0"> 
                                                    <div class="row mb-3">
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="sname">Enter First Name</label>
                                                            <input type="text" class="form-control"
                                                                (keypress)="alphabetOnly($event)" name="motherFirstName"
                                                                formControlName="motherFirstName"
                                                                placeholder="Mother's First Name"
                                                                [ngClass]="{'border border-danger': ((valMemControls['motherFirstName'].touched  || submitted) && !valMemControls['motherFirstName'].valid)}">
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['motherFirstName'].touched || submitted) && !valMemControls['motherFirstName'].valid)">
                                                                First Name is required.
                                                            </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="sname">Enter Last Name</label>
                                                            <input type="text" class="form-control"
                                                                (keypress)="alphabetOnly($event)" name="motherName"
                                                                formControlName="motherLastName" placeholder="Mother's Last Name"
                                                                [ngClass]="{'border border-danger': ((valMemControls['motherLastName'].touched  || submitted) && !valMemControls['motherLastName'].valid)}">
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['motherLastName'].touched || submitted) && !valMemControls['motherLastName'].valid)">
                                                                Last Name is required.
                                                            </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="dob">Date of birth</label>
                                                            <input type="date" name="dob"
                                                                formControlName="motherDOB" autocomplete="off"
                                                                class="new-user form-control bg-transparent"
                                                                [ngClass]="{'border border-danger': ((valMemControls['motherDOB'].touched  || submitted) && !valMemControls['motherDOB'].valid)}" [max]="maxDOB" />
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['motherDOB'].touched || submitted) && !valMemControls['motherDOB'].valid)">
                                                                Date of birth is required.
                                                            </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label for="name" class="lb required">Occupation</label>
                                                                <select class="form-select form-select-custom" aria-label="Default select example " formControlName="MotherOccupation"
                                                                [ngClass]="{'border border-danger': ((valMemControls['MotherOccupation'].touched  || submitted) && !valMemControls['MotherOccupation'].valid)}">
                                                                    <option *ngFor="let occupation of occupationList" value="{{occupation.value}}">{{occupation.text}}</option>
                                                                </select>
                                                                <Span class="text-danger"
                                                                    *ngIf="((valMemControls['MotherOccupation'].touched || submitted) && !valMemControls['MotherOccupation'].valid)">
                                                                    Occupation is required.
                                                                </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="height">Height</label>
                                                            <div class="row">
                                                                <div class="col-6">
                                                                    <select class="form-select form-select-custom" name="motherHeightFeet"
                                                                        formControlName="motherHeightFeet"
                                                                        [ngClass]="{'border border-danger': ((valMemControls['motherHeightFeet'].touched  || submitted) && !valMemControls['motherHeightFeet'].valid)}">
                                                                        <option value="">Feets</option>
                                                                        <option *ngFor="let feet of feetList" value="{{feet}}">{{feet}}
                                                                            Feet</option>
                                                                    </select>
                                                                    <Span class="text-danger"
                                                                        *ngIf="((valMemControls['motherHeightFeet'].touched  || submitted) && !valMemControls['motherHeightFeet'].valid)">
                                                                        Height feet is required.
                                                                    </Span>
                                                                </div>
                                                                <div class="col-6">
                                                                    <select class="form-select form-select-custom"
                                                                        name="motherHeightInches" formControlName="motherHeightInches"
                                                                        [ngClass]="{'border border-danger': ((valMemControls['motherHeightInches'].touched  || submitted) && !valMemControls['motherHeightInches'].valid)}">
                                                                        <option value="">Inches</option>
                                                                        <option *ngFor="let inch of inchList" value="{{inch}}">{{inch}}
                                                                            Inch</option>
                                                                    </select>
                                                                    <Span class="text-danger"
                                                                        *ngIf="((valMemControls['motherHeightInches'].touched  || submitted) && !valMemControls['motherHeightInches'].valid)">
                                                                        Height inch(es) is required.
                                                                    </Span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="weight">Weight (Kg)</label>
                                                            <input type="number" class="form-control" name="motherWeight"
                                                                formControlName="motherWeight" placeholder="Enter KGs"
                                                                [ngClass]="{'border border-danger': ((valMemControls['motherWeight'].touched  || submitted) && !valMemControls['motherWeight'].valid)}"  onkeypress="if(this.value.length=='3') return false">
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['motherWeight'].touched  || submitted) && !valMemControls['motherWeight'].valid)">
                                                                Weight is required.
                                                            </Span>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngIf="healthData?.grandPaSelected">
                                                    <hr class="premium_breakup my-0"> 
                                                    <h5 class="text-start mt-5 member_heading"> Grand Father's Information</h5>
                                                    <hr class="premium_breakup my-0"> 
                                                    <div class="row mb-3">
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="sname">Enter First Name</label>
                                                            <input type="text" class="form-control"
                                                                (keypress)="alphabetOnly($event)" name="grandPaFirstName"
                                                                formControlName="grandPaFirstName"
                                                                placeholder="Grand Father's First Name"
                                                                [ngClass]="{'border border-danger': ((valMemControls['grandPaFirstName'].touched  || submitted) && !valMemControls['grandPaFirstName'].valid)}">
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['grandPaFirstName'].touched || submitted) && !valMemControls['grandPaFirstName'].valid)">
                                                                First Name is required.
                                                            </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="sname">Enter Last Name</label>
                                                            <input type="text" class="form-control"
                                                                (keypress)="alphabetOnly($event)" name="grandPaLastName"
                                                                formControlName="grandPaLastName"
                                                                placeholder="Grand Father's Last Name"
                                                                [ngClass]="{'border border-danger': ((valMemControls['grandPaLastName'].touched  || submitted) && !valMemControls['grandPaLastName'].valid)}">
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['grandPaLastName'].touched || submitted) && !valMemControls['grandPaLastName'].valid)">
                                                                Last Name is required.
                                                            </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="dob">Date of birth</label>
                                                            <input type="date" name="dob"
                                                                formControlName="grandPaDOB" autocomplete="off"
                                                                class="new-user form-control bg-transparent" onkeydown="return false"
                                                                [ngClass]="{'border border-danger': ((valMemControls['grandPaDOB'].touched  || submitted) && !valMemControls['grandPaDOB'].valid)}" [max]="maxDOB"/>
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['grandPaDOB'].touched || submitted) && !valMemControls['grandPaDOB'].valid)">
                                                                Date of birth is required.
                                                            </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label for="name" class="lb required">Occupation</label>
                                                                <select class="form-select form-select-custom" aria-label="Default select example" formControlName="grandPaOccupation"
                                                                [ngClass]="{'border border-danger': ((valMemControls['grandPaOccupation'].touched  || submitted) && !valMemControls['grandPaOccupation'].valid)}">
                                                                    <option *ngFor="let occupation of occupationList" value="{{occupation.value}}">{{occupation.text}}</option>
                                                                </select>
                                                                <Span class="text-danger"
                                                                    *ngIf="((valMemControls['grandPaOccupation'].touched || submitted) && !valMemControls['grandPaOccupation'].valid)">
                                                                    Occupation is required.
                                                                </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="height">Height</label>
                                                            <div class="row">
                                                                <div class="col-6">
                                                                    <select class="form-select form-select-custom"
                                                                        name="grandPaHeightFeet" formControlName="grandPaHeightFeet"
                                                                        [ngClass]="{'border border-danger': ((valMemControls['grandPaHeightFeet'].touched  || submitted) && !valMemControls['grandPaHeightFeet'].valid)}">
                                                                        <option value="">Feets</option>
                                                                        <option *ngFor="let feet of feetList" value="{{feet}}">{{feet}}
                                                                            Feet</option>
                                                                    </select>
                                                                    <Span class="text-danger"
                                                                        *ngIf="((valMemControls['grandPaHeightFeet'].touched  || submitted) && !valMemControls['grandPaHeightFeet'].valid)">
                                                                        Height feet is required.
                                                                    </Span>
                                                                </div>
                                                                <div class="col-6">
                                                                    <select class="form-select form-select-custom"
                                                                        name="grandPaHeightInches" formControlName="grandPaHeightInches"
                                                                        [ngClass]="{'border border-danger': ((valMemControls['grandPaHeightInches'].touched  || submitted) && !valMemControls['grandPaHeightInches'].valid)}">
                                                                        <option value="">Inches</option>
                                                                        <option *ngFor="let inch of inchList" value="{{inch}}">{{inch}}
                                                                            Inch</option>
                                                                    </select>
                                                                    <Span class="text-danger"
                                                                        *ngIf="((valMemControls['grandPaHeightInches'].touched  || submitted) && !valMemControls['grandPaHeightInches'].valid)">
                                                                        Height inch(es) is required.
                                                                    </Span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="weight">Weight (Kg)</label>
                                                            <input type="number" class="form-control" name="grandPaWeight"
                                                                formControlName="grandPaWeight" placeholder="Enter KGs"
                                                                [ngClass]="{'border border-danger': ((valMemControls['grandPaWeight'].touched  || submitted) && !valMemControls['grandPaWeight'].valid)}"  onkeypress="if(this.value.length=='3') return false">
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['grandPaWeight'].touched  || submitted) && !valMemControls['grandPaWeight'].valid)" >
                                                                Weight is required.
                                                            </Span>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngIf="healthData?.grandMaSelected">
                                                    <hr class="premium_breakup my-0">
                                                    <h5 class="text-start mt-5 member_heading">Grand Mother's Information</h5>
                                                    <hr class="premium_breakup my-0">
                                                    <div class="row mb-3">
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="sname">Enter First Name</label>
                                                            <input type="text" class="form-control"
                                                                (keypress)="alphabetOnly($event)" name="grandMaFirstName"
                                                                formControlName="grandMaFirstName"
                                                                placeholder="Grand Mother's First Name"
                                                                [ngClass]="{'border border-danger': ((valMemControls['grandMaFirstName'].touched  || submitted) && !valMemControls['grandMaFirstName'].valid)}">
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['grandMaFirstName'].touched || submitted) && !valMemControls['grandMaFirstName'].valid)">
                                                                First Name is required.
                                                            </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="sname">Enter Last Name</label>
                                                            <input type="text" class="form-control"
                                                                (keypress)="alphabetOnly($event)" name="grandMaLastName"
                                                                formControlName="grandMaLastName"
                                                                placeholder="Grand Mother's Last Name"
                                                                [ngClass]="{'border border-danger': ((valMemControls['grandMaLastName'].touched  || submitted) && !valMemControls['grandMaLastName'].valid)}">
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['grandMaLastName'].touched || submitted) && !valMemControls['grandMaLastName'].valid)">
                                                                Last Name is required.
                                                            </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="dob">Date of birth</label>
                                                            <input type="date" name="dob"
                                                                formControlName="grandMaDOB" autocomplete="off"
                                                                class="new-user form-control bg-transparent" onkeydown="return false"
                                                                [ngClass]="{'border border-danger': ((valMemControls['grandMaDOB'].touched  || submitted) && !valMemControls['grandMaDOB'].valid)}" [max]="maxDOB"/>
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['grandMaDOB'].touched || submitted) && !valMemControls['grandMaDOB'].valid)">
                                                                Date of birth is required.
                                                            </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label for="name" class="lb required">Occupation</label>
                                                                <select class="form-select form-select-custom" aria-label="Default select example" formControlName="grandMaOccupation"
                                                                [ngClass]="{'border border-danger': ((valMemControls['grandMaOccupation'].touched  || submitted) && !valMemControls['grandMaOccupation'].valid)}">
                                                                    <option *ngFor="let occupation of occupationList" value="{{occupation.value}}">{{occupation.text}}</option>
                                                                </select>
                                                                <Span class="text-danger"
                                                                    *ngIf="((valMemControls['grandMaOccupation'].touched || submitted) && !valMemControls['grandMaOccupation'].valid)">
                                                                    Occupation is required.
                                                                </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="height">Height</label>
                                                            <div class="row">
                                                                <div class="col-6">
                                                                    <select class="form-select form-select-custom"
                                                                        name="grandMaHeightFeet" formControlName="grandMaHeightFeet"
                                                                        [ngClass]="{'border border-danger': ((valMemControls['grandMaHeightFeet'].touched  || submitted) && !valMemControls['grandMaHeightFeet'].valid)}">
                                                                        <option value="">Feets</option>
                                                                        <option *ngFor="let feet of feetList" value="{{feet}}">{{feet}}
                                                                            Feet</option>
                                                                    </select>
                                                                    <Span class="text-danger"
                                                                        *ngIf="((valMemControls['grandMaHeightFeet'].touched  || submitted) && !valMemControls['grandMaHeightFeet'].valid)">
                                                                        Height feet is required.
                                                                    </Span>
                                                                </div>
                                                                <div class="col-6">
                                                                    <select class="form-select form-select-custom"
                                                                        name="grandMaHeightInches" formControlName="grandMaHeightInches"
                                                                        [ngClass]="{'border border-danger': ((valMemControls['grandMaHeightInches'].touched  || submitted) && !valMemControls['grandMaHeightInches'].valid)}">
                                                                        <option value="">Inches</option>
                                                                        <option *ngFor="let inch of inchList" value="{{inch}}">{{inch}}
                                                                            Inch</option>
                                                                    </select>
                                                                    <Span class="text-danger"
                                                                        *ngIf="((valMemControls['grandMaHeightInches'].touched  || submitted) && !valMemControls['grandMaHeightInches'].valid)">
                                                                        Height inch(es) is required.
                                                                    </Span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="weight">Weight (Kg)</label>
                                                            <input type="number" class="form-control" name="grandMaWeight"
                                                                formControlName="grandMaWeight" placeholder="Enter KGs"
                                                                [ngClass]="{'border border-danger': ((valMemControls['grandMaWeight'].touched  || submitted) && !valMemControls['grandMaWeight'].valid)}"  onkeypress="if(this.value.length=='3') return false">
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['grandMaWeight'].touched  || submitted) && !valMemControls['grandMaWeight'].valid)">
                                                                Weight is required.
                                                            </Span>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngIf="healthData?.fatherLawSelected">
                                                    <hr class="premium_breakup my-0">
                                                    <h5 class="text-start mt-5 member_heading">Father-in-Law's Information</h5>
                                                    <hr class="premium_breakup my-0">
                                                    <div class="row mb-3">
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="sname">Enter First Name</label>
                                                            <input type="text" class="form-control"
                                                                (keypress)="alphabetOnly($event)" name="fatherLawFirstName"
                                                                formControlName="fatherLawFirstName"
                                                                placeholder="Father-in-Law's First Name"
                                                                [ngClass]="{'border border-danger': ((valMemControls['fatherLawFirstName'].touched  || submitted) && !valMemControls['fatherLawFirstName'].valid)}">
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['fatherLawFirstName'].touched || submitted) && !valMemControls['fatherLawFirstName'].valid)">
                                                                First Name is required.
                                                            </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="sname">Enter Last Name</label>
                                                            <input type="text" class="form-control"
                                                                (keypress)="alphabetOnly($event)" name="fatherLawLastName"
                                                                formControlName="fatherLawLastName"
                                                                placeholder="Father-in-Law's Last Name"
                                                                [ngClass]="{'border border-danger': ((valMemControls['fatherLawLastName'].touched  || submitted) && !valMemControls['fatherLawLastName'].valid)}">
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['fatherLawLastName'].touched || submitted) && !valMemControls['fatherLawLastName'].valid)">
                                                                Last Name is required.
                                                            </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="dob">Date of birth</label>
                                                            <input type="date" name="dob"
                                                                formControlName="fatherLawDOB" autocomplete="off"
                                                                class="new-user form-control bg-transparent" onkeydown="return false"
                                                                [ngClass]="{'border border-danger': ((valMemControls['fatherLawDOB'].touched  || submitted) && !valMemControls['fatherLawDOB'].valid)}" [max]="maxDOB"/>
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['fatherLawDOB'].touched || submitted) && !valMemControls['fatherLawDOB'].valid)">
                                                                Date of birth is required.
                                                            </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label for="name" class="lb required">Occupation</label>
                                                                <select class="form-select form-select-custom" aria-label="Default select example" formControlName="fatherLawOccupation"
                                                                [ngClass]="{'border border-danger': ((valMemControls['fatherLawOccupation'].touched  || submitted) && !valMemControls['fatherLawOccupation'].valid)}">
                                                                    <option *ngFor="let occupation of occupationList" value="{{occupation.value}}">{{occupation.text}}</option>
                                                                </select>
                                                                <Span class="text-danger"
                                                                    *ngIf="((valMemControls['fatherLawOccupation'].touched || submitted) && !valMemControls['fatherLawOccupation'].valid)">
                                                                    Occupation is required.
                                                                </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="height">Height</label>
                                                            <div class="row">
                                                                <div class="col-6">
                                                                    <select class="form-select form-select-custom"
                                                                        name="fatherLawHeightFeet" formControlName="fatherLawHeightFeet"
                                                                        [ngClass]="{'border border-danger': ((valMemControls['fatherLawHeightFeet'].touched  || submitted) && !valMemControls['fatherLawHeightFeet'].valid)}">
                                                                        <option value="">Feets</option>
                                                                        <option *ngFor="let feet of feetList" value="{{feet}}">{{feet}}
                                                                            Feet</option>
                                                                    </select>
                                                                    <Span class="text-danger"
                                                                        *ngIf="((valMemControls['fatherLawHeightFeet'].touched  || submitted) && !valMemControls['fatherLawHeightFeet'].valid)">
                                                                        Height feet is required.
                                                                    </Span>
                                                                </div>
                                                                <div class="col-6">
                                                                    <select class="form-select form-select-custom"
                                                                        name="fatherLawHeightInches"
                                                                        formControlName="fatherLawHeightInches"
                                                                        [ngClass]="{'border border-danger': ((valMemControls['fatherLawHeightInches'].touched  || submitted) && !valMemControls['fatherLawHeightInches'].valid)}">
                                                                        <option value="">Inches</option>
                                                                        <option *ngFor="let inch of inchList" value="{{inch}}">{{inch}}
                                                                            Inch</option>
                                                                    </select>
                                                                    <Span class="text-danger"
                                                                        *ngIf="((valMemControls['fatherLawHeightInches'].touched  || submitted) && !valMemControls['fatherLawHeightInches'].valid)">
                                                                        Height inch(es) is required.
                                                                    </Span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="weight">Weight (Kg)</label>
                                                            <input type="number" class="form-control" name="fatherLawWeight"
                                                                formControlName="fatherLawWeight" placeholder="Enter KGs"
                                                                [ngClass]="{'border border-danger': ((valMemControls['fatherLawWeight'].touched  || submitted) && !valMemControls['fatherLawWeight'].valid)}"  onkeypress="if(this.value.length=='3') return false">
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['fatherLawWeight'].touched  || submitted) && !valMemControls['fatherLawWeight'].valid)">
                                                                Weight is required.
                                                            </Span>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngIf="healthData?.MotherLawSelected">
                                                    <hr class="premium_breakup my-0"> 
                                                    <h5 class="text-start mt-5 member_heading"> Mother-in-Law's Information</h5>
                                                    <hr class="premium_breakup my-0"> 
                                                    <div class="row mb-3">
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="sname">Enter First Name</label>
                                                            <input type="text" class="form-control"
                                                                (keypress)="alphabetOnly($event)" name="motherLawFirstName"
                                                                formControlName="motherLawFirstName"
                                                                placeholder="Mother-in-Law's First Name"
                                                                [ngClass]="{'border border-danger': ((valMemControls['motherLawFirstName'].touched  || submitted) && !valMemControls['motherLawFirstName'].valid)}">
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['motherLawFirstName'].touched || submitted) && !valMemControls['motherLawFirstName'].valid)">
                                                                First Name is required.
                                                            </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="sname">Enter Last Name</label>
                                                            <input type="text" class="form-control"
                                                                (keypress)="alphabetOnly($event)" name="motherLawLastName"
                                                                formControlName="motherLawLastName"
                                                                placeholder="Mother-in-Law's Last Name"
                                                                [ngClass]="{'border border-danger': ((valMemControls['motherLawLastName'].touched  || submitted) && !valMemControls['motherLawLastName'].valid)}">
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['motherLawLastName'].touched || submitted) && !valMemControls['motherLawLastName'].valid)">
                                                                Last Name is required.
                                                            </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="dob">Date of birth</label>
                                                            <input type="date" name="dob"
                                                                formControlName="motherLawDOB" autocomplete="off"
                                                                class="new-user form-control bg-transparent" onkeydown="return false"
                                                                [ngClass]="{'border border-danger': ((valMemControls['motherLawDOB'].touched  || submitted) && !valMemControls['motherLawDOB'].valid)}" [max]="maxDOB" />
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['motherLawDOB'].touched || submitted) && !valMemControls['motherLawDOB'].valid)">
                                                                Date of birth is required.
                                                            </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label for="name" class="lb required">Occupation</label>
                                                                <select class="form-select form-select-custom" aria-label="Default select example" formControlName="motherLawOccupation"
                                                                [ngClass]="{'border border-danger': ((valMemControls['motherLawOccupation'].touched  || submitted) && !valMemControls['motherLawOccupation'].valid)}">
                                                                    <option *ngFor="let occupation of occupationList" value="{{occupation.value}}">{{occupation.text}}</option>
                                                                </select>
                                                                <Span class="text-danger"
                                                                    *ngIf="((valMemControls['motherLawOccupation'].touched || submitted) && !valMemControls['motherLawOccupation'].valid)">
                                                                    Occupation is required.
                                                                </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="height">Height</label>
                                                            <div class="row">
                                                                <div class="col-6">
                                                                    <select class="form-select form-select-custom"
                                                                        name="motherLawHeightFeet" formControlName="motherLawHeightFeet"
                                                                        [ngClass]="{'border border-danger': ((valMemControls['motherLawHeightFeet'].touched  || submitted) && !valMemControls['motherLawHeightFeet'].valid)}">
                                                                        <option value="">Feets</option>
                                                                        <option *ngFor="let feet of feetList" value="{{feet}}">{{feet}}
                                                                            Feet</option>
                                                                    </select>
                                                                    <Span class="text-danger"
                                                                        *ngIf="((valMemControls['motherLawHeightFeet'].touched  || submitted) && !valMemControls['motherLawHeightFeet'].valid)">
                                                                        Height feet is required.
                                                                    </Span>
                                                                </div>
                                                                <div class="col-6">
                                                                    <select class="form-select form-select-custom"
                                                                        name="motherLawHeightInches"
                                                                        formControlName="motherLawHeightInches"
                                                                        [ngClass]="{'border border-danger': ((valMemControls['motherLawHeightInches'].touched  || submitted) && !valMemControls['motherLawHeightInches'].valid)}">
                                                                        <option value="">Inches</option>
                                                                        <option *ngFor="let inch of inchList" value="{{inch}}">{{inch}}
                                                                            Inch</option>
                                                                    </select>
                                                                    <Span class="text-danger"
                                                                        *ngIf="((valMemControls['motherLawHeightInches'].touched  || submitted) && !valMemControls['motherLawHeightInches'].valid)">
                                                                        Height inch(es) is required.
                                                                    </Span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="weight">Weight (Kg)</label>
                                                            <input type="number" class="form-control" name="motherLawWeight"
                                                                formControlName="motherLawWeight" placeholder="Enter KGs"
                                                                [ngClass]="{'border border-danger': ((valMemControls['motherLawWeight'].touched  || submitted) && !valMemControls['motherLawWeight'].valid)}"  onkeypress="if(this.value.length=='3') return false">
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['motherLawWeight'].touched  || submitted) && !valMemControls['motherLawWeight'].valid)">
                                                                Weight is required.
                                                            </Span>
                                                        </div>
            
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngIf="healthData?.uncleSelected">
                                                    <hr class="premium_breakup my-0"> 
                                                    <h5 class="text-start mt-5 member_heading">Uncle's Information</h5>
                                                    <hr class="premium_breakup my-0"> 
                                                    <div class="row mb-3">
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="sname">Enter First Name</label>
                                                            <input type="text" class="form-control"
                                                                (keypress)="alphabetOnly($event)" name="uncleFirstName"
                                                                formControlName="uncleFirstName"
                                                                placeholder="Uncle's First Name"
                                                                [ngClass]="{'border border-danger': ((valMemControls['uncleFirstName'].touched  || submitted) && !valMemControls['uncleFirstName'].valid)}">
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['uncleFirstName'].touched || submitted) && !valMemControls['uncleFirstName'].valid)">
                                                                First Name is required.
                                                            </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="sname">Enter Last Name</label>
                                                            <input type="text" class="form-control"
                                                                (keypress)="alphabetOnly($event)" name="uncleLastName"
                                                                formControlName="uncleLastName" placeholder="Uncle's Last Name"
                                                                [ngClass]="{'border border-danger': ((valMemControls['uncleLastName'].touched  || submitted) && !valMemControls['uncleLastName'].valid)}">
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['uncleLastName'].touched || submitted) && !valMemControls['uncleLastName'].valid)">
                                                                Last Name is required.
                                                            </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="dob">Date of birth</label>
                                                            <input type="date" name="dob"
                                                                formControlName="uncleDOB" autocomplete="off"
                                                                class="new-user form-control bg-transparent"
                                                                [ngClass]="{'border border-danger': ((valMemControls['uncleDOB'].touched  || submitted) && !valMemControls['uncleDOB'].valid)}" [max]="maxDOB"/>
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['uncleDOB'].touched || submitted) && !valMemControls['uncleDOB'].valid)">
                                                                Date of birth is required.
                                                            </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label for="name" class="lb required">Occupation</label>
                                                                <select class="form-select form-select-custom" aria-label="Default select example" formControlName="uncleOccupation"
                                                                [ngClass]="{'border border-danger': ((valMemControls['uncleOccupation'].touched  || submitted) && !valMemControls['uncleOccupation'].valid)}">
                                                                    <option *ngFor="let occupation of occupationList" value="{{occupation.value}}">{{occupation.text}}</option>
                                                                </select>
                                                                <Span class="text-danger"
                                                                    *ngIf="((valMemControls['uncleOccupation'].touched || submitted) && !valMemControls['uncleOccupation'].valid)">
                                                                    Occupation is required.
                                                                </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="height">Height</label>
                                                            <div class="row">
                                                                <div class="col-6">
                                                                    <select class="form-select form-select-custom" name="uncleHeightFeet"
                                                                        formControlName="uncleHeightFeet"
                                                                        [ngClass]="{'border border-danger': ((valMemControls['uncleHeightFeet'].touched  || submitted) && !valMemControls['uncleHeightFeet'].valid)}">
                                                                        <option value="">Feets</option>
                                                                        <option *ngFor="let feet of feetList" value="{{feet}}">{{feet}}
                                                                            Feet</option>
                                                                    </select>
                                                                    <Span class="text-danger"
                                                                        *ngIf="((valMemControls['uncleHeightFeet'].touched  || submitted) && !valMemControls['uncleHeightFeet'].valid)">
                                                                        Height feet is required.
                                                                    </Span>
                                                                </div>
                                                                <div class="col-6">
                                                                    <select class="form-select form-select-custom"
                                                                        name="uncleHeightInches" formControlName="uncleHeightInches"
                                                                        [ngClass]="{'border border-danger': ((valMemControls['uncleHeightInches'].touched  || submitted) && !valMemControls['uncleHeightInches'].valid)}">
                                                                        <option value="">Inches</option>
                                                                        <option *ngFor="let inch of inchList" value="{{inch}}">{{inch}}
                                                                            Inch</option>
                                                                    </select>
                                                                    <Span class="text-danger"
                                                                        *ngIf="((valMemControls['uncleHeightInches'].touched  || submitted) && !valMemControls['uncleHeightInches'].valid)">
                                                                        Height inch(es) is required.
                                                                    </Span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="weight">Weight (Kg)</label>
                                                            <input type="number" class="form-control" name="uncleWeight"
                                                                formControlName="uncleWeight" placeholder="Enter KGs"
                                                                [ngClass]="{'border border-danger': ((valMemControls['uncleWeight'].touched  || submitted) && !valMemControls['uncleWeight'].valid)}"  onkeypress="if(this.value.length=='3') return false">
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['uncleWeight'].touched  || submitted) && !valMemControls['uncleWeight'].valid)">
                                                                Weight is required.
                                                            </Span>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngIf="healthData?.auntSelected">
                                                    <hr class="premium_breakup my-0"> 
                                                    <h5 class="text-start mt-5 member_heading">Aunt's Information</h5>
                                                    <hr class="premium_breakup my-0"> 
                                                    <div class="row mb-3">
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="sname">Enter First Name</label>
                                                            <input type="text" class="form-control"
                                                                (keypress)="alphabetOnly($event)" name="auntFirstName"
                                                                formControlName="auntFirstName" placeholder="Aunt's First Name"
                                                                [ngClass]="{'border border-danger': ((valMemControls['auntFirstName'].touched  || submitted) && !valMemControls['auntFirstName'].valid)}">
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['auntFirstName'].touched || submitted) && !valMemControls['auntFirstName'].valid)">
                                                                First Name is required.
                                                            </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="sname">Enter Last Name</label>
                                                            <input type="text" class="form-control"
                                                                (keypress)="alphabetOnly($event)" name="auntLastName"
                                                                formControlName="auntLastName" placeholder="Aunt's Last Name"
                                                                [ngClass]="{'border border-danger': ((valMemControls['auntLastName'].touched  || submitted) && !valMemControls['auntLastName'].valid)}">
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['auntLastName'].touched || submitted) && !valMemControls['auntLastName'].valid)">
                                                                Last Name is required.
                                                            </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="dob">Date of birth</label>
                                                            <input type="date" name="dob"
                                                                formControlName="auntDOB" autocomplete="off"
                                                                class="new-user form-control bg-transparent" onkeydown="return false"
                                                                [ngClass]="{'border border-danger': ((valMemControls['auntDOB'].touched  || submitted) && !valMemControls['auntDOB'].valid)}" [max]="maxDOB"/>
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['auntDOB'].touched || submitted) && !valMemControls['auntDOB'].valid)">
                                                                Date of birth is required.
                                                            </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label for="name" class="lb required">Occupation</label>
                                                                <select class="form-select form-select-custom" aria-label="Default select example" formControlName="auntOccupation"
                                                                [ngClass]="{'border border-danger': ((valMemControls['auntOccupation'].touched  || submitted) && !valMemControls['auntOccupation'].valid)}">
                                                                    <option *ngFor="let occupation of occupationList" value="{{occupation.value}}">{{occupation.text}}</option>
                                                                </select>
                                                                <Span class="text-danger"
                                                                    *ngIf="((valMemControls['auntOccupation'].touched || submitted) && !valMemControls['auntOccupation'].valid)">
                                                                    Occupation is required.
                                                                </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="height">Height</label>
                                                            <div class="row">
                                                                <div class="col-6">
                                                                    <select class="form-select form-select-custom" name="auntHeightFeet"
                                                                        formControlName="auntHeightFeet"
                                                                        [ngClass]="{'border border-danger': ((valMemControls['auntHeightFeet'].touched  || submitted) && !valMemControls['auntHeightFeet'].valid)}">
                                                                        <option value="">Feets</option>
                                                                        <option *ngFor="let feet of feetList" value="{{feet}}">{{feet}}
                                                                            Feet</option>
                                                                    </select>
                                                                    <Span class="text-danger"
                                                                        *ngIf="((valMemControls['auntHeightFeet'].touched  || submitted) && !valMemControls['auntHeightFeet'].valid)">
                                                                        Height feet is required.
                                                                    </Span>
                                                                </div>
                                                                <div class="col-6">
                                                                    <select class="form-select form-select-custom" name="auntHeightInches"
                                                                        formControlName="auntHeightInches"
                                                                        [ngClass]="{'border border-danger': ((valMemControls['auntHeightInches'].touched  || submitted) && !valMemControls['auntHeightInches'].valid)}">
                                                                        <option value="">Inches</option>
                                                                        <option *ngFor="let inch of inchList" value="{{inch}}">{{inch}}
                                                                            Inch</option>
                                                                    </select>
                                                                    <Span class="text-danger"
                                                                        *ngIf="((valMemControls['auntHeightInches'].touched  || submitted) && !valMemControls['auntHeightInches'].valid)">
                                                                        Height inch(es) is required.
                                                                    </Span>
                                                                </div>
                                                            </div>   
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label class="lb required" for="weight">Weight (Kg)</label>
                                                            <input type="number" class="form-control" name="auntWeight"
                                                                formControlName="auntWeight" placeholder="Enter KGs"
                                                                [ngClass]="{'border border-danger': ((valMemControls['auntWeight'].touched  || submitted) && !valMemControls['auntWeight'].valid)}  "  onkeypress="if(this.value.length=='3') return false">  
                                                            <Span class="text-danger"
                                                                *ngIf="((valMemControls['auntWeight'].touched  || submitted) && !valMemControls['auntWeight'].valid)">
                                                                Weight is required.
                                                            </Span>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </form>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6 mt-5  text-start">
                                            <button class="btn btn-primary-outline btn-outline"
                                                (click)="detail = 'address'">Back</button>
                                        </div>
                                        <div class="col-md-6 mt-5  text-end">
                                            <button class="btn btn-primary"
                                                (click)="InsuranceDetails('nominee')">Next</button>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                            <div id="nominee" class="col-12 c-pl c-pr" *ngIf="detail == 'nominee'">
                                <ng-container>
                                    <div class="card p-5 row br-top-0">
                                        <form id="kt_vehicle_details_form" class="form" [formGroup]="nomineeForm"
                                            novalidate="novalidate">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <label class="lb required" for="name">Nominee First Name</label>
                                                    <input type="text" class="form-control" name="nomineeFirstName"
                                                        formControlName="nomineeFirstName" placeholder="Nominee First Name"
                                                        [ngClass]="{'border border-danger': ((valNomControls['nomineeFirstName'].touched ) && !valNomControls['nomineeFirstName'].valid)}">
                                                    <Span class="text-danger"
                                                        *ngIf="((valNomControls['nomineeFirstName'].touched  || submitted) && !valNomControls['nomineeFirstName'].valid)">
                                                        First Name is required.
                                                    </Span>
                                                </div>
                                                <div class="col-md-6">
                                                    <label class="lb required" for="name">Nominee Last Name</label>
                                                    <input type="text" class="form-control" name="nomineeLastName"
                                                        formControlName="nomineeLastName" placeholder="Nominee Last Name"
                                                        [ngClass]="{'border border-danger': ((valNomControls['nomineeLastName'].touched ) && !valNomControls['nomineeLastName'].valid)}">
                                                    <Span class="text-danger"
                                                        *ngIf="((valNomControls['nomineeLastName'].touched  || submitted) && !valNomControls['nomineeLastName'].valid)">
                                                        Last Name is required.
                                                    </Span>
                                                </div>
                                                <div class="col-md-6 mt-4">
                                                    <label class="lb required" for="name">Relationship with proposer</label>
                                                    <select class="form-select form-select-custom" name="nomineeRelationWithProposer"
                                                        formControlName="nomineeRelationWithProposer"
                                                        [ngClass]="{'border border-danger': ((valNomControls['nomineeRelationWithProposer'].touched ) && !valNomControls['nomineeRelationWithProposer'].valid)}">
                                                        <option value="">Select Relationship</option>
                                                        <option value="Father">Father</option>
                                                        <option value="Mother">Mother</option>
                                                        <option value="Son">Son</option>
                                                        <option value="Daughter">Daughter</option>
                                                        <option value="Uncle">Uncle</option>
                                                        <option value="Aunt">Aunt</option>
                                                        <option value="Brother">Brother</option>
                                                        <option value="Sister">Sister</option>
                                                        <option value="Spouse">Spouse</option>
                                                        <option value="Grand Son">Grand Son</option>
                                                        <option value="Grand Daughter">Grand Daughter</option>
                                                    </select>
                                                    <Span class="text-danger"
                                                        *ngIf="((valNomControls['nomineeRelationWithProposer'].touched  || submitted) && !valNomControls['nomineeRelationWithProposer'].valid)">
                                                        Relationship is required.
                                                    </Span>
                                                </div>
                                                <div class="col-md-6 mt-4">
                                                    <label class="lb required" for="name">Date of Birth</label>
                                                    <input type="date" class="form-control" name="nomineeDOB" [max]="maxDOB"
                                                        formControlName="nomineeDOB" onkeydown="return false"
                                                        [ngClass]="{'border border-danger': ((valNomControls['nomineeDOB'].touched ) && !valNomControls['nomineeDOB'].valid)}">
                                                    <Span class="text-danger"
                                                        *ngIf="((valNomControls['nomineeDOB'].touched  || submitted) && !valNomControls['nomineeDOB'].valid)">
                                                        Date of birth is required.
                                                    </Span>
                                                </div>
                                                <div class="col-md-6 mt-4">
                                                    <label class="required lb" for="gender">Gender</label>
                                                    <select name="gender" id="gender" class="form-select form-select-custom"
                                                            formControlName="nomineeGender"
                                                            [ngClass]="{'border border-danger': ((valNomControls['nomineeGender'].touched ) && !valNomControls['nomineeGender'].valid)}">
                                                        <option *ngFor="let gender of genderList" [value]="gender">
                                                            {{gender == '' ? 'Select Gender' : gender}}
                                                        </option>
                                                    </select>  
                                                    <Span class="text-danger"
                                                        *ngIf="((valNomControls['nomineeGender']?.touched ) && !valNomControls['nomineeGender']?.valid)">
                                                        Gender is required.
                                                    </Span>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6 mt-5  text-start">
                                            <button class="btn btn-primary-outline btn-outline"
                                                (click)="detail = 'members'">Back</button>
                                        </div>
                                        <div class="col-md-6 mt-5  text-end">
                                            <button class="btn btn-primary" [disabled]="nomineeForm.invalid"
                                                (click)="InsuranceDetails('medical')" > Next</button>
                                        </div>
                                    </div>
        
                                </ng-container>
                            </div>
                            <div id="medical" class="col-12 c-pl c-pr" *ngIf="detail == 'medical'">
                                <ng-container>
                                    <div class="card p-5 row br-top-0">
                                        <div class="details_card">
                                            <!-- <form class="form needs-validation" [formGroup]="nomineeForm" novalidate=""> -->
                                                <h4 class="text-start">Help us know the medical condition, if any</h4>
                                                <ng-container *ngFor="let item of insurerDetails">
                                                    <div class="collapsible-panel my-2">
                                                        <div class="panel-header"
                                                            (click)="toggleMembersQuestion(item.for)">
                                                            <span class="d-flex align-items-center">{{item.firstName+'
                                                                '+item.lastName}}</span>
                                                            <span class="collapse-icon"> <img
                                                                    src="../../assets/svgIcon/{{ item.for == this.activePanel ? 'ChevronUp' : 'ChevronDown' }}.svg"
                                                                    alt=""></span>
                                                        </div>
                                                        <div class="panel-content collapsed mh-400-scroll border" style="padding: 0px 10px !important;"
                                                            [ngClass]="{ 'expanded': item.for == this.activePanel}">
                                                            <div class="row">
                                                                <div
                                                                    class="col-12 py-1 border-bottom d-flex align-items-center">
                                                                    <app-dynamic-components [id]="item.for"
                                                                        (dataEvent)="getSavedData($event)"
                                                                        [required_input_fields]="item.medicalQuestion"
                                                                        [questionFor]="item.for"></app-dynamic-components>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            <!-- </form> -->
                                        </div>
        
                                    </div>
        
                                    <div class="row">
                                        <div class="col-md-6 mt-5  text-start">
                                            <button class="btn btn-primary-outline btn-outline"
                                                (click)="detail = 'nominee'">Back</button>
                                        </div>
                                        <div class="col-md-6 mt-5  text-end">
        
                                            <button class="btn btn-primary" (click)="continue()">Continue</button>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="isSummary">
                        <div class="summary-view">
                            <!-- Panel-1 -->
                            <div class="col-12 px-0 shadow_ rounded1 card rounded-bottom1-0">
                                <div class="collapsible-panel rounded-top1">
                                    <div class="panel-header rounded-top1" (click)="panel = 1">
                                        <span class="d-flex align-items-center">Proposer Details
                                            <span class="text-main d-flex font-14 align-items-center" (click)="detail = 'proposer';isSummary = false">
                                                <img src="/assets/svgIcon/PencilLineBlack.svg" alt="Edit" class="icon-main edit-icon cursor-pointer ml-2 mr-1 height-18"> Edit
                                            </span> 
                                        </span>
                                        <span class="collapse-icon"><img src="../../assets/svgIcon/{{ panel == 1 ? 'ChevronUp' : 'ChevronDown' }}.svg" alt=""></span>
                                    </div>
                                    <div class="panel-content" [ngClass]="{ 'expanded': panel == 1,'collapsed': panel != 1 }">
                                        <div class="row">
                                            <div class="col-6 border-bottom">
                                                <div class="form-group">
                                                    <label class="form-label">Name</label>
                                                    <span>{{(valPropControls['proposerFirstName']?.value??'') +' '+ (valPropControls['proposerLastName']?.value??'')}}</span>
                                                </div>
                                            </div>
                                            <div class="col-6 border-bottom">
                                                <div class="form-group">
                                                    <label class="form-label">Date of Birth</label>
                                                    <span>{{valPropControls['selfDOB']?.value}}</span>
                                                </div>
                                            </div>
                                            <div class="col-6 border-bottom">
                                                <div class="form-group">
                                                    <label class="form-label">PAN Card Number</label>
                                                    <span>{{valPropControls['proposerPancard']?.value}}</span>
                                                </div>
                                            </div>
                                            <div class="col-6 border-bottom">
                                                <div class="form-group">
                                                    <label class="form-label">Mobile Number</label>
                                                    <span>{{valPropControls['proposerMobile']?.value}}</span>
                                                </div>
                                            </div>
                                            <div class="col-6 border-bottom">
                                                <div class="form-group">
                                                    <label class="form-label">Email ID</label>
                                                    <span>{{valPropControls['proposerEmail']?.value}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Panel-2 -->
                            <div class="col-12 px-0 shadow_ rounded0 card">
                                <div class="collapsible-panel">
                                    <div class="panel-header" (click)="panel = 2">
                                        <span class="d-flex align-items-center">Address Details
                                            <span class="text-main d-flex font-14 align-items-center" (click)="detail = 'address';isSummary = false">
                                                <img src="/assets/svgIcon/PencilLineBlack.svg" alt="Edit" class="icon-main edit-icon cursor-pointer ml-2 mr-1 height-18"> Edit
                                            </span> 
                                        </span>
                                        <span class="collapse-icon"><img src="../../assets/svgIcon/{{ panel == 2 ? 'ChevronUp' : 'ChevronDown' }}.svg" alt=""></span>
                                    </div>
                                    <div class="panel-content" [ngClass]="{ 'expanded': panel == 2,'collapsed': panel != 2 }">
                                        <div class="row">
                                            <div class="col-6 border-bottom">
                                                <div class="form-group">
                                                    <label class="form-label">Flat Number</label>
                                                    <span>{{valAddressPropControls['flatNumber']?.value}}</span>
                                                </div>
                                            </div>
                                            <div class="col-6 border-bottom">
                                                <div class="form-group">
                                                    <label class="form-label">Street Address</label>
                                                    <span>{{valAddressPropControls['streetAddress']?.value}}</span>
                                                </div>
                                            </div>
                                            <div class="col-6 border-bottom">
                                                <div class="form-group">
                                                    <label class="form-label">State</label>
                                                    <span>{{valAddressPropControls['proposerState']?.value}}</span>
                                                </div>
                                            </div>
                                            <div class="col-6 border-bottom">
                                                <div class="form-group">
                                                    <label class="form-label">District</label>
                                                    <span>{{valAddressPropControls['district']?.value}}</span>
                                                </div>
                                            </div>
                                            <div class="col-6 border-bottom">
                                                <div class="form-group">
                                                    <label class="form-label">City</label>
                                                    <span>{{valAddressPropControls['proposerCity']?.value}}</span>
                                                </div>
                                            </div>
                                            <div class="col-6 border-bottom">
                                                <div class="form-group">
                                                    <label class="form-label">Pincode</label>
                                                    <span>{{valAddressPropControls['proposerPinCode']?.value}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Panel-3 -->
                            <div class="col-12 px-0 shadow_ rounded0 card">
                                <div class="collapsible-panel">
                                    <div class="panel-header" (click)="panel = 3">
                                        <span class="d-flex align-items-center">Members Details
                                            <span class="text-main d-flex font-14 align-items-center" (click)="detail = 'members';isSummary = false">
                                                <img src="/assets/svgIcon/PencilLineBlack.svg" alt="Edit" class="icon-main edit-icon cursor-pointer ml-2 mr-1 height-18"> Edit
                                            </span> 
                                        </span>
                                        <span class="collapse-icon"><img src="../../assets/svgIcon/{{ panel == 3 ? 'ChevronUp' : 'ChevronDown' }}.svg" alt=""></span>
                                    </div>
                                    <div class="panel-content" [ngClass]="{ 'expanded': panel == 3,'collapsed': panel != 3 }">
                                        <h4 class="text-start member_heading mb-0 mt-2">Member 1</h4>
                                        <hr class="premium_breakup my-0">
                                        <div class="row">
                                            <div class="col-6 border-bottom">
                                                <div class="form-group">
                                                    <label class="form-label">Name</label>
                                                    <span>{{valMemControls['selfFirstName']?.value+' '+valMemControls['selfLastName']?.value}}</span>
                                                </div>
                                            </div>
                                            <div class="col-6 border-bottom">
                                                <div class="form-group">
                                                    <label class="form-label">Date of Birth</label>
                                                    <span>{{valMemControls['selfDOB']?.value}}</span>
                                                </div>
                                            </div>
                                            <div class="col-6 border-bottom">
                                                <div class="form-group">
                                                    <label class="form-label">Occupation</label>
                                                    <span>{{valMemControls['selfOccupation']?.value}}</span>
                                                </div>
                                            </div>
                                            <div class="col-6 border-bottom">
                                                <div class="form-group">
                                                    <label class="form-label">Height</label>
                                                    <span>{{valMemControls['selfHeightFeet']?.value +'  Feet ' +  valMemControls['selfHeightInches']?.value + ' Inch(es)' }}</span>
                                                </div>
                                            </div>
                                            <div class="col-6 border-bottom">
                                                <div class="form-group">
                                                    <label class="form-label">Weight (Kg)</label>
                                                    <span>{{valMemControls['selfWeight']?.value}}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <ng-container *ngIf="healthData?.spouseSelected">
                                            <hr class="premium_breakup my-0">
                                            <h4 class="text-start member_heading mb-0 mt-2">Spouse's Information</h4>
                                            <hr class="premium_breakup my-0">
                                            <div class="row">
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Name</label>
                                                        <span>{{valMemControls['spouseFirstName']?.value+' '+valMemControls['spouseLastName']?.value}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Date of Birth</label>
                                                        <span>{{valMemControls['spouseDOB']?.value}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Occupation</label>
                                                        <span>{{valMemControls['spouseOccupation']?.value}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Height</label>
                                                        <span>{{valMemControls['spouseHeightFeet']?.value +'  Feet ' +  valMemControls['spouseHeightInches']?.value + ' Inch(es)' }}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Weight (Kg)</label>
                                                        <span>{{valMemControls['spouseWeight']?.value}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="healthData?.firstSone">
                                            <hr class="premium_breakup my-0">
                                            <h4 class="text-start member_heading mb-0 mt-2">1st Son's Information</h4>
                                            <hr class="premium_breakup my-0">
                                            <div class="row">
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Name</label>
                                                        <span>{{valMemControls['son1FirstName']?.value+' '+valMemControls['son1LastName']?.value}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Date of Birth</label>
                                                        <span>{{valMemControls['son1DOB']?.value}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Occupation</label>
                                                        <span>{{valMemControls['son1Occupation']?.value}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Height</label>
                                                        <span>{{valMemControls['son1HeightFeet']?.value +'  Feet ' +  valMemControls['son1HeightInches']?.value + ' Inch(es)' }}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Weight (Kg)</label>
                                                        <span>{{valMemControls['son1Weight']?.value}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="healthData?.secondSon">
                                            <hr class="premium_breakup my-0">
                                            <h4 class="text-start member_heading mb-0 mt-2">2nd Son's Information</h4>
                                            <hr class="premium_breakup my-0">
                                            <div class="row">
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Name</label>
                                                        <span>{{valMemControls['son2FirstName']?.value+' '+valMemControls['son2LastName']?.value}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Date of Birth</label>
                                                        <span>{{valMemControls['son2DOB']?.value}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Occupation</label>
                                                        <span>{{valMemControls['son2Occupation']?.value}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Height</label>
                                                        <span>{{valMemControls['son2HeightFeet']?.value +'  Feet ' +  valMemControls['son2HeightInches']?.value + ' Inch(es)' }}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Weight (Kg)</label>
                                                        <span>{{valMemControls['son2Weight']?.value}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="healthData?.thirdSon">
                                            <hr class="premium_breakup my-0">
                                            <h4 class="text-start member_heading mb-0 mt-2">3rd Son's Information</h4>
                                            <hr class="premium_breakup my-0">
                                            <div class="row">
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Name</label>
                                                        <span>{{valMemControls['son3FirstName']?.value+' '+valMemControls['son3LastName']?.value}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Date of Birth</label>
                                                        <span>{{valMemControls['son3DOB']?.value}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Occupation</label>
                                                        <span>{{valMemControls['son3Occupation']?.value}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Height</label>
                                                        <span>{{valMemControls['son3HeightFeet']?.value +'  Feet ' +  valMemControls['son3HeightInches']?.value + ' Inch(es)' }}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Weight (Kg)</label>
                                                        <span>{{valMemControls['son3Weight']?.value}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="healthData?.fourthSon">
                                            <hr class="premium_breakup my-0">
                                            <h4 class="text-start member_heading mb-0 mt-2">4th Son's Information</h4>
                                            <hr class="premium_breakup my-0">
                                            <div class="row">
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Name</label>
                                                        <span>{{valMemControls['son4FirstName']?.value+' '+valMemControls['son4LastName']?.value}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Date of Birth</label>
                                                        <span>{{valMemControls['son4DOB']?.value}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Occupation</label>
                                                        <span>{{valMemControls['son4Occupation']?.value}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Height</label>
                                                        <span>{{valMemControls['son4HeightFeet']?.value +'  Feet ' +  valMemControls['son4HeightInches']?.value + ' Inch(es)' }}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Weight (Kg)</label>
                                                        <span>{{valMemControls['son4Weight']?.value}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="healthData?.firstDaughter">
                                            <hr class="premium_breakup my-0">
                                            <h4 class="text-start member_heading mb-0 mt-2">1st Daughter Information</h4>
                                            <hr class="premium_breakup my-0">
                                            <div class="row">
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Name</label>
                                                        <span>{{valMemControls['dau1FirstName']?.value+' '+valMemControls['dau1LastName']?.value}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Date of Birth</label>
                                                        <span>{{valMemControls['dau1DOB']?.value}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Occupation</label>
                                                        <span>{{valMemControls['dau1Occupation']?.value}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Height</label>
                                                        <span>{{valMemControls['dau1HeightFeet']?.value +'  Feet ' +  valMemControls['dau1HeightInches']?.value + ' Inch(es)' }}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Weight (Kg)</label>
                                                        <span>{{valMemControls['dau1Weight']?.value}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="healthData?.secondDaughter">
                                            <hr class="premium_breakup my-0">
                                            <h4 class="text-start member_heading mb-0 mt-2">2nd Daughter Information</h4>
                                            <hr class="premium_breakup my-0">
                                            <div class="row">
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Name</label>
                                                        <span>{{valMemControls['dau2FirstName']?.value+' '+valMemControls['dau2LastName']?.value}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Date of Birth</label>
                                                        <span>{{valMemControls['dau2DOB']?.value}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Occupation</label>
                                                        <span>{{valMemControls['dau2Occupation']?.value}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Height</label>
                                                        <span>{{valMemControls['dau2HeightFeet']?.value +'  Feet ' +  valMemControls['dau2HeightInches']?.value + ' Inch(es)' }}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Weight (Kg)</label>
                                                        <span>{{valMemControls['dau2Weight']?.value}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="healthData?.thirdDaughter">
                                            <hr class="premium_breakup my-0">
                                            <h4 class="text-start member_heading mb-0 mt-2">3rd Daughter Information</h4>
                                            <hr class="premium_breakup my-0">
                                            <div class="row">
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Name</label>
                                                        <span>{{valMemControls['dau3FirstName']?.value+' '+valMemControls['dau3LastName']?.value}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Date of Birth</label>
                                                        <span>{{valMemControls['dau3DOB']?.value}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Occupation</label>
                                                        <span>{{valMemControls['dau3Occupation']?.value}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Height</label>
                                                        <span>{{valMemControls['dau3HeightFeet']?.value +'  Feet ' +  valMemControls['dau3HeightInches']?.value + ' Inch(es)' }}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Weight (Kg)</label>
                                                        <span>{{valMemControls['dau3Weight']?.value}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="healthData?.fourthDaughter">
                                            <hr class="premium_breakup my-0">
                                            <h4 class="text-start member_heading mb-0 mt-2">4th Daughter Information</h4>
                                            <hr class="premium_breakup my-0">
                                            <div class="row">
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Name</label>
                                                        <span>{{valMemControls['dau4FirstName']?.value+' '+valMemControls['dau4LastName']?.value}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Date of Birth</label>
                                                        <span>{{valMemControls['dau4DOB']?.value}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Occupation</label>
                                                        <span>{{valMemControls['dau4Occupation']?.value}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Height</label>
                                                        <span>{{valMemControls['dau4HeightFeet']?.value +'  Feet ' +  valMemControls['dau4HeightInches']?.value + ' Inch(es)' }}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Weight (Kg)</label>
                                                        <span>{{valMemControls['dau4Weight']?.value}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="healthData?.fatherSelected">
                                            <hr class="premium_breakup my-0">
                                            <h4 class="text-start member_heading mb-0 mt-2">Father's Information</h4>
                                            <hr class="premium_breakup my-0">
                                            <div class="row">
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Name</label>
                                                        <span>{{valMemControls['fatherFirstName']?.value+' '+valMemControls['fatherFirstName']?.value}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Date of Birth</label>
                                                        <span>{{valMemControls['fatherDOB']?.value}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Occupation</label>
                                                        <span>{{valMemControls['FatherOccupation']?.value}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Height</label>
                                                        <span>{{valMemControls['fatherHeightFeet']?.value +'  Feet ' +  valMemControls['fatherHeightInches']?.value + ' Inch(es)' }}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Weight (Kg)</label>
                                                        <span>{{valMemControls['fatherWeight']?.value}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="healthData?.motherSelected">
                                            <hr class="premium_breakup my-0">
                                            <h4 class="text-start member_heading mb-0 mt-2">Mother's Information</h4>
                                            <hr class="premium_breakup my-0">
                                            <div class="row">
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Name</label>
                                                        <span>{{valMemControls['motherFirstName']?.value+' '+valMemControls['motherLastName']?.value}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Date of Birth</label>
                                                        <span>{{valMemControls['motherDOB']?.value}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Occupation</label>
                                                        <span>{{valMemControls['MotherOccupation']?.value}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Height</label>
                                                        <span>{{valMemControls['motherHeightFeet']?.value +'  Feet ' +  valMemControls['motherHeightInches']?.value + ' Inch(es)' }}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Weight (Kg)</label>
                                                        <span>{{valMemControls['motherWeight']?.value}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="healthData?.motherSelected">
                                            <hr class="premium_breakup my-0">
                                            <h4 class="text-start member_heading mb-0 mt-2">Mother's Information</h4>
                                            <hr class="premium_breakup my-0">
                                            <div class="row">
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Name</label>
                                                        <span>{{valMemControls['motherFirstName']?.value+' '+valMemControls['motherLastName']?.value}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Date of Birth</label>
                                                        <span>{{valMemControls['motherDOB']?.value}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Occupation</label>
                                                        <span>{{valMemControls['MotherOccupation']?.value}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Height</label>
                                                        <span>{{valMemControls['motherHeightFeet']?.value +'  Feet ' +  valMemControls['motherHeightInches']?.value + ' Inch(es)' }}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Weight (Kg)</label>
                                                        <span>{{valMemControls['motherWeight']?.value}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="healthData?.grandPaSelected">
                                            <hr class="premium_breakup my-0">
                                            <h4 class="text-start member_heading mb-0 mt-2">Grand Father's Information</h4>
                                            <hr class="premium_breakup my-0">
                                            <div class="row">
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Name</label>
                                                        <span>{{valMemControls['grandPaFirstName']?.value+' '+valMemControls['grandPaLastName']?.value}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Date of Birth</label>
                                                        <span>{{valMemControls['grandPaDOB']?.value}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Occupation</label>
                                                        <span>{{valMemControls['grandPaOccupation']?.value}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Height</label>
                                                        <span>{{valMemControls['grandPaHeightFeet']?.value +'  Feet ' +  valMemControls['grandPaHeightInches']?.value + ' Inch(es)' }}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Weight (Kg)</label>
                                                        <span>{{valMemControls['grandPaWeight']?.value}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="healthData?.grandMaSelected">
                                            <hr class="premium_breakup my-0">
                                            <h4 class="text-start member_heading mb-0 mt-2">Grand Mother's Information</h4>
                                            <hr class="premium_breakup my-0">
                                            <div class="row">
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Name</label>
                                                        <span>{{valMemControls['grandMaFirstName']?.value+' '+valMemControls['grandMaLastName']?.value}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Date of Birth</label>
                                                        <span>{{valMemControls['grandMaDOB']?.value}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Occupation</label>
                                                        <span>{{valMemControls['grandMaOccupation']?.value}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Height</label>
                                                        <span>{{valMemControls['grandMaHeightFeet']?.value +'  Feet ' +  valMemControls['grandMaHeightInches']?.value + ' Inch(es)' }}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Weight (Kg)</label>
                                                        <span>{{valMemControls['grandMaWeight']?.value}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="healthData?.fatherLawSelected">
                                            <hr class="premium_breakup my-0">
                                            <h4 class="text-start member_heading mb-0 mt-2">Father-in-Law's Information</h4>
                                            <hr class="premium_breakup my-0">
                                            <div class="row">
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Name</label>
                                                        <span>{{valMemControls['fatherLawFirstName']?.value+' '+valMemControls['fatherLawLastName']?.value}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Date of Birth</label>
                                                        <span>{{valMemControls['fatherLawDOB']?.value}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Occupation</label>
                                                        <span>{{valMemControls['fatherLawOccupation']?.value}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Height</label>
                                                        <span>{{valMemControls['fatherLawHeightFeet']?.value +'  Feet ' +  valMemControls['fatherLawHeightInches']?.value + ' Inch(es)' }}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Weight (Kg)</label>
                                                        <span>{{valMemControls['fatherLawWeight']?.value}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="healthData?.MotherLawSelected">
                                            <hr class="premium_breakup my-0">
                                            <h4 class="text-start member_heading mb-0 mt-2">Mother-in-Law's Information</h4>
                                            <hr class="premium_breakup my-0">
                                            <div class="row">
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Name</label>
                                                        <span>{{valMemControls['motherLawFirstName']?.value+' '+valMemControls['motherLawLastName']?.value}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Date of Birth</label>
                                                        <span>{{valMemControls['motherLawDOB']?.value}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Occupation</label>
                                                        <span>{{valMemControls['motherLawOccupation']?.value}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Height</label>
                                                        <span>{{valMemControls['motherLawHeightFeet']?.value +'  Feet ' +  valMemControls['motherLawHeightInches']?.value + ' Inch(es)' }}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Weight (Kg)</label>
                                                        <span>{{valMemControls['motherLawWeight']?.value}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="healthData?.uncleSelected">
                                            <hr class="premium_breakup my-0">
                                            <h4 class="text-start member_heading mb-0 mt-2">Uncle's Information</h4>
                                            <hr class="premium_breakup my-0">
                                            <div class="row">
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Name</label>
                                                        <span>{{valMemControls['uncleFirstName']?.value+' '+valMemControls['uncleLastName']?.value}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Date of Birth</label>
                                                        <span>{{valMemControls['uncleDOB']?.value}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Occupation</label>
                                                        <span>{{valMemControls['uncleOccupation']?.value}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Height</label>
                                                        <span>{{valMemControls['uncleHeightFeet']?.value +'  Feet ' +  valMemControls['uncleHeightInches']?.value + ' Inch(es)' }}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Weight (Kg)</label>
                                                        <span>{{valMemControls['uncleWeight']?.value}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="healthData?.auntSelected">
                                            <hr class="premium_breakup my-0">
                                            <h4 class="text-start member_heading mb-0 mt-2">Aunt's Information</h4>
                                            <hr class="premium_breakup my-0">
                                            <div class="row">
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Name</label>
                                                        <span>{{valMemControls['auntFirstName']?.value+' '+valMemControls['auntLastName']?.value}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Date of Birth</label>
                                                        <span>{{valMemControls['auntDOB']?.value}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Occupation</label>
                                                        <span>{{valMemControls['auntOccupation']?.value}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">Height</label>
                                                        <span>{{valMemControls['auntHeightFeet']?.value +' Feet '+ valMemControls['auntHeightInches']?.value + ' Inch(es)' }}</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-bottom">
                                                    <div class="form-group">
                                                        <label class="form-label">auntWeight (Kg)</label>
                                                        <span>{{valMemControls['motherWeight']?.value}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>                        
                            <!-- Panel-4 -->
                            <div class="col-12 px-0 shadow_ rounded0 card">
                                <div class="collapsible-panel">
                                    <div class="panel-header" (click)="panel = 4">
                                        <span class="d-flex align-items-center">Nominees Details
                                            <span class="text-main d-flex font-14 align-items-center" (click)="detail = 'nominee';isSummary = false">
                                                <img src="/assets/svgIcon/PencilLineBlack.svg" alt="Edit" class="icon-main edit-icon cursor-pointer ml-2 mr-1 height-18"> Edit
                                            </span> 
                                        </span>
                                        <span class="collapse-icon"><img src="../../assets/svgIcon/{{ panel == 4 ? 'ChevronUp' : 'ChevronDown' }}.svg" alt=""></span>
                                    </div>
                                    <div class="panel-content" [ngClass]="{ 'expanded': panel == 4,'collapsed': panel != 4 }">
                                        <div class="row">
                                            <div class="col-6 border-bottom">
                                                <div class="form-group">
                                                    <label class="form-label">Nominee Name</label>
                                                    <span>{{valNomControls['nomineeFirstName']?.value+' '+valNomControls['nomineeLastName']?.value}}</span>
                                                </div>
                                            </div>
                                            <div class="col-6 border-bottom">
                                                <div class="form-group">
                                                    <label class="form-label">Relationship with Proposer</label>
                                                    <span>{{valNomControls['nomineeRelationWithProposer']?.value}}</span>
                                                </div>
                                            </div>
                                            <div class="col-6 border-bottom">
                                                <div class="form-group">
                                                    <label class="form-label">Date of Birth</label>
                                                    <span>{{valNomControls['nomineeDOB']?.value}}</span>
                                                </div>
                                            </div>
                                            <div class="col-6 border-bottom">
                                                <div class="form-group">
                                                    <label class="form-label">Gender</label>
                                                    <span>{{valNomControls['nomineeGender']?.value}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Panel-5 -->
                            <div class="col-12 px-0 shadow_ rounded0 card rounded-bottom1">
                                <div class="collapsible-panel">
                                    <div class="panel-header" (click)="panel = 5">
                                        <span class="d-flex align-items-center">Medical Details
                                            <span class="text-main d-flex font-14 align-items-center" (click)="detail = 'medical';isSummary = false">
                                                <img src="/assets/svgIcon/PencilLineBlack.svg" alt="Edit" class="icon-main edit-icon cursor-pointer ml-2 mr-1 height-18"> Edit
                                            </span> 
                                        </span>
                                        <span class="collapse-icon"><img src="../../assets/svgIcon/{{ panel == 5 ? 'ChevronUp' : 'ChevronDown' }}.svg" alt=""></span>
                                    </div>
                                    <div class="panel-content mt-2" [ngClass]="{ 'expanded': panel == 5,'collapsed': panel != 5 }">
                                        <ng-container *ngFor="let item of insurerDetails">
                                            <div class="collapsible-panel my-2">
                                                <div class="panel-header bg-grey2"
                                                    (click)="toggleMembersQuestion(item.for)">
                                                    <span class="d-flex align-items-center">{{item.firstName+'
                                                        '+item.lastName}}</span>
                                                    <span class="collapse-icon"> <img
                                                            src="../../assets/svgIcon/{{ item.for == this.activePanel ? 'ChevronUp' : 'ChevronDown' }}.svg"
                                                            alt=""></span>
                                                </div>
                                                <div class="panel-content collapsed mh-400-scroll border"
                                                    style="padding:0px 10px !important"
                                                    [ngClass]="{ 'expanded': item.for == this.activePanel}">
                                                    <div class="row">
                                                        <div
                                                            class="col-12 py-1 border-bottom d-flex align-items-center">
                                                            <app-dynamic-components [id]="item.for"
                                                                (dataEvent)="getSavedData($event)"
                                                                [required_input_fields]="item.medicalQuestion"
                                                                [questionFor]="item.for" [isReadOnly]="true"></app-dynamic-components>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>   
                    </ng-container>
                
                </div>
            </div>
        </ng-container>
        <div *ngIf="isPaymentLink">
            <app-payment-progress [paymentObject]="paymentObject"></app-payment-progress>
        </div>
        <div class="overlay" *ngIf="showKYCPopup">
            <div class="popup2 position-relative col-md-11 col-11 height90">
              <div class="modal-header tooltip_">
                <div class="modal-title">
                  <h4>Complete KYC</h4>
                </div>
                <button type="button" class="close pull-right close1" aria-label="Close" (click)="closeKYCModal()">
                  <span aria-hidden="true"></span>
                </button>
              </div>
              <div class="modal-body position-relative height90">
                <iframe id="ifr2" [src]="kycUrl" target="_parent" frameborder='0' class="iframeclass"></iframe>
              </div>
            </div>
        </div>
        <div class="overlay" *ngIf="showhttpKYCPopup">
            <div class="popup2 position-relative col-md-11 col-11 height90">
              <div class="modal-header tooltip_">
                <div class="modal-title">
                  <h4>Complete KYC</h4>
                </div>
                <button type="button" class="close pull-right close1" aria-label="Close" (click)="closehttpKYCModal()">
                  <span aria-hidden="true"></span>
                </button>
              </div>
              <div class="modal-body position-relative height90">        
                <span class="font-17-13 text-grey5 d-block mt-2">Please use below link to update your KYC details</span>
                <br />
                <a id="failedKYCLink" target="_blank" (click)="closehttpKYCModal()" [href]="kycUrl">Click here to update your
                  KYC details</a>
                <br />
                <span class="font-17-13 text-grey5 d-block mt-2">Once your KYC is done, return to this window and click on the
                  pay button</span>
        
              </div>
            </div>
        </div>
    </div>