import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { GeneralService } from './Services/general.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
    ShowMenu: boolean = false;
  title = 'WebPortal';
  vehicleNo: any;  
  headerWidth:number=0;
  topnav1: HTMLElement;
  
  isAgent: boolean = this.generalService.getIsAgent() === 'true' ? true : false;
  currentpage: string = 'Dashboard';
  isSmallScreen: boolean = false; 
  @HostListener('window:resize', ['$event']) onResize(event: any) { this.checkScreenSize(); } 
  checkScreenSize() { this.isSmallScreen = window.innerWidth <= 450; 
  }
  constructor(private router: Router,private generalService: GeneralService) { }

  ngOnInit() {
    // this.headerWidth =this.topnav1.nativeElement.width;
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (event.url.includes("/login") || event.url == "/" || event.url.includes("/signup") || event.url.includes("/forgotpassword") || event.url.includes("/resetpassword")) {
          this.ShowMenu = false;
        }
        if (event.url.includes("/contactus") || event.url == "/" || event.url.includes("/two-wheeler-insurance") || event.url.includes("/Vacancy") || event.url.includes("/aboutus") || event.url.includes("/career") || event.url.includes("/sitemap") || event.url.includes("/faqs") || event.url.includes("/taxi-insurance") || event.url.includes("/commercial-vehicle-insurance") || event.url.includes("/third-party-car-insurance") || event.url.includes("/third-party-two-wheeler-insurance") || event.url.includes("/family-health-insurance") || event.url.includes("/individual-health-insurance") || event.url.includes("/term-insurance") || event.url.includes("/group-health-insurance") || event.url.includes("/senior-citizen-health-insurance") || event.url.includes("/critical-illness-insurance") || event.url.includes("/arogya-sanjivni-insurance") || event.url.includes("/single-pay-insurance") || event.url.includes("/group-term-insurance") || event.url.includes("/tax-saving-insurance") || event.url.includes("/money-back-insurance") || event.url.includes("/guaranteed-return-insurance") || event.url.includes("/ulip-insurance") || event.url.includes("/pension-insurance") || event.url.includes("/child-insurance") || event.url.includes("/privacy-policy") || event.url.includes("/cancellation-refund") || event.url.includes("/term-condition") || event.url.includes("/disclaimer") || event.url.includes("/grivance-redressal") || event.url.includes("/partner-home") || event.url.includes("/CommercialVechicles") || event.url.includes("/property-policy")  || event.url.includes("/property-summary")|| event.url.includes("/travel-policy") || event.url.includes("/travel-detail") || event.url.includes("/travel-summary") || event.url.includes("/vehicle-quotes") || event.url.includes("/VehicleOwnerDetails") || event.url.includes("/health-quotes") || event.url.includes("/healthOwnerDetails")) {
          this.ShowMenu = false;
        }
        else
        if (this.generalService.getUserLabel() == "NL") {
          this.ShowMenu = false;
        }
        else
        if (this.generalService.getAccessToken() != "") {
          this.ShowMenu = true;
        }
        else {
          this.ShowMenu = false;
        }
      }
    });
    // sessionStorage.clear();
    // localStorage.clear();
  }

  setShowMenuFlag(flag:boolean)
  {
    this.ShowMenu = flag;
  }

  async setCurrentPage(currentpage1: string) {
    this.currentpage = currentpage1;
  }

  // @HostListener('window:resize', ['$event'])
  // onWindowResize() {
  //   this.headerWidth = window.innerWidth;
  //   this.topnav1 = document.getElementById('topnav1') as HTMLElement;
  //   let sidenav =document.getElementById('sideNav') as HTMLElement;
  //   console.log(this.topnav1.style.width);
  //   console.log(sidenav.offsetWidth);
  //   this.topnav1.style.width =  ((window.innerWidth - sidenav.clientWidth) -26) +"px";
  // }
}
