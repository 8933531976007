export class VehicleInfoModel {
  public registrationNumber!: string;
  public model!: number;
  public make!: number;
  public registrationYear!: string;
  public variant!: number;
  public userId!: number;
  public fuelType!: number;
  public policyExpiryDate!: any;
  public vehicleCompanyName: any;
  public vehicleVarientName: any;
  public vehicleModelName: any;
  public fuelTypeName: any;
}

export class LookUpViewModel {
  public id!: number;
  public name!: string;
}
export class PinCodeMasterViewModel {
  public id!: number;
  public country!: string;
  public state!: string;
  public district!: string;
  public Country!: string;
  public cityVillageArea!: string;
  public taluka!: string;
  public pincode!: string;
}

export class RTONamesViewModel {
  public cityRtoId!: number;
  public cityName!: string;
  public regionCode: string;
}
export class RegistrationModel {
  vehicle_engine_number: string;
  vehicle_chasi_number: string;
  rc_number: string;
  registration_date: string;
  maker_model: string;
  maker_description: string;
}

export class VehicleDetails {
  city?: string;
  rto?: string;
  make?: string;
  model?: string;
  variant?: string;
  registrationYear?: string;
  registrationNumber?: string;
  chasisNumber?: string;
  engineNumber?: string;
  registrationDate?: string;
  vehiclecategory?:string;
  isPreviousPolicyHolder?:string;
  previousPolicyType?:string;
  previousPolicyExpiryDate?:string;
  claimsMadeInPreviousPolicy?: string;
  claimsMadeInPreviousPolicyNo?: string;
  claimsMadeInPreviousPolicyAmount?: string;
  firstName?: string;
  lastName?: string;
  ncbprevious?: string; 
  typeOfCover?: string;
  vehicleIDV?:string;
  bodyIDV?:string;
  chassisIDV?:string;
  previousPolicyCompany?:string;
  previousPolicyNumber?:string;
  ncbCurrent?: string;
  fuelType?: string;
  engineCapacityAmount?: string;
  paCover?:string;
  alreadyHavePACover?:string;
  paPolicyTerm?:string;
  registeredInTheNameOf?:string;
  isValidDrivingLicenseAvailable?: string;
  premium?: string;
  isFinanced?: string;
  financedValue?: string;
  financierName?: string;
  financeType?:string;
  vehicleIDVObj?:VehicleIDVObj[];
  isTPPD?:boolean;
  companyNameForCar?:string;
  companyGSTINForCar?:string;
  policyTerm?: string;
  isNew?: boolean;
  category?: string;
  gcVehicle?: string;
  pcVehicle?: string;
  purpose?: string;
  seatingCap?: string;
  miscVehicle?: string;
  gVW?: string;
  permit?: string;
  policyExpiryStatus?: string;
  isOwnerShipTransferred?: string;
  policyClaimed?: string;
  policyClaimedNCB?: string;
  isNCBProtectionOptedInExistingPolicy?: string = "No";
  isNilDepOptedInExistingPolicy?: string = "No";
  isInvoiceCoverOptedInExistingPolicy?: string = "No";
  isConsumableOptedInExistingPolicy?: string ="No";
  isEngineCoverOptedInExistingPolicy?: string = "No";
  isTyreCoverOptedInExistingPolicy?: string ="No";

  numberOfFPP?: string;
  numberOfNFPP?: string;
  numberOfTrailers?: string;
  financierBranchName?:string = "";
  isElecricalAccessoriesCoverOptedInExistingPolicy?: string = "No";
  isNonElecricalAccessoriesOptedInExistingPolicy?: string ="No";
  isBiFuelKitOptedInExistingPolicy?: string ="No";
  pincode?:string;
  vehicleGVWObj?:VehicleGVWObj[];
  vehicleGVW?:string;
  show_TP_QuotesOnly?:string;
  isBHNumber?:string;
}


export class CommercialVehicleDeatils {
  registrationNumber?: string;
  category?: string;
  vehicleType?: string;
  GVW?: string;
  passCap?:string;
  permit?: string;
  expiredStatus?: string;
  existingPolicyComapany?: string;
  previousPolicyExpiryDate?: string;
  previousPolicyType?:string;
  isOwnerShipTransferred?:string;
  claimsMadeInPreviousPolicy?: string;
  ncbCurrent?: string;
  isNew?: boolean;
}

export class PremiumDetails {
  premium?: string;
  gst?: string;
  netPremium?: string;
  
}

export class InsuranceDetails{
  vehicleDetails?:VehicleDetails;
  isAgent?:boolean;
  // Filter?:Filter;
  filter?:Filter;
  VehicleType?:number;
  vehicleType?:number;
  isMockUp?:boolean;
  nomineeDetails?:any;
  proposerDetails?:any;
  quoteId?:string;
  registrationNumber?:string;
  // IsUnNamedPACover?:boolean;
  isUnNamedPACover?:boolean;
  unNamedPACoverAmt?:string;
  isBiFuelKit?:boolean;
  biFuelKitAmt?:string;
  biFuelKitType?:string;//CNG/LPG
  biFuelKitFitting?:string; //CompanyFitted  /AfterMarket
  isElectricalAccessories?:boolean;
  electricalAccessoriesAmt?:string;
  isNonElectricalAccessories?:boolean;
  nonElectricalAccessoriesAmt?:string;
  insuranceCompany?:number;
  userId?:number;
  existingTPPolicyDetails?:ExistingTPPolicyDetails;
  PolicyNumber?:string;
  ApplicationId?:string;
  url?:string;
  requestId?:string;
  isReferal?:boolean;
  isLLToPaidDriver?:boolean;
  isPAToPaidDriver?:boolean;
  isPAToConductor?:boolean;
  isPAToCleaner?:boolean;
  isIMT23?:boolean;
  isIMT47?:boolean;
  isAntiTheftDeviceDiscount?:boolean;
  antiTheftARAINumber?:string;
  isGeographicalExtension?:boolean;
  isLLToNonEmployee?:boolean;
  isLLToEmployee?:boolean;
  numberOfDrivers?: string;
  numberOfCleaners?: string;
  numberOfEmployees?: string;
  numberOfNonEmployees?: string;
  numberOfFPP?: string;
  numberOfNFPP?: string;
  numberOfTrailers?: string;
  llToPaidDriverAmt?: string;
  paToPaidDriverAmt?: string;
  paToConductorAmt?: string;
  paToCleanerAmt?: string;
  isFPP?:boolean = false;
  isNFPP?:boolean = false;
  isNumOfTrailers?:boolean = false;
  isLLToCleaners?:boolean = false;
  geographicalExtensionLocations?: string;
  isLLToConductoer?:boolean = false;
  numberOfConductor?: string;
  isValidPUC?:boolean = false;
  pucNumber?: string = "";
  pucValidUpTo?: string = "";
  isBreakIn?:boolean=false;
  priceBreakup?:PriceBreakup;
  addOnsList?:any;
  isBreakInProposal?:boolean;
}

export class Filter {
  // AddOnsIds?: string;
  addOnsIds?: string;
}

export class SelectedOption{
  label: string
  value:string
  value1:string
  order:number
} 

export class DropDownList{
  text: string
  value:string
} 

export class VehicleIDVObj{
  idv:number
  defaultIdv:number
  minimumIdv:number
  maximumIdv:number
  insuranceCompany:number
}

export class PriceBreakup{
  basicOwnDamagePremium?:string;
  thirdPartyCoverPremium?:string;
  ownDriverCover?:string;
  paidDriverCover?:string;
  zeroDep?:string;
  rSA?:string;
  engineProtectionCover?:string;
  consumables?:string;
  tyreProtector?:string;
  ncB_Discount?:string;
  antiTheftDeviceDiscount?:string;
  voluntaryDeductibleDiscount?:string;
  otherDiscounts?:string;
  netPremium?:string;
  serviceTaxPerc?:string;
  serviceTaxValue?:string;
  finalPremium?:string;
  keyLockReplacement?:string;
  lossofPersonalBelongings?:string;
  addOnValue?:string;
  paCover?:string;
  unamedPassenger?:string;
  biFuelKit?:string;
  biFuelKit_TP?:string;
  electricalAccessories?:string;
  nonElectricalAccessories?:string;
  tppD_Discount?:string;
  fpP_Premium?:string;
  nfpP_Premium?:string;
  trailers_Premium?:string;
  antiTheftDisc?:string;
  paToPaidDriver?:string;
  paToPaidConductor?:string;
  paToPaidCleaner?:string;
  paToEmployee?:string;
  llToPaidDriver?:string;
  llToPaidConductor?:string;
  llToPaidCleaner?:string;
  llToEmployee?:string;
  llToNonEmployee?:string;
  geographicalExtension_OD?:string;
  geographicalExtension_TP?:string;
  alreadyHavPACover?:string;
  deTariffDiscount?:string;
  insuranceCompany?:number;
  llToPassenger?:string;
  imT23?:string;
  additionalGVW?:string;
  commercialDiscount?:string;
}

export class ExistingTPPolicyDetails{
  tpInsurer?:string;
  tpPolicyNumber?:string;
  tpPolicyTerm?:string;
  tpInceptionDate?:string;
  tpExpiryDate?:string;
  tpAddress1?:string;
  tpAddress2?:string;
  tpInsurerAddress?:string;
}

export class HealthPriceBreakup{
  netPremium?:string;
  serviceTaxValue?:string;
  finalPremium?:string; 
  otherDiscounts?:string;
  serviceTaxPerc?:string;
}

export class VehicleGVWObj{
  gVW:number
  defaultGVW:number
  minimumGVW:number
  maximumGVW:number
  insuranceCompany:number
}