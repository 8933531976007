import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MemberFormField, MembersForm } from 'src/app/Models/MembersForm';
import * as moment from 'moment';
import { AutoCompleteItem } from 'src/app/Models/Item';
import { LookUpService } from 'src/app/Services/lookup.service';
@Component({
  selector: 'app-dynamic-member',
  templateUrl: './dynamic-member.component.html',
  styleUrls: ['./dynamic-member.component.css']
})
export class DynamicMemberComponent {
  @Input() required_input_fields: MembersForm;
  @Input() questionFor: string;
  @Input() containerClass: string ="";
  @Input() isReadOnly: boolean =false;
  @Output() dataEvent  = new EventEmitter<any>(); 

  //AutoComplete
  @Input() pincodeList:AutoCompleteItem[] = [];
  today = new Date();
  nomineeMaxDOB = moment(this.today).add(-18, 'years').format('YYYY-MM-DD');
  maxDOB = moment(this.today).format('YYYY-MM-DD');
  constructor(private lookUpService:LookUpService){

  }
  onChange(id:string,event:any, type:any="", parentId:any =""){
    let selectedValue = type !="checkbox" ? event.target.value : (event.target.checked == true ? "Yes" : "No");
    console.log(id + '-' + selectedValue);
    this.required_input_fields.memberFormFields = this.required_input_fields.memberFormFields?.map((obj:MemberFormField) => {
      if (obj.id === id) {
          return { ...obj, selectedValue: selectedValue };
      }
      return obj;
    });
    this.dataEvent.emit({data: this.required_input_fields,parentId:parentId,for:this.required_input_fields.for});
  }

  onPincodeSelection(id:string,data:AutoCompleteItem, type:any="", parentId:any ="")
  {
    this.required_input_fields.memberFormFields = this.required_input_fields.memberFormFields?.map((obj:MemberFormField) => {
      if (obj.id === id) {
          return { ...obj, selectedValue: data.pincodeMaster?.pincode };
      }
      else if(obj.id?.startsWith("AddressState")){
        return { ...obj, selectedValue: data.pincodeMaster?.state?.toUpperCase()??"" };
      }
      else if(obj.id?.startsWith("AddressCity")){
        return { ...obj, selectedValue: data.pincodeMaster?.district?.toUpperCase()??"" };
      }
      return obj;
    });
    this.dataEvent.emit({data: this.required_input_fields,parentId:parentId,for:this.required_input_fields.for});
 }

 onInputChange(event: any): void {
  const initialValue = event.target.value;
  const input = event.target as HTMLInputElement;
    // Strictly replace anything not a digit.
    event.target.value = initialValue.replace(/[^0-9]/g, '');
    if (input.value.length > 6) {
        input.value = input.value.slice(0, 6);
        event.stopPropagation();
      }
    if (initialValue !== event.target.value) {
      event.stopPropagation();    
    }
  }

  async onPincodeChange(data:any) {
    if(data.length >= 3){
      const res = await this.lookUpService.pincodeMasterDetails(data);
      if (res != null && res.result) {
        this.pincodeList = res.result.map((item:any)=> {return {text:item.pincode,value:item.pincode,text1: item.cityVillageArea, id:item.id,pincodeMaster:item}});
      }
    }    
  }

  onInputPhone(event: any): void {
    const initialValue = event.target.value;
    // Strictly replace anything not a digit.
    event.target.value = initialValue.replace(/[^0-9]/g, '');
    if (initialValue !== event.target.value) {
      event.stopPropagation();
    }
  }

  validateNumberInput(event: KeyboardEvent): void {
    // Allow: backspace, delete, tab, escape, enter, and numbers from the numpad and numbers row
    if ([46, 8, 9, 27, 13].indexOf(event.keyCode) !== -1 ||
         // Allow: Ctrl/cmd+A
        (event.keyCode === 65 && (event.ctrlKey === true || event.metaKey === true)) ||
         // Allow: Ctrl/cmd+C
        (event.keyCode === 67 && (event.ctrlKey === true || event.metaKey === true)) ||
         // Allow: Ctrl/cmd+X
        (event.keyCode === 88 && (event.ctrlKey === true || event.metaKey === true)) ||
         // Allow: home, end, left, right
        (event.keyCode >= 35 && event.keyCode <= 39)) {
         // let it happen, don't do anything
         return;
    }
    // Ensure that it is a number and stop the keypress if not
    if ((event.shiftKey || (event.keyCode < 48 || event.keyCode > 57)) && (event.keyCode < 96 || event.keyCode > 105)) {
        event.preventDefault();
    }
  }
  
  isEmailValid(value:any):boolean{
    debugger;
    
    return true;
  }
}
