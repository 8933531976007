<div class="container">
  <div class="bg-grey9">
    <div class="row" [ngClass]="{'d-none' : !isTravelPage}">
      <div class="col-lg-6 col-md-6 col-sm-6 col-xl-6 col-12">
        <div class="insurance_img" style="padding-top: 50px; padding-bottom: 25px;">
          <img src="../../assets/images/Travel_img.png" alt="">
        </div>
      </div>
      
      <div class="col-12 col-md-6 col-lg-6 col-xl-6 align-self-center"  style="display:none">
        <img src="../../../assets/images/newicons/travels.svg" width="60%" height="100%" alt="">
      </div>
      <div class="col-12 col-md-6 col-lg-6 col-xl-6 align-self-center">
        <!-- <form [formGroup]="insuranceForm" class="needs-validation" novalidate autocomplete="off"> -->
        <div class="px-5">
          <h2 class="heading text-left" style="font-size: 32px ;font-weight: 500;">Get the best Travel Insurance for you
            and your family.</h2>
          <h5 class="text-left text-grey4 fw-medium h-tx">Select your Destinations</h5>
          <br>

          <div class="d-flex">
            <app-multi-dropdown class="w-100 multiselect_custom mobileIcon" [items]="destinationList"
              [showSearch]="true" [showAll]="false" [showStatus]="false" [showError]="true"
              [className]="'travel-ui-class'" [selectedItemsCountShow]="5" (itemChange)="onDestinationSelect($event)"
              placeholder="Select destinations">
            </app-multi-dropdown>
            <button class="btn btn-primary inputButton" (click)="isTrevel()">
              Continue
            </button>
          </div>
          <span class="text-danger w-100" *ngIf="showDestinationError">Destrination is required.</span>
        </div>
      </div>
      <div class="overlay verifybikedetailsdiv verifybikedetailsdiv1 modal right" *ngIf="sidemenue">
        <div class="popup2 col-md-7 col-lg-4 col-11" style=" overflow-x: unset;">
          <div class="modal-header tooltip_">
            <h5 class="modal-title">Travel Insurance &nbsp;&nbsp;&nbsp;<img *ngIf="selectedTab == 1"
                src="../../../assets/svgIcon/1-3.svg" alt=""><img src="../../../assets/svgIcon/2-3.svg"
                *ngIf="selectedTab == 2" alt=""><img src="../../../assets/svgIcon/3-3.svg" alt=""
                *ngIf="selectedTab == 3">
            </h5>
            <button type="button" class="close btn pull-right" aria-label="Close" (click)=" close()">
              <span aria-hidden="true"></span>
            </button>
          </div>
          <div class="modal-body">
            <div *ngIf="selectedTab == 1">
              <div class="row">
                <div class="col-md-12 mt-2">
                  <p><img src="../../../assets/svgIcon/arrow_back_ios.svg" alt="" (click)="close()">&nbsp;&nbsp;
                    Select Details </p>
                </div>
                <div class="12">
                  <div class="row border-bottom">
                    <div class="col-md-4 my-2"> <button [class.highlight]="isFamily" class="bt"
                        (click)="goto('self')">Self/Family</button></div>
                    <div class="col-md-4 my-2"> <button [class.highlight]="isGroup" class="bt"
                        (click)="goto('group')">Group</button></div>
                    <div class="col-md-4 my-2"> <button [class.highlight]="isStudent" class="bt"
                        (click)="goto('student')">Student</button></div>
                  </div>
                </div>
                <div class="col-md-12 mt-5 d-flex align-items-center">
                  <p class="mb-0 text-nowrap mr-4">Travel Purpose</p>
                  <select class="form-select form-select-custom" (change)="travelPurposeChange($event)" [(ngModel)]="travelPurpose">
                    <option [value]="'Business'">Business</option>
                    <option [value]="'Leisure'">Leisure</option>
                  </select>
                </div>
                <div class="col-md-12" *ngIf="isFamily">
                  <div class="row">
                    <div class="col-md-12 mt-5 d-flex align-items-center">
                      <p class="mb-0 text-nowrap  mr-4">Select Members</p>
                      <select class="form-select form-select-custom" (change)="bindMembers($event,'family')" [(ngModel)]="familyMembersCountSelected">
                        <option *ngFor="let m of businessMembersCount" [value]="m">{{m}}</option>
                      </select>
                    </div>
                    <div class="col-12 border-bottom"
                      *ngFor="let required_input_fields of required_input_fields_family">
                      <app-dynamic-member [containerClass]="'col-md-6'" (dataEvent)="getSavedData($event,'family')"
                        [required_input_fields]="required_input_fields">
                      </app-dynamic-member>
                    </div>
                  </div>
                </div>
                <div class="col-md-12" *ngIf="isGroup">
                  <div class="row">
                    <div class="col-md-12 mt-5 d-flex align-items-center">
                      <p class="mb-0 text-nowrap  mr-4">Select Members</p>
                      <select class="form-select form-select-custom" (change)="bindMembers($event,'business')" [(ngModel)]="businessMembersCountSelected">
                        <option *ngFor="let m of businessMembersCount" [value]="m">{{m}}</option>
                      </select>
                    </div>
                    <div class="row border-bottom" *ngFor="let required_input_fields of required_input_fields_business">
                      <app-dynamic-member [containerClass]="'col-md-6'" (dataEvent)="getSavedData($event,'business')"
                        [required_input_fields]="required_input_fields">
                      </app-dynamic-member>
                    </div>
                  </div>
                </div>
                <div class="col-md-12" *ngIf="isStudent">
                  <div class="row">
                    <div class="col-md-12 mt-5 d-flex align-items-center">
                      <p class="mb-0 text-nowrap  mr-4">Select Members</p>
                      <select class="form-select form-select-custom" (change)="bindMembers($event,'student')" [(ngModel)]="studentsCountSelected">
                        <option *ngFor="let m of studentsCount" [value]="m">{{m}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="row border-bottom" *ngFor="let required_input_fields of required_input_fields_student">
                    <app-dynamic-member [containerClass]="'col-md-6'" (dataEvent)="getSavedData($event,'student')"
                      [required_input_fields]="required_input_fields">
                    </app-dynamic-member>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="selectedTab == 2">
              <form [formGroup]="travelForm">
                <div class="row">
                  <div class="col-md-12 mt-2">
                    <p><img src="../../../assets/svgIcon/arrow_back_ios.svg" alt="" (click)="Page(1)">&nbsp;&nbsp;
                      Select Travelling Date and Duration </p>
                  </div>
                  <div class="col-md-6 mt-3">
                    <label for="">Travel Start Date</label>
                    <input type="date" name="" class="form-control input" id="" formControlName="travelStartDate"
                      (change)="travelDateChange('start')"
                      [ngClass]="{'border border-danger': travelFormControl['travelStartDate'].touched && travelFormControl['travelStartDate'].value ==''}">
                    <Span class="text-danger"
                      *ngIf="travelFormControl['travelStartDate'].touched && travelFormControl['travelStartDate'].value ==''">
                      Start date is required.
                    </Span>
                  </div>
                  <div class="col-md-6 mt-3">
                    <label for="">Travel End Date</label>
                    <input type="date" name="" class="form-control input" id="" formControlName="travelEndDate"
                      (change)="travelDateChange('end')"
                      [ngClass]="{'border border-danger': travelFormControl['travelEndDate'].touched && travelFormControl['travelEndDate'].value ==''}">
                    <Span class="text-danger"
                      *ngIf="travelFormControl['travelEndDate'].touched && travelFormControl['travelEndDate'].value ==''">
                      End date is required.
                    </Span>
                    <Span class="text-danger" *ngIf="tripDuration < 0">
                      Start date must be less then end date.
                    </Span>


                  </div>
                  <div class="col-md-12 mt-3">
                    <span>Trip Duration: <b>{{tripDuration}} Days</b></span>
                  </div>
                  <div class="col-md-12 mt-5">
                    <label for="">Travelling multiple times in a year?</label>
                  </div>
                  <div class=" col-md-6 mt-2 ">
                    <div class="card  p-4 crd">
                      <div class="form-check">
                        <input class="form-check-input rd" type="radio" name="travelMultipleTimesInAYear"
                          id="travelMultipleTimesInAYear1" formControlName="travelMultipleTimesInAYear" [value]="true">
                        <label class="form-check-label" for="travelMultipleTimesInAYear1">
                          Yes
                        </label>

                      </div>
                    </div>
                  </div>
                  <div class=" col-md-6 mt-2 ">
                    <div class="card  p-4 crd">
                      <div class="form-check">
                        <input class="form-check-input rd" type="radio" name="travelMultipleTimesInAYear"
                          id="travelMultipleTimesInAYear2" formControlName="travelMultipleTimesInAYear" [value]="false">
                        <label class="form-check-label" for="travelMultipleTimesInAYear2">
                          No
                        </label>

                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 mt-4">
                    <div class="row">
                      <div class="col-md-12">
                        <label for="">Trip Frequency</label>
                      </div>
                      <div class=" col-md-6 mt-2 ">
                        <div class="card  p-4 crd">
                          <div class="form-check">
                            <input class="form-check-input rd" type="radio" name="tripFrequencyInDays"
                              id="tripFrequencyInDays1" formControlName="tripFrequencyInDays" [value]="30">
                            <label class="form-check-label" for="tripFrequencyInDays1">
                              30 days
                            </label>

                          </div>
                        </div>
                      </div>
                      <div class=" col-md-6 mt-2 ">
                        <div class="card  p-4 crd">
                          <div class="form-check">
                            <input class="form-check-input rd" type="radio" name="tripFrequencyInDays"
                              id="tripFrequencyInDays2" formControlName="tripFrequencyInDays" [value]="45">
                            <label class="form-check-label" for="tripFrequencyInDays2">
                              45 days
                            </label>

                          </div>
                        </div>
                      </div>
                      <div class=" col-md-6 mt-2 ">
                        <div class="card  p-4 crd">
                          <div class="form-check">
                            <input class="form-check-input rd" type="radio" name="tripFrequencyInDays"
                              id="tripFrequencyInDays3" formControlName="tripFrequencyInDays" [value]="60">
                            <label class="form-check-label" for="tripFrequencyInDays3">
                              60 days
                            </label>

                          </div>
                        </div>
                      </div>
                      <div class=" col-md-6 mt-2 ">
                        <div class="card  p-4 crd">
                          <div class="form-check">
                            <input class="form-check-input rd" type="radio" name="tripFrequencyInDays"
                              id="tripFrequencyInDays4" formControlName="tripFrequencyInDays" [value]="90">
                            <label class="form-check-label" for="tripFrequencyInDays4">
                              90 days
                            </label>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>

            </div>
            <div *ngIf="selectedTab == 3">
              <div class="row">
                <div class="col-md-12 mt-2">
                  <p><img src="../../../assets/svgIcon/arrow_back_ios.svg" alt="" (click)="Page(2)">&nbsp;&nbsp;
                    Any members have medical condition? </p>
                </div>
                <div class="col-md-12 mt-3">
                  <span>If any of the traveller have a known illness, injury or health condition like heart disease,
                    diabetes,
                    cancer etc which will help us cover you</span>
                </div>
                <form [formGroup]="travelForm">
                  <div class=" col-md-6 mt-2 ">
                    <div class="card  p-4 crd">
                      <div class="form-check">
                        <input class="form-check-input rd" type="radio" name="memberHasMedicalCondition"
                          id="memberHasMedicalCondition1" formControlName="memberHasMedicalCondition" value="Yes">
                        <label class="form-check-label" for="memberHasMedicalCondition1">
                          Yes
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class=" col-md-6 mt-2 ">
                    <div class="card  p-4 crd">
                      <div class="form-check">
                        <input class="form-check-input rd" type="radio" name="memberHasMedicalCondition"
                          id="memberHasMedicalCondition2" formControlName="memberHasMedicalCondition" value="No">
                        <label class="form-check-label" for="memberHasMedicalCondition2">
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                </form>

                <div [ngClass]="{'d-none': this.travelFormControl['memberHasMedicalCondition'].value == 'No'}">
                  <div class="col-md-12 mt-4">
                    <label for="">Select Members</label>
                  </div>
                  <div class="row border-bottom" *ngFor="let required_input_fields of required_input_fields_disease">
                    <app-dynamic-member [containerClass]="'col-md-6'"
                      (dataEvent)="getSavedData($event, travelFormControl['planFor'].value)"
                      [required_input_fields]="required_input_fields">
                    </app-dynamic-member>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div class="row btn-mrg">
            <button class="btn btn-primary w-100 rounded-top-0 position-absolute b-0 r-0" *ngIf="selectedTab == 1"
              (click)="Page(2)">Continue</button>
            <button *ngIf="selectedTab == 2" class="btn btn-primary w-100 rounded-top-0 position-absolute b-0 r-0"
              (click)="Page(3)" [disabled]="!validateForm2()">Continue</button>
            <button *ngIf="selectedTab == 3" (click)="getQuotes()"
              class="btn btn-primary w-100 rounded-top-0 position-absolute b-0 r-0">View Quotes</button>
          </div>
        </div>
      </div>

    </div>
    <!-- <div [ngClass]="{'d-none' : isTravelPage}">
    <app-policy-quotes></app-policy-quotes>
</div> -->
  </div>
</div>





<!-- 
<div class="banner">
  <div class="container mt-5 mb-5 ">
    <div style="display: flex; justify-content: space-between;  width: 87%;margin-left: 6%;">
      <div
        style="display: flex; padding: 17px 30px 17px 30px; background-color: #FCF1D6; border-radius: 12px;column-gap: 21px;">
        <div><img src="./assets/images/newicons/insurance-claim.svg"></div>
        <div class="bannerText">Claim 2 wheeler<br> Insurance Now</div>
      </div>
      <div style="display: flex; padding: 22px;  background-color: #F6D1E5; border-radius: 12px;column-gap: 21px;">
        <div><img src="./assets/images/newicons/garage.svg"></div>
        <div class="bannerText">Search nearby<br> cashless garages</div>
      </div>
      <div style="display: flex; padding: 22px;  background-color: #D1F1DF; border-radius: 12px;column-gap: 21px;">
        <div><img src="./assets/images/newicons/repeat-message.svg"></div>
        <div class="bannerText">Earn with Us, Be a<br> Reseller with Us</div>
      </div>
      <div style="display: flex; padding: 22px;  background-color: #D2DAF8; border-radius: 12px;column-gap: 21px;">
        <div><img src="./assets/images/newicons/cash-money-rupee-coin.svg"></div>
        <div class="bannerText">Renewal your 2 <br>wheeler insurance</div>
      </div>
    </div>
  </div>

</div>


<div class="whatins">
  <div class="container mt-5 mb-5 ">
    <div class="row">
      <div class="col-md-7">
        <h2 style="font-size: 35px; margin-top: 30px; font-weight: 600; letter-spacing: 1px;">What is Bike Insurance?
        </h2>
        <span>
          <p style="padding-top: 15px;" class="insText">A bike insurance policy offers comprehensive protection and
            coverage for your bike. It covers repair costs for accidental damages and provides financial security
            against theft, natural disasters, vandalism, and other unforeseen incidents. . With optional add-ons for
            enhanced coverage, such as engine protection and roadside assistance, you can tailor your policy to your
            specific needs. Secure your ride with a bike insurance policy and enjoy peace of mind on every journey.</p>

        </span> 
      </div>
      <div class="col-md-5" style="text-align: right;">
        <img src="./assets/images/newicons/bike.svg" style="width:65%">
      </div>
    </div>
  </div>
</div>

<div class="whyins">
  <div class="container mt-5 mb-5 ">
    <div class="row">
      <div class="col-md-5" style="text-align: left; padding-top: 40px;  ">
        <img src="./assets/images/newicons/video.svg" class="videoClass">
      </div>
      <div class="col-md-7">
        <h2 style="font-size: 35px; margin-top: 30px; font-weight: 600; text-align: right; letter-spacing: 1px;">
          Everything about Bike Insurance</h2>
        <span>
          <p style="padding-top: 20px;" class="insText">
            1. Bike insurance provides coverage for accidental damages, theft, natural disasters, and vandalism,
            including repair costs. <br>
            2. It also offers protection against legal and financial responsibilities for injuries or property damage
            caused to others.<br>
            3. Covers personal accidents, offering compensation for injuries or death resulting from bike accidents.<br>
            4. Allows for policy customization with various plans and add-ons to match individual needs and preferences.
          </p>
        </span> 

      </div>

    </div>
  </div>
</div>


<div class="click4bima">
  <div class="container mt-5 mb-5 ">
    <div class="row" style="padding-top: 50px;">
      <div class="col-md-12">
        <div class="">
          <h1 style="font-size: 40px;; color: #fff; font-weight: 200;">Types of Two-Wheeler Insurance</h1>
        </div>
        <div style="margin-top: 35px;">
          <table>
            <thead>
              <tr>
                <th class="key-features">Key Features</th>
                <th>Comprehensive</th>
                <th>Third-Party(TP)</th>
                <th>Own Damage(OD)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="leftHead">Description</td>
                <td>A comprehensive bike insurance policy covers your own motorcycle or scooter and third-party
                  liabilities. It ensures wide-ranging protection for both your vehicle and legal responsibilities.</td>
                <td>A third-party bike insurance policy covers the cost of damages or injuries you cause to someone
                  else's property or person while riding your bike. It doesn't cover any damages to your own bike.</td>
                <td>An own damage bike insurance policy covers repair or replacement costs if your bike gets damaged. It
                  ensures financial protection for your vehicle against unforeseen damages.</td>
              </tr>
              <tr>
                <td class="leftHead">Coverage</td>
                <td>
                  <ul>
                    <li>Own Damage</li>
                    <li>Third-Party Liabilities</li>
                    <li>Accident, Theft, Fire, Natural Calamities</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Third-Party Liabilities</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Own Damage</li>
                    <li>Accident, Theft, Fire, Natural Calamities</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td class="leftHead">Add-ons Availability</td>
                <td>
                  <ul>
                    <li>Zero/Nil Dep</li>
                    <li>Consumable Cover</li>
                    <li>RTI</li>
                    <li>RSA</li>
                    <li>Engine & Gear Box</li>
                    <li>Daily Allowance</li>
                  </ul>
                </td>
                <td>Not Available</td>
                <td>
                  <ul>
                    <li>Zero/Nil Dep</li>
                    <li>Consumable Cover</li>
                    <li>RTI</li>
                    <li>RSA</li>
                    <li>Engine & Gear Box</li>
                    <li>Daily Allowance</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td class="leftHead">Premium</td>
                <td>High</td>
                <td>Low</td>
                <td>Moderate</td>
              </tr>
              <tr>
                <td class="leftHead">Pros</td>
                <td>
                  <ul>
                    <li>Wide Coverage</li>
                    <li>Additional Benefits with Add-ons</li>
                    <li>Financial Security for Own and Third-Party Damage</li>
                    <li>Legal Compliance</li>
                    <li>PA Cover</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Financial Security for Third-Party Damage</li>
                    <li>Legal Compliance</li>
                    <li>Lower Premium</li>
                    <li>PA Cover</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Covers Accidental Damage</li>
                    <li>Easy Claim Settlement</li>
                    <li>Financial Security for Own Vehicle Damage</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td class="leftHead">Cons</td>
                <td>
                  <ul>
                    <li>Higher Premiums</li>
                    <li>Depreciation Deduction</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Limited Coverage</li>
                    <li>No Financial Protection for Own Damage</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Depreciation Deduction</li>
                    <li>No PA Cover</li>
                    <li>No Financial Protection for Third-Party Damage</li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<div>
  <div style="text-align: center;margin-top: 50px;">
    <h2 style="font-size: 40px; font-weight: 600;">Add-On We Provide for Two-Wheelers</h2>
  </div>
  <div
    style="text-align: center;margin-top: 50px; display: flex;width: 80%; margin-left: 10%; justify-content: space-around;">
    <div class="addonDIv" [ngClass]="{ 'activeDIv': type === 1 }" (click)="getValue(1)">
      <div>
        <img src="./assets/images/newicons/b1.svg">
      </div>
      <div>
        Zero/Nil<br>
        Depreciation
      </div>
    </div>
    <div class="addonDIv" [ngClass]="{ 'activeDIv': type === 2 }" (click)="getValue(2)">
      <div>
        <img src="./assets/images/newicons/b2.svg">
      </div>
      <div>
        Consumable<br>
        Cover
      </div>
    </div>
    <div class="addonDIv" [ngClass]="{ 'activeDIv': type === 3 }" (click)="getValue(3)">
      <div>
        <img src="./assets/images/newicons/b3.svg">
      </div>
      <div>
        Return to<br>
        Invoice
      </div>
    </div>
    <div class="addonDIv" [ngClass]="{ 'activeDIv': type === 4 }" (click)="getValue(4)">
      <div>
        <img src="./assets/images/newicons/b4.svg">
      </div>
      <div>
        Road Side<br>
        Assistance
      </div>
    </div>
    <div class="addonDIv" [ngClass]="{ 'activeDIv': type === 5 }" (click)="getValue(5)">
      <div>
        <img src="./assets/images/newicons/b5.svg">
      </div>
      <div>
        Engine & Gear<br>
        Box Protect
      </div>
    </div>
    <div class="addonDIv" [ngClass]="{ 'activeDIv': type === 6 }" (click)="getValue(6)">
      <div>
        <img src="./assets/images/newicons/b6.svg">
      </div>
      <div>
        Daily<br>
        Allowance
      </div>
    </div>

  </div>
  <div
    style="text-align: center;margin-top: 50px;width: 80%; margin-left: 10%; background-color: var(--grey1); padding:15px 50px 15px 50px; border-radius: 12px;     font-size: large;">
    {{text}}
  </div>
</div>

<div class="become">
  <div class="container mt-5 mb-5 ">
    <div class="row" style="padding-top: 50px;">
      <div class="col-md-12 ">
        <div class="">
          <h1 style="font-size: 40px;;">How to choose best two-wheeler Insurance?</h1>
        </div>
        <div style="margin-top: 35px;">
          <img src="./assets/images/newicons/group.svg">
        </div>
      </div>
    </div>
  </div>
</div>

<div class="whatins">
  <div class="container mt-5 mb-5 ">
    <div class="row">
      <div class="col-md-7">
        <h2 style="font-size: 35px; margin-top: 30px; font-weight: 600; letter-spacing: 1px;">What is covered ?</h2>
        <span>
          <p style="padding-top: 15px;" class="insText">
            1. Coverage for damage to your two-wheeler due to accidents, including collisions, overturning, and other
            mishaps.<br>
            2. Compensation in case of theft or total loss of the vehicle.<br>
            3. Covers damages or injuries caused to third parties, including property damage and bodily injury.<br>
            4. Provides financial protection for the owner-rider in case of accidental death or disability.<br>
            5. Discounts on premium for claim-free years, which can be accumulated over time.

          </p>

        </span> 
      </div>
      <div class="col-md-5" style="text-align: right; padding-top: 40px;">
        <img src="./assets/images/newicons/video.svg" class="videoClass">
      </div>
    </div>
  </div>
</div>

<div class="whyins1">
  <div class="container mt-5 mb-5 ">
    <div class="row">
      <div class="col-md-5" style="text-align: left; padding-top: 40px;">
        <img src="./assets/images/newicons/video.svg" class="videoClass">
      </div>
      <div class="col-md-7">
        <h2 style="font-size: 35px; margin-top: 30px; font-weight: 600; text-align: right;margin-right: 17%;
    letter-spacing: 1px;">
          What is not Covered?</h2>
        <span>
          <p style="padding-top: 20px;" class="insText">
            1. Damage due to regular use, aging, and wear and tear is not covered.<br>
            2. Issues arising from mechanical or electrical failures that are not related to accidents.<br>
            3. Damage caused intentionally or due to reckless behavior is not covered.<br>
            4. Accidents occurring while the rider is under the influence of alcohol or drugs are generally
            excluded.<br>
            5. Damage to or loss of a vehicle that is not properly registered.
          </p>
        </span> 

      </div>

    </div>
  </div>
</div>

<div class="faq">
  <div class="container mt-5 mb-5 ">
    <div class="row" style="padding-top: 50px;">
      <h2>FAQ</h2>
    </div>
    <div class="row" style="padding-top: 15px;">
      <div class="col-md-6">

        <div class="faq">
          <div class="faq-item">
            <div class="faq-question" (click)="toggleAnswer(0)">
              <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 0, 'fa-minus': openItemIndex === 0 }"
                aria-hidden="true"></i>
              1. What does bike insurance cover??
            </div>
            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 0 }"> Bike insurance covers damages to your
              bike from accidents, theft, fire, natural and man-made calamities, and third-party liabilities. It may
              also include personal accident cover and various add-ons.</div>
          </div>

          <div class="faq-item">
            <div class="faq-question" (click)="toggleAnswer(1)">
              <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 1, 'fa-minus': openItemIndex === 1 }"
                aria-hidden="true"></i>
              2. What is the difference between third-party and comprehensive bike insurance?
            </div>
            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 1 }">Third-party insurance covers damages and
              liabilities to third parties caused by your bike. Comprehensive insurance covers both third-party
              liabilities and damages to your own bike..</div>
          </div>

          <div class="faq-item">
            <div class="faq-question" (click)="toggleAnswer(3)">
              <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 3, 'fa-minus': openItemIndex === 3 }"
                aria-hidden="true"></i>
              3. What are add-ons in bike insurance?
            </div>
            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 3 }"> Add-ons are additional coverages you
              can purchase to enhance your policy, such as zero depreciation cover, engine protection, roadside
              assistance, and more.</div>
          </div>

          <div class="faq-item">
            <div class="faq-question" (click)="toggleAnswer(4)">
              <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 4, 'fa-minus': openItemIndex === 4 }"
                aria-hidden="true"></i>
              4. How can I file a claim?
            </div>
            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 4 }">To file a claim, notify your insurer
              immediately after the incident, provide necessary documents (e.g., FIR, repair bills), and follow the
              insurer’s claim procedure, which may include an inspection of the damage.</div>
          </div>

          <div class="faq-item">
            <div class="faq-question" (click)="toggleAnswer(5)">
              <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 5, 'fa-minus': openItemIndex === 5 }"
                aria-hidden="true"></i>
              5. Can I renew my bike insurance online?
            </div>
            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 5 }">Yes, you can renew your policy by
              visiting
              our website, paying the premium, and updating all necessary information.</div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="faq">
          <div class="faq-item">
            <div class="faq-question" (click)="toggleAnswer(6)">
              <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 6, 'fa-minus': openItemIndex === 6 }"
                aria-hidden="true"></i>
              6. How is the premium for bike insurance calculated?
            </div>
            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 6 }"> Premiums are calculated based on
              factors such as the bike's make and model, age, RTO, coverage type, and add-ons.</div>
          </div>

          <div class="faq-item">
            <div class="faq-question" (click)="toggleAnswer(7)">
              <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 7, 'fa-minus': openItemIndex === 7 }"
                aria-hidden="true"></i>
              7. What is a No Claim Bonus (NCB)?
            </div>
            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 7 }">NCB is a discount on your premium for
              each claim-free year. It rewards safe riding and reduces your insurance cost upon renewal.</div>
          </div>

          <div class="faq-item">
            <div class="faq-question" (click)="toggleAnswer(8)">
              <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 8, 'fa-minus': openItemIndex === 8 }"
                aria-hidden="true"></i>
              8. What documents are required for buying bike insurance?
            </div>
            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 8 }"> Common documents include the bike's
              registration certificate (RC), driving license, and identity proof. Some insurers may also require a
              recent photograph of the bike.</div>
          </div>

          <div class="faq-item">
            <div class="faq-question" (click)="toggleAnswer(9)">
              <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 9, 'fa-minus': openItemIndex === 9 }"
                aria-hidden="true"></i>
              9. How can I renew my bike insurance?
            </div>
            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 9 }">Bike insurance can be renewed online
              through the insurer’s website, mobile app, or by visiting an insurance office. Ensure to renew it before
              the expiry date to avoid lapses in coverage.</div>
          </div>

          <div class="faq-item">
            <div class="faq-question" (click)="toggleAnswer(10)">
              <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 10, 'fa-minus': openItemIndex === 10 }"
                aria-hidden="true"></i>
              10. What are the exclusions in bike insurance?
            </div>
            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 10 }">Common exclusions include normal wear
              and tear, mechanical breakdowns, damage caused under the influence of alcohol or drugs, and damage due to
              illegal activities.</div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid background">
  <div class="container partners_section">
    <div class="card-container ">
      <div class="justify-content">
        <div class="text " style="margin-top: 3%;"> 
          <h1 class="heading">Our 2 Wheeler Insurance Partners</h1>
          <span class="font-body">We work together to provide you the best services.</span>
        </div>

        <div class="container ">
          <div style="text-align: center;">
            <div class="image-row">
              <img *ngFor="let img of images" [src]="img" alt="">
            </div>
            <div class="image-row">
              <img *ngFor="let img of images1" [src]="img" alt="">
            </div>
            <div style="width: 100%; text-align: center;margin-bottom: 20px;">
              <a *ngIf="!showAllImages" class="butn" (click)="showAllImages = true">Show More</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->