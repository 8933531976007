import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  constructor() { }
  getUserType()
  {
    return sessionStorage.getItem('userType')??localStorage.getItem('userType')??'';
  }

  getUniqueuserid()
  {
    return sessionStorage.getItem('uniqueuserid')??localStorage.getItem('uniqueuserid')??'';
  }

  getAccessToken()
  {
    return sessionStorage.getItem('access_token')??localStorage.getItem('access_token')??'';
  }

  getUserDisplayName()
  {
    return sessionStorage.getItem('user_display_name')??localStorage.getItem('user_display_name')??'';
  }

  getUserLatitude()
  {
    return sessionStorage.getItem('latitude')??localStorage.getItem('latitude')??'';
  }

  getUserLongitude()
  {
    return sessionStorage.getItem('longitude')??localStorage.getItem('longitude')??'';
  }

  getIsAgent()
  {
    return sessionStorage.getItem('isAgent')??localStorage.getItem('isAgent')??'';
  }

  getUserLabel()
  {
    return sessionStorage.getItem('userLabel')??localStorage.getItem('userLabel')??'';
  }  

  getEmployeeFlag()
  {
    return sessionStorage.getItem('employeeFlag')??localStorage.getItem('employeeFlag')??'';
  }  
  

  // Set
  setUserType(data:any)
  {
    sessionStorage.setItem('userType', data);
    localStorage.setItem('userType',data);
  }

  setUniqueuserid(data:string)
  {
    sessionStorage.setItem('uniqueuserid', data);
    localStorage.setItem('uniqueuserid',data);
  }

  setAccessToken(data:string)
  {
    sessionStorage.setItem('access_token',data);
    localStorage.setItem('access_token',data);
  }

  setUserDisplayName(data:string)
  {
    sessionStorage.setItem('user_display_name',data);
    localStorage.setItem('user_display_name',data);
  }

  setUserLatitude(data:string)
  {
    sessionStorage.setItem('latitude',data);
    localStorage.setItem('latitude',data);
  }

  setUserLongitude(data:string)
  {
    sessionStorage.setItem('longitude',data);
    localStorage.setItem('longitude',data);
  }

  setIsAgent(data:string)
  {
    sessionStorage.setItem('isAgent',data);
    localStorage.setItem('isAgent',data);
  }

  setUserLabel(data:string)
  {
    sessionStorage.setItem('userLabel',data);
    localStorage.setItem('userLabel',data);
  }

  setEmployeeFlag(data:string)
  {
    sessionStorage.setItem('employeeFlag',data);
    localStorage.setItem('employeeFlag',data);
  }

  // Clear 
  removeUserType()
  {
    sessionStorage.removeItem('userType');
    localStorage.removeItem('userType');
  }

  removeUniqueuserid()
  {
    sessionStorage.removeItem('uniqueuserid');
    localStorage.removeItem('uniqueuserid');
  }

  removeAccessToken()
  {
    sessionStorage.removeItem('access_token');
    localStorage.removeItem('access_token');
  }

  removeUserDisplayName()
  {
    sessionStorage.removeItem('user_display_name');
    localStorage.removeItem('user_display_name');
  }

  removeUserLatitude()
  {
    sessionStorage.removeItem('latitude');
    localStorage.removeItem('latitude');
  }

  removeUserLongitude()
  {
    sessionStorage.removeItem('longitude');
    localStorage.removeItem('longitude');
  }

  removeIsAgent()
  {
    sessionStorage.removeItem('isAgent');
    localStorage.removeItem('isAgent');
  }

  removeUserLabel()
  {
    sessionStorage.removeItem('userLabel');
    localStorage.removeItem('userLabel');
  }
  
  removeEmployeeFlag()
  {
    sessionStorage.removeItem('employeeFlag');
    localStorage.removeItem('employeeFlag');
  }
  

  removeAllSession()
  {
    this.removeUserType();
    this.removeAccessToken();
    this.removeUniqueuserid();
    this.removeUserDisplayName();
    this.removeIsAgent();
    this.removeUserLabel();
    this.removeEmployeeFlag();
  }


  uniqueBy(arr:any, prop:any){
    return arr.reduce((a:any, d:any) => {
      if (!a.includes(d[prop])) { a.push(d[prop]); }
      return a;
    }, []);
  }

  setDataToLocalStorage(key:string,data:string)
  {
    sessionStorage.setItem(key,data);
    localStorage.setItem(key,data);
  }

  getDataToLocalStorage(key:string)
  {
    return sessionStorage.getItem(key)??localStorage.getItem(key)??'';
  }

  removeDataFromLocalStorage(key:string)
  {
    sessionStorage.removeItem(key);
    localStorage.removeItem(key);
  }



  removeQuoteLocalStorage(){
    sessionStorage.removeItem('ownerDetails');
    localStorage.removeItem('ownerDetails');
    sessionStorage.removeItem('quoteDetails');
    localStorage.removeItem('quoteDetails');
    // sessionStorage.removeItem(key);
    // localStorage.removeItem(key);
  }
  // sortArray(arr:any, dir:any){
  //   if(dir == "asc")
  //   {
  //     arr = arr.sort((a:any,b:any)=> a.grossPremium > b.grossPremium ? 1 : -1);
  //   }
  //   else if(dir == "desc")
  //   {
  //     this.quoteList = this.quoteList.sort((a:any,b:any)=> b.agentCommission > a.agentCommission ? 1 : -1);
  //   }
  // }

  getFileExtension(fileName:string):string {
    // Find the index of the last dot in the file name
    const lastDotIndex = fileName.lastIndexOf('.');
    // If a dot is found and it's not the last character
    if (lastDotIndex !== -1 && lastDotIndex < fileName.length - 1) {
      // Extract the substring starting from the dot
      const extension = fileName.substring(lastDotIndex + 1);
      return extension.toLowerCase(); // Convert to lowercase for consistency
    }
    // If no dot is found or it's the last character, return an empty string
    return '';
  }

  distinctItems = (data:any) => {
    let unique_values = data.filter((a:any, i:number) => data.findIndex((s:any) => a === s) === i) 
    return unique_values;
  }

  distinctFuel = (data:any) => {
    let unique_values = data.filter((a:any, i:number) => data.findIndex((s:any) => a.name === s.name) === i) 
    return unique_values;
  }

  distinct_text1 = (data:any) => {
    let unique_values = data.filter((a:any, i:number) => data.findIndex((s:any) => a.text1 === s.text1) === i) 
    return unique_values;
  }

  distinct_text = (data:any) => {
    let unique_values = data.filter((a:any, i:number) => data.findIndex((s:any) => a.text === s.text) === i) 
    return unique_values;
  }

  distinct_quote = (data:any) => {
    let unique_values = data.filter((a:any, i:number) => data.findIndex((s:any) => a.insuranceCompany === s.insuranceCompany && a.typeOfCover === s.typeOfCover && a.isTPPD === s.isTPPD) === i) 
    return unique_values;
  }

  toTitleCase(str:any) {
    return str.replace(
      /\w\S*/g,
      (text:any) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
    );
  }
}
