import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { PasswordType } from '../Models/login.component.model';
import { LoginService } from '../Services/login.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import swal from 'sweetalert2';
import { MenuService } from '../Services/menu.service';
import { HttpClient } from '@angular/common/http';

import { FacebookLoginProvider,GoogleLoginProvider, SocialAuthService, SocialUser } from 'angularx-social-login';
import { GeneralService } from '../Services/general.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  user:SocialUser | undefined;
  loggedIn: boolean | undefined;
  isLoggedin?: boolean = undefined;

  currentYear  : number = new Date().getFullYear();
  loginForm: FormGroup;
  AlertMessage: string = '';
  passwordShow: boolean = false;
  IsAlert: boolean = false;
  public passwordType: string = PasswordType.pwd;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  separateDialCode = true;
  ServicesDown: boolean = false;

  // login and logout
  isloggedIn:boolean = true;
  isloggedOut:boolean = false;

  constructor(private ssoauthService:SocialAuthService, private loginService: LoginService, private router: Router, private fb: FormBuilder, private spinner: NgxSpinnerService, private _menuservice: MenuService, private httpClient: HttpClient, private generalService: GeneralService) {
    this.loginForm = this.fb.group({
      "PhoneNumber": ['', Validators.required],
      "Password": ['', [Validators.required]],
    });
    this.generalService.removeAllSession();
    this.StatusCheck();
  }

  ngOnInit(): void {
    this.ssoauthService.authState.subscribe((user)=>{
      this.user = user;
      this.loggedIn = (user != null);
      this.isLoggedin = user != null;
    });
  }

  signInWithFB(): void { //Facebook Login
    this.ssoauthService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  signWithGoogle():void{ //for google sign in
    let val = this.ssoauthService.signIn(GoogleLoginProvider.PROVIDER_ID);
    alert(val);
  }

  signOut():any{ //for google out
    this.ssoauthService.signOut();
  }

  refreshToken(): void {
    this.ssoauthService.refreshAuthToken(FacebookLoginProvider.PROVIDER_ID);
  }

  async StatusCheck() {
    this.spinner.show();
    try {
      const response = await this.loginService.StatusCheck();
      if (response === ``) {
        swal.fire({
          position: 'center',
          icon: 'error',
          title: `Website currently down, apologize for the inconvenience.`,
          showConfirmButton: false,
          timer: 3000,
        });
        this.ServicesDown = true;
      }
     
    }
    catch (error) {
      console.log(error);
    }
    this.spinner.hide();
  }

  async submit() {
    try {
      this.spinner.show();
      this.isloggedIn = false;
      this.isloggedOut = true;
      if (this.loginForm.get('PhoneNumber')?.value != null && typeof (this.loginForm.get('PhoneNumber')?.value) == 'object') {
        let phone = this.loginForm.get('PhoneNumber')?.value;
        this.loginForm.get('PhoneNumber')?.setValue(phone.e164Number.replace(phone.dialCode, ''));
      }

      const request = { PhoneNumber: this.loginForm.get('PhoneNumber')?.value, Password: window.btoa(this.loginForm.get('Password')?.value),OTP_FOR:"CUST" }
      const res = await this.loginService.AuthenticateUser(request);      
      if (res?.isSuccess) {
        let data = res.result;
        this.generalService.setAccessToken(data.accessToken);
        this.generalService.setUniqueuserid(data.uniqueUserId);
        this.generalService.setIsAgent(data.agentId>0?'true':'false');
        this.generalService.setEmployeeFlag(data.employee);
        await this.getDistributorSettings();
        this.router.navigate(['/']);
      } else {
        swal.fire({
          position: 'center',
          icon: 'error',
          title: res?.message,
          showConfirmButton: false,
          timer: 3000,
        });
      }
    } catch (error) {
      console.error(error); 
    } finally {
      this.spinner.hide();
    }
  }


  async getDistributorSettings() {
    const response = await this._menuservice.GetDistributorSettings();
    if (response?.isSuccess) {
      sessionStorage.setItem('distributor_settings', response.result);
    }
  }
  ShowPassword() {
    if (!this.passwordShow) {
      this.passwordType = PasswordType.txt;
      this.passwordShow = true;
    }
    else {
      this.passwordType = PasswordType.pwd;
      this.passwordShow = false;
    }
  }
  onCountryChangeNew(e: any) {
    this.loginForm.get('phoneNumber')?.setValue("");
  }
}
