<app-navbar></app-navbar>
<div>
    <div class="container" *ngIf="insuranceDetailsScreen_one">
        <div class="mt-3">
            <div class="">
                <form #vehical_details="ngForm" class="vehicle-form">
                    <div class="row card m-0 flex-row py-1">
                        <div class="col-auto d-flex align-items-center">
                            <button type="button" class="btn btn-primary px-1 py-1"><img
                                    src="../../assets/svgIcon/ArrowLeft.svg" alt="ArrowLeft"
                                    class="cursor-pointer svg_white edit-icon" (click)="goTo()"></button>
                        </div>
                        <div class="col">
                            <div class="d-flex details-panel" [ngClass]="(this.quoteData?.noOfPersons??'0') == '2' ? 'justify-content-evenly' :'justify-content-between'">
                                <div class="col-auto" *ngIf="healthData.selfSelected">
                                    <label>Self Age</label>
                                    <span>{{healthData.selfAge}}yrs</span>
                                </div>
                                <div class="col-auto" *ngIf="healthData.spouseSelected">
                                    <label>Spouse Age</label>
                                    <span>{{healthData.spouseAge}}yrs</span>
                                </div>
                                <div class="col-auto" *ngIf="healthData.selectedSonAge1>0">
                                    <label>1st Son Age</label>
                                    <span>{{healthData.selectedSonAge1}}yrs</span>
                                </div>
                                <div class="col-auto" *ngIf="healthData.selectedSonAge2>0">
                                    <label>2nd Son Age</label>
                                    <span>{{healthData.selectedSonAge2}}yrs</span>
                                </div>
                                <div class="col-auto" *ngIf="healthData.selectedSonAge3>0">
                                    <label>3rd Son Age</label>
                                    <span>{{healthData.selectedSonAge3}}yrs</span>
                                </div>
                                <div class="col-auto" *ngIf="healthData.selectedSonAge4>0">
                                    <label>4th Son Age</label>
                                    <span>{{healthData.selectedSonAge4}}yrs</span>
                                </div>
                                <div class="col-auto" *ngIf="healthData.selectedSonAge5>0">
                                    <label>5th Son Age</label>
                                    <span>{{healthData.selectedSonAge5}}yrs</span>
                                </div>
                                <div class="col-auto" *ngIf="healthData.selectedSonAge6>0">
                                    <label>6th Son Age</label>
                                    <span>{{healthData.selectedSonAge6}}yrs</span>
                                </div>
                                <div class="col-auto" *ngIf="healthData.selectedDauAge1>0">
                                    <label>1st Daughter Age</label>
                                    <span>{{healthData.selectedDauAge1}}yrs</span>
                                </div>
                                <div class="col-auto" *ngIf="healthData.selectedDauAge2>0">
                                    <label>2nd Daughter Age</label>
                                    <span>{{healthData.selectedDauAge2}}yrs</span>
                                </div>
                                <div class="col-auto" *ngIf="healthData.selectedDauAge3>0">
                                    <label>3rd Daughter Age</label>
                                    <span>{{healthData.selectedDauAge3}}yrs</span>
                                </div>
                                <div class="col-auto" *ngIf="healthData.selectedDauAge4>0">
                                    <label>4th Daughter Age</label>
                                    <span>{{healthData.selectedDauAge4}}yrs</span>
                                </div>
                                <div class="col-auto" *ngIf="healthData.selectedDauAge5>0">
                                    <label>5th Daughter Age</label>
                                    <span>{{healthData.selectedDauAge5}}yrs</span>
                                </div>
                                <div class="col-auto" *ngIf="healthData.selectedDauAge6>0">
                                    <label>6th Daughter Age</label>
                                    <span>{{healthData.selectedDauAge6}}yrs</span>
                                </div>
                                <div class="col-auto" *ngIf="healthData.fatherSelected">
                                    <label>Father Age</label>
                                    <span>{{healthData.fatherAge}}yrs</span>
                                </div>
                                <div class="col-auto" *ngIf="healthData.motherSelected">
                                    <label>Mother Age</label>
                                    <span>{{healthData.motherAge}}yrs</span>
                                </div>
                                <div class="col-auto" *ngIf="healthData.grandPaSelected">
                                    <label>Grand Father Age</label>
                                    <span>{{healthData.grandPaAge}}yrs</span>
                                </div>
                                <div class="col-auto" *ngIf="healthData.grandMaSelected">
                                    <label>Grand Mother Age</label>
                                    <span>{{healthData.grandMaAge}}yrs</span>
                                </div>
                                <div class="col-auto" *ngIf="healthData.fatherLawSelected">
                                    <label>Father-in-Law Age</label>
                                    <span>{{healthData.fatherLawAge}}yrs</span>
                                </div>
                                <div class="col-auto" *ngIf="healthData.motherLawSelected">
                                    <label>Mother-in-Law Age</label>
                                    <span>{{healthData.motherLawAge}}yrs</span>
                                </div>
                                <div class="col-auto" *ngIf="healthData.uncleSelected">
                                    <label>Uncle Age</label>
                                    <span>{{healthData.uncleAge}}yrs</span>
                                </div>
                                <div class="col-auto" *ngIf="healthData.auntSelected">
                                    <label>Aunt Age</label>
                                    <span>{{healthData.auntAge}}yrs</span>
                                </div>
                            </div>
                        </div>                        
                        <!-- <div class="col buttons_div_save" style="text-align: right;">
                            <button *ngIf="!isPopupFormVisible" type="button" class="btn btn-primary py-1" (click)="openPopupForm()"><img src="../../assets/svgIcon/PencilLineBlack.svg" alt="Edit" class="svg_white edit-icon cursor-pointer"></button>
                            <button *ngIf="isPopupFormVisible" type="button" class="btn btn-primary px-2 py-1" (click)="updateVehicleDetailsForm(vehical_details.value)"><img class="svg_white edit-icon cursor-pointer" src="../../assets/svgIcon/FloppyDisk.svg" alt="Apply"></button>
                            <button *ngIf="isPopupFormVisible" type="button" class="btn btn-warning px-2 py-1 ml-2 btn-active" (click)="closePopupForm()"><img class="svg_white edit-icon cursor-pointer" src="../../assets/svgIcon/close-secondary.svg" alt="Cancel"></button>
                            
                        </div> -->
                    </div>
                </form>

            </div>
        </div>
        <div class="mt-3" *ngIf="!isInsurancePlan">
            <div class="row">
                <div class="col-md-4">
                    <div class="card p-5 filter-section leftDiv rounded-bottom-0 mx-0 mb-0">
                        <form id="kt_account_profile_details_form" class="form" [formGroup]="premiumRequestForm" novalidate="novalidate">
                            <div class="justify-content-between">
                                <div>
                                    <h4 class="text-start my-0">Sum Insured</h4>
                                    <select class="form-select" formControlName="sumInsured">
                                        <option *ngFor="let sumInsured of SumInsuredList" [value]="sumInsured.value">{{sumInsured.text}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="justify-content-between">
                                <div>
                                    <h4 class="text-start mb-0">Plan Type</h4>
                                    <select class="form-select" formControlName="planType">
                                        <option value="">Choose Plan Type</option>
                                        <option value="baseplan">Base Plan</option>
                                        <option value="1crvalue">1Cr Value</option>
                                        <option value="arogyasanjivani">Arogya Sanjeevani</option>
                                        <option value="topupplans">Top-up Plans</option>
                                        <option value="multilevelplans">Multi-Level Plans</option>
                                    </select>
                                </div>
                            </div>
                            <div class="justify-content-between">
                                <div>
                                    <h4 class="text-start mb-0">Policy Duration</h4>
                                    <select class="form-select" formControlName="policyDuration">
                                        <option value="">Choose Policy Duration</option>
                                        <option value="1">1 Year</option>
                                        <option value="2">2 Year</option>
                                        <option value="3">3 Year</option>

                                    </select>
                                </div>
                            </div>
                        </form>
                        <!-- <hr class="" />
                        <div class="justify-content-between">
                            <div>
                                <h4 class="text-start">Add-ons</h4>
                            </div>
                        </div> -->
                        <!-- <div class="row">
                            <div class="col-6 align-items-center text-wrap mb-1" *ngFor="let item of addOnnsItems;" [title]="item.name">
                            <input type="checkbox" class="scale-1_3 mt-0 mr-2" [value]="item.id" (change)="onAddOnnChange($event)">{{item.name}}
                            </div>
                        </div> -->
                        <!-- <hr class="" /> -->
                       
                    </div>
                    <button class="btn btn-primary w-100 rounded-0 rounded-bottom"
                        (click)="reCalculateQuote()" [disabled]="premiumRequestFormControl['sumInsured'].value == '0'">Apply</button>
                </div>
                <div class="col-md-8">
                    <div class="row">
                        <div class="col-lg-9 col-md-9 col-sm-9 col-9">
                            <div class="plans_header mt-3">
                                <h2 class="text-start">Insurance plans for you</h2>
                                <span class="font-body" *ngIf="planList != null && planList != undefined && planList.length > 0">Showing {{planList?.length}} plans for you</span>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-3 col-3 c3">
                            <div class="card p-3">
                                <div class="row">
                                    <div class="col-md-2 c2">
                                        <img src="../../assets/svgIcon/SortAscending.svg" alt="SortAscending"
                                            width="22">
                                    </div>
                                    <div class="col-md-10 c10 align-items-center d-flex justify-content-end  pl-0">
                                        <select [(ngModel)]="sort_quote" (change)="sortQuote()"
                                            class="w-100 border-0 py-0 px-2 sort-ddl">
                                            <option *ngFor="let item of sortQuoteList; let i = index;"
                                                value="{{item.value}}">{{item.text}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                    
                    <ng-container *ngIf="!isQuoteFound">
                        <div class="card mt-3">
                          <div class="row ">
                            <div class="col-12 text-center py-2 d-flex justify-content-center">
                              <img class="image-stl" src="/assets/svgIcon/no_quote_found.svg" alt="No Quote Found">
                            </div>
                          </div>
                      </div>
                    </ng-container>
                    <div class="mb-6" *ngFor="let company of groupedQuotesArray">
                        <div *ngFor="let plan of company.quotes;let ind = index" class="card mt-3 col-12" [ngClass]="{'d-none':company.isExpanded == false && ind > 0}">
                            <div class="row align-value min-height78">
                                <div class="col">
                                    <img class="image-stl" src="/assets/images/insurance_companies/{{plan.insuranceCompany}}.png" alt="">
                                </div>
                                <div class="col text-center">
                                    <p class="text-grey4 mb-0">Plan Name</p>
                                    <h4 class="mb-0">{{plan?.planName}}</h4>
                                    <a class="features_link" (click)="viewFeatures()">View Features</a>
                                </div>
                                <div class="col text-center">
                                    <p class="text-grey4 mb-0">Cover Amount</p>
                                    <h4 class="mb-0">₹ {{(plan?.sumInsurred??"0") | thousandSeparator}} </h4>
                                </div>
                                <div class="col text-center">
                                    <p class="text-center text-main mb-0 font-17-13"> ₹{{plan?.priceBreakup?.finalPremium}}/year
                                        <span class="gst-span text-grey4">(GST Included)</span>
                                    </p>
                                    <span _ngcontent-uwr-c79="" class="span-link" (click)="viewBreakupPlan(plan)">View Breakup</span>
                                </div>
                                <div class="col">
                                    <button class="btn btn-primary bt my-2" id="btn"
                                        (click)="buynow(plan)">Buy Now</button>
                                </div>
                            </div>
                        </div>
                        <div class="text-center bg-white text-main rounded rounded-top1-0 fw-bold cursor-pointer see-more-less" *ngIf="(company?.quotes?.length??0) > 1" (click)="togglePlans(company.companyKey)"> {{company.isExpanded == false ? 'Show ' + ((company?.quotes?.length??0)-1) + ' more ' + (((company?.quotes?.length??0)-1) > 1 ? 'plans' : 'plan') : 'See less'}} </div>
                    </div>
                      <skeleton-loader className="mt-3" *ngIf="showLoader"></skeleton-loader>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="overlay" *ngIf="mobileVerificationDiv">
        <div class="popup2 col-md-4 col-11">
            <div class="modal-header">
                <h3>Sign In</h3>
                <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
                    <span aria-hidden="true"></span>
                </button>
            </div>
            <div class="modal-body">
                <div class="">
                    <div class="flex-center flex-column-fluid">
                        <form class="form" novalidate="novalidate" id="kt_sign_in_form" [formGroup]="loginForm">
                            <div class="text-center mb-11">

                            </div>
                            <div class="fv-row mb-7">
                                <label class="input-label required" for="mobile">Mobile Number</label>
                                <ngx-intl-tel-input class="bg-transparent" [enableAutoCountrySelect]="true"
                                    [enablePlaceholder]="true" [searchCountryFlag]="false"
                                    [onlyCountries]="[CountryISO.UnitedStates, CountryISO.India]"
                                    [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                    [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.India"
                                    [phoneValidation]="true" [separateDialCode]="separateDialCode" name="phone"
                                    formControlName="PhoneNumber" [inputId]="'phone_number'"
                                    (countryChange)="onCountryChangeNew($event)" aria-hidden="true" #phoneNumber>

                                </ngx-intl-tel-input>
                            </div>
                            <div class="fv-row mb-3">
                                <div class="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold">
                                    <div><label class="input-label required" for="password">Password</label></div>
                                    <a href="/forgotPassword" class="link-primary">Forgot Password ?</a>
                                </div>
                                <input [type]="this.passwordType" placeholder="Enter Password" name="password"
                                    autocomplete="off" class="form-control bg-transparent" formControlName="Password" />
                                <i class="fa fa-eye-slash field-icon" (click)="ShowPassword()" aria-hidden="true"></i>
                            </div>
                            <div class="d-flex">
                                <input type="checkbox">&nbsp;
                                <span class="remember mt-1">Remember me</span>
                            </div>
                            <div class="d-grid mb-5 mt-5">
                                <button class="btn btn-primary" type="submit" id="kt_sign_in_submit"
                                    [disabled]="loginForm.invalid || ServicesDown" (click)="submit()">
                                    <span class="indicator-label">Sign In</span>
                                </button>
                            </div>
                            <div class="text-center">
                                Don't have an account?
                                <a href="/signup" class="link-primary" (click)="quoteSignup()">Signup Here</a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <div class="overlay" *ngIf="isviewbreakupplan">
        <div class="popup2 col-lg-4 col-md-6 col-11">
            <div class="modal-header tooltip_ py-0">
                <img alt="" class="h-56" src="/assets/images/insurance_companies/{{quoteObj?.insuranceCompany}}.png">
                <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
                    <span aria-hidden="true"></span>
                </button>
            </div>
            <div class="modal-body pt-0">
                <h3 class="text-center my-2">Premium Breakup</h3>
                <hr class="premium_breakup my-1">
                <div class="row ng-star-inserted">
                    <div class="col-8 mt-2">
                        <span >Net Premium - 1 year</span>
                    </div>
                    <div class="col-4 mt-2 text-right">
                        <h5 >₹ {{(quoteObj?.priceBreakup?.netPremium??"0") | thousandSeparator}}</h5>
                    </div>
                </div>
                <div class="row ng-star-inserted">
                    <div class="col-8 mt-2">
                        <span >GST(18%)</span>
                    </div>
                    <div class="col-4 mt-2 text-right">
                        <h5 class="text-danger">+ ₹ {{(quoteObj?.priceBreakup?.serviceTaxValue??"0") | thousandSeparator}}</h5>
                    </div>
                </div>
                <hr class="premium_breakup my-1">
                <div class="row ng-star-inserted">
                    <div class="col-8 mt-2">
                        <h5 >Total Premium</h5>
                    </div>
                    <div class="col-4 mt-2 text-right">
                        <h5 >₹ {{(quoteObj?.priceBreakup?.finalPremium??"0") | thousandSeparator}}</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="overlay" *ngIf="isviewFeatures">
        <div class="popup2 col-md-8 col-11">
            <div class="modal-header">
                <h3>Features</h3>
                <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
                    <span aria-hidden="true"></span>
                </button>
            </div>
            <div class="modal-body">
                <div class="">
                    <div class="flex-center flex-column-fluid">
                        <form class="form" novalidate="novalidate" id="kt_sign_in_form">
                            <div class="featureDiv">
                                <div class="features_tabs">
                                    <ul>
                                        <li (click)="featuresList('coverage')" [ngClass]="{'active_tab1': tab1 }">
                                            Coverage
                                        </li>
                                        <li (click)="featuresList('waiting period')" [ngClass]="{'active_tab1': tab2 }">
                                            Waiting periods</li>
                                        <li (click)="featuresList('Additional Features')"
                                            [ngClass]="{'active_tab1': tab3 }">Additional Features</li>
                                        <li (click)="featuresList('Value Added Service')"
                                            [ngClass]="{'active_tab1': tab4 }">Value Added Services</li>
                                        <li (click)="featuresList('Cashless Hospitals')"
                                            [ngClass]="{'active_tab1': tab5 }">
                                            Cashless Hospitals({{cashlesshospitalsCount}})</li>
                                        <li (click)="featuresList('Documents Upload')"
                                            [ngClass]="{'active_tab1': tab6 }">
                                            Policy Documents</li>

                                    </ul>
                                </div>
                                <div class="features_tabs_content">
                                    <div class="content-block" *ngIf="iscoveragefeatures">
                                        <h5>Coverage</h5>
                                        <div class="faq-item">
                                            <div class="faq-question" (click)="toggleAnswer(0)">
                                                <i class="fa faq-icon"
                                                    [ngClass]="{ 'fa-chevron-down': openItemIndex !== 0, 'fa-chevron-up': openItemIndex === 0 }"
                                                    aria-hidden="true"></i>
                                                Claims paid within 3 months
                                            </div>
                                            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 0 }"> Claims
                                                paid
                                                within 3 months means that when you make a health insurance claim, the
                                                insurance company aims to settle and pay you within a short 3-month
                                                period.
                                            </div>
                                        </div>
                                        <div class="faq-item">
                                            <div class="faq-question" (click)="toggleAnswer(1)">
                                                <i class="fa faq-icon"
                                                    [ngClass]="{ 'fa-chevron-down': openItemIndex !== 1, 'fa-chevron-up': openItemIndex === 1 }"
                                                    aria-hidden="true"></i>
                                                Room rent limit
                                            </div>
                                            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 1 }"> Room
                                                Rent
                                                Limit is the maximum amount your insurance will pay per day for the
                                                hospital
                                                room. It helps manage costs during hospitalization.</div>
                                        </div>
                                        <div class="faq-item">
                                            <div class="faq-question" (click)="toggleAnswer(2)">
                                                <i class="fa faq-icon"
                                                    [ngClass]="{ 'fa-chevron-down': openItemIndex !== 2, 'fa-chevron-up': openItemIndex === 2 }"
                                                    aria-hidden="true"></i>
                                                Restoration of cover
                                            </div>
                                            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 2 }"> If you
                                                use
                                                up your coverage during the year, this feature gives you extra
                                                protection to
                                                cover more medical expenses.</div>
                                        </div>
                                        <div class="faq-item">
                                            <div class="faq-question" (click)="toggleAnswer(3)">
                                                <i class="fa faq-icon"
                                                    [ngClass]="{ 'fa-chevron-down': openItemIndex !== 3, 'fa-chevron-up': openItemIndex === 3 }"
                                                    aria-hidden="true"></i>
                                                Renewal Bonus
                                            </div>
                                            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 3 }"> Renewal
                                                Bonus is like a reward in health insurance. If you renew your policy
                                                without
                                                making claims, you get extra coverage or a discount as a bonus.</div>
                                        </div>
                                        <div class="faq-item">
                                            <div class="faq-question" (click)="toggleAnswer(4)">
                                                <i class="fa faq-icon"
                                                    [ngClass]="{ 'fa-chevron-down': openItemIndex !== 4, 'fa-chevron-up': openItemIndex === 4 }"
                                                    aria-hidden="true"></i>
                                                Cashless hospitals
                                            </div>
                                            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 4 }">
                                                Cashless
                                                Hospitals means you don't pay money upfront for medical bills. Your
                                                health
                                                insurance directly covers the hospital expenses, making it hassle-free
                                                for
                                                you during treatment.</div>
                                        </div>
                                        <div class="faq-item">
                                            <div class="faq-question" (click)="toggleAnswer(5)">
                                                <i class="fa faq-icon"
                                                    [ngClass]="{ 'fa-chevron-down': openItemIndex !== 5, 'fa-chevron-up': openItemIndex === 5 }"
                                                    aria-hidden="true"></i>
                                                Co-pay
                                            </div>
                                            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 5 }"> Co-pay
                                                means you and the insurance company share the cost of medical expenses.
                                                You
                                                pay less, and they cover the rest.</div>
                                        </div>
                                        <div class="faq-item">
                                            <div class="faq-question" (click)="toggleAnswer(6)">
                                                <i class="fa faq-icon"
                                                    [ngClass]="{ 'fa-chevron-down': openItemIndex !== 6, 'fa-chevron-up': openItemIndex === 6 }"
                                                    aria-hidden="true"></i>
                                                Pre-hospitalization coverage
                                            </div>
                                            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 6 }">
                                                Pre-hospitalization coverage means your health insurance takes care of
                                                medical expenses incurred before your hospital stay, such as doctor
                                                visits,
                                                tests, and medications.</div>
                                        </div>
                                        <div class="faq-item">
                                            <div class="faq-question" (click)="toggleAnswer(7)">
                                                <i class="fa faq-icon"
                                                    [ngClass]="{ 'fa-chevron-down': openItemIndex !== 7, 'fa-chevron-up': openItemIndex === 7 }"
                                                    aria-hidden="true"></i>
                                                Post-hospitalization coverage
                                            </div>
                                            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 7 }">
                                                Post-hospitalization coverage means your health insurance still helps
                                                after
                                                leaving the hospital. It pays for some costs like medicines or follow-up
                                                check-ups, ensuring your recovery is supported.</div>
                                        </div>
                                        <div class="faq-item">
                                            <div class="faq-question" (click)="toggleAnswer(8)">
                                                <i class="fa faq-icon"
                                                    [ngClass]="{ 'fa-chevron-down': openItemIndex !== 8, 'fa-chevron-up': openItemIndex === 8 }"
                                                    aria-hidden="true"></i>
                                                Day care treatment
                                            </div>
                                            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 8 }"> Day
                                                care
                                                treatment covers medical procedures or treatments that don't require an
                                                overnight hospital stay. It includes short, specialized treatments like
                                                minor surgeries, dialysis or chemotherapy.</div>
                                        </div>
                                        <div class="faq-item">
                                            <div class="faq-question" (click)="toggleAnswer(9)">
                                                <i class="fa faq-icon"
                                                    [ngClass]="{ 'fa-chevron-down': openItemIndex !== 9, 'fa-chevron-up': openItemIndex === 9 }"
                                                    aria-hidden="true"></i>
                                                Hospitalization at home
                                            </div>
                                            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 9 }">
                                                Hospitalization at home means your insurance covers medical care and
                                                treatment at your own home instead of in a hospital, making recovery
                                                more
                                                comfortable.</div>
                                        </div>
                                        <div class="faq-item">
                                            <div class="faq-question" (click)="toggleAnswer(10)">
                                                <i class="fa faq-icon"
                                                    [ngClass]="{ 'fa-chevron-down': openItemIndex !== 10, 'fa-chevron-up': openItemIndex === 10 }"
                                                    aria-hidden="true"></i>
                                                Ambulance charges
                                            </div>
                                            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 10 }">
                                                Ambulance
                                                charges cover the cost of taking you to the hospital in an emergency.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="content-block" *ngIf="iswaitingperiodfeatures">
                                        <h5>Waiting periods</h5>
                                        <div class="faq-item">
                                            <div class="faq-question" (click)="toggleAnswer(11)">
                                                <i class="fa faq-icon"
                                                    [ngClass]="{ 'fa-chevron-down': openItemIndex !== 11, 'fa-chevron-up': openItemIndex === 11 }"
                                                    aria-hidden="true"></i>
                                                Existing Illness cover
                                            </div>
                                            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 11 }"> The
                                                time
                                                you need to wait before the insurance covers pre-existing health
                                                conditions.
                                                For example, if you have diabetes, or asthma or thyroid before getting
                                                the
                                                insurance, the expenses for these treatment might not be covered until
                                                the
                                                waiting period is over.</div>
                                        </div>
                                        <div class="faq-item">
                                            <div class="faq-question" (click)="toggleAnswer(12)">
                                                <i class="fa faq-icon"
                                                    [ngClass]="{ 'fa-chevron-down': openItemIndex !== 12, 'fa-chevron-up': openItemIndex === 12 }"
                                                    aria-hidden="true"></i>
                                                Maternity cover
                                            </div>
                                            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 12 }">
                                                Maternity
                                                cover helps cover the costs of having a baby. It includes expenses like
                                                hospitalization, delivery, and sometimes pre/post-natal care. There's a
                                                certain time you have to wait after getting health insurance before
                                                using
                                                maternity benefits.</div>
                                        </div>
                                        <div class="faq-item">
                                            <div class="faq-question" (click)="toggleAnswer(13)">
                                                <i class="fa faq-icon"
                                                    [ngClass]="{ 'fa-chevron-down': openItemIndex !== 13, 'fa-chevron-up': openItemIndex === 13 }"
                                                    aria-hidden="true"></i>
                                                Initial Waiting Period
                                            </div>
                                            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 13 }"> It is
                                                a
                                                time when certain benefits won't be available right after you buy the
                                                policy. For example, if you have a health issue, the insurance might not
                                                cover it during this initial period. It helps prevent misuse and keeps
                                                premiums lower.</div>
                                        </div>
                                        <div class="faq-item">
                                            <div class="faq-question" (click)="toggleAnswer(14)">
                                                <i class="fa faq-icon"
                                                    [ngClass]="{ 'fa-chevron-down': openItemIndex !== 14, 'fa-chevron-up': openItemIndex === 14 }"
                                                    aria-hidden="true"></i>
                                                Specific Illness cover
                                            </div>
                                            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 14 }"> It
                                                helps
                                                pay for treatment and related costs for specific diseases like
                                                cataracts,
                                                ENT disorders, osteoporosis, hernia, joint replacement surgery, etc. The
                                                time period varies from one insurance plan to another.</div>
                                        </div>
                                    </div>
                                    <div class="content-block" *ngIf="isadditionalfeatures">
                                        <h5>Additional Features</h5>
                                        <div class="faq-item">
                                            <div class="faq-question" (click)="toggleAnswer(15)">
                                                <i class="fa faq-icon"
                                                    [ngClass]="{ 'fa-chevron-down': openItemIndex !== 15, 'fa-chevron-up': openItemIndex === 15 }"
                                                    aria-hidden="true"></i>
                                                Alternate medicine (AYUSH)
                                            </div>
                                            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 15 }"> This
                                                feature covers covers treatments beyond traditional medicine. This
                                                includes
                                                Ayurveda, Yoga, Unani, Siddha, and Homeopathy.</div>
                                        </div>
                                        <div class="faq-item">
                                            <div class="faq-question" (click)="toggleAnswer(16)">
                                                <i class="fa faq-icon"
                                                    [ngClass]="{ 'fa-chevron-down': openItemIndex !== 16, 'fa-chevron-up': openItemIndex === 16 }"
                                                    aria-hidden="true"></i>
                                                Worldwide coverage
                                            </div>
                                            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 16 }">
                                                Worldwide
                                                coverage means your health insurance works not just in your home country
                                                but
                                                all around the world. So, if you travel or need medical help abroad,
                                                your
                                                insurance has your back.</div>
                                        </div>
                                        <div class="faq-item">
                                            <div class="faq-question" (click)="toggleAnswer(17)">
                                                <i class="fa faq-icon"
                                                    [ngClass]="{ 'fa-chevron-down': openItemIndex !== 17, 'fa-chevron-up': openItemIndex === 17 }"
                                                    aria-hidden="true"></i>
                                                Consumables Coverage
                                            </div>
                                            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 17 }"> This
                                                feature helps pay for daily medical items used during your treatment.
                                                Examples include bandages, syringes, and dressings, easing your
                                                out-of-pocket expenses.</div>
                                        </div>
                                        <div class="faq-item">
                                            <div class="faq-question" (click)="toggleAnswer(18)">
                                                <i class="fa faq-icon"
                                                    [ngClass]="{ 'fa-chevron-down': openItemIndex !== 18, 'fa-chevron-up': openItemIndex === 18 }"
                                                    aria-hidden="true"></i>
                                                Domestic evacuation
                                            </div>
                                            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 18 }">
                                                Domestic
                                                Evacuation covers the cost of moving you to a better medical facility
                                                within
                                                your country for specialized treatment.</div>
                                        </div>
                                        <div class="faq-item">
                                            <div class="faq-question" (click)="toggleAnswer(19)">
                                                <i class="fa faq-icon"
                                                    [ngClass]="{ 'fa-chevron-down': openItemIndex !== 19, 'fa-chevron-up': openItemIndex === 19 }"
                                                    aria-hidden="true"></i>
                                                Cover for organ donor
                                            </div>
                                            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 19 }"> Cover
                                                for
                                                Organ Donor means your health insurance helps with medical costs when
                                                someone donates an organ. For example, it can cover the donor's hospital
                                                bills or related expenses.</div>
                                        </div>
                                        <div class="faq-item">
                                            <div class="faq-question" (click)="toggleAnswer(20)">
                                                <i class="fa faq-icon"
                                                    [ngClass]="{ 'fa-chevron-down': openItemIndex !== 20, 'fa-chevron-up': openItemIndex === 20 }"
                                                    aria-hidden="true"></i>
                                                Animal bite vaccination
                                            </div>
                                            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 20 }"> This
                                                feature helps pay for vaccines needed after an animal bite such as dog
                                                bite
                                                or cat bite etc..</div>
                                        </div>
                                    </div>
                                    <div class="content-block" *ngIf="isvalueaddedfeatures">
                                        <h5>Value Added Services</h5>
                                        <div class="faq-item">
                                            <div class="faq-question" (click)="toggleAnswer(21)">
                                                <i class="fa faq-icon"
                                                    [ngClass]="{ 'fa-chevron-down': openItemIndex !== 21, 'fa-chevron-up': openItemIndex === 21 }"
                                                    aria-hidden="true"></i>
                                                Free health checkup
                                            </div>
                                            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 21 }"> The
                                                insurance company pays for your health checkup like blood tests,
                                                screenings,
                                                and consultations to detect potential health issues early.</div>
                                        </div>
                                        <div class="faq-item">
                                            <div class="faq-question" (click)="toggleAnswer(22)">
                                                <i class="fa faq-icon"
                                                    [ngClass]="{ 'fa-chevron-down': openItemIndex !== 22, 'fa-chevron-up': openItemIndex === 22 }"
                                                    aria-hidden="true"></i>
                                                E-consultation
                                            </div>
                                            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 22 }">
                                                E-consultation allows you to talk to a doctor online. It covers virtual
                                                appointments for medical advice, prescriptions, and guidance.</div>
                                        </div>
                                        <div class="faq-item">
                                            <div class="faq-question" (click)="toggleAnswer(23)">
                                                <i class="fa faq-icon"
                                                    [ngClass]="{ 'fa-chevron-down': openItemIndex !== 23, 'fa-chevron-up': openItemIndex === 23 }"
                                                    aria-hidden="true"></i>
                                                Discount on Renewal
                                            </div>
                                            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 23 }"> Earn a
                                                discount on your health insurance renewal by staying active and fit.
                                            </div>
                                        </div>
                                        <div class="faq-item">
                                            <div class="faq-question" (click)="toggleAnswer(24)">
                                                <i class="fa faq-icon"
                                                    [ngClass]="{ 'fa-chevron-down': openItemIndex !== 24, 'fa-chevron-up': openItemIndex === 24 }"
                                                    aria-hidden="true"></i>
                                                Daily cash allowance
                                            </div>
                                            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 24 }"> "Daily
                                                Cash Allowance means getting a fixed amount daily for additional
                                                expenses
                                                during hospital stays. Examples include covering food, transportation,
                                                or
                                                other personal needs.</div>
                                        </div>
                                        <div class="faq-item">
                                            <div class="faq-question" (click)="toggleAnswer(25)">
                                                <i class="fa faq-icon"
                                                    [ngClass]="{ 'fa-chevron-down': openItemIndex !== 25, 'fa-chevron-up': openItemIndex === 25 }"
                                                    aria-hidden="true"></i>
                                                Out patient consultation benefits
                                            </div>
                                            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 25 }">
                                                Outpatient
                                                Consultation Benefits" in health insurance cover the costs of seeing a
                                                doctor without being admitted to a hospital.</div>
                                        </div>
                                    </div>
                                    <div class="content-block" *ngIf="iscashlesshospitals">
                                        <h5>Cashless Hospitals</h5>
                                    </div>
                                    <div class="content-block" *ngIf="isdocumentsupload">
                                        <h5>Policy Documents</h5>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <app-footer></app-footer> -->

    <div *ngIf="isPayment">
        <app-lifeline-payment [royalDetails]="royalDetails"></app-lifeline-payment>
    </div>