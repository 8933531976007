
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InsuranceService } from 'src/app/Services/insurance.service';
import swal from 'sweetalert2';
import { Router } from '@angular/router';
import { LookUpService } from 'src/app/Services/lookup.service';
import { PincodeMaster } from 'src/app/Models/signUp.component.model';
import { Item } from 'src/app/Models/Item';
import { HealthComponent } from 'src/app/health/health.component';
import { GeneralService } from 'src/app/Services/general.service';
import * as moment from 'moment';

@Component({
  selector: 'app-healthinsurance-policy',
  templateUrl: './healthinsurance-policy.component.html',
  styleUrls: ['./healthinsurance-policy.component.css']
})
export class HealthinsurancePolicyComponent implements OnInit {
  healthTab: string = ''; divNumber: string = 'first'; ageList: any = []; childrenAgeList: any = [];
  healthForm: FormGroup; premiumRequest: FormGroup; isSelf: boolean = false; isSpouse: boolean = false; isHusband: boolean = false; isSon: boolean = false;
  isDaughter: boolean = false; isFather: boolean = false; isMother: boolean = false; isOther: boolean = false; isExistingIllness: boolean = false; isSurgicalProcedure: boolean = false; isNoneoftheAbove: boolean = false; isDiabets: boolean = false; isHypertension: boolean = false; isHeartAliments: boolean = false; isOtherHealthIssue: boolean = false; isgrandFather: boolean = false; isgrandMother: boolean = false; isfatherInLaw: boolean = false; ismotherInLaw: boolean = false; isUncle: boolean = false;
  isAunt: boolean = false; pincodeMaster: PincodeMaster[] = []; isQuotes: boolean = false; son = 1; sonList: any[] = []; daughter = 1; daughterList: any[] = [];
  div: boolean = false; noOfAdults: number = 0; address: any;
  @ViewChild('selfAge') selfAge: any;
  @ViewChild('sonAge') sonAge: any;
  @ViewChild('dauAge') dauAge: any;
  @ViewChild('spouseAge') spouseAge: any;
  @ViewChild('fatherAge') fatherAge: any;
  @ViewChild('motherAge') motherAge: any;
  @ViewChild('grandpaAge') grandpaAge: any;
  @ViewChild('grandmaAge') grandmaAge: any;
  @ViewChild('fatherLawAge') fatherLawAge: any;
  @ViewChild('motherLawAge') motherLawAge: any;
  @ViewChild('uncleAge') uncleAge: any;
  @ViewChild('auntAge') auntAge: any;
  isSelectAge: String = "";
  selfAge1: string = "";
  spouseAge1: string = "";
  fatherAge1: string = "";
  motherAge1: string = "";
  fatherLawAge1: string = "";
  motherLawAge1: string = "";
  grandpaAge1: string = "";
  grandmaAge1: string = "";
  uncleAge1: string = "";
  auntAge1: string = "";



  selectedSonAge1: string = "";
  selectedSonAge2: string = "";
  selectedSonAge3: string = "";
  selectedSonAge4: string = "";
  selectedSonAge5: string = "";
  selectedSonAge6: string = "";

  selectedDauAge1: string = "";
  selectedDauAge2: string = "";
  selectedDauAge3: string = "";
  selectedDauAge4: string = "";
  selectedDauAge5: string = "";
  selectedDauAge6: string = "";

  healthData: any;
  heading: string = 'How old each member?';
  btnFirst: boolean = true;
  btnSecond: boolean = false;
  btnThird: boolean = false;
  btnFourth: boolean = false;
  btnFive: boolean = false;
  FamilybtnFirst: boolean = true;
  FamilybtnSecond: boolean = false;
  FamilybtnThird: boolean = false;
  FamilybtnFourth: boolean = true;
  FamilybtnFive: boolean = false;
  VarifyBtn: boolean = false;
  FamilyVarifyBtn: boolean = false;
  dropdownNumber: number = 0;
  firstSon: boolean = false;
  secondSon: boolean = false;
  thirdSon: boolean = false;
  fourthSon: boolean = false;

  firstDaughter: boolean = false;
  secondDaughter: boolean = false;
  thirdDaughter: boolean = false;
  fourthDaughter: boolean = false;
  familyMemberAge: FormGroup;

  //  @ViewChild('pinCode') pinCode: any;
  //  @ViewChild('pinCityVillage') pinCityVillage: any;


  others(): void {
    this.div = true;
  }
  change(): void {

    this.div = false;
  }

  SumInsuredList:any = []; 
  // [
  //   {text:"Choose Sum Insured",value:"0"},
  //   {text:"Below 5 Lakhs",value:"0-400000"},
  //   {text:"5 to 9 Lakhs",value:"500000-900000"},
  //   {text:"10 to 24 Lakhs",value:"1000000-2400000"},
  //   {text:"25 to 99 Lakhs",value:"2500000-9900000"},
  //   {text:"1cr+",value:"10000000-100000000"}
  // ];

  pincode:any;
  mycity:any;
  constructor(private fb: FormBuilder, private insuranceService: InsuranceService, private router: Router,
    private lookUpService: LookUpService, private generalService:GeneralService) {
    this.GetHealthSumInsuredRanges();
    this.healthForm = this.fb.group({
      "self": [""],
      "spouse": [""],
      "son": [""],
      "daughter": [""],
      "father": [""],
      "mother": [""],
      "other": [""],
      "memberAge": [""],
      "spouseAge": [""],
      "firstDaughter": [""],
      "secondDaughter": [""],
      "sonAge": [""],
      "fatherAge": [""],
      "motherAge": [""],
      "otherAge": [""],
      "streetAddress": [""],
      "yourPincode": [""],
      "selectVillage": [""]

    })

    this.premiumRequest = this.fb.group({
      "noOfPersons": [""],
      "noOfAdults": [""],
      "noOfChilds": [""],
      "planType": [""],
      "sumInsured": [""],
      "city": [""],
      "state": [],
      "policyDuration":["1"]
    });
  }
  InlessItems: Item[] = [];
  ChildNo: number = 0;
  images: any[] = [
    './assets/partners/new/digit.png',
    './assets/partners/new/sompo.png',
    './assets/partners/new/shriram.png',
    './assets/partners/new/reliance.png'
  ]
  images1: any[] = [
    './assets/partners/new/icici.png',
    './assets/partners/new/oriental.png',
    './assets/partners/new/bajaj.png',
    './assets/partners/new/national.png'
  ]
  showAllImages = false;
  openItemIndex = -1;
  toggleAnswer(index: number): void {
    if (this.openItemIndex === index) {
      this.openItemIndex = -1; // Clicking the open item again should close it
    } else {
      this.openItemIndex = index; // Open the clicked item
    }
  }
  text = '';
  type = 0;
  getValue(type: number) {
    this.type = type;
    if (type == 1) {
      this.text = 'Covers the full cost of repairs or replacements without accounting for depreciation, ensuring you receive the maximum claim amount.';
    }
    else if (type == 2) {
      this.text = 'Consumable cover reimburses the cost of items like engine oil, nuts, bolts, and other consumables used during bike repairs.';
    }
    else if (type == 3) {
      this.text = 'Return to Invoice cover reimburses the difference between your bike’s insured declared value and its original invoice value in case of total loss or theft.';
    }
    else if (type == 4) {
      this.text = 'Roadside assistance provides emergency support for issues like breakdowns, flat tires, or fuel shortages, ensuring help is available wherever you are.';
    }
    else if (type == 5) {
      this.text = 'Engine and gearbox protection covers repair or replacement costs for damage caused to the engine and transmission system due to issues like water ingress or mechanical failure.';
    }
    else if (type == 6) {
      this.text = 'Daily allowance provides a fixed amount of compensation for each day your bike is under repair at a garage, helping to cover alternative transportation costs.';
    }

  }

  ngOnInit(): void {
    this.getValue(1);
    this.getAge();
    this.getSonDaugherAge();
    this.daughterList = [];
    this.sonList = [];


    this.illnessItemsSelf = [
      { id: 'Self_one', name: 'Diabetes', checked: false },
      { id: 'Self_two', name: 'BP / Hypertension', checked: false },
      { id: 'Self_three', name: 'Heart Aliments', checked: false },
      { id: 'Self_four', name: 'Other', checked: false },
      { id: 'Self_five', name: 'No Disease', checked: false },
    ]
    this.illnessItemsSpouse = [
      { id: 'Spouse_one', name: 'Diabetes', checked: false },
      { id: 'Spouse_two', name: 'BP / Hypertension', checked: false },
      { id: 'Spouse_three', name: 'Heart Aliments', checked: false },
      { id: 'Spouse_four', name: 'Other', checked: false },
      { id: 'Spouse_five', name: 'No Disease', checked: false },

    ]
    this.illnessItemsHusband = [
      { id: 'Husband_one', name: 'Diabetes', checked: false },
      { id: 'Husband_two', name: 'BP / Hypertension', checked: false },
      { id: 'Husband_three', name: 'Heart Aliments', checked: false },
      { id: 'Husband_four', name: 'Other', checked: false },
      { id: 'Husband_five', name: 'No Disease', checked: false },
    ]
    this.illnessItemsSon1 = [
      { id: 'Son1_one', name: 'Diabetes', checked: false },
      { id: 'Son1_two', name: 'BP / Hypertension', checked: false },
      { id: 'Son1_three', name: 'Heart Aliments', checked: false },
      { id: 'Son1_four', name: 'Other', checked: false },
      { id: 'Son1_five', name: 'No Disease', checked: false },

    ]
    this.illnessItemsSon2 = [
      { id: 'Son2_one', name: 'Diabetes', checked: false },
      { id: 'Son2_two', name: 'BP / Hypertension', checked: false },
      { id: 'Son2_three', name: 'Heart Aliments', checked: false },
      { id: 'Son2_four', name: 'Other', checked: false },
      { id: 'Son2_five', name: 'No Disease', checked: false },

    ]
    this.illnessItemsSon3 = [
      { id: 'Son3_one', name: 'Diabetes', checked: false },
      { id: 'Son3_two', name: 'BP / Hypertension', checked: false },
      { id: 'Son3_three', name: 'Heart Aliments', checked: false },
      { id: 'Son3_four', name: 'Other', checked: false },
      { id: 'Son3_five', name: 'No Disease', checked: false },

    ]
    this.illnessItemsSon4 = [
      { id: 'Son4_one', name: 'Diabetes', checked: false },
      { id: 'Son4_two', name: 'BP / Hypertension', checked: false },
      { id: 'Son4_three', name: 'Heart Aliments', checked: false },
      { id: 'Son4_four', name: 'Other', checked: false },
      { id: 'Son4_five', name: 'No Disease', checked: false },

    ]
    this.illnessItemsDaughter1 = [
      { id: 'Daughter1_one', name: 'Diabetes', checked: false },
      { id: 'Daughter1_two', name: 'BP / Hypertension', checked: false },
      { id: 'Daughter1_three', name: 'Heart Aliments', checked: false },
      { id: 'Daughter1_four', name: 'Other', checked: false },
      { id: 'Daughter1_five', name: 'No Disease', checked: false },

    ]
    this.illnessItemsDaughter2 = [
      { id: 'Daughter2_one', name: 'Diabetes', checked: false },
      { id: 'Daughter2_two', name: 'BP / Hypertension', checked: false },
      { id: 'Daughter2_three', name: 'Heart Aliments', checked: false },
      { id: 'Daughter2_four', name: 'Other', checked: false },
      { id: 'Daughter2_five', name: 'No Disease', checked: false },

    ]
    this.illnessItemsDaughter3 = [
      { id: 'Daughter3_one', name: 'Diabetes', checked: false },
      { id: 'Daughter3_two', name: 'BP / Hypertension', checked: false },
      { id: 'Daughter3_three', name: 'Heart Aliments', checked: false },
      { id: 'Daughter3_four', name: 'Other', checked: false },
      { id: 'Daughter3_five', name: 'No Disease', checked: false },

    ]
    this.illnessItemsDaughter4 = [
      { id: 'Daughter4_one', name: 'Diabetes', checked: false },
      { id: 'Daughter4_two', name: 'BP / Hypertension', checked: false },
      { id: 'Daughter4_three', name: 'Heart Aliments', checked: false },
      { id: 'Daughter4_four', name: 'Other', checked: false },
      { id: 'Daughter4_five', name: 'No Disease', checked: false },

    ]

    this.illnessItemsFather = [
      { id: 'Father_one', name: 'Diabetes', checked: false },
      { id: 'Father_two', name: 'BP / Hypertension', checked: false },
      { id: 'Father_three', name: 'Heart Aliments', checked: false },
      { id: 'Father_four', name: 'Other', checked: false },
      { id: 'Father_five', name: 'No Disease', checked: false },

    ]
    this.illnessItemsMother = [
      { id: 'Mother_one', name: 'Diabetes', checked: false },
      { id: 'Mother_two', name: 'BP / Hypertension', checked: false },
      { id: 'Mother_three', name: 'Heart Aliments', checked: false },
      { id: 'Mother_four    ', name: 'Other', checked: false },
      { id: 'Mother_five', name: 'No Disease', checked: false },

    ]
    this.illnessItemsgrandFather = [
      { id: 'grandFather_one', name: 'Diabetes', checked: false },
      { id: 'grandFather_two', name: 'BP / Hypertension', checked: false },
      { id: 'grandFather_three', name: 'Heart Aliments', checked: false },
      { id: 'grandFather_four', name: 'Other', checked: false },
      { id: 'grandFather_five', name: 'No Disease', checked: false },

    ]
    this.illnessItemsgrandMother = [
      { id: 'grandMother_one', name: 'Diabetes', checked: false },
      { id: 'grandMother_two', name: 'BP / Hypertension', checked: false },
      { id: 'grandMother_three', name: 'Heart Aliments', checked: false },
      { id: 'grandMother_four', name: 'Other', checked: false },
      { id: 'grandMother_five', name: 'No Disease', checked: false },

    ]
    this.illnessItemsFotherInLaw = [
      { id: 'FotherInLaw_one', name: 'Diabetes', checked: false },
      { id: 'FotherInLaw_two', name: 'BP / Hypertension', checked: false },
      { id: 'FotherInLaw_three', name: 'Heart Aliments', checked: false },
      { id: 'FotherInLaw_four', name: 'Other', checked: false },
      { id: 'FotherInLaw_five', name: 'No Disease', checked: false },

    ]
    this.illnessItemsMotherInLaw = [
      { id: 'MotherInLaw_one', name: 'Diabetes', checked: false },
      { id: 'MotherInLaw_two', name: 'BP / Hypertension', checked: false },
      { id: 'MotherInLaw_three', name: 'Heart Aliments', checked: false },
      { id: 'MotherInLaw_four', name: 'Other', checked: false },
      { id: 'MotherInLaw_five', name: 'No Disease', checked: false },
    ]
    this.illnessItemsUncle = [
      { id: 'Uncle_one', name: 'Diabetes', checked: false },
      { id: 'Uncle_two', name: 'BP / Hypertension', checked: false },
      { id: 'Uncle_three', name: 'Heart Aliments', checked: false },
      { id: 'Uncle_four', name: 'Other', checked: false },
      { id: 'Uncle_five', name: 'No Disease', checked: false },

    ]
    this.illnessItemsAunt = [
      { id: 'Aunt_one', name: 'Diabetes', checked: false },
      { id: 'Aunt_two', name: 'BP / Hypertension', checked: false },
      { id: 'Aunt_three', name: 'Heart Aliments', checked: false },
      { id: 'Aunt_four', name: 'Other', checked: false },
      { id: 'Aunt_five', name: 'No Disease', checked: false },

    ]
  }


  async getAge() {
    this.ageList = [];
    for (var i = 18; i <= 100; i++) {
      this.ageList.push(i)
    }
  }

  async getSonDaugherAge() {
    this.childrenAgeList = [];
    for (var i = 1; i <= 100; i++) {
      this.childrenAgeList.push(i)
    }
  }


  async pincodeUserChangeEvent() {
    this.isBtn4 = false;
    this.isFamBtn4 = false;
    const res = await this.lookUpService.pincodeMasterDetails(this.pincode);
    if (res != null && res.result) {
      this.pincodeMaster = res.result;
      // this.pincodeMasterId = this.pincodeMaster?.id;
    }
  }



  Tab(name: string, indexValue?: string): void {
    if (indexValue == '1') {
      this.isSelf = this.healthForm.value.self;
      this.isSpouse = this.healthForm.value.spouse;
      this.isHusband = this.healthForm.value.spouse;
      this.isSon = this.healthForm.value.son;
      this.isDaughter = this.healthForm.value.daughter;
      this.isFather = this.healthForm.value.father;
      this.isMother = this.healthForm.value.mother;
      this.isOther = this.healthForm.value.other;
      this.isExistingIllness = this.healthForm.value.other;
      this.isSurgicalProcedure = this.healthForm.value.other;
      this.isNoneoftheAbove = this.healthForm.value.other;
      this.isDiabets = this.healthForm.value.other;
      this.isHypertension = this.healthForm.value.other;
      this.isHeartAliments = this.healthForm.value.other;
      this.isOtherHealthIssue = this.healthForm.value.other;
      let self = this.isSelf ? 1 : 0;
      let spouse = this.isSpouse ? 1 : 0;
      let Husband = this.isHusband ? 1 : 0;
      let father = this.isFather ? 1 : 0;
      let mother = this.isMother ? 1 : 0;
      let other = this.isOther ? 1 : 0;

      this.noOfAdults = self + Husband + spouse + father + mother + other;


    }
    this.healthTab = name;
  }
  Divs(number: string): void {
    this.divNumber = number;
  }

  async changeCity() {
    this.address = this.pincodeMaster.filter(x => x.id == this.mycity);
  }

  relationData: any;
  async getHealthQuotes() {
    if (!this.isSon)
      this.sonList = [];
    if (!this.isDaughter)
      this.daughterList = [];
    this.premiumRequest.controls['noOfPersons'].setValue((this.noOfAdults + this.daughterList.length + this.sonList.length).toString());
    this.premiumRequest.controls['noOfAdults'].setValue(this.noOfAdults.toString());
    this.premiumRequest.controls['noOfChilds'].setValue((this.daughterList.length + this.sonList.length).toString());
    this.premiumRequest.controls['city'].setValue(this.address[0]?.district);
    this.premiumRequest.controls['state'].setValue(this.address[0]?.state);
    this.premiumRequest.controls['planType'].setValue("");
    this.premiumRequest.controls['sumInsured'].setValue("500000");

    this.isQuotes = true;
  }

  getAmount(){
    // let amount = "500000"
    // switch(this.amountSelected){
    //   case "1" : amount = "100000";break;
    //   case "2" : amount = "200000";break;
    //   case "3" : amount = "300000";break;
    //   case "4" : amount = "400000";break;
    //   case "5" : amount = "500000";break;
    //   case "6" : amount = "600000";break;
    //   case "7" : amount = "700000";break;
    //   case "8" : amount = "800000";break;
    //   case "9" : amount = "900000";break;
    //   case "10" : amount = "1000000";break;
    // }
    // return amount;
    return this.amountSelected;
  }

  sonChangeValue(values: any): void {
    if (values.currentTarget.checked) {
      this.sonList.push(this.son);
    }
    else {
      this.sonList = [];
    }
  }

  daughterChangeValue(values: any): void {
    if (values.currentTarget.checked) {
      this.daughterList.push(this.daughter);
    }
    else {
      this.daughterList = [];
    }
  }


  addSon(): void {
    if (this.son <= 3) {
      this.son = this.son + 1;
      this.sonList.push(this.son);
    }
  }
  removeSon(): void {
    if (this.son <= 0) {
      this.son = 0;
      this.sonList = [];
    } else {
      this.son = this.son - 1;
      this.sonList.splice(this.son, 1);
    }
  }

  addDaughter(): void {
    if (this.daughter <= 3) {
      this.daughter = this.daughter + 1;
      this.daughterList.push(this.daughter);
    }
  }
  removeDaughter(): void {
    if (this.daughter <= 0) {
      this.daughter = 0;
      this.daughterList = [];
    } else {
      this.daughterList.splice(this.daughter, 1);
      this.daughter = this.daughter - 1;

    }
  }


  async getSonDetails(data: any, index: any) {
    switch (index) {
      case 0: {
        this.selectedSonAge1 = data;
        break;
      }
      case 1: {
        this.selectedSonAge2 = data;
        break;
      }
      case 2: {
        this.selectedSonAge3 = data;
        break;
      }
      case 3: {
        this.selectedSonAge4 = data;
        break;
      }
      case 4: {
        this.selectedSonAge5 = data;
        break;
      }
      case 5: {
        this.selectedSonAge6 = data;
        break;
      }
      default: {
        this.selectedSonAge1 = "";
        this.selectedSonAge2 = "";
        this.selectedSonAge3 = "";
        this.selectedSonAge4 = "";
        this.selectedSonAge5 = "";
        this.selectedSonAge6 = "";
        break;
      }
    }

  }

  async getDaughterDetails(data: any, index: any) {
    switch (index) {
      case 0: {
        this.selectedDauAge1 = data;
        break;
      }
      case 1: {
        this.selectedDauAge2 = data;
        break;
      }
      case 2: {
        this.selectedDauAge3 = data;
        break;
      }
      case 3: {
        this.selectedDauAge4 = data;
        break;
      }
      case 4: {
        this.selectedDauAge5 = data;
        break;
      }
      case 5: {
        this.selectedDauAge6 = data;
        break;
      }
      default: {
        this.selectedDauAge1 = "";
        this.selectedDauAge2 = "";
        this.selectedDauAge3 = "";
        this.selectedDauAge4 = "";
        this.selectedDauAge5 = "";
        this.selectedDauAge6 = "";
        break;
      }
    }

  }

  async getHealthPlans() {
    let self = this.isSelf ? 1 : 0; let spouse = this.isSpouse ? 1 : 0; let Husband = this.isHusband ? 1 : 0; let father = this.isFather ? 1 : 0; let mother = this.isMother ? 1 : 0;

    // let other = this.isOther? 1:0;
    let grandFather = this.isgrandFather ? 1 : 0; let grandMother = this.isgrandMother ? 1 : 0; let fatherInLaw = this.isfatherInLaw ? 1 : 0;
    let motherInLaw = this.ismotherInLaw ? 1 : 0; let Uncle = this.isUncle ? 1 : 0; let Aunt = this.isAunt ? 1 : 0;
    this.noOfAdults = self + spouse + father + mother + grandFather + grandMother + fatherInLaw + motherInLaw + Uncle + Aunt;
    // ;
    this.premiumRequest.controls['noOfPersons'].setValue((this.noOfAdults + this.daughterList.length + this.sonList.length).toString());
    this.premiumRequest.controls['noOfAdults'].setValue(this.noOfAdults.toString());
    this.premiumRequest.controls['noOfChilds'].setValue((this.daughterList.length + this.sonList.length).toString());
    // ;
    this.premiumRequest.controls['city'].setValue(this.address[0]?.district);
    this.premiumRequest.controls['state'].setValue(this.address[0]?.state);
    this.premiumRequest.controls['planType'].setValue("");
    this.premiumRequest.controls['sumInsured'].setValue(this.getAmount());
    // ;

    this.healthinsurancepolicyindividual = false;
    this.healthinsurancepolicyfamily = false;
    // ;
    this.healthData = {
      amountSelected: this.amountSelected,
      selfSelected: this.isSelf,
      selfAge: this.selfAge1,
      selfGender: this.gender,

      sonSelected: this.sonList.length > 0 ? true : false,
      selectedSonAge1: this.selectedSonAge1,
      selectedSonAge2: this.selectedSonAge2,
      selectedSonAge3: this.selectedSonAge3,
      selectedSonAge4: this.selectedSonAge4,
      selectedSonAge5: this.selectedSonAge5,
      selectedSonAge6: this.selectedSonAge6,

      dauSelected: this.daughterList.length > 0 ? true : false,
      selectedDauAge1: this.selectedDauAge1,
      selectedDauAge2: this.selectedDauAge2,
      selectedDauAge3: this.selectedDauAge3,
      selectedDauAge4: this.selectedDauAge4,
      selectedDauAge5: this.selectedDauAge5,
      selectedDauAge6: this.selectedDauAge6,

      spouseSelected: this.isSpouse,
      husbandSelected: this.isHusband,
      spouseAge: this.spouseAge1,

      fatherSelected: this.isFather,
      fatherAge: this.fatherAge1,

      motherSelected: this.isMother,
      motherAge: this.motherAge1,

      fatherLawSelected: this.isfatherInLaw,
      fatherLawAge: this.fatherLawAge1,

      motherLawSelected: this.ismotherInLaw,
      motherLawAge: this.motherLawAge1,

      grandPaSelected: this.isgrandFather,
      grandPaAge: this.grandpaAge1,

      grandMaSelected: this.isgrandMother,
      grandMaAge: this.grandmaAge1,

      uncleSelected: this.isUncle,
      uncleAge: this.uncleAge1,

      auntSelected: this.isAunt,
      auntAge: this.auntAge1,
      firstSone: this.firstSon,
      secondSon: this.secondSon,
      thirdSon: this.thirdSon,
      fourthSon: this.fourthSon,
      firstDaughter: this.firstDaughter,
      secondDaughter: this.secondDaughter,
      thirdDaughter: this.thirdDaughter,
      fourthDaughter: this.fourthDaughter

    }

    // this.generalService.setDataToLocalStorage("healthBriefData",JSON.stringify(this.premiumRequest.value));
    // this.generalService.setDataToLocalStorage("healthDetailData",JSON.stringify(this.healthData));

    let healthObj:any = {};
    let healthObjStr = this.generalService.getDataToLocalStorage("healthObj");
    debugger;
    if(healthObjStr != null && healthObjStr != undefined && healthObjStr != ''){
      healthObj = JSON.parse(healthObjStr);
      healthObj.quoteData = this.premiumRequest.value;
      healthObj.healthData = this.healthData;
      healthObj.planList = []
    }
    else
    {
      healthObj ={
        quoteData :this.premiumRequest.value,
        healthData : this.healthData,
        proposerForm : "",
        addressInfo : "",
        nomineeForm : "",
        selectedQuoteData :"",
        planList : []
      }; 
    }
    this.generalService.setDataToLocalStorage("healthObj",JSON.stringify(healthObj));
    this.router.navigate(['/health-quotes',{q:moment(new Date()).format("YYYYMMDDHHmmssSSSSSS")}]);    
  }
  gender: any = 'male';
  disease: any = 'yes';
  isChecked: boolean = false;
  isChecked1: boolean = false;

  illnessItemsSelf: Item[] = [];
  illnessItemsSpouse: Item[] = [];
  illnessItemsHusband: Item[] = [];
  illnessItemsSon1: Item[] = [];
  illnessItemsSon2: Item[] = [];
  illnessItemsSon3: Item[] = [];
  illnessItemsSon4: Item[] = [];
  illnessItemsDaughter1: Item[] = [];
  illnessItemsDaughter2: Item[] = [];
  illnessItemsDaughter3: Item[] = [];
  illnessItemsDaughter4: Item[] = [];
  illnessItemsFather: Item[] = [];
  illnessItemsMother: Item[] = [];
  illnessItemsgrandFather: Item[] = [];
  illnessItemsgrandMother: Item[] = [];
  illnessItemsFotherInLaw: Item[] = [];
  illnessItemsMotherInLaw: Item[] = [];
  illnessItemsUncle: Item[] = [];
  illnessItemsAunt: Item[] = [];
  showSearch: boolean = false;
  showSearch1: boolean = false;
  showAll: boolean = false;
  showAll1: boolean = false;
  showStatus: boolean = false;
  showStatus1: boolean = false;
  showError: boolean = false;
  showError1: boolean = false;
  selectedTab: number = 1;
  selectCard1: boolean = false;
  selectCard2: boolean = false;
  selectCard3: boolean = false;
  selectCard4: boolean = false;
  selectCard5: boolean = false;
  selectCard6: boolean = false;
  selectCard7: boolean = false;
  selectCard8: boolean = false;
  selectCard20: boolean = false;
  selectCard21: boolean = false;
  selectCard22: boolean = false;
  selectCard23: boolean = false;
  selectCard24: boolean = false;
  selectCard25: boolean = false;
  selectCard26: boolean = false;
  selectCard27: boolean = false;
  selectCard28: boolean = false;
  selectCard29: boolean = false;
  selectCard30: boolean = false;
  selectCard31: boolean = false;
  selectCard32: boolean = false;
  selectCard33: boolean = false;
  selectCard34: boolean = false;
  selectamount1: boolean = false;
  selectamount2: boolean = false;
  selectamount3: boolean = false;
  selectamount4: boolean = false;
  selectamount5: boolean = false;
  selectamount6: boolean = false;
  selectamount7: boolean = false;
  selectamount8: boolean = false;
  selectamount9: boolean = false;
  selectamount10: boolean = false;
  isViewQuotes: boolean = false;
  homeForm1: boolean = false;
  isDaughtersDiv: boolean = false;
  isSonsDiv: boolean = false;
  isGenderDiv: boolean = false;
  isOthersDiv: boolean = false;
  isotherhealthIssueDiv: boolean = false;
  age1: boolean = false;
  age2: boolean = false;
  age3: boolean = false;
  age4: boolean = false;
  age5: boolean = false;
  age6: boolean = false;
  age7: boolean = false;
  age8: boolean = false;
  age9: boolean = false;
  age10: boolean = false;
  age11: boolean = false;
  age12: boolean = false;
  age13: boolean = false;
  age14: boolean = false;
  age15: boolean = false;
  age16: boolean = false;
  age17: boolean = false;
  age18: boolean = false;
  age19: boolean = false;
  age20: boolean = false;
  mother: boolean = true;
  father: boolean = true;
  fatherInLaw: boolean = true;
  motherLaw: boolean = true;
  ExistingCount = 1;
  isBtn1: boolean = true;
  isBtn2: boolean = true;
  isBtn3: boolean = true;
  isBtn4: boolean = true;
  isFamBtn1: boolean = true;
  isFamBtn2: boolean = true;
  isFamBtn3: boolean = true;
  isFamBtn4: boolean = true;
  isSelectedPersion: string = "";
  selectedMembers: number = 0;
  selectedCard(value: string) {
    this.isBtn1 = false;
    this.isFamBtn1 = false;
    if (value == 'Self') {
      this.isSelf = !this.isSelf;
      this.selectCard1 = !this.selectCard1;
      this.isGenderDiv = !this.isGenderDiv;
      this.age1 = !this.age1;
      this.ExistingCount++;
      this.isSelectedPersion = "Self";
      if (this.isSelf) {
        this.dropdownNumber++;
        this.selectedMembers++;

      }
      if (!this.isSelf) {
        this.dropdownNumber--;
        this.selectedMembers--;
        if (this.indx == 1 && this.evnt != '')
          this.ageCount--;

      }
      // this.showw=!this.showw;
      this.isSelectAge = "Age";
    }
    else if (value == 'Wife') {
      this.isSpouse = !this.isSpouse;
      this.selectCard2 = !this.selectCard2;
      this.age2 = !this.age2;
      this.ExistingCount++;
      this.isSelectedPersion = "Wife";
      this.showw = false;
      this.isSelectAge = "Age";
      if (this.isSpouse) {
        this.dropdownNumber++;
        this.selectedMembers++;
      }
      if (!this.isSpouse) {
        this.dropdownNumber--;
        this.selectedMembers--;
        // this.ageCount--;
        if (this.indx == 2 && this.evnt != '')
          this.ageCount--;
      }
    }
    else if (value == 'Husband') {
      this.isHusband = !this.isHusband;
      this.selectCard2 = !this.selectCard2;
      this.age2 = !this.age2;
      this.ExistingCount++;
      this.isSelectedPersion = "Husband";
      this.showw = false;
      this.isSelectAge = "Age";
      if (this.isHusband) {
        this.dropdownNumber++;
        this.selectedMembers++;
      }
      if (!this.isHusband) {
        this.dropdownNumber--;
        this.selectedMembers--;
        // this.ageCount--;
      }
    }
    else if (value == 'Son') {
      if (this.isDaughtersDiv != true || (this.isDaughtersDiv == true && this.doughtercounterValue < 4)) {
        this.isSon = !this.isSon;
        this.selectCard3 = !this.selectCard3;
        this.isSonsDiv = !this.isSonsDiv;
        this.counterValue = 1;
        this.firstSon = !this.firstSon;
        this.totalCounterValueFunction();
        this.age3 = !this.age3;
        this.ExistingCount++;
        if (!this.isSon) {
          this.age13 = false;
          this.age14 = false;
          this.age15 = false;
        }
        if (this.isSon) {
          this.dropdownNumber++;
          this.selectedMembers++;
        }
        if (!this.isSon) {
          this.dropdownNumber--;
          this.selectedMembers--;
          if (this.indx == 3 && this.evnt != '')
            this.ageCount--;
          // this.ageCount--;
        }
        this.isSelectedPersion = "Son";
        this.showw = false;
        this.isSelectAge = "Age";


      }
    }
    else if (value == 'Daughter') {
      if (this.isSonsDiv != true || (this.isSonsDiv == true && this.counterValue < 4)) {
        this.isDaughter = !this.isDaughter;
        this.selectCard4 = !this.selectCard4;
        this.isDaughtersDiv = !this.isDaughtersDiv;
        this.doughtercounterValue = 1;
        this.firstDaughter = !this.firstDaughter;
        this.totalCounterValueFunction();
        this.age4 = !this.age4;
        this.ExistingCount++;
        if (!this.isDaughter) {
          this.age16 = false;
          this.age17 = false;
          this.age18 = false;
        }
        this.isSelectedPersion = "Daughter";
        this.showw = false;
        this.isSelectAge = "Age";
        if (this.isDaughter) {
          this.dropdownNumber++;
          this.selectedMembers++;
        }
        if (!this.isDaughter) {
          this.dropdownNumber--;
          this.selectedMembers--;
          if (this.indx == 7 && this.evnt != '')
            this.ageCount--;
          // this.ageCount--;
        }

      }
    }
    else if (value == 'Father') {
      this.isFather = !this.isFather;
      this.selectCard5 = !this.selectCard5;
      this.age5 = !this.age5;

      this.ExistingCount++;

      // if (this.isFather) {
      //   this.motherLaw = false;
      //   this.fatherInLaw = false;
      // } else if (!this.isFather) {
      //   this.motherLaw = true;
      //   this.fatherInLaw = true;
      // }
      this.isSelectedPersion = "Father";
      this.showw = false;
      this.isSelectAge = "Age";
      if (this.isFather) {
        this.dropdownNumber++;
        this.selectedMembers++;
      }
      if (!this.isFather) {
        this.dropdownNumber--;
        this.selectedMembers--;
        // this.ageCount--;
        if (this.indx == 11 && this.evnt != '')
          this.ageCount--;
      }
    }
    else if (value == 'Mother') {
      this.isMother = !this.isMother;
      this.selectCard6 = !this.selectCard6;
      this.age6 = !this.age6;

      this.ExistingCount++;
      // if (this.isMother) {
      //   this.motherLaw = false;
      //   this.fatherInLaw = false;
      // } else if (!this.isMother) {
      //   this.motherLaw = true;
      //   this.fatherInLaw = true;
      // }
      this.isSelectedPersion = "Mother";
      this.showw = false;
      this.isSelectAge = "Age";
      if (this.isMother) {
        this.dropdownNumber++;
        this.selectedMembers++;
      }
      if (!this.isMother) {
        this.dropdownNumber--;
        this.selectedMembers--;
        if (this.indx == 12 && this.evnt != '')
          this.ageCount--;
        // this.ageCount--;
      }
    }
    else if (value == 'Other') {
      this.isOther = !this.isOther;
      this.selectCard7 = !this.selectCard7;
      this.isOthersDiv = !this.isOthersDiv;


    }
    else if (value == 'Grand Mother') {
      this.isOther = !this.isOther;
      this.selectCard27 = !this.selectCard27;
      this.isgrandMother = !this.isgrandMother
      this.age7 = !this.age7;
      this.ExistingCount++;
      this.isSelectedPersion = "Grand Mother";
      this.showw = false;
      this.isSelectAge = "Age";
      if (this.isgrandMother) {
        this.dropdownNumber++;
        this.selectedMembers++;
      }
      if (!this.isgrandMother) {
        this.dropdownNumber--;
        this.selectedMembers--;
        if (this.indx == 13 && this.evnt != '')
          this.ageCount--;
        // this.ageCount--;
      }
    }
    else if (value == 'Grand Father') {
      this.isOther = !this.isOther;
      this.selectCard28 = !this.selectCard28;
      this.isgrandFather = !this.isgrandFather;
      this.age8 = !this.age8;
      this.ExistingCount++;
      this.isSelectAge = "Age";

      this.isSelectedPersion = "Grand Father";
      this.showw = false;
      if (this.isgrandFather) {
        this.dropdownNumber++;
        this.selectedMembers++;
      }
      if (!this.isgrandFather) {
        this.dropdownNumber--;
        this.selectedMembers--;
        if (this.indx == 14 && this.evnt != '')
          this.ageCount--;
        // this.ageCount--;
      }
    }
    else if (value == 'Fater-in-law') {
      this.isOther = !this.isOther;
      this.selectCard29 = !this.selectCard29;
      this.isfatherInLaw = !this.isfatherInLaw;
      this.age9 = !this.age9;
      this.isSelectedPersion = "Fater-in-law";

      this.isSelectAge = "Age";
      this.ExistingCount++;
      this.showw = false;
      // if (this.isfatherInLaw) {
      //   this.mother = false;
      //   this.father = false;
      // } else if (!this.isfatherInLaw) {
      //   this.mother = true;
      //   this.father = true;
      // }
      if (this.isfatherInLaw) {
        this.dropdownNumber++;
        this.selectedMembers++;
      }
      if (!this.isfatherInLaw) {
        this.dropdownNumber--;
        this.selectedMembers--;
        if (this.indx == 15 && this.evnt != '')
          this.ageCount--;
        // this.ageCount--;
      }
    }
    else if (value == 'Mother-in-law') {
      this.isOther = !this.isOther;
      this.selectCard30 = !this.selectCard30;
      this.ismotherInLaw = !this.ismotherInLaw;
      this.age10 = !this.age10;
      this.ExistingCount++;
      this.isSelectAge = "Age";
      //  if (this.ismotherInLaw) {
      //   this.mother = false;
      //   this.father = false;
      // } else if (!this.ismotherInLaw) {
      //   this.mother = true;
      //   this.father = true;
      // }
      this.isSelectedPersion = "Mother-in-law";
      this.showw = false;
      if (this.ismotherInLaw) {
        this.dropdownNumber++;
        this.selectedMembers++;
      }
      if (!this.ismotherInLaw) {
        this.dropdownNumber--;
        this.selectedMembers--;
        if (this.indx == 16 && this.evnt != '')
          this.ageCount--;
        // this.ageCount--;
      }
    }
    else if (value == 'Uncle') {
      this.isOther = !this.isOther;
      this.selectCard31 = !this.selectCard31;
      this.isUncle = !this.isUncle;
      this.age11 = !this.age11;
      this.ExistingCount++;
      this.isSelectedPersion = "Uncle";
      this.showw = false;
      this.isSelectAge = "Age";

      if (this.isUncle) {
        this.dropdownNumber++;
        this.selectedMembers++;
      }
      if (!this.isUncle) {
        this.dropdownNumber--;
        this.selectedMembers--;
        if (this.indx == 17 && this.evnt != '')
          this.ageCount--;
        // this.ageCount--;
      }
    }
    else if (value == 'Aunt') {
      this.isOther = !this.isOther;
      this.selectCard32 = !this.selectCard32;
      this.isAunt = !this.isAunt;
      this.age12 = !this.age12;
      this.ExistingCount++;
      this.isSelectedPersion = "Aunt";
      this.showw = false;
      if (this.isAunt) {
        this.dropdownNumber++;
        this.selectedMembers++;
      }
      if (!this.isAunt) {
        this.dropdownNumber--;
        this.selectedMembers--;
        if (this.indx == 18 && this.evnt != '')
          this.ageCount--;
        // this.ageCount--;
      }
      this.isSelectAge = "Age";
    }
    else if (value == 'Existing Illness') {
      this.isExistingIllness = !this.isExistingIllness;
      this.selectCard20 = !this.selectCard20;
      this.selectCard22 = false;
    }
    else if (value == 'Surgical procedure') {
      this.isSurgicalProcedure = !this.isSurgicalProcedure;
      this.selectCard21 = !this.selectCard21;
      this.selectCard22 = false;
    }
    else if (value == 'None of these') {
      this.isNoneoftheAbove = !this.isNoneoftheAbove
      this.selectCard22 = !this.selectCard22;
      this.selectCard21 = false;
      this.selectCard20 = false;
    }
    else if (value == 'Diabetes') {
      this.isDiabets = !this.isDiabets;
      this.selectCard23 = !this.selectCard23;
    }
    else if (value == 'BP / Hypertension') {
      this.isHypertension = !this.isHypertension;
      this.selectCard24 = !this.selectCard24;
    }
    else if (value == 'Heart Aliments') {
      this.isHeartAliments = !this.isHeartAliments;
      this.selectCard25 = !this.selectCard25;
    }
    else if (value == 'Other health issues') {
      this.isOtherHealthIssue = !this.isOtherHealthIssue;
      this.selectCard26 = !this.selectCard26;
      this.isotherhealthIssueDiv = !this.isotherhealthIssueDiv;
    }
  }

  amountSelected: string = "0";

  selectedCovergageAmount(e: any) {
    debugger;
    this.amountSelected = e.target.value;
    this.isBtn2 = false;
    this.isFamBtn2 = this.amountSelected == "0";
    // if (val == '1lakh') {
    //   this.amountSelected = "1";
    //   this.selectamount1 = true;
    //   this.selectamount2 = false;
    //   this.selectamount3 = false;
    //   this.selectamount4 = false;
    //   this.selectamount5 = false;
    //   this.selectamount6 = false;
    //   this.selectamount7 = false;
    //   this.selectamount8 = false;
    //   this.selectamount9 = false;
    //   this.selectamount10 = false;
    // }
    // if (val == '2lakh') {
    //   this.amountSelected = "2";
    //   this.selectamount1 = false;
    //   this.selectamount2 = true;
    //   this.selectamount3 = false;
    //   this.selectamount4 = false;
    //   this.selectamount5 = false;
    //   this.selectamount6 = false;
    //   this.selectamount7 = false;
    //   this.selectamount8 = false;
    //   this.selectamount9 = false;
    //   this.selectamount10 = false;
    // }
    // if (val == '3lakh') {
    //   this.amountSelected = "3";
    //   this.selectamount1 = false;
    //   this.selectamount2 = false;
    //   this.selectamount3 = true;
    //   this.selectamount4 = false;
    //   this.selectamount5 = false;
    //   this.selectamount6 = false;
    //   this.selectamount7 = false;
    //   this.selectamount8 = false;
    //   this.selectamount9 = false;
    //   this.selectamount10 = false;
    // }
    // if (val == '4lakh') {
    //   this.amountSelected = "4";
    //   this.selectamount1 = false;
    //   this.selectamount2 = false;
    //   this.selectamount3 = false;
    //   this.selectamount4 = true;
    //   this.selectamount5 = false;
    //   this.selectamount6 = false;
    //   this.selectamount7 = false;
    //   this.selectamount8 = false;
    //   this.selectamount9 = false;
    //   this.selectamount10 = false;
    // }
    // if (val == '5lakh') {
    //   this.amountSelected = "5";
    //   this.selectamount1 = false;
    //   this.selectamount2 = false;
    //   this.selectamount3 = false;
    //   this.selectamount4 = false;
    //   this.selectamount5 = true;
    //   this.selectamount6 = false;
    //   this.selectamount7 = false;
    //   this.selectamount8 = false;
    //   this.selectamount9 = false;
    //   this.selectamount10 = false;
    // }
    // if (val == '6lakh') {
    //   this.amountSelected = "6";
    //   this.selectamount1 = false;
    //   this.selectamount2 = false;
    //   this.selectamount3 = false;
    //   this.selectamount4 = false;
    //   this.selectamount5 = false;
    //   this.selectamount6 = true;
    //   this.selectamount7 = false;
    //   this.selectamount8 = false;
    //   this.selectamount9 = false;
    //   this.selectamount10 = false;
    // }
    // if (val == '7lakh') {
    //   this.amountSelected = "7";
    //   this.selectamount1 = false;
    //   this.selectamount2 = false;
    //   this.selectamount3 = false;
    //   this.selectamount4 = false;
    //   this.selectamount5 = false;
    //   this.selectamount6 = false;
    //   this.selectamount7 = true;
    //   this.selectamount8 = false;
    //   this.selectamount9 = false;
    //   this.selectamount10 = false;
    // }
    // if (val == '8lakh') {
    //   this.amountSelected = "8";
    //   this.selectamount1 = false;
    //   this.selectamount2 = false;
    //   this.selectamount3 = false;
    //   this.selectamount4 = false;
    //   this.selectamount5 = false;
    //   this.selectamount6 = false;
    //   this.selectamount7 = false;
    //   this.selectamount8 = true;
    //   this.selectamount9 = false;
    //   this.selectamount10 = false;
    // }
    // if (val == '9lakh') {
    //   this.amountSelected = "9";
    //   this.selectamount1 = false;
    //   this.selectamount2 = false;
    //   this.selectamount3 = false;
    //   this.selectamount4 = false;
    //   this.selectamount5 = false;
    //   this.selectamount6 = false;
    //   this.selectamount7 = false;
    //   this.selectamount8 = false;
    //   this.selectamount9 = true;
    //   this.selectamount10 = false;
    // }
    // if (val == '10lakh') {
    //   this.amountSelected = "10";
    //   this.selectamount1 = false;
    //   this.selectamount2 = false;
    //   this.selectamount3 = false;
    //   this.selectamount4 = false;
    //   this.selectamount5 = false;
    //   this.selectamount6 = false;
    //   this.selectamount7 = false;
    //   this.selectamount8 = false;
    //   this.selectamount9 = false;
    //   this.selectamount10 = true;
    // }
  }
  activeTab: number = 1;
  activeTabs: number = 5;
  activeTabs1: number = 1;
  activeTab3: boolean = false;
  healthinsurancepolicyindividual: boolean = false;
  healthinsurancepolicyfamily: boolean = false;
  isWhoInsure: boolean = true;
  isAge: boolean = false;
  isLocation: boolean = false;
  isDetails: boolean = false;
  isWhoInsureFilled: boolean = false;
  isAgeFilled: boolean = false;
  isLocationFilled: boolean = false;
  isDetailsFilled: boolean = false;
  isIllness: boolean = false;
  isViewPlanList: boolean = false;
  healthForm1: boolean = true;
  selectCoverageplan: boolean = true;
  insuranceTab(val: any) {
    if (val == 'individual') {
      this.healthinsurancepolicyindividual = true;
    }
    if (val == 'family') {
      this.healthinsurancepolicyfamily = true;
      this.ClearAll();
    }
  }
  closeModal() {
    this.healthinsurancepolicyindividual = false;
    this.healthinsurancepolicyfamily = false;
    this.isTabs = "1",
      this.isFamilyTab = "1";
    this.firstDaughter = false;
    this.firstSon = false;
    this.ExistingCount = 0;
    this.ClearAll();
    // location.reload();

  }
  couter: number = 3;
  counterValue: number = 0;
  doughtercounterValue: number = 0;
  counterFunction(val: any) {
    // ;
    if (val == 'decrement') {
      this.counterValue--;
      if (this.counterValue <= 0) {
        this.selectCard3 = false;
        this.isSonsDiv = false;
        this.counterValue = 0;

        this.ExistingCount--;
        this.dropdownNumber--;

        this.selectedMembers--;
        // this.ageCount--;
        if (this.indx == 4 && this.evnt != '')
          this.ageCount--;
        if (this.indx == 5 && this.evnt != '')
          this.ageCount--;
        if (this.indx == 6 && this.evnt != '')
          this.ageCount--;



      }
    }
    if (val == 'increment') {
      if (this.counterValue <= 4) {
        if (this.counterValue <= (this.couter - this.doughtercounterValue)) {
          this.counterValue++;
          this.ExistingCount++;
          this.dropdownNumber++;

          this.selectedMembers++;

        }


      }
      // if (this.isDaughtersDiv == true && (this.counterValue + this.doughtercounterValue >= 4)) {
      //   this.isDaughtersDiv = false;
      //   this.selectCard4 = false;
      //   this.doughtercounterValue = 0;
      //   this.age4 = false;
      //   this.age16 = false;
      //   this.age17 = false;
      //   this.age18 = false;
      //   if (this.counterValue <= 4) {
      //     this.counterValue++;
      //   }

      // }

      // else if (this.isDaughtersDiv == true && (this.counterValue + this.doughtercounterValue < 4) ||
      //   (this.isDaughtersDiv == false && this.counterValue < 4)) {
      //   this.counterValue++;
      // }

      // else
      // {
      //   this.counterValue++;
      // } 
      //  if(this.counterValue<=4){
      //   if (this.counterValue <= (this.couter - this.doughtercounterValue)) {
      //     this.counterValue++;
      //   }}


    }

    if (this.counterValue == 0) {

      this.age3 = false;
      this.age13 = false;
      this.age14 = false;
      this.age15 = false;
      this.firstSon = false;
      this.secondSon = false;
      this.thirdSon = false;
      this.fourthSon = false;
    }
    if (this.counterValue == 1) {

      this.age3 = true;
      this.age13 = false;
      this.age14 = false;
      this.age15 = false;

      this.firstSon = true;
      this.secondSon = false;
      this.thirdSon = false;
      this.fourthSon = false;
    }

    if (this.counterValue == 2) {
      this.age3 = true;
      this.age13 = true;
      this.age14 = false;
      this.age15 = false;

      this.firstSon = true;
      this.secondSon = true;
      this.thirdSon = false;
      this.fourthSon = false;
    }

    if (this.counterValue == 3) {
      this.age3 = true;
      this.age13 = true;
      this.age14 = true;
      this.age15 = false;
      this.firstSon = true;
      this.secondSon = true;
      this.thirdSon = true;
      this.fourthSon = false;
    }

    if (this.counterValue == 4) {
      this.age3 = true;
      this.age13 = true;
      this.age14 = true;
      this.age15 = true;
      this.firstSon = true;
      this.secondSon = true;
      this.thirdSon = true;
      this.fourthSon = true;

    }
    this.totalCounterValueFunction();
  }

  counterFunctionDoughter(value: any) {
    if (value == 'decrement') {
      this.doughtercounterValue--;
      if (this.doughtercounterValue <= 0) {
        this.selectCard4 = false;
        this.doughtercounterValue = 0;
        this.isDaughtersDiv = false;
        this.ExistingCount--;
        this.dropdownNumber--;

        this.selectedMembers--;
        // this.ageCount--;
        if (this.indx == 8 && this.evnt != '')
          this.ageCount--;
        if (this.indx == 9 && this.evnt != '')
          this.ageCount--;
        if (this.indx == 10 && this.evnt != '')
          this.ageCount--;

      }

    }
    if (value == 'increment') {
      if (this.doughtercounterValue <= 4) {
        if (this.doughtercounterValue <= (this.couter - this.counterValue)) {
          this.doughtercounterValue++;
          this.ExistingCount++;
          this.dropdownNumber++;

          this.selectedMembers++;

        }


      }
    }
    // if(value == 'increment') {
    //   if(this.isSonsDiv == true && (this.counterValue + this.doughtercounterValue >= 4))
    //   {
    //       this.isSonsDiv = false;
    //       this.selectCard3 = false;
    //       this.counterValue = 0;
    //       this.age3=false;
    //       this.age13=false;
    //       this.age14=false;
    //       this.age15=false;
    //       if(this.doughtercounterValue < 4)
    //       {
    //         this.doughtercounterValue++;
    //       }
    //   } else if(this.isSonsDiv == true && (this.counterValue + this.doughtercounterValue < 4) ||
    //     (this.isSonsDiv == false && this.doughtercounterValue < 4))
    //   {
    //       this.doughtercounterValue++;
    //   } 
    // }
    if (this.doughtercounterValue == 0) {
      this.age4 = false;
      this.age16 = false;
      this.age17 = false;
      this.age18 = false;
      this.firstDaughter = false;
      this.secondDaughter = false;
      this.thirdDaughter = false;
      this.fourthDaughter = false;

    }
    if (this.doughtercounterValue == 1) {
      this.age4 = true;
      this.age16 = false;
      this.age17 = false;
      this.age18 = false;

      this.firstDaughter = true;
      this.secondDaughter = false;
      this.thirdDaughter = false;
      this.fourthDaughter = false;

    }
    if (this.doughtercounterValue == 2) {
      this.age4 = true;
      this.age16 = true;
      this.age17 = false;
      this.age18 = false;

      this.firstDaughter = true;
      this.secondDaughter = true;
      this.thirdDaughter = false;
      this.fourthDaughter = false;

    }

    if (this.doughtercounterValue == 3) {
      this.age4 = true;
      this.age16 = true;
      this.age17 = true;
      this.age18 = false;

      this.firstDaughter = true;
      this.secondDaughter = true;
      this.thirdDaughter = true;
      this.fourthDaughter = false;

    }

    if (this.doughtercounterValue == 4) {
      this.age4 = true;
      this.age16 = true;
      this.age17 = true;
      this.age18 = true;

      this.firstDaughter = true;
      this.secondDaughter = true;
      this.thirdDaughter = true;
      this.fourthDaughter = true;

    }
    // if(this.doughtercounterValue >= 4) {
    //   this.isSonsDiv = false;
    //   this.selectCard3 = false;
    // } else {
    //   // this.isSonsDiv = true;
    //   // this.selectCard3 = true;
    // }
    this.totalCounterValueFunction();
  }
  totalCounterValue: number = 0;
  totalCounterValueFunction() {
    this.sonList = [];
    this.daughterList = [];
    this.totalCounterValue = this.counterValue + this.doughtercounterValue;
    if (this.totalCounterValue > 4) {

    }
    for (var i = 0, len = this.counterValue; i < len; i++) {
      this.sonList.push(i);
    }
    for (var J = 0, len = this.doughtercounterValue; J < len; J++) {
      this.daughterList.push(J);
    }
  }
  activeMenu = 0;
  continue() {
    if (this.selectedTab < 7) {

      this.selectedTab = this.selectedTab + 1;
      console.log('enter')
      console.log(this.selectedTab)
    }

    if (this.activeMenu == 0) {
      this.isAge = true;
      this.isWhoInsureFilled = true;
    }
    else if (this.activeMenu == 1) {
      this.isLocation = true;
      this.isAgeFilled = true;
    }
    else if (this.activeMenu == 2) {
      this.isDetails = true;
      this.isLocationFilled = true;
    }
    this.activeMenu++;
  }
  back() {
    if (this.selectedTab > 1) {
      this.selectedTab = this.selectedTab - 1;
    }
  }

  viewIllness() {
    this.isIllness = !this.isIllness;
    this.healthForm1 = !this.healthForm1
  }

  viewPlanList() {
    this.isViewPlanList = !this.isViewPlanList;
  }
  isFamilyTab: string = '1'
  FamilyNextTabs(val: string) {
    debugger;
    this.isFamilyTab = val;
    if (this.isFamilyTab == '1') {
      this.FamilybtnFirst = true;
      this.FamilybtnSecond = false;
      this.FamilybtnThird = false;
      this.FamilybtnFourth = false;;
      this.FamilyVarifyBtn = false;
    }
    else if (this.isFamilyTab == '2') {
      this.FamilybtnFirst = false;
      this.FamilybtnSecond = true;
      this.FamilybtnThird = false;
      this.FamilybtnFourth = false;
      this.FamilyVarifyBtn = false;
    }
    else if (this.isFamilyTab == '3') {
      this.FamilybtnFirst = false;
      this.FamilybtnSecond = false;
      this.FamilybtnThird = true;
      this.FamilybtnFourth = false;
      this.FamilyVarifyBtn = false;
    }
    else if (this.isFamilyTab == '4') {
      this.FamilybtnFirst = false;
      this.FamilybtnSecond = false;
      this.FamilybtnThird = false;
      this.FamilybtnFourth = true;
      this.FamilyVarifyBtn = false;

      if (this.Famillnesss == 'no') {
        this.isFamilyTab = '5'
        this.NoDiseaseFam = false;
        this.FamilybtnFourth = false;
        this.FamilyVarifyBtn = true;
        // this.FamilybtnFirst = false;
        // this.FamilybtnSecond = false;
        // this.FamilybtnThird = false;
        // this.FamilybtnFourth = true;
        // this.FamilyVarifyBtn = false;
      }
      else if (this.Famillnesss == 'yes') {
        this.NoDiseaseFam = true;
        // this.FamilybtnFirst = false;
        // this.FamilybtnSecond = false;
        // this.FamilybtnThird = true;
        // this.FamilybtnFourth = false;
        // this.FamilyVarifyBtn = false;
      }
    }
    else if (this.isFamilyTab == '5') {
      this.FamilybtnFirst = false;
      this.FamilybtnSecond = false;
      this.FamilybtnThird = false;
      this.FamilybtnFourth = false;
      this.FamilyVarifyBtn = true;
    }

  }
  isTabs: string = '1';
  IndividualNextTabs(val: string) {
    this.isTabs = val;
    if (this.isTabs == '1') {
      this.btnFirst = true;
      this.btnSecond = false;
      this.btnThird = false;
      this.btnFourth = false;;
      this.VarifyBtn = false;
    }

    if (this.isTabs == '2') {
      this.btnFirst = false;
      this.btnSecond = true;
      this.btnThird = false;
      this.btnFourth = false;
      this.VarifyBtn = false;
    }

    if (this.isTabs == '3') {
      this.btnFirst = false;
      this.btnSecond = false;
      this.btnThird = true;
      this.btnFourth = false;
      this.VarifyBtn = false;
    }

    if (this.isTabs == '4') {
      this.btnFirst = false;
      this.btnSecond = false;
      this.btnThird = false;
      this.btnFourth = true;
      this.VarifyBtn = false;



    }

    if (this.isTabs == '5') {
      this.btnFirst = false;
      this.btnSecond = false;
      this.btnThird = false;
      this.btnFourth = false;
      this.VarifyBtn = true;
    }
  }
  isDone: boolean = true;
  isDone1: boolean = false;
  illnesss: string = "";
  NoDisease: boolean = true;
  Famillnesss: string = "no";
  goTo(val: string) {
    this.illnesss = val;
    if (this.illnesss == 'no') {
      this.NoDisease = false;
      this.isDone = false;
      this.btnFirst = false;
      this.btnSecond = false;
      this.btnThird = false;
      this.btnFourth = true;
    }
    if (this.illnesss == 'yes') {
      this.NoDisease = true;
      this.isDone = false;
      this.btnFirst = false;
      this.btnSecond = false;
      this.btnThird = true;
      this.btnFourth = false;
      this.VarifyBtn = false;
    }

  }
  NoDiseaseFam: boolean = false
  gotoFam(val: string) {
    this.Famillnesss = val;
    this.isDone1 = false;
    if (this.Famillnesss == 'no') {
      this.NoDiseaseFam = false;
      this.FamilybtnFirst = false;
      this.FamilybtnSecond = false;
      this.FamilybtnThird = false;
      this.FamilybtnFourth = true;
      this.FamilyVarifyBtn = false;
    }
    if (this.Famillnesss == 'yes') {
      this.NoDiseaseFam = true;
      this.FamilybtnFirst = false;
      this.FamilybtnSecond = false;
      this.FamilybtnThird = true;
      this.FamilybtnFourth = false;
      this.FamilyVarifyBtn = false;
    }
  }

  GoBack() {
    if (this.illnesss == 'no') {
      this.isTabs = "3";
      this.btnFirst = false;
      this.btnSecond = false;
      this.btnThird = true;
      this.btnFourth = false;
      this.VarifyBtn = false;
    }

    if (this.illnesss == 'yes') {
      this.isTabs = "4";

      this.btnFirst = false;
      this.btnSecond = false;
      this.btnThird = false;
      this.btnFourth = true;
      this.VarifyBtn = false;

    }


    if (this.Famillnesss == 'no') {
      this.isFamilyTab = "3";

      this.FamilybtnFirst = false;
      this.FamilybtnSecond = false;
      this.FamilybtnThird = true;
      this.FamilybtnFourth = false;
      this.FamilyVarifyBtn = false;
    }

    if (this.Famillnesss == 'yes') {

      this.isFamilyTab = "4";
      this.FamilybtnFirst = false;
      this.FamilybtnSecond = false;
      this.FamilybtnThird = false;
      this.FamilybtnFourth = true;
      this.FamilyVarifyBtn = false;
    }
    this.VarifyBtn = false;
  }
  // inlless()
  // {
  //   this.isBtn3=false;
  // }
  more$Less() {
    this.isOther = !this.isOther;
    this.selectCard7 = !this.selectCard7;
    this.isOthersDiv = !this.isOthersDiv;
  }




  showw: boolean = false;
  famShoww: boolean = false;

  show() {
    this.showw = true;
    this.famShoww = !this.famShoww;
  }
  ClearAll() {
    this.amountSelected = "0";
    this.Famillnesss = "no";
    this.NoDiseaseFam = false;
    this.isFamBtn4 = true;
    this.pincode = "";
    this.mycity = "";
    this.isSelectedPersion = '';
    this.selectCard1 = false;
    this.selectCard2 = false;
    this.selectCard3 = false; ``
    this.selectCard4 = false;
    this.selectCard5 = false;
    this.selectCard6 = false;
    this.selectCard7 = false;
    this.selectCard8 = false;
    this.selectCard20 = false;
    this.selectCard21 = false;
    this.selectCard22 = false;
    this.selectCard23 = false;
    this.selectCard24 = false;
    this.selectCard25 = false;
    this.selectCard26 = false;
    this.selectCard27 = false;
    this.selectCard28 = false;
    this.selectCard29 = false;
    this.selectCard30 = false;
    this.selectCard31 = false;
    this.selectCard32 = false;
    this.selectCard33 = false;
    this.selectCard34 = false;
    this.btnFirst = true;
    this.btnSecond = false;
    this.btnThird = false;
    this.btnFourth = false;
    this.btnFive = false;
    this.FamilybtnFirst = true;
    this.FamilybtnSecond = false;
    this.FamilybtnThird = false;
    this.FamilybtnFourth = false;
    this.FamilybtnFive = false;
    this.VarifyBtn = false;
    this.FamilyVarifyBtn = false;
    this.selectamount1 = false;
    this.selectamount2 = false;
    this.selectamount3 = false;
    this.selectamount4 = false;
    this.selectamount5 = false;
    this.selectamount6 = false;
    this.selectamount7 = false;
    this.selectamount8 = false;
    this.selectamount9 = false;
    this.selectamount10 = false;
    this.age1 = false;
    this.age2 = false;
    this.age3 = false;
    this.age4 = false;
    this.age5 = false;
    this.age6 = false;
    this.age7 = false;
    this.age8 = false;
    this.age9 = false;
    this.age10 = false;
    this.age11 = false;
    this.age12 = false;
    this.age13 = false;
    this.age14 = false;
    this.age15 = false;
    this.age16 = false;
    this.age17 = false;
    this.age18 = false;
    this.age19 = false;
    this.age20 = false;
    this.isBtn1 = true;
    this.isBtn2 = true;
    this.isBtn3 = true;
    this.isBtn4 = true;
    this.isFamBtn1 = true;
    this.isFamBtn2 = true;
    this.isFamBtn3 = true;
    this.isFamBtn4 = true;
    this.isGenderDiv = false;
    this.isSonsDiv = false;
    this.isDaughtersDiv = false;
    this.mother = true;
    this.father = true;
    this.fatherInLaw = true;
    this.motherLaw = true;
    this.isSelf = false;
    this.isSpouse = false;
    this.isHusband = false;
    this.isSon = false;
    this.isDaughter = false;
    this.isFather = false;
    this.isMother = false;
    this.isgrandFather = false;
    this.isgrandMother = false;
    this.isfatherInLaw = false;
    this.ismotherInLaw = false;
    this.isUncle = false;
    this.isAunt = false;

    this.firstSon= false;
    this.secondSon= false;
    this.thirdSon= false;
    this.fourthSon= false;
  
    this.firstDaughter= false;
    this.secondDaughter= false;
    this.thirdDaughter= false;
    this.fourthDaughter= false;

    this.selfAge1 = "";
    this.spouseAge1 = "";
    this.fatherAge1 = "";
    this.motherAge1 = "";
    this.fatherLawAge1 = "";
    this.motherLawAge1 = "";
    this.grandpaAge1 = "";
    this.grandmaAge1 = "";
    this.uncleAge1 = "";
    this.auntAge1 = "";

    this.selectedSonAge1 = "";
    this.selectedSonAge2 = "";
    this.selectedSonAge3 = "";
    this.selectedSonAge4 = "";
    this.selectedSonAge5 = "";
    this.selectedSonAge6 = "";

    this.selectedDauAge1 = "";
    this.selectedDauAge2 = "";
    this.selectedDauAge3 = "";
    this.selectedDauAge4 = "";
    this.selectedDauAge5 = "";
    this.selectedDauAge6 = "";

    this.ageCount = 0;
    this.doughtercounterValue = 0;
    this.counterValue = 0;
  }
  onInput(event: any): void {
    const initialValue = event.target.value;
    // Strictly replace anything not a digit.
    event.target.value = initialValue.replace(/[^0-9]/g, '');
    if (initialValue !== event.target.value) {
      event.stopPropagation();
    }
  }

  validateNumberInput(event: KeyboardEvent): void {
    // Allow: backspace, delete, tab, escape, enter, and numbers from the numpad and numbers row
    if ([46, 8, 9, 27, 13].indexOf(event.keyCode) !== -1 ||
      // Allow: Ctrl/cmd+A
      (event.keyCode === 65 && (event.ctrlKey === true || event.metaKey === true)) ||
      // Allow: Ctrl/cmd+C
      (event.keyCode === 67 && (event.ctrlKey === true || event.metaKey === true)) ||
      // Allow: Ctrl/cmd+X
      (event.keyCode === 88 && (event.ctrlKey === true || event.metaKey === true)) ||
      // Allow: home, end, left, right
      (event.keyCode >= 35 && event.keyCode <= 39)) {
      // let it happen, don't do anything
      return;
    }
    // Ensure that it is a number and stop the keypress if not
    if ((event.shiftKey || (event.keyCode < 48 || event.keyCode > 57)) && (event.keyCode < 96 || event.keyCode > 105)) {
      event.preventDefault();
    }
  }
  InllesVal: string = "";
  InllesId: string = "";
  InllesButton: boolean = true;
  existing_illnesss(data: Item[]) {
    this.InllesVal = data.map((m: Item) => { return m.name }).toString();
    this.InllesId = data.map((m: Item) => { return m.id }).toString();
    if (this.InllesVal.length > 0) {
      this.InllesButton = false;
    }
    else {
      this.InllesButton = true;
    }
  }
  val: string
  FamillnesssVaidation(item: Item[]) {

    if (this.isSelf) {
      this.val = item.map((m: Item) => { return m.id }).toString();
      if (this.val.length > 0) {
        this.familyValidationButton = false;

      }
      else {
        this.familyValidationButton = true;

      }
    }
  }

  nIndex: number = 1;
  dropdownInteracted: boolean[] = new Array().fill(false);
  selectedItems: any[] = [];
  familyValidationButton: boolean = true;
  num: number = 0;
  onMultiSelectChange(index: number, selectItems: Item[]): void {

    this.dropdownInteracted[index] = true;
    this.selectedItems[index] = selectItems;
    console.log('Dropdown Interacted:', this.dropdownInteracted, selectItems);

    // Check the value of allDropdownsInteracted
    let allDropdownsInteracted = this.dropdownInteracted.every(interacted => interacted);

    if (selectItems.length <= 0) {
      this.num--;
      this.familyValidationButton = true;
    }
    else {
      this.num++;
    }
    console.log(this.dropdownNumber, this.num);
    if (this.num == this.dropdownNumber) {
      this.familyValidationButton = false;
    }

  }
  ageCount: number = 0;
  selectedAge: string;
  MemberAges: boolean = false;
  selfA: boolean = false;
  spouseA: boolean = false;
  son1A: boolean = false;
  son2A: boolean = false;
  son3A: boolean = false;
  son4A: boolean = false;
  dau1A: boolean = false;
  dau2A: boolean = false;
  dau3A: boolean = false;
  dau4A: boolean = false;
  fatherA: boolean = false;
  motherA: boolean = false;
  grandMotherA: boolean = false;
  grandFatherA: boolean = false;
  fatherLowA: boolean = false;
  motherLowA: boolean = false;
  unkalA: boolean = false;
  auntA: boolean = false;
  indx: number;
  evnt: any;
  check(index: any, event: any) {
    this.indx = index;
    this.evnt = event.value;
    if (index == 1 && !this.selfA && event.value != '' && event.value != 'Age' && this.age1) {
      this.ageCount++;
      this.selfA = true;
    }
    if (index == 2 && !this.spouseA && event.value != '' && event.value != 'Age' && this.age2) {
      this.ageCount++;
      this.spouseA = true;
    }
    if (index == 3 && !this.son1A && event.value != '' && event.value != 'Age' && this.age3) {
      this.ageCount++;
      this.son1A = true;
    }
    if (index == 4 && !this.son2A && event.value != '' && event.value != 'Age' && this.age13) {
      this.ageCount++;
      this.son2A = true;
    }
    if (index == 5 && !this.son3A && event.value != '' && event.value != 'Age' && this.age14) {
      this.ageCount++;
      this.son3A = true;
    }
    if (index == 6 && !this.son4A && event.value != '' && event.value != 'Age' && this.age15) {
      this.ageCount++;
      this.son4A = true;
    }
    if (index == 7 && !this.dau1A && event.value != '' && event.value != 'Age' && this.age4) {
      this.ageCount++;
      this.dau1A = true;
    }
    if (index == 8 && !this.dau2A && event.value != '' && event.value != 'Age' && this.age16) {
      this.ageCount++;
      this.dau2A = true;
    }
    if (index == 9 && !this.dau3A && event.value != '' && event.value != 'Age' && this.age17) {
      this.ageCount++;
      this.dau3A = true;
    }
    if (index == 10 && !this.dau4A && event.value != '' && event.value != 'Age' && this.age18) {
      this.ageCount++;
      this.dau4A = true;
    }
    if (index == 11 && !this.fatherA && event.value != '' && event.value != 'Age' && this.age5) {
      this.ageCount++;
      this.fatherA = true;
    }
    if (index == 12 && !this.motherA && event.value != '' && event.value != 'Age' && this.age6) {
      this.ageCount++;
      this.motherA = true;
    }
    if (index == 13 && !this.grandMotherA && event.value != '' && event.value != 'Age' && this.age7) {
      this.ageCount++;
      this.grandMotherA = true;
    }
    if (index == 14 && !this.grandFatherA && event.value != '' && event.value != 'Age' && this.age8) {
      this.ageCount++;
      this.grandFatherA = true;
    }

    if (index == 15 && !this.fatherLowA && event.value != '' && event.value != 'Age' && this.age9) {
      this.ageCount++;
      this.fatherLowA = true;
    }
    if (index == 16 && !this.motherLowA && event.value != '' && event.value != 'Age' && this.age10) {
      this.ageCount++;
      this.motherLowA = true;
    }
    if (index == 17 && !this.unkalA && event.value != '' && event.value != 'Age' && this.age11) {
      this.ageCount++;
      this.unkalA = true;
    }
    if (index == 18 && !this.auntA && event.value != '' && event.value != 'Age' && this.age12) {
      this.ageCount++;
      this.auntA = true;
    }
    console.log(this.selectedMembers, this.ageCount);

  }
  ageValidation(): boolean {
    if(this.isSelf && this.selfAge1 == "")
      {
        return true;
      }
    if(this.isSpouse && this.spouseAge1 == "")
      {
        return true;
      }
    if(this.firstSon && this.selectedSonAge1 == "")
      {
        return true;
      }
    if(this.secondSon && this.selectedSonAge2 == "")
      {
        return true;
      }
    if(this.thirdSon && this.selectedSonAge3 == "")
      {
        return true;
      }
    if(this.fourthSon && this.selectedSonAge4 == "")
      {
        return true;
      }  
    if(this.firstDaughter && this.selectedDauAge1 == "")
      {
        return true;
      }
    if(this.secondDaughter && this.selectedDauAge2 == "")
      {
        return true;
      }
    if(this.thirdDaughter && this.selectedDauAge3 == "")
      {
        return true;
      }
    if(this.fourthDaughter && this.selectedDauAge4 == "")
      {
        return true;
      } 
    if(this.isFather && this.fatherAge1 == "")
      {
        return true;
      } 
    if(this.isMother && this.motherAge1 == "")
      {
        return true;
      } 
    if(this.isgrandFather && this.grandpaAge1 == "")
      {
        return true;
      }  
    if(this.isgrandMother && this.grandmaAge1 == "")
      {
        return true;
      }  
    if(this.isfatherInLaw && this.fatherLawAge1 == "")
      {
        return true;
      }  
    if(this.ismotherInLaw && this.motherLawAge1 == "")
      {
        return true;
      } 
    if(this.isUncle && this.uncleAge1 == "")
      {
        return true;
      }
    if(this.isAunt && this.auntAge1 == "")
      {
        return true;
      }               
    return false;
  }

  async GetHealthSumInsuredRanges(){
    const res = await this.lookUpService.GetHealthSumInsuredRanges();
    if (res != null && res.result) {
      this.SumInsuredList = res.result;
    }
  }
}
